import React, { useCallback, useEffect, useState } from 'react'
import { formatCurrency, isValidEmail } from '../../util/string'
import {
  Container,
  Row,
  Col,
  Button,
  //Card,
  //CardBody,
  //Input,
  //Dropdown,
  // DropdownToggle,
  // DropdownItem,
  // DropdownMenu,
  FormGroup,
  Label
} from 'reactstrap'
import { X } from 'react-feather'
import ErrorImage from '../../assets/images/eventozz/checkout/erro.svg'
import SuccessImage from '../../assets/images/eventozz/checkout/success.svg'
import { toast } from 'react-toastify'
import { Loading } from '../../components/Loading'
import styles from './styles.module.scss'
import eventzz from '../../services/eventzz'
import { isValidCpf, onlyUnsignedNumbers } from 'util/function'
import pdv from '../../services/pdv'
import { CardTicket } from 'components/cardTicket'
import { InputsTicketInfo } from 'components/inputsTicketInfo'
import Select from 'react-select'
import { debounce } from 'lodash'
import { CurrencyInput } from 'components/CurrencyInput'
import customer from 'services/customer'

export const PdvTicketsContainer = ({ eventId, organizerId }) => {
  const [loading, setLoading] = useState(false)
  const [tickets, setTickets] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [cartId, setCartId] = useState(null)
  const [showInputErros, setShowInputErros] = useState(false)
  const [activeStep, setActiveStep] = useState({
    step: 0,
    description: 'Choose tickets'
  })
  const [ticketsData, setTicketsData] = useState({})
  const [showSuccessFeedback, setShowSuccessFeedback] = useState(true)
  const [showErrorFeedback, setShowErrorFeedback] = useState(false)
  const [paymentFeedback, setPaymentFeedback] = useState({
    title: '',
    message: '',
    image: null,
    qrCodeLink: ''
  })
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [paymentOption, setPaymentOption] = useState(null)
  const [installmentsNumber, setIntallmentNumber] = useState(null)
  const [paymentOptions, setPaymentOptions] = useState([])
  const [finalPrice, setFinalPrice] = useState(null)

  const { getTickets } = eventzz
  const { cartSave, pdvSave } = pdv
  const { checkPhoneIsWhatsApp } = customer

  const loadTicketTypes = useCallback(async () => {
    if (!eventId) {
      setTickets([])
      toast.warning(
        'Selecione um evento para buscar os ingressos disponíveis',
        {
          autoClose: 10000
        }
      )
      setLoading(false)
      return
    }
    try {
      const result = await getTickets(eventId)
      if (result.status == 200) {
        let ticketTypes = result?.data?.data
        console.log(ticketTypes)
        let ticketsTemp = []
        ticketsTemp = ticketTypes.map((a) => ({
          ...a,
          id: a.id,
          eventId: a.id_evento,
          status: a.status,
          name: a.nome,
          description: a.descricao,
          price: a.valor,
          availableQtd: a.qtd_disponivel,
          totalQtd: a.qtd_total,
          promotionalQtd: a.qtd_disponivel,
          validTo: a.valido_ate,
          validFrom: a.valido_de,
          promotionalPrice: a.valor_promocional,
          updatedAt: a.updated_at,
          createdAt: a.created_at,
          currentQtd: 0,
          activeValue: a.valor
        }))
        setTickets(ticketsTemp)
        console.log(ticketsTemp)
      } else {
        console.log(result)
        setTickets([])
      }
    } catch (error) {
      console.log(error)
      setTickets([])
    }
  }, [eventId])

  const deboundChange = useCallback(debounce(saveToCart, 1000), [
    cartId,
    eventId
  ])

  const handleChangeTicketQuantity = async (option, ticketId) => {
    console.log(option, ticketId)

    let errorStatus = {
      negative: false,
      maxQuantity: false
    }

    const newTickets = await Promise.all(
      tickets.map((a, i) => {
        let activeValue = a.price
        if (a.id === ticketId) {
          if (option === 'plus' && a.currentQtd + 1 > a.availableQtd) {
            errorStatus.maxQuantity = true
          }
          if (option === 'minus' && a.currentQtd - 1 < 0) {
            errorStatus.negative = true
          }

          if (option === 'plus') {
            activeValue =
              a.promotionalQtd > 0 && a.currentQtd + 1 >= a.promotionalQtd
                ? a.promotionalPrice
                : a.price
          }
          if (option === 'minus') {
            activeValue =
              a.promotionalQtd > 0 && a.currentQtd - 1 >= a.promotionalQtd
                ? a.promotionalPrice
                : a.price
          }
          return {
            ...a,
            activeValue,
            currentQtd: option === 'minus' ? a.currentQtd - 1 : a.currentQtd + 1
          }
        } else {
          return {
            ...a
          }
        }
      })
    )

    if (errorStatus.negative) return

    if (errorStatus.maxQuantity)
      return toast.info('A quantidade informada não está mais disponível')

    console.log('newTickets -> ', newTickets)

    setTickets(newTickets)
    deboundChange(newTickets)
  }

  const handleClose = () => {
    setLoading(false)
    setActiveStep({
      step: 0,
      description: 'Choose tickets'
    })
  }

  const handleNextStep = async () => {
    console.log(tickets)
    const checkHasTickets = tickets?.find((item) => item.currentQtd > 0)
    if (!checkHasTickets) {
      return toast.error('Você precisa adicionar pelo menos 1 ingresso')
    }

    if (!paymentMethod) {
      return toast.error('Você precisa selecionar a forma de pagamento')
    }

    generateInputTicketsData()
    console.log(tickets)
    setActiveStep({
      step: 1,
      description: 'fill in ticket details'
    })
  }

  async function saveToCart(cartTickets) {
    try {
      if (cartTickets?.length > 0) {
        setLoading(true)
        const result = await cartSave({ cart: cartTickets, cartId, eventId })
        setLoading(false)
        if (result.status == 200) {
          console.log(result.data.paymentInfo?.paymentOptions)
          if (result.data.paymentInfo?.paymentOptions) {
            setPaymentOptions(result.data.paymentInfo.paymentOptions)
          }
          toast.success('Ingressos adicionados ao carrinho', {
            autoClose: 2000
          })
          setCartId(result.data.cartId)
          return true
        } else {
          toast.error('Error ao adicionar ao carrinho', {
            autoClose: 2000
          })
          return false
        }
      } else {
        toast.error('Você precisa adicionar pelo menos 1 ingresso', {
          autoClose: 2000
        })
        return false
      }
    } catch (error) {
      console.log(error)
      toast.error('Error ao adicionar ao carrinho', {
        autoClose: 2000
      })
      return false
    }
  }

  const handleBack = () => {
    setActiveStep({
      step: 0,
      description: 'Choose tickets'
    })
  }

  const handleSubmit = async () => {
    let showErrorTemp = false
    Object.values(ticketsData).map((ticketType) => {
      ticketType.map((ticket) => {
        if (
          ticket.name.length < 2 ||
          (ticket.cpf !== '' && !isValidCpf(ticket.cpf)) ||
          ticket.phone.length < 10 ||
          (ticket.email !== '' && !isValidEmail(ticket.email)) ||
          !ticket.isValidPhoneNumber ||
          (organizerId === 22 &&
            (!ticket.position || ticket.position?.length < 2)) ||
          (organizerId === 22 &&
            (!ticket.companyName || ticket.companyName?.length < 2)) ||
          (organizerId === 22 && !ticket.shirtSize)
        ) {
          showErrorTemp = true
        }
      })
    })
    if (showErrorTemp) {
      setShowInputErros(true)
      return toast.error('Preencha todos os campos para cada ingresso')
    }
    setShowInputErros(false)

    if (!eventId) {
      return toast.error('Não conseguimos identificar o evento!')
    }
    setLoading(true)

    const ticketsDataTemp = []
    Object.values(ticketsData).map((ticketType) => {
      if (ticketType?.length > 0) {
        ticketType.map((ticket) => {
          let vector = {
            description: ticket?.description,
            idIngresso: ticket?.idIngresso,
            name: ticket?.name,
            cpf: ticket?.cpf,
            phone: ticket?.phone,
            email: ticket?.email,
            extraFields: ''
          }
          console.log(organizerId)

          if (organizerId === 22) {
            const extraFields = {
              position: ticket?.position,
              companyName: ticket?.companyName,
              shirtSize: ticket?.shirtSize
            }
            vector.extraFields = JSON.stringify(extraFields)
          }

          ticketsDataTemp.push(vector)
        })
      }
    })

    const ticketsQtd = tickets.map((item) => ({
      qtd: item.currentQtd,
      ticketId: item.id
    }))

    const body = {
      eventId,
      ticketsData: ticketsDataTemp,
      ticketsQtd,
      paymentMethod,
      cartId,
      totalAmount,
      installmentsNumber,
      customFinalValue: finalPrice
    }
    console.log(body)

    try {
      const response = await pdvSave(body)
      console.log(response)
      setLoading(false)
      if (response?.data?.status === 200) {
        toast.success(
          response?.data?.msg ? response.data.msg : 'Ingressos salvos'
        )
        setShowErrorFeedback(false)
        setShowSuccessFeedback(true)

        setActiveStep({
          step: 2,
          description: 'Feedback'
        })
      } else {
        toast.error(
          response?.data?.msg
            ? response.data.msg
            : 'Não foi possível salvar os ingressos'
        )
        setShowSuccessFeedback(false)
        setShowErrorFeedback(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const generateInputTicketsData = useCallback(async () => {
    // return;
    let ticketsDataTemp = {}
    await Promise.all(
      tickets.map((item) => {
        let ticketsDataItem = ticketsData[item.id] ? ticketsData[item.id] : []

        if (item.currentQtd === 0) {
          ticketsDataItem = []
        } else if (item.currentQtd < ticketsDataItem?.length) {
          ticketsDataItem = ticketsDataItem.filter(
            (a, i) => item.currentQtd > i
          )
        } else if (item.currentQtd > ticketsDataItem.length) {
          console.log(ticketsDataItem)
          let initialQuantity = item?.currentQtd - ticketsDataItem?.length
          for (let i = 0; i < initialQuantity; i++) {
            ticketsDataItem.push({
              description: `${i + 1}º - ${item.name}`,
              idIngresso: item.id,
              name: '',
              cpf: '',
              phone: '',
              email: '',
              isValidPhoneNumber: false,
              isCheckingPhoneNumber: false
            })
          }
        }
        ticketsDataTemp[item.id] = ticketsDataItem
      })
    )
    console.log(ticketsDataTemp)

    setTicketsData(ticketsDataTemp)
    console.log(ticketsDataTemp)
  }, [tickets, ticketsData])

  const handleChangeTicketData = async ({
    value,
    field,
    ticketsDataIndex,
    ticketIndex
  }) => {
    console.log(value)
    console.log(Object.values(ticketsData))
    let newTicketsData = {}

    let isCheckingPhoneNumber = false

    if (field === 'phone') {
      if (onlyUnsignedNumbers(value)?.length > 11) {
        return
      } else if (onlyUnsignedNumbers(value)?.length === 11) {
        isCheckingPhoneNumber = true
      } else {
        isCheckingPhoneNumber = false
      }
    }

    Object.values(ticketsData).map((ticketType, index) => {
      console.log(ticketType)

      const idIngresso = ticketType[0]?.idIngresso
      console.log(idIngresso)
      if (!idIngresso) {
        return
      }

      const newTicketData = ticketType.map((ticket, i) => {
        console.log(index, ticketsDataIndex, i, ticketIndex)
        if (index === ticketsDataIndex && i === ticketIndex) {
          return {
            ...ticket,
            isValidPhoneNumber: isCheckingPhoneNumber
              ? false
              : ticket.isValidPhoneNumber,
            isCheckingPhoneNumber,
            [field]: value
          }
        } else {
          return ticket
        }
      })
      newTicketsData[idIngresso] = newTicketData
    })
    console.log(newTicketsData)

    setTicketsData(newTicketsData)

    //checando se o número é válido
    if (isCheckingPhoneNumber) {
      const response = await checkPhoneIsWhatsApp(onlyUnsignedNumbers(value))
      console.log(response?.data?.data?.exists)
      // return;
      if (response.status === 200) {
        let newTicketsDataChecked = {}
        Object.values(newTicketsData).map((ticketType, index) => {
          const idIngresso = ticketType[0].idIngresso

          const newTicketData = ticketType.map((ticket, i) => {
            if (index === ticketsDataIndex && i === ticketIndex) {
              console.log(ticket)
              if (!response?.data?.data?.exists) {
                toast.error(
                  'O número de telefone informado não é um número de whatsapp válido'
                )
                return {
                  ...ticket,
                  isCheckingPhoneNumber: false
                }
              } else {
                return {
                  ...ticket,
                  isValidPhoneNumber: true,
                  isCheckingPhoneNumber: false
                }
              }
            } else {
              return ticket
            }
          })
          newTicketsDataChecked[idIngresso] = newTicketData
        })
        setTicketsData(newTicketsDataChecked)
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao tentar verificar se o telefone possui WhatsApp'
        )
        return false
      }
    }
  }

  const handleTryAgain = () => {
    setShowErrorFeedback(false)
    setShowSuccessFeedback(false)
    setActiveStep({
      step: 1,
      description: 'fill in ticket details'
    })
  }

  const handleCloseSuccess = () => {
    setTotalAmount(0)
    setPaymentMethod(null)
    setPaymentOption(null)
    setPaymentOptions([])
    loadTicketTypes()
    setCartId(null)
    setTicketsData([])
    console.log('theeeere')
    setActiveStep({
      step: 0,
      description: 'Choose tickets'
    })
  }

  const handleChangePaymentOption = (value) => {
    console.log(value)
    console.log(paymentOptions)
    const totalAmount = paymentOptions.find(
      (item) => item.value === value
    )?.totalValue
    if (value === 'pix' || value === 'boleto') {
      setPaymentMethod(value)
      setIntallmentNumber(1)
      setPaymentOption(value)
    } else {
      let valueSplit = value.split('_')
      console.log(valueSplit)
      if (valueSplit?.length === 2) {
        setPaymentMethod(valueSplit[0])
        setIntallmentNumber(Number(valueSplit[1]))
        setPaymentOption(value)
      } else {
        toast.error('Não foi possível selecionar a forma de pagamento')
      }
    }
    setTotalAmount(totalAmount ? totalAmount : 0)
  }

  const handleSetFinalPrice = (value) => {
    setFinalPrice(value)
  }

  const handleBlurFinalPrice = () => {
    console.log(paymentOptions)
    console.log(finalPrice)

    if (finalPrice && finalPrice > 0) {
      let paymentOptionsTemp = [...paymentOptions]
      paymentOptionsTemp = paymentOptionsTemp.filter(
        (item) => item.value !== 'boleto'
      )

      paymentOptionsTemp.unshift({
        installmentsNumber: 1,
        label: `Boleto - ${formatCurrency({ value: finalPrice })}`,
        paymentMethod: 'boleto',
        totalValue: finalPrice,
        value: 'boleto'
      })

      setPaymentOptions(paymentOptionsTemp)
    } else {
      let paymentOptionsTemp = [...paymentOptions]
      paymentOptionsTemp = paymentOptionsTemp.filter(
        (item) => item.value !== 'boleto'
      )

      if (paymentMethod === 'boleto' || paymentOption === 'boleto') {
        setPaymentOption(null)
        setPaymentOption(null)
      }
      setPaymentOptions(paymentOptionsTemp)
    }
  }

  useEffect(() => {
    loadTicketTypes()
  }, [eventId])

  return (
    <>
      <Loading open={loading} handleClose={handleClose} />

      <Container>
        <form className={styles.text}>
          {tickets?.length === 0 ? (
            <div className={styles.dataEmpty}>
              {!eventId
                ? 'Selecione um evento para realizar a venda'
                : 'Nenhum ingresso disponível'}
            </div>
          ) : (
            <>
              {activeStep.step === 0 && (
                <>
                  <Row
                    className={[
                      styles.dFlex,
                      styles.alignItems,
                      styles.justifyContent
                    ]}
                  >
                    {tickets.map((ticket, index) => {
                      return (
                        <CardTicket
                          key={ticket.id}
                          ticket={ticket}
                          handleChangeTicketQuantity={
                            handleChangeTicketQuantity
                          }
                        />
                      )
                    })}
                  </Row>
                  <Row>
                    {!loading && totalAmount ? (
                      <Row xs={12}>
                        <Col xs={12} sm={6} item className={styles.margin}>
                          <div>
                            <span>
                              {formatCurrency({ value: totalAmount })}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                    {!loading && paymentOptions?.length > 0 ? (
                      <Row>
                        {organizerId === 22 && (
                          <Row
                            xs={12}
                            className={[
                              styles.dFlex,
                              styles.justifyContent
                            ].join(' ')}
                          >
                            <Col xs={12} sm={6} item>
                              <CurrencyInput
                                variant="outlined"
                                label="Preço final boleto"
                                value={finalPrice}
                                handleChange={handleSetFinalPrice}
                                handleBlur={handleBlurFinalPrice}
                              />
                            </Col>
                          </Row>
                        )}
                        <Row
                          xs={12}
                          className={[
                            styles.dFlex,
                            styles.justifyContent,
                            styles.mt10
                          ].join(' ')}
                        >
                          <Col
                            xs={12}
                            sm={6}
                            className={styles.formSelect}
                            item
                          >
                            <FormGroup className={styles.formSelect}>
                              <Select
                                options={paymentOptions}
                                value={
                                  paymentOptions.find(
                                    (a) => a.value === paymentOption
                                  )
                                    ? paymentOptions.find(
                                        (a) => a.value === paymentOption
                                      )
                                    : null
                                }
                                onChange={(value) =>
                                  handleChangePaymentOption(value?.value)
                                }
                                placeholder="Forma de pagamento"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Row>
                    ) : null}
                  </Row>
                </>
              )}

              {activeStep.step === 1 && (
                <>
                  <div className="section-title pdv">
                    <h2>Preencha as informações dos ingressos</h2>
                  </div>
                  {Object.values(ticketsData).length > 0 ? (
                    <>
                      {Object.values(ticketsData).map((ticketType, index) =>
                        ticketType.map((ticket, i) => (
                          <InputsTicketInfo
                            organizerId={organizerId}
                            ticket={ticket}
                            handleChangeTicketData={handleChangeTicketData}
                            ticketsDataIndex={index}
                            ticketIndex={i}
                            showInputErros={showInputErros}
                          />
                        ))
                      )}
                    </>
                  ) : (
                    <Row>
                      <Col xs={12} item>
                        <h4>Nenhum ingresso adicionado</h4>
                      </Col>
                    </Row>
                  )}
                </>
              )}
              {activeStep.step === 2 && (
                <>
                  {showSuccessFeedback && (
                    <Row className={styles.containerFeedback}>
                      <Col
                        className={[
                          styles.containerMsgFeedback,
                          styles.mAuto
                        ].join(' ')}
                        xs={12}
                        md={6}
                      >
                        <div className={styles.containerTitleFeedback}>
                          <h2>
                            {paymentFeedback?.title
                              ? paymentFeedback.title
                              : 'Ingressos reservados!'}
                          </h2>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: paymentFeedback?.message
                              ? paymentFeedback.message
                              : ''
                          }}
                        ></div>
                      </Col>
                      <Col
                        className="container-img-feedback success-feedback position-relative"
                        xs={12}
                        md={6}
                      >
                        <img src={SuccessImage} alt="Imagem sucesso" />
                      </Col>
                    </Row>
                  )}
                  {showErrorFeedback && (
                    <Row className={styles.containerFeedback}>
                      <Col
                        className={[
                          styles.containerMsgFeedback,
                          styles.mAuto
                        ].join(' ')}
                        xs={12}
                        md={6}
                      >
                        <div className={styles.containerTitleFeedback}>
                          <h2>
                            {paymentFeedback?.title
                              ? paymentFeedback.title
                              : 'Compra não autorizada!'}
                          </h2>
                        </div>
                        <div></div>

                        <Col xs={12} sm={6}>
                          <a
                            className={[
                              styles.checkoutButton,
                              styles.minHeight45
                            ].join(' ')}
                            type="button"
                            onClick={() => handleTryAgain()}
                          >
                            Tentar novamente
                          </a>
                        </Col>
                      </Col>
                      <Col
                        className="container-img-feedback position-relative"
                        xs={12}
                        md={6}
                      >
                        <img src={ErrorImage} alt="Imagem erro" />
                      </Col>
                    </Row>
                  )}
                </>
              )}

              {!loading && totalAmount > 0 ? (
                <>
                  <Container
                    className={styles.footerModalButtons}
                    margin="20px"
                    display="flex"
                    alignItems="center"
                    justifyContent={
                      activeStep.step === 0 ? 'flex-end' : 'space-between'
                    }
                    mt={10}
                    mb={5}
                  >
                    {activeStep.step > 0 && (
                      <Button
                        className={styles.marginButton}
                        variant="contained"
                        color={activeStep.step <= 1 ? 'secondary' : 'primary'}
                        onClick={() =>
                          activeStep.step <= 1
                            ? handleBack()
                            : handleCloseSuccess()
                        }
                      >
                        {activeStep.step <= 1 ? 'Voltar' : 'Fechar'}
                      </Button>
                    )}
                    {activeStep.step <= 1 && (
                      <Button
                        onClick={() =>
                          activeStep.step === 1
                            ? handleSubmit()
                            : handleNextStep()
                        }
                        variant="contained"
                        color="primary"
                      >
                        {activeStep.step === 1 ? 'Finalizar' : 'Próximo'}
                      </Button>
                    )}
                  </Container>
                </>
              ) : null}
            </>
          )}
        </form>
      </Container>
    </>
  )
}
