import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button
} from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
import CollapseComponent from '../../components/Table/OrderCollapse'
import { ModalTrashLancamento } from '../../components/modalTrashLancamento'
import {CardIndicador} from '../../components/CardIndicador'
import { formatCurrency } from '../../util/string'
import afiliadoService from 'services/afiliado'

///MODAIS
import Select from 'react-select'
import { listFinancialEvent, eventzzSelect } from '../../store/actions'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'

const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Evento', field: 'nomeEvento' },
  { label: 'lucroReal', field: 'lucroReal' }
]

const PainelAfiliado = () => {
  const { getAfiliado } = afiliadoService

  const [loading, setLoading] = useState(false)
  const [isRoot, setIsRoot] = useState(false)
  const [idRow, setIdRow] = useState(false)
  const [currentRow, setCurrentRow] = useState([])
  const [showModal, setShowModal] = useState(false)


  const [eventzzList, setEventzzList] = useState([])
  const [listResume, setListResume] = useState({
    comissaoTotal: 0,
    userId: '',
    comissaoRecebida: 0,
    comissaoAberta: 0,
    isAffiliated: false
  })


  const [tableConfig, setTableConfig] = useState({
    options: {
      trash: false,
      more: false,
      download: false
    },
    config: {
      handleTrashButton: 'Excluir'
    },
    filters: {
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 10
  })

  const handleClose = () => {
    setLoading(false)
  }

  const loadDados = async () => {
      const response = await getAfiliado()
      
      if (response.status === 200) {
        const responseData = response?.data
        console.log(responseData?.data?.eventos[0])
        const dadosFormatados = responseData?.data?.eventos.map((item) => ({
          ...item,
          lucroReal: formatCurrency({value: item.lucroReal})
        }));
                  
        setCurrentRow(dadosFormatados);
        setListResume({
          userId: responseData?.data?.userId,
          comissaoTotal: responseData?.data?.comissaoTotal,
          comissaoRecebida: responseData?.data?.comissaoRecebida,
          comissaoAberta: responseData?.data?.comissaoAberta,
          isAffiliated: responseData?.data?.isAffiliated
        });
  
        setLoading(false)
        return true
      } else {
        toast.error(response?.msg)
        setLoading(false)
        return false
      }
  }

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()

    if (response.isRoot) {
      setIsRoot(true)
    } else {
      setIsRoot(false)
    }
    if (response.status === 200) {
      const responseData = response?.data

      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])



  async function handlerTrash (row) {
    setIdRow(row?.id)
    setShowModal(true)
  }

  useEffect(() => {
    loadEventzz()
  }, [])

  useEffect(() => {
    loadDados()
  }, [tableConfig?.filters?.eventId])

  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Programa de Afiliados | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Programa de Afiliados | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Afiliados
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Card style={{padding:20}}>
              <Row>
                  <CardIndicador 
                    icon={'TICKETS'} value={listResume.userId} type={'number'}
                    text={'Meu ID'} color='warning' xl={2} md={4} xs={4}
                  />
                  <CardIndicador 
                    icon={'GRAPH'} type={'text'} xl={3} md={4} xs={4} text={'Status da Conta'}
                    value={ listResume.isAffiliated === 1 ? 'Ativo' : 'Desativado'}
                    color={ listResume.isAffiliated === 1 ? 'success' : 'warning' }
                  />
            </Row>
            <Row>
                  <CardIndicador 
                    icon={'MONEY'} value={listResume.comissaoTotal} type={'money'}
                    text={'Comissão Total'} color='primary' xl={3} md={6} xs={6}
                  />
                  <CardIndicador 
                    icon={'MONEY'} value={listResume.comissaoRecebida} type={'money'}
                    text={'Comissão Recebida'} color='primary' xl={3} md={6} xs={6}
                  />
                  <CardIndicador 
                    icon={'MONEY'} value={listResume.comissaoAberta} type={'money'}
                    text={'Saldo em Aberto'} color='success' xl={3} md={6} xs={6}
                  />
              </Row>
              <span>
                <b style={{marginBottom: 2}}>Atenção Afiliados!</b>
                <p style={{marginBottom: 0, fontSize: 12}}>Informamos que os pagamentos das comissões serão realizados até o quinto dia útil do mês posterior ao evento.
                É extremamente importante que todos os seus dados de perfil estejam devidamente preenchidos para garantir o recebimento da sua comissão. Caso haja alguma pendência em seu perfil, o pagamento poderá ser prejudicado.
                Por favor, mantenha seus dados sempre atualizados. Obrigado!</p>
              </span>
              <DatatableTables
                title={'Eventos Afiliados'}
                columns={columns}
                rows={currentRow}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
                CollapseComponent={CollapseComponent}
                handlerTrash={handlerTrash}
              />
            </Card>
          </Row>
        </Container>
      </div>
      <ModalTrashLancamento
        onClose={onCloseModal}
        showModal={showModal}
        idRow={idRow}
        loadDados={loadDados}
      />
    </React.Fragment>
  )
}

PainelAfiliado.propTypes = {
  t: PropTypes.any
}

export default PainelAfiliado
