import React, { useRef } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import ReactModal from 'react-modal'
import Cropper, { ReactCropperElement } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
//@ts-ignore
import styles from './styles.module.scss'

ReactModal.setAppElement('#root')

export const ModalCropImage = ({
  showModal,
  onClose,
  imgBase64,
  aspectRatio,
  onConfirm,
  width = null,
  height = null,
  imageDimensions
}) => {
  const cropperRef = useRef<ReactCropperElement>(null)
  const containerRef = useRef(null)

  const onCrop = () => {
    // const cropper = cropperRef.current?.cropper
    // console.log(cropper.getCroppedCanvas().toDataURL())
  }

  const handleCancelCrop = () => {
    onClose()
  }

  const handleConfirm = () => {
    const canvas = cropperRef.current?.cropper.getCroppedCanvas(
      width && height ? { width, height } : undefined
    )
    const croppedData = cropperRef.current.cropper.getCropBoxData()
    console.log({ croppedData })
    console.log({ imageDimensions })
    const cropperWidth = containerRef.current.clientWidth
    const cropperHeight = containerRef.current.clientHeight
    console.log({ cropperWidth, cropperHeight })

    const adjustedCroppedData = {
      left: croppedData.left * (imageDimensions.width / cropperWidth),
      top: croppedData.top * (imageDimensions.height / cropperHeight),
      width: croppedData.width * (imageDimensions.width / cropperWidth),
      height: croppedData.height * (imageDimensions.height / cropperHeight)
    }

    onConfirm({
      croppedData: adjustedCroppedData,
      croppedBase64: canvas.toDataURL()
    })
  }

  if (!showModal) {
    return null
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className={styles.reactModal}
      overlayClassName={styles.modalOverlay}
    >
      <Container>
        <Row className={styles.modalText}>
          <Col xs={12}>
            <h1>Ajuste a imagem</h1>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12}>
            <div ref={containerRef}>
              <Cropper
                src={imgBase64}
                className={styles.cropperContainer}
                // Cropper.js options
                aspectRatio={aspectRatio}
                guides={false}
                crop={onCrop}
                ref={cropperRef}
                viewMode={1}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={styles.footerModalButtons}>
            <Button
              className={styles.buttonsModal}
              onClick={handleConfirm}
              variant="primary"
            >
              Confirmar
            </Button>
            <Button
              className={styles.buttonsModal}
              onClick={handleCancelCrop}
              variant="secondary"
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </Container>
    </ReactModal>
  )
}
