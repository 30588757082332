import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {
    Row,
    Col,
    Button,
    Card
} from "reactstrap"
import { Loading } from '../Loading';
import { ToastContainer } from 'react-toastify'
import './styles.css';
import { jsPDF } from "jspdf";
import { font } from "./font";
import { Document, Page, Text, View, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer';
import { styles } from './styles';

import * as htmlToImage from 'html-to-image';

export const ModalCertificado = ({ showModal, onClose, certificados, eventName }) => {

    const today = new Date().toLocaleDateString('pt-br');
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("Nome do Participante");
    const [cpf, setCPF] = useState('000.000.000-00');
    const [subtitle, setSubtitle] = useState('DE CONCLUSÃO');
    const [text, setText] = useState(`
    Participou com exito do evento "{evento}" e atendeu os requisitos para adiquirir esse certificado`);
    const [color, setColor] = useState(1);
    const [data, setData] = useState(today);
    const [legenda, setLegenda] = useState('');
    const [legenda2, setLegenda2] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [aluno, setAluno] = useState('');
    const [logo, setLogo] = useState('');
    const domEl = useRef(null);


    useEffect(() => {
        if (certificados.length === 1) {
            setName(certificados[0]?.name)
            setAluno(certificados[0]?.name)
            setCPF(certificados[0]?.cpf)
        }

        setLegenda2('Participante')
        setResponsavel('Nome do Organizador')
        setLegenda("Diretor")
        
      }, [certificados])

    const handleClose = () => {
        setLoading(false);
    }

    const PDFExport = () => (
    <Document >
        {
            certificados.map((item, index)=>{
                return(
                    <Page size="A4" style={styles.page} orientation='landscape'>
                        <View 
                            style={styles.background}
                            absolute
                            zIndex={10}
                        >
                            <View style={styles.div}>
                                <Image style={styles.logo}
                                    src={logo === '' ? ('/images/eventozz/logoCertificado.png') : (logo)}
                                />
                            </View>
                            
                            <Text style={styles.titulo}>CERTIFICADO</Text>
                            <Text style={styles.subtitle}>{subtitle}</Text>
                            <Text style={styles.p}>Certificamos que</Text>
                            <Text style={styles.name}>{item.name}</Text>
                            <Text style={styles.cpf}>{item?.cpf}</Text>
                            <Text style={styles.text}>{text.replaceAll('{evento}', eventName)}</Text>
                            <Text style={styles.data}>{data}</Text>
                            <View style={styles.footerCertificado}>
                                <View style={{width: '70%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
                                    <View style={styles.assign}>
                                        <View style={styles.line}></View>
                                        <Text style={styles.assignText}>{responsavel}</Text>
                                        <Text style={styles.assignTextSub}>{legenda}</Text>
                                    </View>
                                    <View style={styles.assign}>
                                        <View style={styles.line}></View>
                                        <Text style={styles.assignText}>{item.name}</Text>
                                        <Text style={styles.assignTextSub}>{legenda2}</Text>
                                    </View>
                                </View>
                                <Text style={styles.textFooter}>Certificado gerado pela plataforma eventozz.com</Text>
                            </View>
                        </View>
                        <Image
                            absolute
                            zIndex={-1}
                            style={styles.img}
                            src={`/images/eventozz/background${color}.png`}
                        />
                    </Page>
                )
            })
        }
    </Document>
    );

    const handleChange = e => {
        setColor(e.target.value)
      };
    
    
      const convertToBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handlerLogo = async (e) => {
        // Selecionando o arquivo
        const file = e.target.files[0];
        const convertedFile = await convertToBase64(file)
        setLogo(convertedFile)
      };
      
    return (
        <Modal show={showModal} fullscreen={true} onHide={() => onClose()}>
        {loading && <Loading open={loading} handleClose={handleClose} />}
        <ToastContainer />
        <Modal.Header closeButton>
          <Modal.Title>Gerar Certificado{certificados.length !== 1 && ('s')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                    <Form>
                        <Row>
                        {certificados.length === 1 && (
                         <>
                            <Col xl={4} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoFocus
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl={4} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>CPF</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoFocus
                                        value={cpf}
                                        onChange={(e) => {
                                            setCPF(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </>)}
                            <Col xl={4} md={6}>
                                <PDFDownloadLink document={<PDFExport />} fileName={`certificados-${eventName}.pdf`}>
                                    {({ blob, url, loading, error }) => {
                                        return loading ? 'Gerando Certificado...' :  <Button
                                                variant="outlined"
                                                color="primary"
                                                type='button'
                                                style={{ marginTop: '30px' }}
                                            >
                                                Gerar Certificado{certificados.length !== 1 && ('s')}
                                            </Button>
                                    }
                                    }
                                    </PDFDownloadLink>
                            </Col>
                        </Row>
                        <Row>
                        <Col xl={9}>
                            <Card  id="domEl" ref={domEl} className={`certificadoCard  ${
                                Number(color) === 1 ? ('color1') : (
                                Number(color) === 2 ? ('color2') : (
                                    Number(color) === 3 ? ('color3') : (
                                    Number(color) === 4 && ('color4')
                                    )
                                )
                                )
                            }`}>
                                {logo === '' ? (
                                    <img 
                                    src="/images/eventozz/logoCertificado.png"
                                    className='logoCertificado'
                                    ></img>
                                ) : (
                                    <img 
                                    src={`${logo}`}
                                    className='logoCertificado'
                                    ></img>
                                )}
                                <h1>CERTIFICADO</h1>
                                <h4>{subtitle}</h4>
                                <p>Certificamos que</p>
                                <span>{certificados.length === 1 ? (name) : ('Nome do Participante')}</span>
                                <label>{certificados.length === 1 ? (cpf) : ('000.000.000-00')}</label>
                                <h5>{text.replaceAll('{evento}', eventName)}</h5>
                                <h5>{data}</h5>
                                <Row className='footerCertificado'>
                                    <Col xl={2} md={2}>

                                    </Col>
                                    <Col xl={4} md={4}>
                                        <hr style={{margin:'0.2rem 0'}}></hr>
                                        <p className='nome-pessoa'>{responsavel}</p>
                                        <label className='legend'>{legenda}</label>
                                    </Col>
                                    <Col xl={4} md={4}>
                                        <hr style={{margin:'0.2rem 0'}}></hr>
                                        <p className='nome-pessoa'>{certificados.length === 1 ? (aluno) : ('Nome do Participante')}</p>
                                        <label className='legend'>{legenda2}</label>
                                    </Col>
                                    <Col xl={2} md={2}>

                                    </Col>
                                </Row>
                                <span className='LogoEventozz' >Certificado gerado pela plataforma eventozz.com</span>
                            </Card>
                            </Col>
                            <Col xl={3}>
                                <Row>
                                    <Form.Group controlId="color">
                                        <Form.Label>Color</Form.Label>
                                        <Row>
                                        <Form.Check
                                            value={1}
                                            type="radio"
                                            label="Modelo 1"
                                            onChange={handleChange}
                                            checked={color === 1}
                                            style={{width:'50%'}}
                                        />
                                        <Form.Check
                                            value={2}
                                            type="radio"
                                            label="Modelo 2"
                                            onChange={handleChange}
                                            checked={color === 2}
                                            style={{width:'50%'}}
                                        />
                                        </Row>
                                        <Row>
                                        <Form.Check
                                            value={3}
                                            type="radio"
                                            label="Modelo 3"
                                            onChange={handleChange}
                                            checked={color === 3}
                                            style={{width:'50%'}}
                                        />
                                        <Form.Check
                                            value={4}
                                            type="radio"
                                            label="Modelo 4"
                                            onChange={handleChange}
                                            checked={color === 4}
                                            style={{width:'50%'}}
                                        />
                                        </Row>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group>
                                        <Form.Label>Logo</Form.Label>
                                        <Form.Control
                                            type="file"
                                            autoFocus
                                            onChange={(e) => {
                                                handlerLogo(e)
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group>
                                        <Form.Label>Subtitulo</Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoFocus
                                            value={subtitle}
                                            onChange={(e) => {
                                                setSubtitle(e.target.value)
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group>
                                        <Form.Label>Texto</Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoFocus
                                            value={text}
                                            onChange={(e) => {
                                                setText(e.target.value)
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group>
                                        <Form.Label>Data</Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoFocus
                                            value={data}
                                            onChange={(e) => {
                                                setData(e.target.value)
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group>
                                        <Form.Label>Responsavel</Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoFocus
                                            value={responsavel}
                                            onChange={(e) => {
                                                setResponsavel(e.target.value)
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group>
                                        <Form.Label>Legenda</Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoFocus
                                            value={legenda}
                                            onChange={(e) => {
                                                setLegenda(e.target.value)
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                {certificados.length === 1 && (
                                    <Row>
                                    <Form.Group>
                                        <Form.Label>Participante</Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoFocus
                                            value={aluno}
                                            onChange={(e) => {
                                                setAluno(e.target.value)
                                            }}
                                        />
                                    </Form.Group>
                                    </Row>
                                )}
                                <Row>
                                    <Form.Group>
                                        <Form.Label>Legenda</Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoFocus
                                            value={legenda2}
                                            onChange={(e) => {
                                                setLegenda2(e.target.value)
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
             
        </Modal.Body>
      </Modal>
    )
}