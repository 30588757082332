import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { ToastContainer, toast } from 'react-toastify'
///MODAIS
// import '../styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
//@ts-ignore
import styles from './styles.module.scss'
import eventzz from 'services/eventzz'
import { Loading } from 'components/Loading'
import { userService } from 'services/user'
import { debounce } from 'lodash'
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Row
} from 'react-bootstrap'
import { isValidEmail } from 'util/function'
import { permissionsService } from 'services/permissions'

interface Menu {
  checked: boolean
  permissions: Permission[]
}

interface Permission {
  id: number
  name: string
  checked: boolean
}

interface MenuMap {
  [key: string]: Menu
}

interface PermissionsMap {
  [key: string]: Permission[]
}

export const PermissionsOrganizer = ({ match }) => {
  const { eventId, userId } = match.params
  const { eventzzSelect } = eventzz
  const { checkUserEmail } = userService
  const { getUserEmail } = userService
  const { list: listPermissions, updateOrganizerPermissions } =
    permissionsService
  const [userPermissionsList, setUserPermissionsList] = useState<MenuMap>({})
  const [rendered, setRendered] = useState(false)
  const [loading, setLoading] = useState(false)
  const [copyLink, setCopyLink] = useState(false)
  const [userChecked, setUserChecked] = useState(false)
  const [eventzzList, setEventzzList] = useState([])

  const [showErrors, setShowErrors] = useState(false)
  const [email, setEmail] = useState(null)

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()
    if (response.status === 200) {
      const responseData = response?.data?.data

      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  const loadUserEmail = useCallback(async () => {
    const response = await getUserEmail(userId)
    if (response.status === 200) {
      const responseData = response?.data?.data
      console.log({ responseData })
      setEmail(responseData.email)
      setUserChecked(true)
      // setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [userId])

  const checkEmail = useCallback(async (value) => {
    console.log({ value })
    if (!isValidEmail(value)) {
      return
    }
    const response = await checkUserEmail({ email: value })
    console.log({ response })

    if (response?.status === 200 && response?.data?.success) {
      console.log('here')

      setUserChecked(true)
      setLoading(false)
      return true
    } else {
      console.log('there')
      setUserChecked(false)
      toast.error(response?.data?.msg)
      setLoading(false)
      return false
    }
  }, [])

  const handlePermissionChange = (
    categoryId: string,
    permissionId: number | null,
    checked: boolean
  ) => {
    console.log({ categoryId, permissionId, checked })

    if (!permissionId) {
      const newCheckedPermissions = { ...userPermissionsList }
      const category = newCheckedPermissions[categoryId]
      const newPermissions = category.permissions.map((item) => {
        return {
          ...item,
          checked: checked
        }
      })

      newCheckedPermissions[categoryId] = {
        checked,
        permissions: newPermissions
      }
      setUserPermissionsList(newCheckedPermissions)
    } else {
      const newCheckedPermissions = { ...userPermissionsList }
      const category = newCheckedPermissions[categoryId]

      const newPermissions = category.permissions.map((item) => {
        if (item.id === permissionId) {
          return {
            ...item,
            checked: checked
          }
        }
        return item
      })
      const newChecked = newPermissions.some((item) => item.checked)

      newCheckedPermissions[categoryId] = {
        checked: newChecked,
        permissions: newPermissions
      }
      setUserPermissionsList(newCheckedPermissions)
    }
  }

  const checkPermissions = async () => {
    try {
      const response = await listPermissions({ userId, eventId })
      if (response.status === 200) {
        const data: MenuMap = response.data.data
        console.log(data)

        setUserPermissionsList(data)
      } else {
        toast.error('Não foi possível listar as permissões')
      }
    } catch (error) {
      console.log({ error })
      toast.error('Ocorreu um erro ao listar as permissões')
    }
  }

  function handleGoBack() {
    window.location.href = '/eventozz'
  }

  async function handleSubmit() {
    try {
      console.log(userPermissionsList)
      const permissionsChecked = []
      Object.entries(userPermissionsList).map(([category, permissions]) => {
        if (permissions.checked) {
          permissions.permissions.forEach((item) => {
            if (item.checked) {
              permissionsChecked.push(item.id)
            }
          })
        }
      })
      console.log({ permissionsChecked })

      // return
      if (!isValidEmail(email)) {
        return toast.error('É obrigatório inserir um email válido')
      }
      setLoading(true)

      const response = await updateOrganizerPermissions({
        email,
        eventId,
        permissions: permissionsChecked
      })
      console.log(response)
      if (response.status === 200 && response?.data?.success) {
        toast.success(response.data.msg || 'Permissões atualizadas')
        setLoading(false)
        if (response?.data?.data?.length) {
          const data = response?.data?.data[0]
          setCopyLink(data)
        }
      } else if (response.status === 401) {
        toast.error('Você não está logado ou sua sessão expirou')
        setLoading(false)
        return false
      } else if (response.status === 500) {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
        )
        setLoading(false)
        return false
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao salvar as permissões'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      setLoading(false)
      console.log({ error })
    }
  }

  const deboundChange = useCallback(debounce(checkEmail, 1500), [])

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    } else {
      loadEventzz()
      checkPermissions()
      if (userId) {
        loadUserEmail()
      }
    }
  }, [rendered])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Permissões do organizador</title>
        </MetaTags>
        <Container>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Permissões do organizador</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Adicione o evento a um organizador e defina em quais páginas
                    ele terá acesso a esse evento
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Card className={`${styles.p200} pt-2`}>
              <Card.Body>
                <Row>
                  <Col className="m-auto">
                    <Row>
                      <Col xs={12} md={6} xl={4}>
                        <FormGroup>
                          <Form.Label>Evento*</Form.Label>
                          <Form.Control
                            disabled={true}
                            value={
                              eventzzList.find(
                                (a) => a.value === Number(eventId)
                              )
                                ? eventzzList.find(
                                    (a) => a.value === Number(eventId)
                                  )?.label
                                : null
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6} xl={4}>
                        <FormGroup>
                          <Form.Label>Email do organizador*</Form.Label>
                          <Form.Control
                            onChange={(e) => {
                              setEmail(e.target.value)
                              deboundChange(e.target.value)
                            }}
                            value={email}
                            type="email"
                            disabled={!!userId}
                          />
                        </FormGroup>
                        {showErrors && !email && (
                          <small className="invalid-feedback d-block">
                            *Campo obrigatório
                          </small>
                        )}
                      </Col>
                    </Row>

                    <Accordion defaultActiveKey="0" className="pt-4">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Defina as permissões por página
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            {Object.entries(userPermissionsList).map(
                              ([category, permissions]) => {
                                return (
                                  <Col xs={12} key={category}>
                                    {permissions?.permissions?.length === 1 ? (
                                      <Form.Check
                                        label={
                                          <Form.Label>{category}</Form.Label>
                                        }
                                        checked={permissions.checked}
                                        onChange={(e) =>
                                          handlePermissionChange(
                                            category,
                                            null,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    ) : (
                                      <>
                                        <Form.Check
                                          label={
                                            <Form.Label>{category}</Form.Label>
                                          }
                                          checked={permissions.checked}
                                          onChange={(e) =>
                                            handlePermissionChange(
                                              category,
                                              null,
                                              e.target.checked
                                            )
                                          }
                                        />
                                        {permissions.checked && (
                                          <>
                                            {permissions.permissions.map(
                                              (permission) => (
                                                <Col
                                                  xs={12}
                                                  className={styles.subMenu}
                                                  key={permission.id}
                                                >
                                                  <Form.Check
                                                    label={
                                                      <Form.Label>
                                                        {permission.name}
                                                      </Form.Label>
                                                    }
                                                    checked={permission.checked}
                                                    onChange={(e) =>
                                                      handlePermissionChange(
                                                        category,
                                                        permission.id,
                                                        e.target.checked
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              )
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Col>
                                )
                              }
                            )}
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Row className="pt-4 justify-content-end">
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-end"
                      >
                        <Button
                          type="button"
                          variant="danger"
                          onClick={() => handleGoBack()}
                          style={{ marginTop: '30px' }}
                        >
                          Voltar
                        </Button>
                        {copyLink ? (
                          <Button
                            className="mx-3"
                            type="button"
                            variant="success"
                            onClick={() => true}
                            style={{ marginTop: '30px' }}
                          >
                            Copiar link do ingresso
                          </Button>
                        ) : (
                          <Button
                            className="mx-3"
                            variant="outline-primary"
                            type="button"
                            color="primary"
                            onClick={() => handleSubmit()}
                            style={{ marginTop: '30px' }}
                            disabled={!userChecked}
                          >
                            Salvar
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
