import React, { useEffect, useState } from 'react'
import DatatableTables from '../index.js'
import { Collapse } from 'reactstrap'
import { Tr, Td } from 'react-super-responsive-table'

import styles from './styles.module.scss'

import { toast } from 'react-toastify'
import { ModalConfirmResendTicket } from '../../modalConfirmResendTicket'

export function OrderActivityCollapse({
  open,
  row,
  callback,
  onCreateCheckout
}) {
  const [orderTickets, setOrderTickets] = useState([])

  const [currentTicket, setCurrentTicket] = useState(null)
  const [
    showModalConfirmResendTicketQrcode,
    setShowModalConfirmResendTicketQrcode
  ] = useState(false)

  const columns = [
    { label: 'Cod', field: 'id' },
    { label: 'Cliente', field: 'customerName' },
    { label: 'Email', field: 'customerEmail' },
    { label: 'CPF', field: 'customerCPF' },
    { label: 'Telefone', field: 'customerPhoneNumber' },
    { label: 'Tipo', field: 'ticketType' },
    { label: 'Campos adicionais', field: 'additionalFields' }
    // { label: 'Utilizado', field: 'alreadyUsedFormatted' },
    // { label: 'Data de Uso', field: 'dateUsed' }
  ]

  // eslint-disable-next-line no-unused-vars
  const [tableConfig, setTableConfig] = useState({
    options: {
      more: true,
      pagination: false,
      resendTicket: true
    },
    config: {
      resendTicketText: 'Reenviar Qr code da inscrição'
    },
    total: 0,
    currentPage: 1,
    rowsPerPage: 10
  })

  function handleResendTickets(ticket) {
    if (ticket?.id && row?.eventId && Number(row?.status) === 1) {
      setCurrentTicket(ticket)
      setShowModalConfirmResendTicketQrcode(true)
    } else {
      toast.error('Não foi possível reenviar o qr code desta inscrição')
    }
  }

  const onCloseModalConfirmResendTicketQrcode = () => {
    setCurrentTicket(null)
    setShowModalConfirmResendTicketQrcode(false)
  }

  useEffect(() => {
    if (row?.tickets) {
      const ticketsTemp = row?.tickets.map((ticket) => {
        return {
          ...ticket,
          alreadyUsedFormatted: ticket.alreadyUsed
            ? 'Utilizado'
            : 'Não utilizado',
          dateUsed: ticket.dateUsed ? ticket.dateUsed : '------'
        }
      })
      setOrderTickets(ticketsTemp)
    }
  }, [row])

  return (
    <Tr>
      <Td
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={columns.length + 1 + (tableConfig?.options?.more ? 1 : 0)}
      >
        <Collapse
          isOpen={open}
          timeout="auto"
          unmountOnExit
          className={styles.tableContainer}
        >
          <DatatableTables
            rows={orderTickets}
            columns={columns}
            tableConfig={tableConfig}
            onChangePage={() => {}}
            onChangeRowsPerPage={() => {}}
            hideBackgroundHeader={true}
            onCreateCheckout={onCreateCheckout}
            onResendTickets={handleResendTickets}
            layoutCollapse={true}
          />
          <ModalConfirmResendTicket
            eventId={row ? row.eventId : null}
            onClose={onCloseModalConfirmResendTicketQrcode}
            row={currentTicket}
            callback={callback}
            showModal={showModalConfirmResendTicketQrcode}
            type="activity"
          />
        </Collapse>
      </Td>
    </Tr>
  )
}
