import { api } from './axios'

export const ticketsService = {
  listSpecialTickets: async ({ eventId }) => {
    const response = await api
      .get('/tickets/special-ticket-list', {
        params: {
          eventId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  selectTickets: async ({ eventId }) => {
    const response = await api
      .get('/tickets/select', {
        params: {
          eventId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  createSpecialTicket: async (body) => {
    const response = await api
      .post('/tickets/create-special-ticket', { ...body })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  findTicketType: async ({ ticketTypeId }) => {
    const response = await api
      .get('/tickets/find-type', {
        params: {
          ticketTypeId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  changeTicketTypeStatus: async ({ specialTicketId, status }) => {
    const response = await api
      .put('/tickets/special-ticket-change-status', {
        specialTicketId,
        status
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
