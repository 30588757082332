import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

function DisabledButton(props) {
  return (
    <OverlayTrigger
      overlay={<Tooltip id="tooltip-disabled">{props?.title}</Tooltip>}
    >
      <span className="d-inline-block">
        <Button {...props} style={{ pointerEvents: 'none', marginTop: 30 }}>
          {props?.text}
        </Button>
      </span>
    </OverlayTrigger>
  )
}

export function CustomButton({ text, disabled, showTooltip, ...rest }) {
  if (disabled && showTooltip) {
    return <DisabledButton text={text} disabled={disabled} {...rest} />
  }
  return (
    <Button disabled={disabled} {...rest}>
      {text}
    </Button>
  )
}
