import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import { solicitaSaque } from '../../store/actions'
import './styles.css'
import { Container, Row, Col, Button, Input } from 'reactstrap'
import { Loading } from '../Loading'

export const ModalSaque = ({ showModal, onClose, eventId, maxWithdrawal }) => {
  const [loading, setLoading] = useState(false)
  // valor solicitado para saque
  const [withdrawValue, setWithdrawValue] = useState(maxWithdrawal)
  // pix informado para envio dos valores
  const [pixInfo, setPixInfo] = useState('')
  // confirmação do password
  const [passConfirm, setpassConfirm] = useState('')

  useEffect(() => {
    setWithdrawValue(Number(maxWithdrawal).toFixed(2))
  }, [maxWithdrawal])

  const handleClose = () => {
    setLoading(false)
  }

  const handlerSubmit = async () => {
    setLoading(true)
    if (
      Number(withdrawValue).toFixed(2) > Number(maxWithdrawal).toFixed(2) ||
      pixInfo == '' ||
      withdrawValue < 0 ||
      passConfirm == ''
    ) {
      toast.error('Erro ao solicitar, verifique o valor solicitado e chave PIX')
      setLoading(false)
      return false
    } else {
      const response = await solicitaSaque({
        eventId,
        withdrawValue,
        pixInfo,
        passConfirm
      })
      if (response.status === 200) {
        toast.success(response?.msg ? response?.msg : 'Saque Solicitado')
        onClose()
        setLoading(false)
      } else {
        toast.error(
          response?.msg || 'Ocorreu um erro ao tentar solicitar saque'
        )
        setLoading(false)
        return false
      }
    }
  }

  const handleCancelSendQrcodes = () => {
    onClose()
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
    >
      <Container>
        <Loading open={loading} handleClose={handleClose} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Deseja Solicitar Saque?</h1>
            </Col>
          </Row>
          <Row className="WithdrawRequest">
            <label for="exampleEmail" class>
              Informe o valor do Saque
            </label>
            <Input
              className="inputMoney"
              required={true}
              title="Digite o valor para saque"
              value={withdrawValue}
              onChange={(e) => setWithdrawValue(e.target.value)}
            />
            <label for="exampleEmail" class>
              Chave Pix
            </label>
            <Input
              className="inputModal"
              required={true}
              title="Informe sua chave pix"
              value={pixInfo}
              onChange={(e) => setPixInfo(e.target.value)}
            />
            <label for="exampleEmail" class>
              Senha
            </label>
            <Input
              id="password"
              type="password"
              className="inputModal"
              required={true}
              title="Insira sua senha"
              value={passConfirm}
              onChange={(e) => setpassConfirm(e.target.value)}
            />
          </Row>
          <Row className="footerModalButtons">
            <Col xs={6}>
              <Button
                className="buttonsModalWithdraw"
                onClick={handlerSubmit}
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="buttonsModalWithdraw"
                onClick={handleCancelSendQrcodes}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>
            </Col>
          </Row>
          <Row className="WithdrawInfo">
            <label for="customWithdrawText" class>
              Atenção! Este é um aviso importante para informar que o
              organizador se responsabiliza pelo repasse financeiro solicitado
              para a conta de terceiros. Esta solicitação é um registro jurídico
              de autorização para o repasse para o Pix inserido.
            </label>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
