import React, { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap'

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import PaginationComponent from './Pagination'
import { formatCurrency } from '../../util/string'

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import './datatable.css'
import {
  ChevronDown,
  ChevronUp,
  MoreHorizontal,
  UserPlus,
  Gift,
  Copy,
  Check,
  Send,
  Settings,
  PlusCircle,
  Download,
  FilePlus,
  Lock,
  Unlock,
  Trash,
  AlertCircle
} from 'react-feather'
import { Badge } from 'react-bootstrap'
import { AiOutlineUsergroupAdd } from 'react-icons/ai'
import { TbTicket } from 'react-icons/tb'
import { SkeletonTable } from 'components/SkeletonTable'

const ResponsiveTables = ({
  title = null,
  titleVariant = '',
  columns = [],
  rows = [],
  tableConfig = {},
  setTableConfig = null,
  CollapseComponent = null,
  callback = null,
  onCreateCheckout = null,
  onResendPurchaseTickets = null,
  onAdd = null,
  layoutCollapse = false,
  onAddCourtesyTicket = null,
  onAddAfiliado = null,
  onMarkAsUsed = null,
  onResendTickets = null,
  onEdit = null,
  onEventEdit = null,
  onUserEdit = null,
  onEventCupom = null,
  onDelete = null,
  handleClickSellerLink = null,
  handleDownload = null,
  actualEmail = null,
  addOrganizer = null,
  handleCopy = null,
  onAddSpecialTicket = null,
  onUpdateStatus = null,
  applyPadding = true,
  handlerTrash = null,
  gerarEtiqueta = null,
  gerarCertficado = null,
  onAddOrganizer = null,
  onTicket = null,
  loading = false
}) => {
  const [colNumber, setColNumber] = useState(1)

  const pageNumber = Math.ceil(
    Number(tableConfig?.total) / Number(tableConfig?.rowsPerPage)
  )

  function switchFieldDescription(field) {
    let fieldDescription = ''

    switch (field) {
      case 'companyName':
        fieldDescription = 'Empresa'
        break
      case 'position':
        fieldDescription = 'Cargo'
        break
      case 'shirtSize':
        fieldDescription = 'Camisa'
        break

      default:
        fieldDescription = field
        break
    }
    return fieldDescription
  }

  const ColAditionalFields = ({ additionalFields }) => {
    try {
      if (additionalFields && JSON.parse(additionalFields)) {
        const parsedFields = JSON.parse(additionalFields)
        return (
          <ul>
            {Object.keys(parsedFields).map((key, index) => {
              return (
                <li key={index.toString()}>
                  <i>{switchFieldDescription(key)}</i> -{' '}
                  <strong>{parsedFields[key]}</strong>
                </li>
              )
            })}
          </ul>
        )
      }
      return <>--//--</>
    } catch (error) {
      return <>---//---</>
    }
  }

  const ColOptions = ({
    row,
    tableConfig,
    handleAdd,
    handleAddCourtesyTicket,
    handleAfiliado,
    handleMarkAsUsed,
    handleResendPurchaseTickets,
    handleResendTickets,
    handleEdit,
    handleUserEdit,
    handleAddCupom,
    handleDelete,
    handleClickSellerLink,
    handleDownload,
    handleCopy,
    handleAddSpecialTicket,
    handleUpdateStatus,
    handleAddOrganizer,
    handleTickets
  }) => {
    const [open, setOpen] = useState(false)
    const handleClick = () => {
      setOpen(!open)
    }
    return (
      <Td>
        <Button
          id="basic-button"
          onClick={() => {
            handleClick()
          }}
        >
          <MoreHorizontal />
        </Button>
        {open && (
          <>
            <div className="divClose" onClick={() => setOpen(false)}></div>
            <div className="divFlutuante">
              <div className="divFlutuanteHeader">Opções</div>
              <ul>
                {tableConfig?.options?.add && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleAdd()
                    }}
                  >
                    <div className="menuItem">
                      <UserPlus />{' '}
                      {tableConfig?.config?.addText
                        ? tableConfig?.config?.addText
                        : 'Adicionar'}
                    </div>
                  </li>
                )}

                {tableConfig?.options?.addCourtesyTicket && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleAddCourtesyTicket()
                    }}
                  >
                    <div className="menuItem">
                      <Gift />{' '}
                      {tableConfig?.config?.addCourtesyTicketText
                        ? tableConfig?.config?.addCourtesyTicketText
                        : 'Adicionar cortesia'}
                    </div>
                  </li>
                )}
                {tableConfig?.options?.addAfiliado && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleAfiliado()
                    }}
                  >
                    <div className="menuItem">
                      <UserPlus />{' '}
                      {tableConfig?.config?.addAfiliado
                        ? tableConfig?.config?.addAfiliado
                        : 'Adicionar Indicação'}
                    </div>
                  </li>
                )}
                {tableConfig?.options?.copyLinkSeller && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleClickSellerLink(row?.id)
                    }}
                  >
                    <div className="menuItem">
                      <Copy />
                      Copiar Link
                    </div>
                  </li>
                )}
                {tableConfig?.options?.markAsUsed && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      if (!row.maxUsageCheck) {
                        handleMarkAsUsed()
                      }
                    }}
                  >
                    <div className="menuItem">
                      {row.maxUsageCheck ? <AlertCircle /> : <Check />}
                      {row.maxUsageCheck
                        ? tableConfig?.config?.markAsUsedDisabledText
                        : tableConfig?.config?.markAsUsedText}
                    </div>
                  </li>
                )}
                {tableConfig?.options?.resendPurchase &&
                  Number(row?.status) === 1 && (
                    <li
                      onClick={() => {
                        setOpen(false)
                        handleResendPurchaseTickets()
                      }}
                    >
                      <div className="menuItem">
                        <Send />
                        {tableConfig?.config?.resendPurchaseText}
                      </div>
                    </li>
                  )}
                {tableConfig?.options?.resendTicket && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleResendTickets()
                    }}
                  >
                    <div className="menuItem">
                      <Send />
                      {tableConfig?.config?.resendTicketText}
                    </div>
                  </li>
                )}
                {tableConfig?.options?.edit && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleEdit()
                    }}
                  >
                    <div className="menuItem">
                      <Settings />
                      {tableConfig?.config?.handleEditText}
                    </div>
                  </li>
                )}
                {tableConfig?.options?.userEdit && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      console.log('0')
                      handleUserEdit()
                    }}
                  >
                    <div className="menuItem">
                      <Settings /> {tableConfig?.config?.handleUserEditText}
                    </div>
                  </li>
                )}
                {tableConfig?.options?.addCupom && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleAddCupom()
                    }}
                  >
                    <div className="menuItem">
                      <PlusCircle />
                      {tableConfig?.config?.addCupomText}
                    </div>
                  </li>
                )}
                {tableConfig?.options?.download && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleDownload()
                    }}
                  >
                    <div className="menuItem">
                      <Download />
                      {tableConfig?.config?.handleDownloadText}
                    </div>
                  </li>
                )}
                {tableConfig?.options?.copy && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleCopy()
                    }}
                  >
                    <div className="menuItem">
                      <Copy />
                      {tableConfig?.config?.handleCopyText}
                    </div>
                  </li>
                )}

                {tableConfig?.options?.addSpecialTicket && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleAddSpecialTicket()
                    }}
                  >
                    <div className="menuItem">
                      <FilePlus />
                      {tableConfig?.config?.addSpecialTicketText}
                    </div>
                  </li>
                )}

                {tableConfig?.options?.changeStatus && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleUpdateStatus()
                    }}
                  >
                    <div className="menuItem">
                      {row?.status ? <Lock /> : <Unlock />}
                      {tableConfig?.config?.handleChangeText[row?.status]}
                    </div>
                  </li>
                )}
                {tableConfig?.options?.delete && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleDelete()
                    }}
                  >
                    <div className="menuItem">
                      {row?.status ? <Trash /> : <Trash />}
                      {tableConfig?.config?.deleteText}
                    </div>
                  </li>
                )}
                {tableConfig?.options?.addOrganizer && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleAddOrganizer()
                    }}
                  >
                    <div className="menuItem">
                      <AiOutlineUsergroupAdd size={25} />
                      {tableConfig?.config?.addOrganizerText}
                    </div>
                  </li>
                )}
                {tableConfig?.options?.tickets && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleTickets()
                    }}
                  >
                    <div className="menuItem">
                      <TbTicket size={25} />
                      {tableConfig?.config?.ticketsText}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </>
        )}
      </Td>
    )
  }

  function Rows({
    row,
    columns,
    tableConfig,
    onAdd,
    onAddCourtesyTicket,
    onAddAfiliado,
    CollapseComponent,
    onCreateCheckout,
    onMarkAsUsed,
    onResendPurchaseTickets,
    onResendTickets,
    callback,
    onEdit,
    onUserEdit,
    onEventCupom,
    handleClickSellerLink,
    onDownload,
    onCopy,
    onAddSpecialTicket,
    onUpdateStatus,
    onDelete,
    onAddOrganizer,
    onTicket
  }) {
    const [open, setOpen] = useState(false)

    const handleOpenOrder = async () => {
      setOpen(!open)
    }
    const handleAdd = () => {
      if (onAdd) {
        onAdd(row)
      }
    }

    const handleAddCourtesyTicket = () => {
      if (onAddCourtesyTicket) {
        onAddCourtesyTicket(row)
      }
    }

    const handleAfiliado = () => {
      console.log('====================================')
      console.log('====================================')
      console.log(onAddAfiliado)

      if (onAddAfiliado) {
        console.log('------------------->', row)
        onAddAfiliado(row)
      }
    }

    const handleCreateCheckout = () => {
      if (onCreateCheckout) {
        onCreateCheckout(row)
      }
    }

    const handleMarkAsUsed = () => {
      if (onMarkAsUsed) {
        onMarkAsUsed(row)
      }
    }

    const handleResendPurchaseTickets = () => {
      if (onResendPurchaseTickets) {
        onResendPurchaseTickets(row)
      }
    }

    const handleResendTickets = () => {
      if (onResendTickets) {
        onResendTickets(row)
      }
    }

    const handleEdit = () => {
      if (onEdit) {
        onEdit(row)
      }
    }
    const handleUserEdit = () => {
      console.log('1')
      if (onUserEdit) {
        onUserEdit(row)
      }
    }
    const handleAddOrganizer = () => {
      console.log('1')
      if (onAddOrganizer) {
        onAddOrganizer(row)
      }
    }

    const handleAddCupom = () => {
      if (onEventCupom) {
        onEventCupom(row)
      }
    }
    const handleDelete = () => {
      if (onDelete) {
        onDelete(row)
      }
    }
    const handleDownload = () => {
      if (onDownload) {
        onDownload(row)
      }
    }

    const handleCopy = () => {
      if (onCopy) {
        onCopy(row)
      }
    }

    const handleAddSpecialTicket = () => {
      if (onAddSpecialTicket) {
        onAddSpecialTicket(row)
      }
    }

    const handleUpdateStatus = () => {
      if (onUpdateStatus) {
        onUpdateStatus(row)
      }
    }

    const handleTickets = () => {
      if (onTicket) {
        onTicket(row)
      }
    }

    return (
      <>
        <Tr>
          {tableConfig?.options?.collapse && (
            <Td>
              <span onClick={() => handleOpenOrder()}>
                {open ? <ChevronUp /> : <ChevronDown />}
              </span>
            </Td>
          )}
          {columns
            ?.filter((item) => !item.hide)
            .map((col, index) => {
              return (
                <Td key={index.toString()}>
                  {col.field === 'additionalFields' ? (
                    <ColAditionalFields additionalFields={row[col.field]} />
                  ) : col.badge ? (
                    <Badge bg={col.info[row?.[col.field]]?.color}>
                      {col.info[row?.[col.field]]?.text}
                    </Badge>
                  ) : col.field === 'valor_fluxo' ? (
                    row?.['type'] === 1 ? (
                      <span style={{ color: '#020413', fontWeight: 600 }}>
                        {row?.[col.field]}
                      </span>
                    ) : (
                      <span style={{ color: '#a6142c', fontWeight: 600 }}>
                        {row?.[col.field]}
                      </span>
                    )
                  ) : col.field === 'transferOpen' ? (
                    <span
                      style={{
                        color: row?.[col.field] < 0.5 ? '#398024' : '#a6142c',
                        fontWeight: 600
                      }}
                    >
                      {formatCurrency({
                        value: row?.[col.field] < 0.5 ? 0 : row?.[col.field]
                      })}
                    </span>
                  ) : col.field === 'lucro' ? (
                    <span
                      style={{
                        color: '#398024',
                        fontWeight: 700
                      }}
                    >
                      {row?.[col.field]}
                    </span>
                  ) : (
                    row?.[col.field]
                  )}
                </Td>
              )
            })}
          {tableConfig?.options?.makeCertificado && (
            <Td
              onClick={() => {
                gerarCertficado(row)
              }}
              style={{ cursor: 'pointer' }}
            >
              <FilePlus />
            </Td>
          )}
          {tableConfig?.options?.makeEtiqueta && (
            <Td
              onClick={() => {
                gerarEtiqueta([row])
              }}
              style={{ cursor: 'pointer' }}
            >
              <Download />
            </Td>
          )}
          {tableConfig?.options?.trash &&
            (actualEmail ? (
              <Td
                onClick={() => {
                  actualEmail === row['email']
                    ? console.log('')
                    : handlerTrash(row)
                }}
              >
                <div className="menuItem">
                  {actualEmail !== row['email'] && (
                    <>
                      <Trash />
                      {tableConfig?.config?.handleTrashButton}
                    </>
                  )}
                </div>
              </Td>
            ) : (
              <Td
                onClick={() => {
                  handlerTrash(row)
                }}
              >
                <div className="menuItem">
                  <Trash />
                  {tableConfig?.config?.handleTrashButton}
                </div>
              </Td>
            ))}
          {tableConfig?.options?.more && (
            <ColOptions
              row={row}
              handleAdd={handleAdd}
              handleAddCourtesyTicket={handleAddCourtesyTicket}
              handleAfiliado={handleAfiliado}
              handleCreateCheckout={handleCreateCheckout}
              tableConfig={tableConfig}
              handleMarkAsUsed={handleMarkAsUsed}
              handleResendPurchaseTickets={handleResendPurchaseTickets}
              handleResendTickets={handleResendTickets}
              handleEdit={handleEdit}
              handleUserEdit={handleUserEdit}
              handleAddCupom={handleAddCupom}
              handleDelete={handleDelete}
              handleClickSellerLink={handleClickSellerLink}
              handleDownload={handleDownload}
              handleCopy={handleCopy}
              handleAddSpecialTicket={handleAddSpecialTicket}
              handleUpdateStatus={handleUpdateStatus}
              handleAddOrganizer={handleAddOrganizer}
              handleTickets={handleTickets}
            />
          )}
        </Tr>
        {tableConfig?.options?.collapse && CollapseComponent && (
          <>
            <CollapseComponent
              open={open}
              tableConfig={tableConfig}
              onCreateCheckout={onCreateCheckout}
              row={row}
              columns={columns}
              callback={callback}
            />
          </>
        )}
      </>
    )
  }

  console.log({ loading })
  function calculateColNumber() {
    let columnNumber = 0
    if (tableConfig?.options?.collapse) {
      columnNumber++
    }
    if (
      tableConfig?.options?.more ||
      tableConfig?.options?.trash ||
      tableConfig?.options?.makeCertificado ||
      tableConfig?.options?.makeEtiqueta
    ) {
      columnNumber++
    }
    columnNumber =
      columnNumber + (columns.filter((item) => !item.hide)?.length || 0)
    setColNumber(columnNumber)
  }

  useEffect(() => {
    calculateColNumber()
  }, [columns, tableConfig?.options])

  return (
    <React.Fragment>
      <div
        className={`${applyPadding ? 'page-content' : ''} ${
          layoutCollapse ? 'tableCollapse' : ''
        }`}
      >
        <div className="container-fluid">
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {addOrganizer && (
                    <div className="organizzer">
                      <Button
                        type="button"
                        color="danger"
                        onClick={() => addOrganizer()}
                      >
                        {' '}
                        Adicionar Organizador
                      </Button>
                    </div>
                  )}
                  <CardTitle className={titleVariant ? titleVariant : 'h4'}>
                    {title}{' '}
                  </CardTitle>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered table-eventozz"
                      >
                        <Thead>
                          <Tr className="tableHead">
                            {tableConfig?.options?.collapse && (
                              <Th
                                data-priority="index"
                                className="tableHeadName"
                              ></Th>
                            )}
                            {columns
                              .filter((item) => !item.hide)
                              .map((column, index) => (
                                <Th
                                  key={index.toString()}
                                  data-priority="index"
                                  className="tableHeadName"
                                >
                                  {column?.label}
                                </Th>
                              ))}
                            {(tableConfig?.options?.more ||
                              tableConfig?.options?.trash ||
                              tableConfig?.options?.makeCertificado ||
                              tableConfig?.options?.makeEtiqueta) && (
                              <Th
                                data-priority="index"
                                className="tableHeadName"
                              >
                                Opções
                              </Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {loading ? (
                            <>
                              <Tr>
                                <Td colSpan={colNumber}>
                                  <SkeletonTable />
                                </Td>
                              </Tr>

                              <Tr>
                                <Td colSpan={colNumber}>
                                  <SkeletonTable />
                                </Td>
                              </Tr>
                            </>
                          ) : (
                            rows.map((row, index) => (
                              <Rows
                                key={`${index}_${row.field}`}
                                row={row}
                                columns={columns}
                                tableConfig={tableConfig}
                                CollapseComponent={CollapseComponent}
                                onAdd={onAdd}
                                onAddCourtesyTicket={onAddCourtesyTicket}
                                onAddAfiliado={onAddAfiliado}
                                onCreateCheckout={onCreateCheckout}
                                onMarkAsUsed={onMarkAsUsed}
                                onResendPurchaseTickets={
                                  onResendPurchaseTickets
                                }
                                onResendTickets={onResendTickets}
                                callback={callback}
                                onEdit={onEdit}
                                onEventEdit={onEventEdit}
                                onUserEdit={onUserEdit}
                                onEventCupom={onEventCupom}
                                onDelete={onDelete}
                                handleClickSellerLink={handleClickSellerLink}
                                onDownload={handleDownload}
                                onCopy={handleCopy}
                                onAddSpecialTicket={onAddSpecialTicket}
                                onUpdateStatus={onUpdateStatus}
                                onAddOrganizer={onAddOrganizer}
                                onTicket={onTicket}
                              />
                            ))
                          )}
                        </Tbody>
                      </Table>
                      {!layoutCollapse && (
                        <div className="paginationContent">
                          {tableConfig?.options?.pagination && (
                            <PaginationComponent
                              pageNumber={pageNumber}
                              tableConfig={tableConfig}
                              setTableConfig={setTableConfig}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ResponsiveTables
