import React from 'react'
import ReactModal from 'react-modal'
import { Container, Row, Col, Button } from 'reactstrap'
import './styles.css'

export const ModaConfirmTrashPatrocinador = ({
  showModal,
  onClose,
  id,
  setLoading,
  loading,
  callback
}) => {
  const handleClose = () => {
    setLoading(false)
  }

  const handleSubmit = async () => {
    onClose()
    callback(id)
    setLoading(true)
  }

  const handleCancelAddSeller = () => {
    onClose()
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
    >
      <Container>
        <Row className="modalText">
          <Col xs={12} justifyContent="flex-start" item>
            <h1 className={'titleRoot'}>
              Deseja Excluir Esse{' '}
              {id?.key === 'palestrante'
                ? 'Palestrante'
                : 'Patrocinador/Parceiro'}
              ?
            </h1>
          </Col>
        </Row>
        <Row className="footerModalButtons">
          <Col xs={6}>
            <Button
              className="buttonsModal"
              onClick={handleSubmit}
              variant="contained"
              color="primary"
            >
              Excluir
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              className="buttonsModal"
              onClick={handleCancelAddSeller}
              variant="contained"
              color="secondary"
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </Container>
    </ReactModal>
  )
}
