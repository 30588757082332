import { Bar } from 'react-chartjs-2';

export const BarChart = ({data, label, labels, respostas}) => {
  const chartData = {
    labels,
    datasets: [
      {
        label,
        data,
        backgroundColor: [
          '#333547de',
          '#333547de',
          '#333547de',
          '#333547de',
          '#333547de',
          '#333547de',
          '#333547de',
          '#333547de',
          '#333547de'
        ],
        borderColor: [
          '#333547de',
          '#333547de',
          '#333547de',
          '#333547de',
          '#333547de',
          '#333547de',
          '#333547de',
          '#333547de',
          '#333547de'
        ],
        borderWidth: 1
      }
    ]
  };

  const options = {
    scales: {
      yAxes: [{
        ticks: {
          callback: function(value) {
            return value;
          }
        }
      }]
    }
  };

  return (
    <div style={{
      padding: 20
    }}>
      <Bar data={chartData} options={options}/>
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 16,
          fontWeight: '400',
          color: '#333'
        }}
      >{respostas?.toFixed(2)}% de respostas</span>
    </div>
  );

}
