import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import { sendPurchaseQrCode } from '../../store/actions'
import './styles.css'
import { Container, Row, Col, Button } from 'reactstrap'
import { Loading } from '../Loading'
import { activityService } from 'services/activity'

export const ModalConfirmResendPurchaseQrcodes = ({
  showModal,
  onClose,
  row,
  type = 'ticket'
}) => {
  const { sendActivityPurchaseQrCode } = activityService
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setLoading(false)
  }

  const handleSubmitSendQrcodes = async () => {
    console.log(row)
    setLoading(true)
    let response = null
    if (type === 'ticket') {
      response = await sendPurchaseQrCode({
        eventId: row?.eventId,
        purchaseId: row?.id
      })
    } else if (type === 'activity') {
      response = await sendActivityPurchaseQrCode({
        eventId: row?.eventId,
        purchaseId: row?.id
      })
    }
    console.log('==============')
    console.log(response)
    if (response.status === 200) {
      toast.success(response?.msg ? response?.msg : 'Qr codes enviados')
      onClose()
      setLoading(false)
    } else {
      toast.error(
        response?.msg || 'Ocorreu um erro ao tentar enviar os qr codes'
      )
      setLoading(false)
      return false
    }
  }

  const handleCancelSendQrcodes = () => {
    onClose()
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container>
        <Loading open={loading} handleClose={handleClose} />
          <form>
            <Row className="modalText">
              <Col xs={12}>
                <h1 className={'titleRoot'}>Confirmar reenvio de qr codes?</h1>
              </Col>
              <Col xs={12}>
                <h5 className={'titleRoot'}>
                  {' '}
                  Os Qr codes serão reenviados para o WhatsApp informado na hora
                  da venda
                </h5>
              </Col>
            </Row>

            <Row className="footerModalButtons">
              <Col xs={6}>
                <Button
                  className="buttonsModal"
                  onClick={handleSubmitSendQrcodes}
                  variant="contained"
                  color="primary"
                >
                  Confirmar
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  className="buttonsModal"
                  onClick={handleCancelSendQrcodes}
                  variant="contained"
                  color="secondary"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </form>
      </Container>
    </ReactModal>
  )
}
