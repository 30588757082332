import React from 'react'
import { FormGroup, Label } from 'reactstrap'
import { NumericFormat } from 'react-number-format'
import { Form } from 'react-bootstrap'

const CustomTextField = (props) => {
  return (
    <Form.Control
      {...props}
      fullWidth
      variant="outlined"
      InputProps={{ style: { backgroundColor: '#fff' } }}
    />
  )
}

export const CurrencyInput = ({
  variant = 'outlined',
  margin = 'normal',
  value,
  label,
  handleChange,
  handleBlur,
  error,
  helperText,
  ...rest
}) => {
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <NumericFormat
        value={value}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale={true}
        customInput={CustomTextField}
        margin={margin}
        variant={variant}
        onValueChange={(values) =>
          handleChange(values?.floatValue ? values.floatValue : null)
        }
        onBlur={handleBlur}
        error={error}
        helperText={helperText}
        {...rest}
      />
    </FormGroup>
  )
}
