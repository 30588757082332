import React from 'react'

interface ErrorMessageProps {
  field: string
  message: string
}

interface ValidationErrorMessageProps {
  errorMessage: ErrorMessageProps[]
}

export const ValidationErrorMessage = ({
  errorMessage
}: ValidationErrorMessageProps) => (
  <div>
    {errorMessage.map((error, index) => (
      <div key={index} dangerouslySetInnerHTML={{ __html: error.message }} />
    ))}
  </div>
)
