import React from 'react'
import { formatCurrency, isValidEmail } from '../../util/string'
import { PlusCircle, Trash } from 'react-feather'
import {
  Form,
  FormGroup,
  Label,
  FormText,
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap'
import styles from './styles.module.css'

export const CardTicket = ({ ticket, handleChangeTicketQuantity }) => {
  return (
    <Container xs={12} sm={10} md={6} lg={4} item className={styles.card}>
      <Col xs={6} sm={6} md={6} lg={6} item className="ticket-animate">
        <div className="box-ticket animate">
          <h3>{ticket.name}</h3>
          <div className={styles.price_box_ticket}>
            {formatCurrency({ value: ticket.activeValue })}
          </div>
          <Row className={styles.display_flex}>
            <Col xs={4}>
              <a
                style={{ fontSize: 40 }}
                className="btn btn-outline-danger"
                onClick={() => handleChangeTicketQuantity('minus', ticket.id)}
              >
                <span>
                  <Trash width="1rem" height="1rem" />
                </span>
              </a>
            </Col>
            <Col xs={4} className="mAuto">
              <span id="quantidade_ingressos2">{ticket.currentQtd}</span>
            </Col>
            <Col xs={4}>
              <a
                style={{ fontSize: 40 }}
                className="btn btn-outline-success"
                onClick={() => handleChangeTicketQuantity('plus', ticket.id)}
              >
                <span>
                  <PlusCircle />
                </span>
              </a>
            </Col>
          </Row>
        </div>
      </Col>
    </Container>
  )
}
