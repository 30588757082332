import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

export function useImageUpload() {
  const [imageUrl, setImageUrl] = useState(null)
  const [initialImageUrl, setInitialImageUrl] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0
  })

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      const fileRejection = fileRejections[0]
      console.log({ fileRejection })
      if (fileRejection.errors[0].code === 'file-too-large') {
        return toast.error('O arquivo pode ter no máximo 3M')
      }
      return toast.error('O arquivo escolhido não pode ser usado')
    }

    const file = acceptedFiles[0]

    const image = new Image()
    image.addEventListener('load', () => {
      try {
        console.log(`${image.width}x${image.height}`)
        const fileReader = new FileReader()

        fileReader.onload = function (fileLoadedEvent) {
          console.log(fileLoadedEvent)

          const imageBase64 = fileLoadedEvent.target?.result
          setImageDimensions({
            width: image.width,
            height: image.height
          })
          setImageUrl(imageBase64)
          setImageFile(file)
        }
        fileReader.readAsDataURL(file)
      } catch (error) {
        return toast.error('Ocorreu um erro ao fazer o upload do arquivo')
      }
    })
    image.src = URL.createObjectURL(file)
  }, [])

  return {
    imageUrl,
    imageFile,
    setImageUrl,
    initialImageUrl,
    setInitialImageUrl,
    onDrop,
    imageDimensions
  }
}
