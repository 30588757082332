import React, { useEffect, useState } from 'react'
import { onlyNumbers, phoneMask } from '../../util/string'
import ReactModal from 'react-modal'

import { toast } from 'react-toastify'
import { create } from '../../store/actions'
import { Loading } from '../Loading'
import './styles.scss'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'

export const ModalAddSellerToEvent = ({
  showModalAddSeller,
  onSubmit,
  onCancel,
  eventId,
  event
}) => {
  console.log({ event })

  const [loading, setLoading] = useState(false)
  const [copyLink, setCopyLink] = useState(null)

  const [newSeller, setNewSeller] = useState({
    name: null,
    phone: null
  })
  const [sellerVerified, setSellerVerified] = useState(false)

  const handleChange = ({ value, field }) => {
    if (field === 'phone' && value.length > 11) {
      return
    }
    setNewSeller((prev) => ({
      ...prev,
      [field]: value
    }))
  }

  const clearFields = () => {
    setNewSeller({
      name: null,
      phone: null
    })
    setSellerVerified(false)
    setCopyLink(null)
  }

  const handleSubmitAddSeller = async () => {
    try {
      setLoading(true)
      const response = await create({
        eventId,
        ...newSeller
      })
      console.log(response)

      if (response.success && response.data?.sellerId) {
        toast.success(response?.msg ? response?.msg : 'Vendedor adicionado')
        setLoading(false)
        setCopyLink(
          `${process.env.REACT_APP_SITE_URL}evento/${event?.slug}/?vendedor=${response.data.sellerId}`
        )
      } else {
        console.log('=============')
        toast.error(
          response?.msg || 'Ocorreu um erro ao tentar adicionar o vendedor'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  const handleCancelAddSeller = () => {
    clearFields()

    onCancel()
  }
  const handleCopy = () => {
    console.log('here')

    navigator.clipboard.writeText(copyLink)
    toast.success('O link de divulgação do vendedor foi copiado')
    onSubmit()
    clearFields()
  }

  return (
    <ReactModal
      isOpen={showModalAddSeller}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container>
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Adicionar vendedor ao evento</h1>
            </Col>
          </Row>
          <Row>
            <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
              <FormGroup>
                <Form.Label for="name">Nome *</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  id="name"
                  value={newSeller.name}
                  onChange={(event) =>
                    handleChange({ value: event.target.value, field: 'name' })
                  }
                />
              </FormGroup>
            </Col>
            <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
              <FormGroup>
                <Form.Label for="phone">Telefone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  id="phone"
                  value={phoneMask(newSeller.phone)}
                  onChange={(event) =>
                    handleChange({
                      value: onlyNumbers(event.target.value),
                      field: 'phone'
                    })
                  }
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className="footerModalButtons">
            <Col xs={6}>
              <Button
                onClick={handleCancelAddSeller}
                variant="secondary"
                color="secondary"
              >
                Fechar
              </Button>
            </Col>
            <Col xs={6}>
              {copyLink ? (
                <Button
                  type="button"
                  variant="success"
                  onClick={() => handleCopy()}
                >
                  Copiar link do vendedor
                </Button>
              ) : (
                <Button
                  onClick={handleSubmitAddSeller}
                  variant="primary"
                  color="primary"
                >
                  Adicionar
                </Button>
              )}
            </Col>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
