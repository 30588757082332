/* eslint-disable react/jsx-key */
/* eslint-disable array-bracket-newline */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Input,
  FormGroup,
  Label
} from 'reactstrap'
import { jsPDF } from "jspdf";
import { debounce } from 'lodash'

import { ToastContainer, toast } from 'react-toastify'
///MODAIS
import Select from 'react-select'
import DatatableTables from '../../components/Table'
import { Document, Page, Text, View, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer';
import {ModalEtiqueta} from '../../components/modalEtiquetas';
import {
  eventzzSelect
} from '../../store/actions'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
import { Loading } from 'components/Loading'
import eventzz from '../../services/eventzz'
import { phoneMask, cpfMask } from '../../util/string'
import { styles } from './styles';

const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Nome', field: 'name' },
  { label: 'Telefone', field: 'telefone' },
  { label: 'E-mail', field: 'email' }
]

const Etiqueta = () => {
  const { dadosSorteio, saveSorteio } = eventzz

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [etiquetas, setEtiquetas] = useState([])
  const [eventzzList, setEventzzList] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  const componentRef = useRef();
  const [searchTerm, setSearchTerm] = useState('')


  const [tableConfig, setTableConfig] = useState({
    options: {
      makeEtiqueta: true
    },
    config: {
      markAsUsedText: 'Confirmar utilização'
    },
    filters: {
      searchTerm: null,
      status: 'paid',
      eventId: null,
      ticketType: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

 
  const handleClose = () => {
    setLoading(false)
  }

  function handleChangeEvent(value) {
    console.log(value)
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        eventId: Number(value?.value) === 0 ? null : value?.value,
        label: value?.label
      }
    }))
    setLoading(true)
  }

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()
    if (response.status === 200) {
      const responseData = response?.data
      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  const onCloseModal = () => {
    setShowModal(false)
  }
  const loadDados = async () => {
    setLoading(true);
    const promisseResult = await Promise.all([
      dadosSorteio({
        eventId: tableConfig?.filters?.eventId,
        searchTerm: tableConfig?.filters?.searchTerm
      })
    ]).then(async response => {
      const result = response[0];
      if (result?.status === 200) {
      
        const formattedData = result?.data?.data?.items.map((item) => ({
          ...item,
          telefone: phoneMask(item.telefone)
        }))
        setFilteredRows(formattedData)
      } else {
        toast.error(result?.msg)
      }
      setLoading(false)
      return true
    })
  }

  useEffect(() => {
    if (tableConfig?.filters?.eventId) {
      loadDados();
    }
  }, [
    tableConfig?.filters?.eventId,
    tableConfig?.filters?.searchTerm
  ])

  function handleSearch(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        searchTerm: value
      }
    }))
  }


  useEffect(() => {
    loadEventzz()
  }, [])


  const gerarEtiqueta = (row) => {
    setEtiquetas(row)
    setShowModal(true)
  }

    
  const deboundChange = useCallback(debounce(handleSearch, 1000), [])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Etiquetas | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Etiquetas | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Etiquetas
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Card style={{padding:20}}>
                <Col xl={12} md={12}>
                  <Row>
                    <Col xl={6} md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Eventos</Label>
                        <Select
                          value={
                            eventzzList.find(
                              (a) => a.value === tableConfig?.filters?.eventId
                            )
                              ? eventzzList.find(
                                  (a) =>
                                    a.value === tableConfig?.filters?.eventId
                                )
                              : null
                          }
                          onChange={(value) => handleChangeEvent(value)}
                          options={eventzzList}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>
                    {tableConfig?.filters?.eventId && (
                      <>
                    <Col xl={3} md={4}>
                      <FormGroup>
                        <Label for="exampleEmail">Pesquisar</Label>
                        <Input
                          type="text"
                          name="search"
                          id="search"
                          value={searchTerm}
                          onChange={(e) => {
                            setSearchTerm(e.target.value)
                            deboundChange(e.target.value)
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={6}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => gerarEtiqueta(filteredRows)}
                              style={{ marginTop: '30px' }}
                            >
                              Gerar Todas Etiquetas
                            </Button>
                          </Col>
                          </>
                        )}
                  </Row>
                  <Row style={{padding:20}}>
                    {tableConfig?.filters?.eventId ? (
                      <>
                        <Row>
                        </Row>
                          <Row>
                            <Col xl={12} md={12}>
                              <DatatableTables
                                title={'Eventozz'}
                                columns={columns}
                                rows={filteredRows}
                                tableConfig={tableConfig}
                                gerarEtiqueta={gerarEtiqueta}
                                setTableConfig={setTableConfig}
                              />
                            </Col>
                          </Row>
                        </>
                    ) : (
                      <Col xl={12} md={12} style={{
                        padding: 150, display: 'flex', justifyContent: 'center'
                      }}>
                        <span>Nenhum Evento Selecionado</span>
                      </Col>
                    )}
                  </Row>
                </Col>
            </Card>
          </Row>
        </Container>
        <ModalEtiqueta
          showModal={showModal}
          onClose={onCloseModal}
          etiquetas={etiquetas}
          eventName={tableConfig?.filters?.label}
        />
      </div>
    </React.Fragment>
  )
}

Etiqueta.propTypes = {
  t: PropTypes.any
}

export default Etiqueta
