import { api } from './axios'

const Services = {
  solicitaSaque: async (params) => {
    const response = await api
      .get('/financial/saque', {
        params: {
          ...params
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  exportTickets: async (params) => {
    const response = await api
      .get('/tickets/excel', {
        params: {
          ...params
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getFinancialSummary: async (params) => {
    const response = await api
      .get('/financial/summary', {
        params: {
          ...params
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  listFinancialEvent: async ({ rowsPerPage, page, eventId }) => {
    const response = await api
      .get('/financial/fluxo-de-caixa', {
        params: {
          rowsPerPage,
          page,
          eventId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  // função chamada back -> front
  listFinancial: async ({
    rowsPerPage,
    page,
    startSaleDate,
    endSaleDate,
    eventId
  }) => {
    const response = await api
      .get('/financial/list', {
        params: {
          rowsPerPage,
          page,
          startSaleDate,
          endSaleDate,
          eventId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },

  /* Função que requisita as futuras liberações - OK */
  listFutureFinancial: async ({ rowsPerPage, page, eventId }) => {
    const response = await api
      .get('/financial/futurewithdraw', {
        params: {
          rowsPerPage,
          page,
          eventId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  exportFutureFinancial: async ({ eventId }) => {
    const response = await api
      .post('/financial/export-future-withdraw-xlsx', {
        eventId
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  courtesySave: async ({ body }) => {
    const response = await api
      .post('/courtesy/save', {
        ...body
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })
    return response
  },
  getTickets: async (id) => {
    const response = await api
      .get('/list/tickets', {
        params: {
          eventId: id
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  listTickets: async ({
    rowsPerPage,
    page,
    searchTerm,
    eventId,
    status,
    ticketType
  }) => {
    const response = await api
      .get('/tickets/list-dash', {
        params: {
          rowsPerPage,
          page,
          searchTerm,
          eventId,
          status,
          ticketType
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  create: async ({ eventId, name, phone }) => {
    const response = await api
      .post('/seller/create', {
        eventId,
        name,
        phone
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  create_cupom: async (cupom) => {
    const response = await api
      .post('/cupom/create', cupom)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  verify: async (params) => {
    const response = await api
      .get('/seller/verify', {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  atualizarUser: async (user) => {
    const response = await api
      .put('/ListaUsuarios-root/update-user', user)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  listSellersInEvent: async ({ eventId }) => {
    const response = await api
      .get('/eventzz/list-sellers', {
        params: {
          eventId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  markAsUsed: async ({ eventId, code }) => {
    const response = await api
      .post('/tickets/update', {
        eventId,
        code
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  listOrders: async ({
    rowsPerPage,
    page,
    searchTerm,
    startSaleDate,
    endSaleDate,
    eventId,
    statusTransaction,
    sellerId,
    plataforma,
    incluirCortesias
  }) => {
    const response = await api
      .get('/order/list', {
        params: {
          rowsPerPage,
          page,
          searchTerm,
          startSaleDate,
          endSaleDate,
          eventId,
          statusTransaction,
          sellerId,
          plataforma,
          incluirCortesias
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },

  listUsed: async ({ eventId, statusDescription, searchTerm, ticketType }) => {
    console.log('dados', {
      eventId,
      statusDescription,
      searchTerm,
      ticketType
    })

    const response = await api
      .get('/tickets/used-number-tickets', {
        params: {
          eventId,
          statusDescription,
          searchTerm,
          ticketType
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  }
}
export default Services
