import React, { useState, useCallback, useEffect, useRef } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Col, Container, Row } from 'reactstrap'
import { Loading } from 'components/Loading'
import 'jodit/build/jodit.min.css'
// import { TabPanel } from 'components/tabPanel'
import { toast } from 'react-toastify'
import { Button, Form } from 'react-bootstrap'
import styles from './styles.module.scss'
import { activityService } from 'services/activity'
import {
  CustomToolbarOption,
  CustomToolbarOptionsContainer,
  CustomToolbarOptionsTitle,
  DraggableContainer,
  DraggableHelper,
  QrcodeImg
} from './styledComponents'
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'

import { useImageUpload } from 'hooks/useImageUpload'
import htmlToDraft from 'html-to-draftjs'
import Draggable from 'react-draggable'
import ReactSlider from 'react-slider'
import { FileUpload } from 'components/fileUpload'
import qrCodeImage from '../../../assets/images/eventozz/qr-code.png'
import { stateFromHTML } from 'draft-js-import-html'
import { CustomButton } from 'components/customButton'
import draftToHtml from 'draftjs-to-html'
import JoditEditor from 'jodit-react'
import {
  StyledThumb,
  StyledTrack
} from 'components/eventozz/eventForm/tabTickets/styledComponents'

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  minHeight: 500,
  placeholder: 'Faça a descrição das atividades do seu evento'
}

const VariablesOptions = (props) => {
  function addName() {
    const { editorState, onChange } = props
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{Nome do participante}', //${name}
      editorState.getCurrentInlineStyle()
    )
    onChange(EditorState.push(editorState, contentState, 'insert-characters'))
  }

  function addEventName() {
    const { editorState, onChange } = props
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{Nome do evento}', //${eventName}
      editorState.getCurrentInlineStyle()
    )
    onChange(EditorState.push(editorState, contentState, 'insert-characters'))
  }

  function addActivityname() {
    const { editorState, onChange } = props
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{Nome da atividade}', //${ticketType}
      editorState.getCurrentInlineStyle()
    )
    onChange(EditorState.push(editorState, contentState, 'insert-characters'))
  }

  return (
    <>
      <CustomToolbarOptionsTitle style={{ width: '100%', display: 'flex' }}>
        Variáveis disponíveis
      </CustomToolbarOptionsTitle>
      <CustomToolbarOptionsContainer style={{ width: '100%', display: 'flex' }}>
        <CustomToolbarOption onClick={addName}>
          Nome do participante
        </CustomToolbarOption>
        <CustomToolbarOption onClick={addEventName}>
          Nome do evento
        </CustomToolbarOption>
        <CustomToolbarOption onClick={addActivityname}>
          Nome da atividade
        </CustomToolbarOption>
      </CustomToolbarOptionsContainer>
    </>
  )
}

export const ActivitySettings = ({ eventId }) => {
  const { findSettings, updateActivitySettings } = activityService
  const editorRef = useRef(null)

  const {
    imageFile: qrCodeBackgroundFile,
    imageUrl: qrCodeBackground,
    setImageUrl: setQrCodeBackground,
    onDrop
  } = useImageUpload({
    imageHeight: 1080,
    imageWidth: 1080
  })

  const [dropzoneError, setDropzoneError] = useState(false)

  const [msgError, setMsgError] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [qrCodeMsg, setQrCodeMsg] = useState(null)
  const [activitySettingsData, setActivitySettingsData] = useState(null)
  const [description, setDescription] = useState(null)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [qrCodeHeight, setQrCodeHeight] = useState(100)
  const [qrCodeWidth, setQrCodeWidth] = useState(100)
  const [qrCodePositionLeft, setQrCodePositionLeft] = useState(null)
  const [qrCodePositionTop, setQrCodePositionTop] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [errors, setErros] = useState(null)

  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setLoading(false)
  }

  const trackQrCodePosition = (data) => {
    setQrCodePositionLeft(data.x * 2)
    setQrCodePositionTop(data.y * 2)
  }

  const handleChangeQrCodeDimensions = useCallback(
    (value) => {
      setQrCodeWidth(value)
      setQrCodeHeight(value)
    },
    [setQrCodeWidth, setQrCodeHeight]
  )

  function handleChangeEditor(editorState) {
    setMsgError(false)
    setEditorState(editorState)
  }

  function handleChangeDescription(value) {
    setDescription(value)
  }

  function fillTextQrCodeMsgEditor(currentMsg) {
    if (!currentMsg) return

    let formattedMsg = currentMsg.replace(
      /\${name}|\${eventName}|\${ticketType}/gi,
      function (variable) {
        switch (variable) {
          case '${name}':
            return '{Nome do participante}'
          case '${eventName}':
            return '{Nome do evento}'
          case '${ticketType}':
            return '{Nome da atividade}'
          default:
            return ''
        }
      }
    )

    formattedMsg = formattedMsg.replace(/\*(.*)\*/g, function (variable) {
      variable = variable.replaceAll('*', '')
      return `<strong>${variable}</strong>`
    })

    formattedMsg = formattedMsg.replace(/\_(.*)\_/g, function (variable) {
      variable = variable.replaceAll('_', '')
      return `<em>${variable}</em>`
    })

    formattedMsg = formattedMsg.replace(/\~(.*)\~/g, function (variable) {
      variable = variable.replaceAll('~', '')
      return `<del>${variable}</del>`
    })

    setQrCodeMsg(formattedMsg)

    const blocksFromHtml = htmlToDraft(formattedMsg)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    setEditorState(EditorState.createWithContent(contentState))
  }

  function fillInitialData() {
    console.log(activitySettingsData)
    if (activitySettingsData?.description) {
      setDescription(activitySettingsData.description)
    }
    if (activitySettingsData?.qrCodeMsg) {
      console.log(activitySettingsData?.qrCodeBackground ? activitySettingsData.qrCodeBackground : null)
      console.log(activitySettingsData.qrCodeHeight ? activitySettingsData.qrCodeHeight : 100)
      console.log(activitySettingsData.qrCodeWidth ? activitySettingsData.qrCodeWidth : 100)
      console.log(activitySettingsData.qrCodePositionTop ? activitySettingsData.qrCodePositionTop : 0)
      console.log(activitySettingsData.qrCodePositionLeft ? activitySettingsData.qrCodePositionLeft : 0)
      setQrCodeBackground(activitySettingsData?.qrCodeBackground ? activitySettingsData.qrCodeBackground : null)
      setQrCodeHeight(activitySettingsData.qrCodeHeight ? activitySettingsData.qrCodeHeight : 100)
      setQrCodeWidth(activitySettingsData.qrCodeWidth ? activitySettingsData.qrCodeWidth : 100)
      setQrCodePositionTop(activitySettingsData.qrCodePositionTop ? activitySettingsData.qrCodePositionTop : 0)
      setQrCodePositionLeft(
        activitySettingsData.qrCodePositionLeft ? activitySettingsData.qrCodePositionLeft : 0
      )
      fillTextQrCodeMsgEditor(activitySettingsData.qrCodeMsg)
    }
  }

  const findActivitySettings = useCallback(async () => {
    const response = await findSettings({
      eventId
    })
    console.log(response)
    if (response.status === 200 && response?.data?.success) {
      console.log('there')
      setLoading(false)
      setActivitySettingsData(response.data.data)
    } else if (response.status === 401) {
      toast.error('Você não está logado ou sua sessão expirou')
      setLoading(false)
      return false
    } else if (response.status === 500) {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
      )
      setLoading(false)
      return false
    } else {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro ao buscar os dados do evento'
      )
      setLoading(false)
      return false
    }
  }, [eventId])

  function handleCancel() {
    console.log('cancelado')
    window.location.href = '/atividades-do-evento'
  }

  async function handleSubmit() {
    // console.log(ticketTypes);
    // return
    try {
      setLoading(true)
      let currentMsgValue = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      )
      currentMsgValue = currentMsgValue.replaceAll('<p></p>', '')

      const formattedMsg = currentMsgValue.replace(
        /\&nbsp;|{Nome do participante}|{Nome do evento}|{Nome da atividade}|<br>|<\/?p[^>]*>|<\/?strong[^>]*>|<\/?em[^>]*>|<\/?del[^>]*>/g,
        function (variable) {
          switch (variable) {
            case '</p>':
              return '\r\n'
            case '<br>':
              return '\r\n'
            case '<strong>':
              return '*'
            case '</strong>':
              return '*'
            case '<em>':
              return '_'
            case '</em>':
              return '_'
            case '<del>':
              return '~'
            case '</del>':
              return '~'
            case '{Nome do participante}':
              return '${name}'
              break
            case '{Nome do evento}':
              return '${eventName}'
              break
            case '{Nome da atividade}':
              return '${ticketType}'
              break
            default:
              return ''
          }
        }
      )

      let hasErrors = false

      if (!currentMsgValue?.trim()) {
        hasErrors = true
        setMsgError(true)
      }

      if (!qrCodeBackground) {
        hasErrors = true
        setDropzoneError(true)
      }

      if (hasErrors) {
        setLoading(false)
        return
      }

      const checkDescription = description.replace(
        /\&nbsp;|<br>|<\/?p[^>]*>|<\/?strong[^>]*>|<\/?em[^>]*>|<\/?del[^>]*>|<\/?s[^>]*>/g,
        function () {
          return ''
        }
      )

      const formData = new FormData()
      formData.append(
        'qrCodeBackgroundFile',
        qrCodeBackgroundFile ? qrCodeBackgroundFile : ''
      )
      formData.append('qrCodeWidth', qrCodeWidth ? qrCodeWidth?.toString() : '')
      formData.append(
        'qrCodeHeight',
        qrCodeHeight ? qrCodeHeight.toString() : ''
      )
      formData.append(
        'qrCodePositionLeft',
        qrCodePositionLeft ? qrCodePositionLeft.toString() : ''
      )
      formData.append(
        'qrCodePositionTop',
        qrCodePositionTop ? qrCodePositionTop.toString() : ''
      )
      formData.append('qrCodeMsg', formattedMsg ? formattedMsg : '')
      formData.append('description', checkDescription ? description : '')
      console.log(formData)
      console.log(eventId)

      const response = await updateActivitySettings({ formData, eventId })

      if (response.status === 200 && response?.data?.success) {
        toast.success('As alterações foram salvas', {
          autoClose: 2000,
          onClose: () => {
            window.location.href = '/atividades-do-evento'
          }
        })
        setLoading(false)
      } else if (response.status === 401) {
        toast.error('Você não está logado ou sua sessão expirou')
        setLoading(false)
        return false
      } else if (response.status === 500) {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
        )
        setLoading(false)
        return false
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao salvar as configurações das atividades'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log(error)
    }
  }

  const Thumb = (props, state) => (
    <StyledThumb {...props}>{state.valueNow}</StyledThumb>
  )

  const Track = (props, state) => <StyledTrack {...props} index={state.index} />

  useEffect(() => {
    if (!!eventId) {
      setLoading(true)
      findActivitySettings()
    }
  }, [eventId])

  useEffect(() => {
    if (!!activitySettingsData) {
      fillInitialData()
    }
  }, [activitySettingsData])

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Atividades | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Gestão de atividades do evento
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Loading open={loading} handleClose={handleClose} />
          <Row variant={null}>
            <Container className="pt-4">
              <Row>
                <Form.Label>Descrição das atividades</Form.Label>

                <div>
                  <JoditEditor
                    config={config}
                    ref={editorRef}
                    onChange={handleChangeDescription}
                    value={description}
                  />
                </div>
              </Row>
              <Row>
                <Col
                  xs={12}
                  md={6}
                  justifyContent="flex-start"
                  item
                  className="mt-5"
                >
                  <h5>Texto e imagem do Qr code</h5>
                </Col>
              </Row>

              <Row container xs={12}>
                <Col xs={12} md={6}>
                  {qrCodeBackground ? (
                    <>
                      <DraggableContainer qrCodeBackground={qrCodeBackground}>
                        <Draggable
                          onDrag={(e, data) => trackQrCodePosition(data)}
                          defaultPosition={{
                            x: qrCodePositionLeft / 2,
                            y: qrCodePositionTop / 2
                          }}
                          bounds={{
                            left: 0,
                            top: 0,
                            right: 540 - (qrCodeWidth ? qrCodeWidth / 2 : 0),
                            bottom: 540 - (qrCodeHeight ? qrCodeHeight / 2 : 0)
                          }}
                        >
                          <div className={styles.draggableArea}>
                            <DraggableHelper
                              width={qrCodeWidth ? qrCodeWidth / 2 : 0}
                              height={qrCodeHeight ? qrCodeHeight / 2 : 0}
                            />
                            <QrcodeImg
                              src={qrCodeImage}
                              alt="Imagem qrcode"
                              width={qrCodeWidth ? qrCodeWidth / 2 : 0}
                              height={qrCodeHeight ? qrCodeHeight / 2 : 0}
                            />
                          </div>
                        </Draggable>
                      </DraggableContainer>
                      <div className={styles.containerSlider}>
                        <ReactSlider
                          className="horizontal-slider"
                          marks
                          markClassName="example-mark"
                          min={100}
                          max={1080}
                          defaultValue={qrCodeWidth}
                          thumbClassName="example-thumb"
                          trackClassName="example-track"
                          renderThumb={Thumb}
                          renderTrack={Track}
                          onChange={(value) => {
                            handleChangeQrCodeDimensions(Number(value))
                          }}
                        />
                        <h5>
                          Largura do Qr code <b>{qrCodeWidth}px</b>
                        </h5>
                      </div>
                      <FileUpload
                        dropzoneError={false}
                        text="Clique aqui para alterar a imagem de background do qr code"
                        suggestion="(Imagem 1080x1080 px *.jpeg ou *.png)"
                        onDrop={onDrop}
                        className={styles.dropzoneAtFooter}
                      />
                    </>
                  ) : (
                    <FileUpload
                      dropzoneError={dropzoneError}
                      text="Solte a imagem aqui, ou clique para selecionar a imagem de background do qr code"
                      suggestion="(Imagem 1080x1080 px *.jpeg ou *.png)"
                      onDrop={onDrop}
                      className={styles.onlyDropzone}
                    />
                  )}
                </Col>
                <Col xs={12} md={6}>
                  {msgError && (
                    <>
                      <span className="is-invalid"></span>
                      <p
                        className={`invalid-feedback ${styles.helperTextEditor}`}
                      >
                        Esse campo é obrigatório
                      </p>
                    </>
                  )}
                  <Editor
                    handlePastedText={(text, html, editorState, onChange) => {
                      if (/<\/?[a-z][\s\S]*>/i.test(text)) {
                        const blockMap = stateFromHTML(text).blockMap
                        const newState = Modifier.replaceWithFragment(
                          editorState.getCurrentContent(),
                          editorState.getSelection(),
                          blockMap
                        )
                        onChange(
                          EditorState.push(
                            editorState,
                            newState,
                            'insert-fragment'
                          )
                        )
                        return true
                      }
                    }}
                    editorState={editorState}
                    wrapperClassName={`${styles.textEditorWrapper} ${
                      msgError ? styles.errorValidation : ''
                    }`}
                    editorClassName={styles.textEditorContent}
                    toolbarClassName={styles.textEditorToolbar}
                    onEditorStateChange={handleChangeEditor}
                    toolbarCustomButtons={[<VariablesOptions key={0} />]}
                    toolbar={{
                      options: ['inline'],
                      inline: {
                        options: ['bold', 'italic', 'strikethrough']
                      }
                    }}
                  />
                </Col>
              </Row>

              <Row className="pt-4 pb-4 justify-content-end">
                <Col xs={12} md={4} className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    type="button"
                    color="danger"
                    onClick={() => handleCancel()}
                    style={{ marginTop: '30px' }}
                  >
                    Voltar
                  </Button>
                  <CustomButton
                    className="mx-3"
                    variant="primary"
                    type="button"
                    onClick={() => handleSubmit()}
                    color="primary"
                    style={{ marginTop: '30px' }}
                    text="Salvar"
                  />
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  )
}
