import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Card, FormGroup, Label, Button } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
import CollapseComponent from '../../components/Table/OrderCollapse'
import { formatCurrency } from '../../util/string'
import { CardIndicador } from '../../components/CardIndicador'

///MODAIS
// alterar esse import direto para o vendas
import {
  listFinancial,
  listFutureFinancial,
  eventzzSelect
} from '../../store/actions'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
import { ModalSaque } from '../../components/modalSaque'
import { Select } from 'components/select'
import FinancialCollapse from 'components/Table/FinancialCollapse'
import { TableHeader } from './components/tableHeader'
import { exportFile } from 'util/function'
import Services from 'services/vendas'

/*Colunas das tabelas de próximos repasses*/
const nexttransfers = [
  { label: 'Data', field: 'data_liberacao' },
  { label: 'Valor', field: 'valor' }
]

/*Colunas das tabelas de transações concluídas*/
const columns = [
  { label: 'Cod', field: 'id' },
  { label: 'Evento', field: 'eventName' },
  { label: 'Data', field: 'saleData' },
  { label: 'Valor Repassado', field: 'value' },
  { label: 'Tipo', field: 'formPayment' }
  // { label: 'Comprovante', field: 'ticket', download: true }
]

const Financeiro = () => {
  const [loading, setLoading] = useState(false)
  const [loadingFutureWithdraw, setLoadingFutureWithdraw] = useState(false)
  const [loadingWithdrawalList, setLoadingWithdrawalList] = useState(false)
  const [isRoot, setIsRoot] = useState(false)
  const [currentRow, setCurrentRow] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [futurecurrentRow, setFuturecurrentRow] = useState([])
  const [eventzzList, setEventzzList] = useState([])
  const [rendered, setRendered] = useState(false)

  const [listResume, setListResume] = useState({
    totalAmount: 0,
    totalReceiver: 0,
    totalAvailable: 0,
    itsMoney: 0
  })

  /*Configurações da tabela de futuros repasses*/
  const [nexttransfersConfig, setnexttransfersConfig] = useState({
    options: {
      more: false,
      download: false,
      pagination: true,
      collapse: true
    },
    config: {
      handleDownloadText: 'Baixar comprovante'
    },
    filters: {
      futuredata: null,
      eventId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 10
  })

  /*Configurações da tabela de transações concluídas*/
  const [tableConfig, setTableConfig] = useState({
    options: {
      more: true,
      download: true
    },
    config: {
      handleDownloadText: 'Baixar comprovante'
    },
    filters: {
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 10
  })

  /*Carregando dados da tabela de futuras transações - */
  const loadFutureTransferList = async () => {
    if (tableConfig?.filters?.eventId) {
      const response = await listFutureFinancial({
        rowsPerPage: nexttransfersConfig.rowsPerPage,
        page: nexttransfersConfig.currentPage,
        eventId: tableConfig?.filters?.eventId
      })
      if (response.status === 200) {
        const responseData = response?.data?.items
        setFuturecurrentRow(responseData)
        setLoading(false)
        setLoadingFutureWithdraw(false)
        setnexttransfersConfig((prev) => ({
          ...prev,
          total: response?.data?.total
        }))
        return true
      } else {
        toast.error(response?.msg)
        setLoading(false)
        setLoadingFutureWithdraw(false)
        return false
      }
    }
  }

  /*Carregando dados da tabela de transações concluídas*/
  const loadTransferList = async () => {
    if (tableConfig?.filters?.eventId) {
      const response = await listFinancial({
        rowsPerPage: tableConfig.rowsPerPage,
        page: tableConfig.currentPage,
        startSaleDate: tableConfig?.filters?.startSaleDate,
        endSaleDate: tableConfig?.filters?.endSaleDate,
        eventId: tableConfig?.filters?.eventId
      })
      if (response.status === 200) {
        const responseData = response?.data

        const data = responseData?.items
        const total = responseData?.total
        const totalAmount = responseData?.totalAmount
        const totalReceiver = Number(responseData?.totalReceiver)
        const totalAvailable = Number(responseData?.totalAvailable)
        const itsMoney = Number(responseData?.itsMoney)

        const formattedData = data.map((item) => ({
          ...item,
          value: formatCurrency({ value: item.value })
        }))

        setCurrentRow(formattedData)
        setTableConfig((prev) => ({
          ...prev,
          total
        }))
        setListResume({
          totalAmount,
          totalReceiver,
          totalAvailable,
          itsMoney
        })
        setLoading(false)
        setLoadingWithdrawalList(false)
        return true
      } else {
        toast.error(response?.msg)
        setLoading(false)
        setLoadingWithdrawalList(false)
        return false
      }
    }
  }

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()

    if (response.isRoot) {
      setIsRoot(true)
    } else {
      setIsRoot(false)
    }
    if (response.status === 200) {
      const responseData = response?.data

      setEventzzList(responseData)
      setLoading(false)
      toast.info('Selecione um Evento')
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  function handleChangeEvent(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        eventId: value?.value === 0 ? null : value?.value
      }
    }))
    setLoading(true)
    setLoadingWithdrawalList(true)
    setLoadingFutureWithdraw(true)
  }

  function handleCreateNewTransfer() {
    window.location.href = '/financeiro/novo-repasse'
  }

  function handlerSaque() {
    if (tableConfig?.filters?.eventId) {
      setShowModal(true)
    } else {
      toast.info('Selecione um Evento')
    }
  }

  function handleDownload(row) {
    if (!row.ticket) {
      return toast.warning(
        'O comprovante dessa transferência não está disponível'
      )
    }
    const a = document.createElement('a')
    a.href = row.ticket
    a.download = 'true'
    a.click()
    return toast.success('Download realizado')
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  const handleExportXlsx = useCallback(async () => {
    setLoading(true)
    try {
      if (tableConfig?.filters?.eventId) {
        const response = await Services.exportFutureFinancial({
          eventId: tableConfig?.filters?.eventId
        })
        if (response.status === 200) {
          const responseData = response?.data
          console.log(responseData);
          
          exportFile({
            base64: responseData.data,
            fileName: responseData.fileName || 'Saques Futuros',
            extension: 'xlsx'
          })
          toast.success(
            response?.data?.msg || 'O arquivo foi exportado com sucesso!'
          )
          setLoading(false)
        } else {
          toast.error(
            response?.msg ||
              'Ocorreu um erro ao tentar exportar os próximos saques'
          )
          return false
        }
      }
    } catch (error) {
      setLoading(false)
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }

    if (rendered) {
      loadEventzz()
    }
  }, [rendered])

  useEffect(() => {
    loadTransferList()
  }, [tableConfig?.filters?.eventId, nexttransfersConfig?.filters.eventId])

  useEffect(() => {
    setLoadingFutureWithdraw(true)
    loadFutureTransferList()
  }, [
    tableConfig?.filters?.eventId,
    nexttransfersConfig?.filters.eventId,
    nexttransfersConfig.currentPage,
    nexttransfersConfig.rowsPerPage
  ])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Financeiro | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Financeiro | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão financeira
                  </li>
                </ol>
              </Col>
              {isRoot && (
                <Col md={4} className="text-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCreateNewTransfer()}
                    style={{ marginTop: '30px' }}
                  >
                    + Novo repasse
                  </Button>
                </Col>
              )}
            </Row>
          </div>

          <Row>
            <Card>
              <Row>
                <Col xl={12} md={12} style={{ padding: 20, paddingBottom: 64 }}>
                  <Row>
                    <CardIndicador
                      icon={'MONEY'}
                      value={listResume?.totalAmount}
                      type={'money'}
                      text={'Total de Vendas (Liquido)'}
                      color="primary"
                      xl={3}
                      md={6}
                      xs={6}
                    />
                    <CardIndicador
                      icon={'MONEY'}
                      value={listResume?.totalReceiver}
                      type={'money'}
                      text={'Repasse da Eventozz'}
                      color="primary"
                      xl={3}
                      md={6}
                      xs={6}
                    />
                    <CardIndicador
                      icon={'DINHEIRO'}
                      value={listResume?.itsMoney}
                      type={'money'}
                      text={'Dinheiro + Faturado'}
                      color="primary"
                      xl={3}
                      md={6}
                      xs={6}
                    />
                    <CardIndicador
                      icon={'MONEY'}
                      value={
                        listResume.totalAmount -
                        listResume.totalReceiver -
                        listResume.itsMoney
                      }
                      type={'money'}
                      text={'Falta Receber'}
                      color="primary"
                      xl={3}
                      md={6}
                      xs={6}
                    />
                    <CardIndicador
                      icon={'MONEY'}
                      value={
                        listResume.totalAvailable -
                        listResume.itsMoney -
                        listResume.totalReceiver
                      }
                      type={'money'}
                      text={'Liberado para Saque'}
                      color="primary"
                      xl={3}
                      md={6}
                      xs={6}
                    />
                    <CardIndicador
                      icon={'MONEY'}
                      value={listResume.totalAmount - listResume.totalAvailable}
                      type={'money'}
                      text={'Saque Bloqueado'}
                      color="primary"
                      xl={3}
                      md={6}
                      xs={6}
                    />
                  </Row>
                  <Row>
                    <Col xl={4} md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Eventos</Label>
                        <Select
                          value={
                            eventzzList.find(
                              (a) => a.value === tableConfig?.filters?.eventId
                            )
                              ? eventzzList.find(
                                  (a) =>
                                    a.value === tableConfig?.filters?.eventId
                                )
                              : null
                          }
                          onChange={(value) => handleChangeEvent(value)}
                          options={eventzzList}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                          hasErrors={false}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={4} md={6}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handlerSaque()}
                        style={{ marginTop: '30px' }}
                      >
                        Solicitar Saque
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {tableConfig?.filters?.eventId ? (
                <DatatableTables
                  title={
                    <TableHeader
                      title="Futuros Saques"
                      onClick={handleExportXlsx}
                    />
                  }
                  columns={nexttransfers}
                  rows={futurecurrentRow}
                  tableConfig={nexttransfersConfig}
                  setTableConfig={setnexttransfersConfig}
                  CollapseComponent={FinancialCollapse}
                  loading={loadingFutureWithdraw}
                  applyPadding={false}
                />
              ) : null}

              {tableConfig?.filters?.eventId ? (
                <DatatableTables
                  title={'Saques concluídos'}
                  columns={columns}
                  rows={currentRow}
                  tableConfig={tableConfig}
                  setTableConfig={setTableConfig}
                  CollapseComponent={CollapseComponent}
                  handleDownload={handleDownload}
                  loading={loadingWithdrawalList}
                  applyPadding={false}
                />
              ) : null}
            </Card>
          </Row>
        </Container>
      </div>
      <ModalSaque
        onClose={onCloseModal}
        eventId={tableConfig?.filters?.eventId}
        maxWithdrawal={
          listResume.totalAvailable -
          listResume.itsMoney -
          listResume.totalReceiver
        }
        showModal={showModal}
      />
    </React.Fragment>
  )
}

Financeiro.propTypes = {
  t: PropTypes.any
}

export default Financeiro
