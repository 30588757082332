import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import { Loading } from '../../components/Loading'
import { ToastContainer, toast } from 'react-toastify'
import DatatableTables from '../../components/Table'
import { Container, Row, Col, Card, FormGroup, Label, Button } from 'reactstrap'
import Select from 'react-select'
import { userListAll, eventzzSelect } from '../../store/actions'
import { formatDateT, cpfMask, phoneMask } from '../../util/string'
import { exportFile } from 'util/function'
import eventService from 'services/eventzz'
import { ModalAddEditUsers } from 'components/modalAddEditUsers'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'

// const columns = [
//   { label: 'Nome', field: 'name' },
//   { label: 'Email', field: 'email' },
//   { label: 'Telefone', field: 'fone' }
// ]
const columns = [
  { label: 'Nome', field: 'name' },
  { label: 'Email', field: 'email' },
  { label: 'Telefone', field: 'fone' }
]

const UserGeral = () => {
  const { exportEventozz } = eventService
  const [loading, setLoading] = useState(false)
  const [eventzzList, setEventzzList] = useState([])
  const [isRoot, setIsRoot] = useState(false)
  const [filteredRows, setFilteredRows] = useState([])
  const [userEdit, setUserEdit] = useState(null)
  const [showModalAddEditUsers, setShowModalAddEditUsers] = useState(false)
  const [updatedTable, setUpdatedTable] = useState(false)

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,

      tickets: false,
      more: false,
      pagination: true,
      markAsUsed: false,
      delete: false,
      userEdit: false
    },
    config: {},
    filters: {
      searchTerm: null,
      status: 'paid',
      eventId: null,
      ticketType: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const handleExportXlsx = useCallback(async () => {
    setLoading(true)
    try {
      const response = await exportEventozz({
        searchTerm: tableConfig?.filters?.searchTerm,
        aberto: tableConfig?.filters?.aberto,
        ativo: tableConfig?.filters?.ativo,
        dateFrom: tableConfig?.filters?.dateFrom,
        dateTo: tableConfig?.filters?.dateTo,
        category: tableConfig?.filters?.category,
        type: tableConfig?.filters?.type,
        isFree: tableConfig?.filters?.isFree
      })

      if (response?.data?.success && response?.data?.data) {
        const responseData = response?.data
        exportFile({
          base64: response.data.data,
          fileName: 'Meus eventos',
          extension: 'xlsx'
        })
        toast.success(
          response?.data?.msg || 'O arquivo foi exportado com sucesso!'
        )
        setLoading(false)
        return true
      } else {
        toast.error(
          response?.msg || 'Ocorreu um erro ao tentar listar os eventozz'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      setLoading(false)
    }
  }, [
    tableConfig.currentPage,
    tableConfig.rowsPerPage,
    tableConfig.filters,
    tableConfig.total
  ])

  const handleClose = () => {
    setLoading(false)
  }
  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()

    if (response.isRoot) {
      setIsRoot(true)
    } else {
      setIsRoot(false)
    }
    if (response.status === 200) {
      const responseData = response?.data

      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  const loadDados = useCallback(async () => {
    setLoading(true)
    const response = await userListAll({
      rowsPerPage: tableConfig.rowsPerPage,
      currentPage: tableConfig.currentPage,
      eventId: tableConfig.filters.eventId
    })

    console.log('------------------')
    console.log(response)

    if (response.status === 200) {
      console.log('------------------')
      console.log(response)
      const responseData = response?.data
      const total = response?.data?.length
      console.log(total)
      const formattedData = responseData?.map((item) => ({
        ...item,
        fone: phoneMask(item?.phone)
      }))
      setTableConfig((prev) => ({
        ...prev,
        total
      }))

      setFilteredRows(formattedData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [
    tableConfig.currentPage,
    tableConfig.rowsPerPage,
    tableConfig.filters,
    tableConfig.total
  ])
  function handleChangeEvent(value) {
    setTableConfig((prev) => ({
      ...prev,

      currentPage: 0,
      filters: {
        ...prev.filters,
        eventId: value?.value === 0 ? null : value?.value
      }
    }))
    setLoading(true)
  }

  useEffect(() => {
    if (tableConfig.filters.eventId) {
      loadDados()
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  useEffect(() => {
    loadEventzz()
  }, [])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleCLose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Usuário Geral | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">
                  Usuários Gerais do sistema | Eventozz
                </h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Usuários Gerais
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl={3} md={4}>
              <FormGroup>
                <Label for="exampleEmail">Evento</Label>
                <Select
                  value={
                    eventzzList.find(
                      (a) => a.value === tableConfig?.filters?.eventId
                    )
                      ? eventzzList.find(
                          (a) => a.value === tableConfig?.filters?.eventId
                        )
                      : null
                  }
                  onChange={(value) => handleChangeEvent(value)}
                  options={eventzzList}
                  classNamePrefix="select2-selection"
                  placeholder="Selecione..."
                />
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => handleExportXlsx()}
                  style={{ marginTop: '30px' }}
                >
                  Exportar xlsx
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Container>

        <DatatableTables
          title={'Usuários'}
          columns={columns}
          rows={filteredRows}
          tableConfig={tableConfig}
          setTableConfig={setTableConfig}
          // onUserEdit={handleOpenUserEdit}
        />
      </div>
    </React.Fragment>
  )
}

UserGeral.propTypes = {
  t: PropTypes.any
}
export default UserGeral
