import React, { useCallback, useEffect, useState } from 'react'
import { isValidEmail, phoneMask } from '../../util/string'
import { isValidCpf, dateMask } from '../../util/function'
import ErrorImage from '../../assets/images/eventozz/checkout/erro.svg'
import SuccessImage from '../../assets/images/eventozz/checkout/success.svg'
import ReactModal from 'react-modal'
import { InputsTicketInfo } from './inputsTicketInfo'
import styles from './styles.module.css'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormGroup,
  FormFeedback,
  Input,
  Label
} from 'reactstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Loading } from '../Loading'
import { atualizarUser } from 'store/actions'

import { X } from 'react-feather'

export const ModalAddEditUsers = ({
  showModal,
  onSuccess,
  onCancel,
  userEdit,
  setUserEdit
}) => {
  const [loading, setLoading] = useState(false)

  const [estado, setEstado] = useState(null)

  const [error, setError] = useState(null)
  const [activeStep, setActiveStep] = useState({
    step: 0,
    description: 'Choose tickets'
  })

  const handleChange = ({ value, field }) => {
    console.log({ value, field })
    setUserEdit((prev) => ({
      ...prev,
      [field]: value
    }))
    if (value !== null || value !== undefined) {
      setError('')
      console.log('deu certo')
    } else {
      setError('O Campo está nulo')
      console.log('O campo está nulo')
    }
  }

  const handleClose = () => {
    setLoading(false)
    onCancel()
    setActiveStep({
      step: 0,
      description: 'Choose tickets'
    })
  }

  const handleCloseSuccess = () => {
    onSuccess()
    setActiveStep({
      step: 0,
      description: 'Choose tickets'
    })
  }
  const handleSubmitEditUser = async () => {
    setLoading(true)
    // console.log({
    //   eventId,
    //   nome_cortesia: newCupom.nome_cortesia,
    //   quantidade_disponivel: newCupom.quantidade_disponivel,
    //   tipo_desconto: newCupom.tipo_desconto,
    //   expiration: newCupom.expiration
    // })

    if (
      (userEdit ||
        userEdit?.name ||
        userEdit?.address_city ||
        userEdit?.address_district ||
        userEdit?.address_street ||
        userEdit?.address_number ||
        userEdit?.cpf ||
        userEdit?.address_cep ||
        userEdit?.nascimento ||
        userEdit?.fone) === null
    ) {
      toast.error('Os campos não estão preenchidos')
      setLoading(false)

      return false
    } else {
      const payload = {
        ...userEdit,
        id: userEdit?.id
      }
      console.log(payload)
      const response = await atualizarUser(payload)
      if (response.status === 200) {
        toast.success(response?.msg ? response?.msg : 'Cupom Adicionado')
        onSuccess()
        // clearFields()
        setLoading(false)
      } else {
        toast.error(
          response?.msg || 'Ocorreu um erro ao tentar adicionar o cupom'
        )
        setLoading(false)
        return false
      }
    }
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
    >
      <Container>
        <Loading open={loading} handleClose={handleClose} />
        <span
          className="closeButtom"
          style={{ cursor: 'pointer' }}
          onClick={handleClose}
        >
          <X />
        </span>
        <Card className={'modalBody'}>
          <form style={{ padding: '10px' }}>
            <Row className="modalText">
              <Col xs={12}>
                <h1 className={'titleRoot'}>
                  Editar usuário {userEdit?.name ? ` - ${userEdit?.name}` : ''}
                </h1>
              </Col>
              <Row>
                <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <Label>Nome do Usuário</Label>
                    <Input
                      type="text"
                      name="nome"
                      id="name"
                      invalid={!!error}
                      value={userEdit?.name}
                      onChange={(event) =>
                        handleChange({
                          value: event.target.value,
                          field: 'name'
                        })
                      }
                    />
                    <FormFeedback>{error}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <Label>Cidade</Label>
                    <Input
                      type="text"
                      name="address_city"
                      id="address_city"
                      invalid={!!error}
                      value={userEdit?.address_city}
                      onChange={(event) =>
                        handleChange({
                          value: event.target.value,
                          field: 'address_city'
                        })
                      }
                    />
                    <FormFeedback>{error}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <Label>Bairro</Label>
                    <Input
                      type="text"
                      name="address_district"
                      id="address_district"
                      invalid={!!error}
                      value={userEdit?.address_district}
                      onChange={(event) =>
                        handleChange({
                          value: event.target.value,
                          field: 'address_district'
                        })
                      }
                    />
                    <FormFeedback>{error}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <Label>Rua</Label>
                    <Input
                      type="text"
                      name="address_street"
                      id="address_street"
                      invalid={!!error}
                      value={userEdit?.address_street}
                      onChange={(event) =>
                        handleChange({
                          value: event.target.value,
                          field: 'address_street'
                        })
                      }
                    />
                    <FormFeedback>{error}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
                  <Label> Número da casa</Label>
                  <FormGroup>
                    <Input
                      fullWidth
                      type="number"
                      invalid={!!error}
                      value={userEdit?.address_number}
                      onChange={(event) =>
                        handleChange({
                          value: event.target.value,
                          field: 'address_number'
                        })
                      }
                      margin="normal"
                      variant="outlined"
                      className={styles.textFieldRoot}
                    />
                    <FormFeedback>{error}</FormFeedback>
                  </FormGroup>
                </Col>

                <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
                  <Label> CPF</Label>
                  <FormGroup>
                    <Input
                      fullWidth
                      type="text"
                      invalid={!!error}
                      value={userEdit?.cpf}
                      onChange={(event) =>
                        handleChange({
                          value: event.target.value,
                          field: 'cpf'
                        })
                      }
                      margin="normal"
                      variant="outlined"
                      className={styles.textFieldRoot}
                    />
                    <FormFeedback>{error}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
                  <Label> CEP </Label>
                  <FormGroup>
                    <Input
                      fullWidth
                      type="text"
                      invalid={!!error}
                      value={userEdit?.address_cep}
                      onChange={(event) =>
                        handleChange({
                          value: event.target.value,
                          field: 'address_cep'
                        })
                      }
                      margin="normal"
                      variant="outlined"
                      className={styles.textFieldRoot}
                    />
                    <FormFeedback>{error}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <Label for="exampleEmail">Data de nascimento</Label>
                    <Input
                      type="text"
                      name="Data de nascimento"
                      id="inicio"
                      invalid={!!error}
                      value={dateMask(userEdit?.nascimento)}
                      format="DD/MM/yyyy"
                      onChange={(event) => {
                        handleChange({
                          value: event.target.value,
                          field: 'nascimento'
                        })
                      }}
                    />
                    <FormFeedback>{error}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
                  <Label>Telefone</Label>
                  <FormGroup>
                    <Input
                      fullWidth
                      type="text"
                      invalid={!!error}
                      value={phoneMask(userEdit?.fone)}
                      className={[styles.inputCurrency, styles.textFieldRoot]}
                      name="fone"
                      margin="normal"
                      variant="outlined"
                      onChange={(event) =>
                        handleChange({
                          value: event.target.value,
                          field: 'fone'
                        })
                      }
                    />
                    <FormFeedback>{error}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="footerModalButtons">
                <Col xs={6}>
                  <Button
                    className="buttonsModal"
                    // onClick={() => handleCancelAddCupom()}
                    variant="contained"
                    color="secondary"
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className="buttonsModal"
                    onClick={() => handleSubmitEditUser()}
                    variant="contained"
                    color="primary"
                  >
                    Adicionar
                  </Button>
                </Col>
              </Row>
            </Row>
          </form>
        </Card>
      </Container>
    </ReactModal>
  )
}
