export function formatCurrency({ value, style = 'currency', decimal = 2 }) {
  return Intl.NumberFormat('pt-br', {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
    style,
    currency: 'BRL'
  }).format(value)
}

export function formatDateIso({ value }) {
  try {
    const valueTemp = value.split('/')
    return valueTemp.reverse().join('-')
  } catch (error) {
    return ''
  }
  return
}
export function removeAcento(text) {
  text = text.toLowerCase()
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c')
  text = text.replace(new RegExp('[Ĺ]', 'gi'), 'l')
  return text
}

export function isValidEmail(email) {
  const usuario = email.substring(0, email.indexOf('@'))
  const dominio = email.substring(email.indexOf('@') + 1, email.length)
  if (
    usuario.length >= 1 &&
    dominio.length >= 3 &&
    usuario.search('@') === -1 &&
    dominio.search('@') === -1 &&
    usuario.search(' ') === -1 &&
    dominio.search(' ') === -1 &&
    dominio.search('.') !== -1 &&
    dominio.indexOf('.') >= 1 &&
    dominio.lastIndexOf('.') < dominio.length - 1
  ) {
    return true
  } else {
    return false
  }
}

export function onlyNumbers(value) {
  return value?.replace(/[^0-9]+/g, '')
}

export function onlyUnsignedNumbers(_string) {
  return _string?.replace(/[^0-9]+/g, '')
}

export const cpfMask = (cpfCnpj) => {
  if (cpfCnpj === null || cpfCnpj === undefined) return
  //if (String(cpfCnpj).length === 11) {
  return String(cpfCnpj)
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
  //}
}

export const handleFormatDate = (data) => {
  return data.map((item) => {
    item['updated_at'] = new Intl.DateTimeFormat('pt-br', {
      timeStyle: 'medium',
      dateStyle: 'short'
    }).format(new Date(item['updated_at']))

    item['expiration'] = new Intl.DateTimeFormat('pt-br', {
      dateStyle: 'short'
    }).format(new Date(item['expiration']))
    return item
  })
}

export const phoneMask = (number) => {
  const phone = onlyNumbers(number)
  return phone?.replace(/(\d{2})(\d{5})(\d)/, '($1) $2-$3')
}

export const percentageMask = (number) => {
  return number.replace(/[^0-9,.]+/g, '').replace(/([0-9,.]+?$)/, '$1%')
}

export const formatDate = (date) => {
  const splitDate = date.split('/')
  if (splitDate.length === 3) {
    const formattedDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
    return formattedDate
  }
  return '01-01-2000'
}

export const formatDateT = (date) => {
  if (date === null || date === undefined) return

  let splitDate = date.split('T')
  splitDate = splitDate[0].split('-')

  if (splitDate.length === 3) {
    const formattedDate = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
    return formattedDate
  }
  return '01-01-2000'
}
export const formatDateHourT = (date) => {
  const splitDate = date.split('T')
  const newDate = splitDate[0].split('-')
  const newDateHour = splitDate[1].split(':')

  if (newDate.length === 3) {
    const formattedDate = `${newDate[2]}/${newDate[1]}/${newDate[0]} ${
      Number(newDateHour[0]) - 6
    }:${newDateHour[1]}`
    return formattedDate
  }
  return '01-01-2000'
}

export const dataMask = (date) => {
  if (date === null || date === undefined) return

  return String(date)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})\d+?$/, '$1')
}

export const cepMask = (cep) => {
  if (!cep) return ''

  return cep
    .replace(/\D/g, '') // remove todos os caracteres que não são dígitos
    .replace(/(\d{5})(\d)/, '$1-$2') // adiciona o traço entre os dois grupos de dígitos
}
