import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Input,
  FormGroup,
  Label
} from 'reactstrap'
import Form from 'react-bootstrap/Form'

import 'chartist/dist/scss/chartist.scss'
import DatatableTables from '../../components/Table'
import { CollapseEventozz } from '../../components/Table/EventozzCollapse'
import { eventzzList } from '../../store/actions'
import { format } from 'date-fns'
import { Loading } from '../../components/Loading'
import { ModalAddSellerToEvent } from '../../components/modalAddSellerToEvent'
import { ModalAddCourtesyTicket } from '../../components/modalAddCourtesyTicket'
import { ModalAddCupomToEvent } from '../../components/modalAddCupom'
import { ModalAddAfiliado } from '../../components/modalAddAfiliado'
import { toast } from 'react-toastify'
import ReactDatePicker from 'react-datepicker'
import { debounce } from 'lodash'
import eventService from 'services/eventzz'
import afiliadoService from 'services/afiliado'
import { exportFile } from 'util/function'

const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Nome', field: 'eventName' },
  { label: 'Categoria', field: 'eventCategory' },
  { label: 'Inicio', field: 'startDate' },
  { label: 'Fim', field: 'endDate' },
  { label: 'Tipo de taxa', field: 'fee' },
  { label: 'Gratuito', field: 'isFree' },
  { label: 'Aberto', field: 'aberto' },
  { label: 'Ativo', field: 'ativo' },
  { label: 'Data de criação', field: 'createdAt' }
]

const Eventos = () => {
  const { exportEventozz } = eventService
  const { ServiceAddAfiliado } = afiliadoService
  const [rendered, setRendered] = useState(false)
  const [filteredRows, setFilteredRows] = useState([])
  const [eventId, setEventId] = useState(null)
  const [currentEvent, setCurrentEvent] = useState(null)
  const [eventName, setEventName] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [showModalAddSeller, setShowModalAddSeller] = useState(false)
  const [showModalAddCupom, setShowModalAddCupom] = useState(false)
  const [showModalCourtesyTicket, setShowModalCourtesyTicket] = useState(false)
  const [loading, setLoading] = useState(true)
  const [startSaleDate, setStartDate] = useState(null)
  const [showModalAfiliado, setShowModalAfiliado] = useState(false)

  const [endSaleDate, setEndDate] = useState(null)
  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: true,
      eventzz: true,
      more: true,
      addAfiliado: true,
      pagination: true,
      add: true,
      addCourtesyTicket: true,
      edit: true,
      addSpecialTicket: true,
      addCupom: true,
      addOrganizer: true,
      tickets: true
    },
    config: {
      addText: 'Adicionar vendedor',
      addCourtesyTicketText: 'Criar ingresso de cortesia',
      addAfiliado: 'Adicionar Indicação',
      handleEditText: 'Editar evento',
      addSpecialTicketText: 'Adicionar ingresso especial',
      addCupomText: 'Adicionar Cupom',
      addOrganizerText: 'Adicionar Organizador',
      ticketsText: 'Ingressos disponíveis'
    },
    filters: {
      searchTerm: null,
      dateFrom: null,
      dateTo: null,
      aberto: true,
      ativo: true,
      category: null,
      type: null,
      isFree: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 10
  })

  const loadEventzz = useCallback(async () => {
    const response = await eventzzList({
      rowsPerPage: tableConfig.rowsPerPage,
      page: tableConfig.currentPage,
      searchTerm: tableConfig?.filters?.searchTerm,
      aberto: tableConfig?.filters?.aberto,
      ativo: tableConfig?.filters?.ativo,
      dateFrom: tableConfig?.filters?.dateFrom,
      dateTo: tableConfig?.filters?.dateTo,
      category: tableConfig?.filters?.category,
      type: tableConfig?.filters?.type,
      isFree: tableConfig?.filters?.isFree
    })

    if (response.status === 200) {
      const responseData = response?.data
      const data = responseData?.items
      const total = responseData?.total

      const formattedData = data.map((item) => ({
        ...item,
        startDate: format(
          new Date(String(item?.startDate)),
          'dd/MM/yyyy HH:mm:ss'
        ),
        endDate: format(new Date(String(item?.endDate)), 'dd/MM/yyyy HH:mm:ss'),
        createdAt: format(new Date(item?.createdAt), 'dd/MM/yyyy HH:mm:ss'),
        commingSoon: item?.commingSoon
          ? format(new Date(item?.commingSoon), 'dd/MM/yyyy HH:mm:ss')
          : '',
        startSaleDate: item?.startSaleDate
          ? format(new Date(item?.startSaleDate), 'dd/MM/yyyy HH:mm:ss')
          : '',
        endSaleDate: item?.endSaleDate
          ? format(new Date(item?.endSaleDate), 'dd/MM/yyyy HH:mm:ss')
          : '',
        isFree: item?.isFree ? 'Sim' : 'Não',
        fee: item?.fee === 'organizador' ? 'Organizador' : 'Cliente',
        aberto: !item?.fechado ? 'Sim' : 'Não',
        ativo: item?.active ? 'Sim' : 'Não'
      }))
      setFilteredRows(formattedData)
      setTableConfig((prev) => ({
        ...prev,
        total
      }))

      setLoading(false)
      return true
    } else {
      toast.error(
        response?.msg || 'Ocorreu um erro ao tentar listar os eventozz'
      )
      setLoading(false)
      return false
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  function handleClearFilters() {
    setSearchTerm('')
    setStartDate(null)
    setEndDate(null)
    setTableConfig((prev) => ({
      ...prev,
      filters: {
        searchTerm: null,
        dateFrom: null,
        dateTo: null,
        aberto: true,
        ativo: true,
        category: null,
        type: null,
        isFree: null
      }
    }))
    setLoading(true)
  }

  const handleExportXlsx = useCallback(async () => {
    setLoading(true)
    try {
      const response = await exportEventozz({
        searchTerm: tableConfig?.filters?.searchTerm,
        aberto: tableConfig?.filters?.aberto,
        ativo: tableConfig?.filters?.ativo,
        dateFrom: tableConfig?.filters?.dateFrom,
        dateTo: tableConfig?.filters?.dateTo,
        category: tableConfig?.filters?.category,
        type: tableConfig?.filters?.type,
        isFree: tableConfig?.filters?.isFree
      })

      if (response?.data?.success && response?.data?.data) {
        exportFile({
          base64: response.data.data,
          fileName: 'Meus eventos',
          extension: 'xlsx'
        })
        toast.success(
          response?.data?.msg || 'O arquivo foi exportado com sucesso!'
        )
        setLoading(false)
        return true
      } else {
        toast.error(
          response?.msg || 'Ocorreu um erro ao tentar listar os eventozz'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      setLoading(false)
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  function handleAddSeller(row) {
    setEventId(row?.id)
    setCurrentEvent(row)
    setShowModalAddSeller(true)
  }

  function handleAddCourtesyTicket(row) {
    setEventId(row?.id)
    setEventName(row?.eventName)
    setShowModalCourtesyTicket(true)
  }

  function handleSubmitAddSeller() {
    setEventId(null)
    setShowModalAddSeller(false)
  }

  function handleCancelAddSeller() {
    setEventId(null)
    setShowModalAddSeller(false)
  }

  function handleSuccessCourtesyTicket() {
    setShowModalCourtesyTicket(false)
    setEventId(null)
    setEventName(null)
  }

  function handleSuccessCupom() {
    setShowModalAddCupom(false)
    setEventId(null)
  }

  function handleCancelCourtesyTicket() {
    setShowModalCourtesyTicket(false)
    setEventId(null)
    setEventName(null)
  }

  function handleCancelCupom() {
    setShowModalAddCupom(false)
    setEventId(null)
  }

  function handleOpenEventEdit(row) {
    window.location.href = `/eventozz/${row.id}`
  }
  function handleOpenCupom(row) {
    setEventId(row?.id)
    setShowModalAddCupom(true)
  }

  function handleAddSpecialTicket(row) {
    window.location.href = `/eventozz/${row.id}/novo-ingresso-especial`
  }

  function handleAddOrganizer(row) {
    console.log({ row })
    window.location.href = `/eventozz/${row.id}/adicionar-permissoes-organizador`
  }

  function handleManageTicketType(row) {
    console.log({ row })
    window.location.href = `/eventozz/${row.id}/ingressos-disponiveis`
  }

  const handleClose = () => {
    setLoading(false)
  }

  function handleDateChange(date, field) {
    const formattedDate = new Intl.DateTimeFormat('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(date)

    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        [field]: date ? formattedDate : null
      }
    }))
    setLoading(true)
  }

  function handleSearch(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        searchTerm: value
      }
    }))
    setLoading(true)
  }

  function handleCheckAtivoEventozz(checked) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        ativo: checked ? true : null
      }
    }))
    setLoading(true)
  }
  function handleCheckAbertoEventozz(checked) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        aberto: checked ? true : null
      }
    }))
    setLoading(true)
  }
  function handleCheckEventozzFree(checked) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        isFree: checked ? true : null
      }
    }))
    setLoading(true)
  }

  function handleCreateEvent() {
    window.location.href = '/eventozz/novo'
  }

  const deboundChange = useCallback(debounce(handleSearch, 1000), [])

  const deboundDateChange = useCallback(debounce(handleDateChange, 1000), [])

  function handleAfiliado(row) {
    setEventId(row?.id)
    setEventName(row?.eventName)
    setShowModalAfiliado(true)
  }

  async function handleSuccessAfiliado(idEventoAfiliado, idAfiliado) {
    setShowModalAfiliado(false)
    setEventId(null)
    setEventName(null)
    setLoading(true)

    const response = await ServiceAddAfiliado({
      idEventoAfiliado,
      idAfiliado
    })

    if (response?.status === 200) {
      toast.success(response?.data?.msg || 'Indicação cadastrada com sucesso!')
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg || 'Erro ao cadastrar Indicação')
      setLoading(false)
      return false
    }
  }

  function handleCancelAfiliado() {
    setShowModalAfiliado(false)
    setEventId(null)
    setEventName(null)
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    } else {
      loadEventzz()
    }
  }, [
    tableConfig.currentPage,
    tableConfig.rowsPerPage,
    tableConfig.filters,
    rendered
  ])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}

      <div className="page-content">
        <MetaTags>
          <title>Eventos | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col xs={12} md={8}>
                <h6 className="page-title">Eventos</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Gestão de Eventos</li>
                </ol>
              </Col>
              <Col md={4} className="text-end">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleCreateEvent()}
                  style={{ marginTop: '30px' }}
                >
                  + Novo evento
                </Button>
              </Col>
            </Row>
          </div>
          <Row>
            <Card>
              <Row>
                <Col xl={12} md={12} style={{ padding: 20 }}>
                  <Row>
                    <Col xl={3} md={4}>
                      <FormGroup>
                        <Label for="exampleEmail">Início</Label>
                        <ReactDatePicker
                          className="form-control"
                          selected={startSaleDate}
                          onChange={(date) => {
                            deboundDateChange(date, 'dateFrom')
                            setStartDate(date)
                          }}
                          placeholderText="dd/mm/aaaa"
                          dateFormat="dd/MM/yyyy"
                          locale="pt-BR"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={4}>
                      <FormGroup>
                        <Label for="dateTo">Fim</Label>
                        <ReactDatePicker
                          id="dateTo"
                          className="form-control"
                          selected={endSaleDate}
                          onChange={(date) => {
                            deboundDateChange(date, 'dateTo')
                            setEndDate(date)
                          }}
                          placeholderText="dd/mm/aaaa"
                          dateFormat="dd/MM/yyyy"
                          locale="pt-BR"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={4}>
                      <FormGroup>
                        <Label for="exampleEmail">Pesquisar</Label>
                        <Input
                          type="text"
                          name="search"
                          id="search"
                          value={searchTerm}
                          onChange={(e) => {
                            setSearchTerm(e.target.value)
                            deboundChange(e.target.value)
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={3} md={4}>
                      <Form style={{ marginTop: 30 }}>
                        <Form.Check
                          type="switch"
                          label="Eventozz Abertos"
                          id="custom-switch"
                          checked={Boolean(tableConfig?.filters?.aberto)}
                          onChange={(event) =>
                            handleCheckAbertoEventozz(event.target.checked)
                          }
                        />
                      </Form>
                    </Col>
                    <Col xl={3} md={4}>
                      <Form style={{ marginTop: 30 }}>
                        <Form.Check
                          type="switch"
                          label="Eventozz Ativos"
                          id="custom-switch"
                          checked={Boolean(tableConfig?.filters?.ativo)}
                          onChange={(event) =>
                            handleCheckAtivoEventozz(event.target.checked)
                          }
                        />
                      </Form>
                    </Col>
                    <Col xl={3} md={4}>
                      <Form style={{ marginTop: 30 }}>
                        <Form.Check
                          type="switch"
                          label="Eventozz gratuitos"
                          id="custom-switch"
                          checked={Boolean(tableConfig?.filters?.isFree)}
                          onChange={(event) =>
                            handleCheckEventozzFree(event.target.checked)
                          }
                        />
                      </Form>
                    </Col>

                    <Col
                      xl={9}
                      md={4}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleClearFilters()}
                        style={{ marginTop: '30px', marginRight: 15 }}
                      >
                        Limpar filtros
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => handleExportXlsx()}
                        style={{ marginTop: '30px' }}
                      >
                        Exportar xlsx
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <DatatableTables
                title={'Eventozz'}
                columns={columns}
                rows={filteredRows}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
                CollapseComponent={CollapseEventozz}
                onAdd={handleAddSeller}
                onAddCourtesyTicket={handleAddCourtesyTicket}
                onAddAfiliado={handleAfiliado}
                onEdit={handleOpenEventEdit}
                onEventCupom={handleOpenCupom}
                onAddSpecialTicket={handleAddSpecialTicket}
                onAddOrganizer={handleAddOrganizer}
                onTicket={handleManageTicketType}
              />
            </Card>
          </Row>
          <ModalAddSellerToEvent
            showModalAddSeller={showModalAddSeller}
            onSubmit={handleSubmitAddSeller}
            onCancel={handleCancelAddSeller}
            eventId={eventId}
            event={currentEvent}
          />
          <ModalAddCourtesyTicket
            showModal={showModalCourtesyTicket}
            onSuccess={handleSuccessCourtesyTicket}
            onCancel={handleCancelCourtesyTicket}
            eventId={eventId}
            eventName={eventName}
          />
          <ModalAddCupomToEvent
            showModal={showModalAddCupom}
            onSuccess={handleSuccessCupom}
            onCancel={handleCancelCupom}
            eventId={eventId}
          />
          <ModalAddAfiliado
            showModal={showModalAfiliado}
            onSuccess={handleSuccessAfiliado}
            onCancel={handleCancelAfiliado}
            eventId={eventId}
            eventName={eventName}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Eventos
