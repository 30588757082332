import React from 'react'
import { Container, Form } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import styles from './styles.module.scss'

export function FileUpload({
  dropzoneError,
  text,
  suggestion,
  onDrop,
  className,
  maxSize = 500000
}) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize,
    multiple: false,
    accept: ['image/jpeg', 'image/png']
  })

  return (
    <>
      {dropzoneError && (
        <p className={styles.helperText}>Esse campo é obrigatório</p>
      )}
      <Container
        {...getRootProps({
          className: `${styles.dropzone} ${className ? className : ''} ${
            dropzoneError ? styles.errorValidation : ''
          }`
        })}
      >
        <input {...getInputProps()} />
        <Form.Label>{text}</Form.Label>
        <em>{suggestion}</em>
      </Container>
    </>
  )
}
