import { api } from './axios'

export default {
  list: async ({
    rowsPerPage,
    page,
    searchTerm,
    dateFrom,
    dateTo,
    active,
    category,
    type,
    isFree
  }) => {
    const response = await api
      .get('/eventzz/list', {
        params: {
          rowsPerPage,
          page,
          searchTerm,
          dateFrom,
          dateTo,
          active,
          category,
          type,
          isFree
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  deletePatrocinador: async ({ id }) => {
    const response = await api
      .get('/eventzz/delete/patrocinador', {
        params: {
          id
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  deletePalestrante: async ({ id }) => {
    const response = await api
      .get('/eventzz/delete/palestrante', {
        params: {
          id
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  saveSorteio: async ({ user, premio, idEvento }) => {
    const response = await api
      .get('/eventzz/saveSorteio', {
        params: {
          user,
          premio,
          idEvento
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  dadosSorteio: async ({ eventId, searchTerm }) => {
    const response = await api
      .get('/eventzz/dadosSorteio', {
        params: {
          eventId,
          searchTerm
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  listSellersInEvent: async ({ eventId }) => {
    const response = await api
      .get('/eventzz/list-sellers', {
        params: {
          eventId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  loadFinancialDetails: async ({
    eventId,
    statusTransaction,
    sellerId,
    searchTerm
  }) => {
    const response = await api
      .get('/eventzz/financial-details', {
        params: {
          eventId,
          statusTransaction,
          sellerId,
          searchTerm
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getTickets: async (id) => {
    const response = await api
      .get('/list/tickets', {
        params: {
          eventId: id
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  listPalestrante: async ({ eventId }) => {
    const response = await api
      .get('/eventzz/list/palestrante', {
        params: {
          eventId
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  listPatrocinadores: async ({ eventId }) => {
    const response = await api
      .get('/eventzz/list/patrocinadores', {
        params: {
          eventId
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  find: async ({ eventId }) => {
    const response = await api
      .get('/eventzz/find', {
        params: {
          eventId
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  updateTickets: async ({ formData }) => {
    const response = await api
      .post('/eventzz/tickets/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  CreatePalestrante: async ({ formData }) => {
    const response = await api
      .post('/eventzz/palestrante', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })
    return response
  },
  CreatePatrocinador: async ({ formData }) => {
    const response = await api
      .post('/eventzz/patrocinador', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })
    return response
  },
  updateEventImages: async ({ formData }) => {
    const response = await api
      .post('/eventzz/images/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  updateEventSettings: async ({
    eventId,
    customFees: customFeesTemp,
    useWhatsapp,
    externalLink,
    gateway,
    minimumFee,
    active,
    customPDVFee
  }) => {
    const response = await api
      .post(
        '/eventzz/settings/update',
        {
          eventId,
          customFees: customFeesTemp,
          useWhatsapp,
          externalLink,
          gateway,
          minimumFee,
          active,
          customPDVFee
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })
    return response
  },
  eventzzSelect: async () => {
    const response = await api
      .get('/eventzz/select')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  verifySlug: async (text) => {
    const response = await api
      .get('/eventozz/verify-slug', {
        params: {
          text
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  createEventozz: async (fields) => {
    const response = await api
      .post('/eventozz/create', fields)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateEventozz: async (fields) => {
    const response = await api
      .post('/eventozz/update', fields)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  checkEventRegistration: async ({ eventId }) => {
    const response = await api
      .get('/eventzz/check-registration', {
        params: {
          eventId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  requestEventActivation: async ({ eventId }) => {
    const response = await api
      .post('/eventozz/request-activation', { eventId })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  exportEventozz: async ({
    searchTerm,
    dateFrom,
    dateTo,
    active,
    category,
    type,
    isFree
  }) => {
    const response = await api
      .post('/eventzz/export-xlsx', {
        searchTerm,
        dateFrom,
        dateTo,
        active,
        category,
        type,
        isFree
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateEventExtraFields: async ({
    eventId,
    extraFields,
    hideCpf,
    hideEmail,
    useBoleto,
    useCredito,
    maximumInstallments
  }) => {
    const response = await api
      .post(
        '/eventozz/extra-fields/update',
        {
          eventId,
          extraFields,
          hideCpf,
          hideEmail,
          useBoleto,
          useCredito,
          maximumInstallments
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })
    return response
  }
}
