// import { api } from './axios'

export const apisExternasService = {
  buscarCep: async (cep) => {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
    const endereco = await response.json()
    return {
      status: response.status,
      data: endereco
    }
  },
  buscarEstados: async () => {
    const response = await fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados`
    )
    const estados = await response.json()
    return {
      status: response.status,
      data: estados
    }
  },
  buscarCidades: async (state) => {
    const response = await fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
    )
    const municipios = await response.json()
    return {
      status: response.status,
      data: municipios
    }
  }
}
