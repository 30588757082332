import React, { useCallback, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { Loading } from '../../Loading'
import { listSellersInEvent } from '../../../store/actions'
import DatatableTables from '../index.js'

export const TabSellers = ({ currentValue, open, row }) => {
  const [isLoadingSellers, setIsLoadingSellers] = useState(false)
  const [sellers, setSellers] = useState([])

  const loadSellers = useCallback(async () => {
    setIsLoadingSellers(true)

    const response = await listSellersInEvent({ eventId: row?.id })

    if (response.status === 200) {
      const data = response?.data
      setSellers(data)
      setIsLoadingSellers(false)
    } else {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro ao tentar listar os vendedores'
      )
      setIsLoadingSellers(false)
      return false
    }
  }, [row])

  useEffect(() => {
    if (currentValue === 2 || currentValue === '2') {
      loadSellers()
    }
  }, [currentValue])

  const handleClick = (id) => {
    const link = `${process.env.REACT_APP_SITE_URL}evento/${row?.slug}/?vendedor=${id}`
    navigator.clipboard.writeText(link)
    toast.success('O link de divulgação do vendedor foi copiado')
  }

  return (
    <>
      <ToastContainer />
      {isLoadingSellers ? (
        <Loading />
      ) : (
        <DatatableTables
          title={'Vendedores adicionados ao evento'}
          applyPadding={false}
          columns={[
            { label: 'Id', field: 'id' },
            { label: 'Nome', field: 'name' },
            { label: 'Telefone', field: 'phoneNumber' }
          ]}
          rows={sellers}
          handleClickSellerLink={handleClick}
          tableConfig={{
            options: {
              collapse: false,
              order: true,
              more: true,
              pagination: false,
              copyLinkSeller: true
            }
          }}
        />
      )}
    </>
  )
}
