/* eslint-disable react/jsx-key */
/* eslint-disable array-bracket-newline */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import DatatableTables from '../../components/Table'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Card, FormGroup, Label, Button } from 'reactstrap'

import { ToastContainer, toast } from 'react-toastify'
///MODAIS
import Select from 'react-select'
import {
  eventzzSelect,
  cuponsList,
  deleteCupomEvent
} from '../../store/actions'
import { ModalAddCupomToEvent } from '../../components/modalAddCupom'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
import { Loading } from 'components/Loading'

const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Id_evento', field: 'id_evento' },
  { label: 'Nome', field: 'nome_cortesia' },
  { label: 'Quantidade', field: 'quantidade_disponivel' },
  { label: 'Tipo de Desconto', field: 'tipo_desconto' },
  { label: 'Valor', field: 'valor_cortesia' },
  { label: 'Data de criação', field: 'updated_at' },
  { label: 'Data de Expiração', field: 'expiration' }
]

const Cupons = () => {
  const [loading, setLoading] = useState(false)
  const [showModalAddCupom, setShowModalAddCupom] = useState(false)
  const [eventzzList, setEventzzList] = useState([])
  const [eventId, setEventId] = useState(null)
  const [filteredRows, setFilteredRows] = useState([])

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      tickets: false,
      more: true,
      pagination: true,
      markAsUsed: false,
      delete: true
    },
    config: {
      markAsUsedText: 'Confirmar utilização',
      deleteText: 'Excluir Cupom'
    },
    filters: {
      searchTerm: null,
      status: 'paid',
      eventId: null,
      ticketType: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const handleClose = () => {
    setLoading(false)
  }

  function handleChangeEvent(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        eventId: Number(value?.value) === 0 ? null : value?.value
      }
    }))
    setLoading(true)
  }

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()
    if (response.status === 200) {
      const responseData = response?.data
      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  function handleFormatDate(data) {
    return data.map((item) => {
      item['updated_at'] = new Intl.DateTimeFormat('pt-br', {
        timeStyle: 'medium',
        dateStyle: 'short'
      }).format(new Date(item['updated_at']))

      item['expiration'] = new Intl.DateTimeFormat('pt-br', {
        dateStyle: 'short'
      }).format(new Date(item['expiration']))
      return item
    })
  }

  const loadDados = async () => {
    setLoading(true)
    const response = await cuponsList({
      event_id: tableConfig.filters.eventId,
      rowsPerPage: tableConfig.rowsPerPage,
      currentPage: tableConfig.currentPage
    })

    if (response.status === 200) {
      console.log(response.data)
      const rows = handleFormatDate(response.data)

      setFilteredRows(rows)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }
  async function handleEventDelete(row) {
    console.log(row)
    setLoading(true)
    const response = await deleteCupomEvent({
      id: row.id
    })

    if (response.status === 201) {
      toast.success(response?.msg ? response?.msg : 'Cupom Deletado')
      setLoading(false)
      await loadDados()
    } else {
      toast.error(response?.msg)
      setLoading(false)
    }
  }
  useEffect(() => {
    loadEventzz()
  }, [])

  useEffect(() => {
    if (tableConfig.filters.eventId) {
      loadDados()
    }
  }, [
    tableConfig.currentPage,
    tableConfig.rowsPerPage,
    tableConfig.filters,
    tableConfig.filters.eventId
  ])
  function handleSuccessCupom() {
    setShowModalAddCupom(false)
    setEventId(null)
  }
  function handleCancelCupom() {
    setShowModalAddCupom(false)
    setEventId(null)
  }

  function handleNewCupom() {
    setEventId(tableConfig.filters.eventId)
    if (tableConfig.filters.eventId === null) {
      toast.error('Nenhum Evento selecionado')
      console.log('entrou no if')
    } else {
      setShowModalAddCupom(true)
    }

    console.log('chegou')
    console.log(eventId)
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Cupons | Eventozz</title>
        </MetaTags>

        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Cupons | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Cupons
                  </li>
                </ol>
              </Col>

              <Col md={4} className="text-end">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleNewCupom()}
                  style={{ marginTop: '30px' }}
                >
                  + Novo Cupom
                </Button>
              </Col>
            </Row>
          </div>

          <Row>
            <Card style={{ padding: 20 }}>
              <Col xl={10} md={10}>
                <Row>
                  <Col xl={3} md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Eventos</Label>
                      <Select
                        value={
                          eventzzList.find(
                            (a) => a.value === tableConfig?.filters?.eventId
                          )
                            ? eventzzList.find(
                                (a) => a.value === tableConfig?.filters?.eventId
                              )
                            : null
                        }
                        onChange={(value) => handleChangeEvent(value)}
                        options={eventzzList}
                        classNamePrefix="select2-selection"
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Card>
          </Row>
        </Container>
        <DatatableTables
          title={'Eventozz'}
          columns={columns}
          rows={filteredRows}
          tableConfig={tableConfig}
          setTableConfig={setTableConfig}
          onDelete={handleEventDelete}
        />
        <ModalAddCupomToEvent
          showModal={showModalAddCupom}
          onSuccess={handleSuccessCupom}
          onCancel={handleCancelCupom}
          eventId={eventId}
        />
      </div>
    </React.Fragment>
  )
}

Cupons.propTypes = {
  t: PropTypes.any
}

export default Cupons
