import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Label } from 'reactstrap'
import { Button, FormGroup, Container, Col, Row } from 'react-bootstrap'
import { FaAngellist } from 'react-icons/fa'
import { Loading } from '../../components/Loading'
import { Graph } from '../../components/Graph'
import { toast } from 'react-toastify'

import 'chartist/dist/scss/chartist.scss'
import { CardIndicador } from '../../components/CardIndicador'

import { loadFinancial } from '../../store/actions'
import ReactDatePicker from 'react-datepicker'
import { debounce } from 'lodash'

//css
import './dashboard.scss'

const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const [rendered, setRendered] = useState(false)
  const [data, setData] = useState([])
  const [seriesPaymentType, setSeriesPaymentType] = useState([])
  const [labelsPaymentType, setLabelsPaymentType] = useState([])
  const [quantityPaymentType, setQuantityPaymentType] = useState([])

  const [startSaleDate, setStartSaleDate] = useState(null)
  const [endSaleDate, setEndSaleDate] = useState(null)

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: true,
      order: true,
      more: true,
      pagination: true,
      resendPurchase: true,
      createCheckout: localStorage.getItem('root@session')
    },
    config: {
      resendPurchaseText: 'Reenviar Qr codes da compra'
    },
    filters: {
      searchTerm: null,
      statusTransaction: 'paid',
      paidSale: false,
      endSaleDate: null,
      startSaleDate: null,
      eventId: null,
      sellerId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const loadIndicators = async () => {
    setLoading(true)
    const response = await loadFinancial({
      eventId: tableConfig?.filters?.eventId,
      statusTransaction: tableConfig?.filters?.statusTransaction,
      endSaleDate: tableConfig?.filters?.endSaleDate,
      startSaleDate: tableConfig?.filters?.startSaleDate
    })

    if (response.status === 200) {
      setData(response.data)
      const labels = []
      const series = []
      const quantity = []

      response.data.resultsSales.map((item) => {
        if (Number(item.valor_total) !== 0) {
          labels.push(item.paymentType)
          series.push(item.valor_total)
          quantity.push(item.quantity)
        }
      })

      setLabelsPaymentType(labels)
      setQuantityPaymentType(quantity)
      setSeriesPaymentType(series)

      setLoading(false)
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }
  const handleClose = () => {
    setLoading(false)
  }
  function handlerParceiros() {
    window.location.href = '/extra-eventozz'
  }
  function handlerCupons() {
    window.location.href = '/cupons-eventozz'
  }
  function handleCreateEvent() {
    window.location.href = '/eventozz/novo'
  }
  function handleEvent() {
    window.location.href = '/eventozz'
  }
  function handleSales() {
    window.location.href = '/vendas'
  }
  function handleFinancial() {
    window.location.href = '/financeiro'
  }
  function handleDateChange(date, field) {
    const formattedDate = new Intl.DateTimeFormat('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(date)

    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        [field]: formattedDate
      }
    }))
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    } else {
      loadIndicators()
    }
  }, [
    tableConfig?.filters?.endSaleDate,
    tableConfig?.filters?.startSaleDate,
    rendered
  ])

  const deboundDateChange = useCallback(debounce(handleDateChange, 2000), [])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}

      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem Vindo a Eventozz
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              {/* <FormGroup className="containerM"> */}
              <Col xs={12} sm={4}>
                <Card className="cardM">
                  <div className="cardHeader">
                    <div className="cardIcon">
                      <FaAngellist style={{ height: '17px', width: '22px' }} />
                    </div>
                    <div className="titleCard">Gerencie seu evento</div>
                  </div>
                  <div className="cardBody">
                    Você pode editar os dados do seu um evento em poucos cliques
                  </div>
                  <div className="cardFooter">
                    <Button className="btnM" onClick={() => handleEvent()}>
                      Acesse
                    </Button>
                  </div>
                </Card>
              </Col>
              <Col xs={12} sm={4}>
                <Card className="cardM">
                  <div className="cardHeader">
                    <div className="cardIcon">
                      <FaAngellist style={{ height: '17px', width: '22px' }} />
                    </div>
                    <div className="titleCard">Crie seu evento</div>
                  </div>
                  <div className="cardBody">
                    Você pode criar eventos personalizados em minutos, fácil e
                    rápido.
                  </div>
                  <div className="cardFooter">
                    <Button
                      className="btnM"
                      onClick={() => handleCreateEvent()}
                    >
                      Acesse
                    </Button>
                  </div>
                </Card>
              </Col>
              <Col xs={12} sm={4}>
                <Card className="cardM">
                  <div className="cardHeader">
                    <div className="cardIcon">
                      <FaAngellist style={{ height: '17px', width: '22px' }} />
                    </div>
                    <div className="titleCard">Adicione Parceiros</div>
                  </div>
                  <div className="cardBody">
                    Adicione parceiros que irão aparecer na pagina do seu
                    evento.
                  </div>
                  <div className="cardFooter">
                    <Button className="btnM" onClick={() => handlerParceiros()}>
                      Acesse
                    </Button>
                  </div>
                </Card>
              </Col>
              <Col xs={12} sm={4}>
                <Card className="cardM">
                  <div className="cardHeader">
                    <div className="cardIcon">
                      <FaAngellist style={{ height: '17px', width: '22px' }} />
                    </div>
                    <div className="titleCard">Crie Cupons</div>
                  </div>
                  <div className="cardBody">
                    Você pode criar cupons de desconto para o seu evento.
                  </div>
                  <div className="cardFooter">
                    <Button className="btnM" onClick={() => handlerCupons()}>
                      Acesse
                    </Button>
                  </div>
                </Card>
              </Col>
              <Col xs={12} sm={4}>
                <Card className="cardM">
                  <div className="cardHeader">
                    <div className="cardIcon">
                      <FaAngellist style={{ height: '17px', width: '22px' }} />
                    </div>
                    <div className="titleCard">Gerenciar Vendas</div>
                  </div>
                  <div className="cardBody">
                    Você pode monitorar suas vendas de forma prática e
                    eficiente.
                  </div>
                  <div className="cardFooter">
                    <Button className="btnM" onClick={() => handleSales()}>
                      Acesse
                    </Button>
                  </div>
                </Card>
              </Col>
              <Col xs={12} sm={4}>
                <Card className="cardM">
                  <div className="cardHeader">
                    <div className="cardIcon">
                      <FaAngellist style={{ height: '17px', width: '22px' }} />
                    </div>
                    <div className="titleCard">Controle Financeiro</div>
                  </div>
                  <div className="cardBody">
                    Controle de fluxo de caixa, gestão de repasse financeiro e
                    muito mais.
                  </div>
                  <div className="cardFooter">
                    <Button className="btnM" onClick={() => handleFinancial()}>
                      Acesse
                    </Button>
                  </div>
                </Card>
              </Col>

              {/* </FormGroup> */}
            </Row>
          </div>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col
              xl={12}
              md={12}
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <span
                style={{
                  fontSize: 25,
                  fontWeight: 'bold'
                }}
              >
                Painel de Indicadores
              </span>
              <p
                style={{
                  marginBottom: 20,
                  marginTop: -10
                }}
              >
                <i className="fas fa-arrow-down"></i>
              </p>
            </Col>
            <Col xl={3} md={4}>
              <FormGroup>
                <Label for="exampleEmail">Início</Label>
                <ReactDatePicker
                  className="form-control"
                  selected={startSaleDate}
                  onChange={(date) => {
                    deboundDateChange(date, 'startSaleDate')
                    setStartSaleDate(date)
                  }}
                  placeholderText="dd/mm/aaaa"
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                />
              </FormGroup>
            </Col>
            <Col xl={3} md={4}>
              <FormGroup>
                <Label for="exampleEmail">Fim</Label>
                <ReactDatePicker
                  className="form-control"
                  selected={endSaleDate}
                  onChange={(date) => {
                    deboundDateChange(date, 'endSaleDate')
                    setEndSaleDate(date)
                  }}
                  placeholderText="dd/mm/aaaa"
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <CardIndicador
              icon={'MONEY'}
              value={data?.totalValue || 0}
              type={'money'}
              text={'Movimentação'}
              color="primary"
              xl={3}
              md={6}
              xs={6}
            />
            <CardIndicador
              icon={'MONEY'}
              value={data?.totalTaxe || 0}
              type={'money'}
              text={'Taxa'}
              color="primary"
              xl={3}
              md={6}
              xs={6}
            />
            <CardIndicador
              icon={'MONEY'}
              value={data?.totalRest || 0}
              type={'money'}
              text={'Movimentação Liquida'}
              color="primary"
              xl={3}
              md={6}
              xs={6}
            />
          </Row>
          <Row>
            <CardIndicador
              icon={'TICKETS'}
              value={data?.quantity || 0}
              type={'number'}
              text={'Compras'}
              color="success"
              xl={2}
              md={4}
              xs={4}
            />
            <CardIndicador
              icon={'TICKETS'}
              value={data?.quantityTickets || 0}
              type={'number'}
              text={'Tickets'}
              color="success"
              xl={2}
              md={4}
              xs={4}
            />
          </Row>

          <Row>
            <Col xl={4}>
              <Card>
                <CardBody style={{ height: 350 }}>
                  <h4 className="card-title mb-4">Eventos</h4>
                  <Graph
                    data={[data?.totalEventos, data?.totalEventosOpen]}
                    type="Doughnut"
                    labels={[
                      `Realizados (${data?.totalEventos})`,
                      `Abertos (${data?.totalEventosOpen})`
                    ]}
                    height={'75%'}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardBody style={{ height: 350 }}>
                  <h4 className="card-title mb-4">Meios de Pagamentos (R$)</h4>
                  <Graph
                    data={seriesPaymentType}
                    type="Vertical"
                    labels={labelsPaymentType}
                    width={'100%'}
                    height={'90%'}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardBody style={{ height: 350 }}>
                  <h4 className="card-title mb-4">
                    Meios de Pagamentos (Quant.)
                  </h4>
                  <Graph
                    data={quantityPaymentType}
                    type="Vertical"
                    labels={labelsPaymentType}
                    width={'100%'}
                    height={'90%'}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default Dashboard
