import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Label,
  Form,
  Button,
  Input
} from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import { Loading } from '../../../components/Loading'
///MODAIS
import Select from 'react-select'
import { eventzzSelect } from '../../../store/actions'
import '../styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import styles from './styles.module.scss'
import { ptBR } from 'date-fns/locale'
import { CurrencyInput } from 'components/CurrencyInput'
import Dropzone from 'react-dropzone'
import { financialServices } from 'services/financial'
import { Redirect } from 'react-router-dom'

registerLocale('pt-BR', ptBR)

const transferTypeOptions = [
  { value: 'Pix', label: 'Pix' },
  { value: 'Transferência', label: 'Transferência' }
]
export const NovoRepasse = () => {
  const { createTransfer } = financialServices

  const [isRoot, setIsRoot] = useState(true)
  const [loading, setLoading] = useState(false)
  const [eventzzList, setEventzzList] = useState([])

  const [showErrors, setShowErrors] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [transferEvent, setTransferEvent] = useState(null)
  const [transferType, setTransferType] = useState(null)
  const [transferDate, setTransferDate] = useState(null)
  const [transferFormattedDate, setTransferFormattedDate] = useState(null)
  const [transferFile, setTransferFile] = useState(null)
  const [transferFileUrl, setTransferFileUrl] = useState(null)
  const [transferFileName, setTransferFileName] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [transferValue, setTransferValue] = useState(null)

  const handleClose = () => {
    setLoading(false)
  }

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()
    if (response.isRoot) {
      setIsRoot(true)
    } else {
      setIsRoot(false)
    }
    if (response.status === 200) {
      const responseData = response?.data

      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  function handleChangeEvent(value) {
    setTransferEvent(value.value)
    // setLoading(true)
  }

  function handleSetTransferValue(value) {
    console.log(value)
    setTransferValue(value)
  }

  function handleAcceptedFiles(files) {
    console.log(files)
    if (files[0]?.name) {
      setTransferFileName(files[0].name?.split('.')[0])
      setTransferFile(files[0])

      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onload = function () {
        console.log(reader.result)
        setTransferFileUrl(reader.result)
      }
    }
  }

  function handleGoBack() {
    window.location.href = '/financeiro'
  }

  async function handleSubmit() {
    let hasError = false

    if (!transferEvent) {
      hasError = true
    }
    if (!transferValue) {
      hasError = true
    }
    if (!transferFormattedDate) {
      hasError = true
    }
    if (!transferType) {
      hasError = true
    }
    console.log(hasError)
    if (hasError) {
      setShowErrors(hasError)
      return null
    }

    setLoading(true)

    const formData = new FormData()
    formData.append('comprovante', transferFile ? transferFile : '')
    formData.append('eventId', transferEvent ? transferEvent.toString() : '')
    formData.append('date', transferFormattedDate ? transferFormattedDate : '')
    formData.append('fileName', transferFileName ? transferFileName : '')
    formData.append('transferType', transferType ? transferType : '')
    formData.append('value', transferValue ? transferValue.toString() : '')

    const response = await createTransfer({ formData })
    console.log(response)
    if (response.status === 200 && response?.data?.success) {
      toast.success('As alterações foram salvas')
      setLoading(false)
      setTimeout(() => {
        handleGoBack()
      }, 1500)
    } else if (response.status === 401) {
      toast.error('Você não está logado ou sua sessão expirou')
      setLoading(false)
      return false
    } else if (response.status === 500) {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
      )
      setLoading(false)
      return false
    } else {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro ao salvar o novo repasse financeiro'
      )
      setLoading(false)
      return false
    }
  }

  function handleChangeTransferDate(date) {
    const formattedDate = new Intl.DateTimeFormat('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(date)
    setTransferFormattedDate(formattedDate)
    setTransferDate(date)
  }

  useEffect(() => {
    loadEventzz()
  }, [])

  if (!isRoot) {
    return <Redirect to="/financeiro" />
  }
  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Financeiro | Novo repasse</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Financeiro | Novo repasse</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Informe um novo repasse ao organizador
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Card className={`${styles.p200} pt-2`}>
              <Row>
                <Col md={10} className="m-auto">
                  <Row>
                    <Col xs={12} md={6} xl={4}>
                      <FormGroup>
                        <Label>Evento*</Label>
                        <Select
                          value={
                            eventzzList.find((a) => a.value === transferEvent)
                              ? eventzzList.find(
                                  (a) => a.value === transferEvent
                                )
                              : null
                          }
                          onChange={(value) => handleChangeEvent(value)}
                          placeholder="Selecione..."
                          options={eventzzList}
                          classNamePrefix="select2-selection"
                        />
                        {showErrors && !transferEvent && (
                          <small className="invalid-feedback d-block">
                            *Campo obrigatório
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6} xl={4}>
                      <FormGroup>
                        <Label>Tipo de transferência*</Label>
                        <Select
                          value={
                            transferTypeOptions.find(
                              (a) => a.value === transferType
                            )
                              ? transferTypeOptions.find(
                                  (a) => a.value === transferType
                                )
                              : null
                          }
                          onChange={(value) => setTransferType(value.value)}
                          options={transferTypeOptions}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                        {showErrors && !transferType && (
                          <small className="invalid-feedback d-block">
                            *Campo obrigatório
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6} xl={4}>
                      <FormGroup>
                        <Label>Data*</Label>
                        <ReactDatePicker
                          className="form-control"
                          selected={transferDate}
                          onChange={(date) => handleChangeTransferDate(date)}
                          placeholderText="dd/mm/aaaa"
                          dateFormat="dd/MM/yyyy"
                          locale="pt-BR"
                        />
                        {showErrors && !transferDate && (
                          <small className="invalid-feedback d-block">
                            *Campo obrigatório
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col xs={12} md={6} xl={4}>
                      <CurrencyInput
                        variant="outlined"
                        label="Valor do repasse*"
                        value={transferValue}
                        handleChange={handleSetTransferValue}
                      />
                      {showErrors && !transferValue && (
                        <small className="invalid-feedback d-block">
                          *Campo obrigatório
                        </small>
                      )}
                    </Col>
                    <Col xs={12} md={6} xl={4}>
                      <Label>Nome do arquivo</Label>
                      <Input
                        value={transferFileName}
                        onChange={(event) =>
                          setTransferFileName(event.target.value)
                        }
                        // eslint-disable-next-line no-unneeded-ternary
                        disabled={transferFile ? false : true}
                      />
                    </Col>
                  </Row>

                  <Row className="pt-4">
                    <Col xs={12} md={6}>
                      <Form>
                        <Label>Comprovante</Label>
                        <Dropzone
                          multiple={false}
                          accept={['image/jpeg', 'image/png']}
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                                </div>
                                <h4>
                                  Solte o arquivo aqui ou clique para fazer o
                                  upload.
                                </h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </Form>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      className="align-items-center d-flex justify-content-center"
                    >
                      {transferFileUrl && (
                        <img
                          src={transferFileUrl}
                          alt="img comprovante de transferência"
                          className={styles.imgComprovante}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className="pt-4 justify-content-end">
                    <Col xs={12} md={4} className="d-flex justify-content-end">
                      <Button
                        variant="outlined"
                        type="button"
                        color="danger"
                        onClick={() => handleGoBack()}
                        style={{ marginTop: '30px' }}
                      >
                        Voltar
                      </Button>
                      <Button
                        className="mx-3"
                        variant="outlined"
                        type="button"
                        color="primary"
                        onClick={() => handleSubmit()}
                        style={{ marginTop: '30px' }}
                      >
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
