import React from 'react'
import Select from 'react-select'
import { formatCurrency, isValidEmail, cpfMask } from '../../util/string'
import {
  isValidCpf,
  onlyUnsignedNumbers,
  phoneMaskForList
} from '../../util/function'
import { Loading } from '../Loading'
import styles from './styles.module.css'
import {
  X,
  ChevronUp,
  MoreHorizontal,
  UserPlus,
  Gift,
  Copy,
  Check,
  Send,
  Settings,
  ArrowRight
} from 'react-feather'
import {
  Form,
  FormGroup,
  Label,
  FormText,
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap'

const shirtSizeOptions = [
  { label: 'Escolha uma opção', value: '' },
  { label: 'PP', value: 'PP' },
  { label: 'P', value: 'P' },
  { label: 'M', value: 'M' },
  { label: 'G', value: 'G' },
  { label: 'GG', value: 'GG' },
  { label: 'GG1', value: 'GG1' },
  { label: 'GG2', value: 'GG2' }
]

export const InputsTicketInfo = ({
  organizerId,
  ticket,
  handleChangeTicketData,
  ticketsDataIndex,
  ticketIndex,
  showInputErros
}) => {
  return (
    <Row className={styles.containerTicketsData} style={{ padding: '10px' }}>
      <Col xs={12} item>
        <h4>{ticket.description}</h4>
      </Col>
      <Col xs={12} md={6} className={styles.containerInputTickets} item>
        <input
          className={
            showInputErros && ticket.name.length < 2 ? 'input-error' : ''
          }
          type="text"
          value={ticket.name}
          onChange={(e) =>
            handleChangeTicketData({
              value: e.target.value,
              field: 'name',
              ticketsDataIndex,
              ticketIndex
            })
          }
          name="nome_completo"
          placeholder="Nome Completo"
        />
        {showInputErros && ticket.name.length < 2 && (
          <label className="text-error">
            Informe o nome completo da pessoa que irá utilizar o ingresso.
          </label>
        )}
      </Col>
      <Col xs={12} md={6} className={styles.containerInputTickets} item>
        <input
          className={
            showInputErros && ticket.cpf !== '' && !isValidCpf(ticket.cpf)
              ? 'input-error'
              : ''
          }
          type="text"
          value={cpfMask(ticket.cpf)}
          onChange={(e) =>
            handleChangeTicketData({
              value: onlyUnsignedNumbers(e.target.value),
              field: 'cpf',
              ticketsDataIndex,
              ticketIndex
            })
          }
          inputMode="numeric"
          name="cpf"
          placeholder="CPF"
          maxLength={14}
        />
        {showInputErros && ticket.cpf !== '' && !isValidCpf(ticket.cpf) && (
          <label className="text-error">
            Informe o cpf da pessoa que irá utilizar o ingresso (ou deixe o
            campo em branco).
          </label>
        )}
      </Col>
      <Col xs={12} md={6} className={styles.containerInputTickets} item>
        <div className={styles.containerPhoneNumber}>
          <input
            disabled={ticket?.isCheckingPhoneNumber}
            className={
              showInputErros && ticket.phone.length < 10 ? 'input-error' : ''
            }
            type="text"
            value={phoneMaskForList(ticket.phone)}
            onChange={(e) =>
              handleChangeTicketData({
                value: onlyUnsignedNumbers(e.target.value),
                field: 'phone',
                ticketsDataIndex,
                ticketIndex
              })
            }
            inputMode="numeric"
            name="telefone"
            placeholder="Telefone"
            id="telefone_2"
            maxLength={16}
          />
          {ticket?.isCheckingPhoneNumber && <Loading />}
        </div>
        {showInputErros &&
          (ticket.phone.length < 10 || !ticket?.isValidPhoneNumber) && (
            <label className="text-error">
              Informe um número válido (Enviaremos o qr code do ingresso por
              WhatsApp).
            </label>
          )}
      </Col>
      <Col xs={12} md={6} className={styles.containerInputTickets} item>
        <input
          className={
            showInputErros && ticket.email !== '' && !isValidEmail(ticket.email)
              ? 'input-error'
              : ''
          }
          type="email"
          value={ticket.email}
          onChange={(e) =>
            handleChangeTicketData({
              value: e.target.value,
              field: 'email',
              ticketsDataIndex,
              ticketIndex
            })
          }
          name="email"
          placeholder="Email"
        />
        {showInputErros &&
          ticket.email !== '' &&
          !isValidEmail(ticket.email) && (
            <label className="text-error">
              Informe um email válido (ou deixe o campo em branco) (Enviaremos o
              qr code do ingresso por email).
            </label>
          )}
      </Col>
      {organizerId === 22 && (
        <>
          <Col className={styles.containerInputTickets} xs={12} md={6} item>
            <input
              className={
                showInputErros &&
                (!ticket.position ||
                  (ticket?.position && ticket?.position?.length < 2))
                  ? 'input-error'
                  : ''
              }
              type="text"
              value={ticket.position}
              onChange={(e) =>
                handleChangeTicketData({
                  value: e.target.value,
                  field: 'position',
                  ticketsDataIndex,
                  ticketIndex
                })
              }
              name="position"
              placeholder="Cargo *"
            />
            {showInputErros &&
              (!ticket.position ||
                (ticket?.position && ticket?.position?.length < 2)) && (
                <label className="text-error">
                  Informe o cargo do funcionário.
                </label>
              )}
          </Col>
          <Col className={styles.containerInputTickets} xs={12} md={6} item>
            <input
              className={
                showInputErros &&
                (!ticket?.companyName ||
                  (ticket?.companyName && ticket?.companyName?.length < 2))
                  ? 'input-error'
                  : ''
              }
              type="text"
              value={ticket.companyName}
              onChange={(e) =>
                handleChangeTicketData({
                  value: e.target.value,
                  field: 'companyName',
                  ticketsDataIndex,
                  ticketIndex
                })
              }
              name="companyName"
              placeholder="Nome fantasia da empresa *"
            />
            {showInputErros &&
              (!ticket?.companyName ||
                (ticket?.companyName && ticket?.companyName?.length < 2)) && (
                <label className="text-error">Informe o nome da empresa.</label>
              )}
          </Col>
          <Col xs={12} md={6} item>
            <Select
              options={shirtSizeOptions}
              value={
                shirtSizeOptions.find((a) => a.value === ticket?.shirtSize)
                  ? shirtSizeOptions.find((a) => a.value === ticket?.shirtSize)
                  : null
              }
              onChange={(e) =>
                handleChangeTicketData({
                  value: e?.value,
                  field: 'shirtSize',
                  ticketsDataIndex,
                  ticketIndex
                })
              }
              placeholder="Escolha o tamanho da camisa *"
            />
            {showInputErros && !ticket?.shirtSize && (
              <label>Informe o tamanho da camisa.</label>
            )}
          </Col>
        </>
      )}
    </Row>
  )
}
