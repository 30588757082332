import MetaTags from 'react-meta-tags'
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import Select from 'react-select'

import { toast } from 'react-toastify'

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb'
import { Loading } from '../../components/Loading'
import {
  cpfMask,
  phoneMask,
  cepMask,
  onlyUnsignedNumbers
} from '../../util/string'
import { stateList, pixList } from '../../util/mocks'

// actions
import Services from '../../services/auth'
import { Accordion, Button, Card, Form } from 'react-bootstrap'
import { apisExternasService } from 'services/apisExternas'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { isValid, format } from 'date-fns'
import './styles.scss'
import DatatableTables from '../../components/Table'
import { userService } from 'services/user'
import { ModalAddAppUser } from 'components/modalAddAppUser'
import { ModalConfirm } from 'components/modalConfirm'
import { ModalAddEventToAppUser } from 'components/modalAddEventToAppUser'
import { AppUserCollapse } from 'components/Table/AppUsersCollapse'
// import { ModalTrashOrganizzer } from '../../components/modalTrashOrganizzer'
// import { ModalAddOrganizzer } from '../../components/modalAddOrganizzer'

const columns = [{ label: 'Usuário', field: 'userName' }]
registerLocale('pt-BR', ptBR)

export const UserProfile = () => {
  const [loading, setLoading] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const { listUsersApp, deleteUserApp } = userService

  const [rendered, setRendered] = useState(false)
  const [showModalAddAppUser, setShowModalAddAppUser] = useState(false)
  const [showModalAddEventToAppUser, setShowModalAddEventToAppUser] =
    useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  // const [name, setname] = useState('')
  // const [showModal, setShowModal] = useState(false)
  // const [showModal2, setShowModal2] = useState(false)

  // const [idRow, setIdRow] = useState(false)
  const [usersAppList, setUsersAppList] = useState([])
  const [dados, setDados] = useState({
    name: null,
    email: null,
    cpf: null,
    fone: null,
    nascimento: null,
    cep: null,
    state: null,
    city: null,
    district: null,
    street: null,
    number: null,
    pix: null,
    type_pix: null
  })
  const [currentPassword, setCurrentPassword] = useState({
    value: null,
    error: null
  })
  const [newPassword, setNewPassword] = useState({
    value: null,
    error: null
  })
  const [confirmNewPassword, setConfirmNewPassword] = useState({
    value: null,
    error: null
  })
  const [tableConfig, setTableConfig] = useState({
    options: {
      more: true,
      pagination: false,
      collapse: true,
      copy: true,
      edit: true,
      delete: true,
      add: true
    },
    config: {
      addText: 'Editar permissões a eventos',
      handleCopyText: 'Copiar usuário e senha',
      handleEditText: 'Editar usuário',
      deleteText: 'Excluir usuário'
    },
    filters: {}
  })

  async function getDados() {
    setLoading(true)
    const response = await Services.getInfoUserDash()
    if (response.status === 200 && response?.data?.success) {
      const info = response.data.data.items
      setLoading(false)
      console.log({ nascimento: info.nascimento })
      console.log({ isvalid: isValid(new Date(info.nascimento)) })
      setDados((prev) => ({
        ...prev,
        name: info.name,
        email: info.email,
        cpf: cpfMask(info.cpf),
        fone: phoneMask(info.fone),
        nascimento: isValid(new Date(info.nascimento))
          ? new Date(info.nascimento)
          : null,
        cep: info.address_cep,
        pix: info.pix,
        type_pix: info.type_pix,
        state: info.address_state,
        city: info.address_city,
        district: info.address_district,
        street: info.address_street,
        number: info.address_number
      }))
      // setOrganizerList(response.data.data.listOrganizzer)
    } else if (response.status === 401) {
      toast.error('Você não está logado ou sua sessão expirou')
      setLoading(false)
      return false
    } else if (response.status === 500) {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
      )
      setLoading(false)
      return false
    } else {
      toast.error(
        response?.response?.data?.msg || 'Ocorreu um erro ao buscar seus dados'
      )
      setLoading(false)
      return false
    }
  }

  async function getAppUsers() {
    try {
      const response = await listUsersApp()
      if (response?.status === 200) {
        setUsersAppList(response?.data?.data || [])
      } else {
        toast.error('Não foi possível listar os usuários do app')
      }
    } catch (error) {}
  }

  async function handlerDados(key, value) {
    try {
      if (key === 'fone') {
        value = phoneMask(value)
        if (value.length > 15) {
          return
        }
      }

      if (key === 'cep') {
        value = cepMask(value)
        if (onlyUnsignedNumbers(value).length === 8) {
          console.log('here')
          setLoading(true)
          apisExternasService
            .buscarCep(onlyUnsignedNumbers(value))
            .then((response) => {
              setLoading(false)
              if (response?.status === 200) {
                const endereco = response.data
                setDados((prev) => ({
                  ...prev,
                  cep: endereco.cep,
                  state: endereco.uf,
                  city: endereco.localidade,
                  district: endereco.bairro,
                  street: endereco.logradouro,
                  number: ''
                }))
              }
            })
            .catch((error) => {
              console.log({ error })
              setLoading(false)
            })
        } else if (onlyUnsignedNumbers(value).length > 8) {
          return
        }
      }
      console.log(value)
      setDados((prev) => ({
        ...prev,
        [key]: value
      }))
    } catch (error) {
      setLoading(false)

      console.log({ error })
    }
  }

  const handleClose = () => {
    setLoading(false)
  }

  const handlerSave = async () => {
    try {
      const payload = {
        ...dados,
        address_cep: onlyUnsignedNumbers(dados.cep),
        address_city: dados.city,
        address_district: dados.district,
        address_number: dados.number,
        address_state: dados.state,
        pix: dados.pix,
        type_pix: dados.type_pix,
        address_street: dados.street,
        fone: onlyUnsignedNumbers(dados.fone),
        nascimento: isValid(dados.nascimento)
          ? format(dados.nascimento, 'yyyy-MM-dd')
          : null
      }

      setLoading(true)
      const response = await Services.updateUserDashboard(payload)
      if (response.status === 200 && response?.data?.success) {
        setLoading(false)
        toast.success('Editado com Sucesso')
      } else if (response.status === 500) {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
        )
        setLoading(false)
        return false
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao editar seus dados'
        )
        setLoading(false)
        return false
      }
      setLoading(false)
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  const handlerSavePassword = async () => {
    try {
      if (!currentPassword.value) {
        return setCurrentPassword({
          ...currentPassword,
          error: 'É obrigatório confirmar a senha atual'
        })
      }
      console.log({
        currentPassword,
        newPassword,
        confirmNewPassword
      })
      if (!newPassword.value || newPassword.value?.length < 5) {
        return setNewPassword({
          ...newPassword,
          error: 'A nova senha precisa ter no mínimo 5 caracteres'
        })
      }
      if (
        !confirmNewPassword?.value ||
        newPassword.value !== confirmNewPassword.value
      ) {
        setNewPassword({
          ...newPassword,
          error: 'As senhas não são iguais'
        })
        return setConfirmNewPassword({
          ...confirmNewPassword,
          error: 'As senhas não são iguais'
        })
      }

      setLoading(true)
      const response = await Services.updateUserPassword({
        password: currentPassword.value,
        newPassword: newPassword.value
      })
      if (response.status === 200 && response?.data?.success) {
        setLoading(false)
        toast.success('Senha atualizada com sucesso')
        setCurrentPassword({
          value: '',
          error: null
        })
        setNewPassword({
          value: '',
          error: null
        })
        setConfirmNewPassword({
          value: '',
          error: null
        })
      } else if (response.status === 500) {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
        )
        setLoading(false)
        return false
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao atualizar sua senha'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log({ error })
    }
  }
  // async function handlerTrash (row) {
  //   setIdRow(row?.id)
  //   setShowModal(true)
  // }

  const onCloseModal = () => {
    setShowModalAddAppUser(false)
  }

  const onSuccessModal = () => {
    setShowModalAddAppUser(false)
    getAppUsers()
  }

  const onCloseModalAddEvent = () => {
    setShowModalAddEventToAppUser(false)
  }

  const onSuccessModalAddEvent = () => {
    setShowModalAddEventToAppUser(false)
    getAppUsers()
  }

  const handlerUpdate = (row) => {
    console.log({
      row
    })
    setCurrentUser(row)
    setShowModalAddAppUser(true)
  }

  const handlerDeleteUser = (row) => {
    console.log({
      row
    })
    setCurrentUser(row)
    setShowModalConfirm(true)
  }

  const handlerConfirmDeleteUser = async () => {
    try {
      console.log({ currentUser })
      setShowModalConfirm(false)
      setLoading(true)

      const response = await deleteUserApp(currentUser.id)
      if (response.status === 200) {
        toast.success(response?.msg ? response?.msg : 'Usuário excluído')
        setLoading(false)
        getAppUsers()
      } else {
        console.log('=============')
        toast.error(
          response?.msg || 'Ocorreu um erro ao tentar excluir o usuário'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  const handlerCancelDeleteUser = async () => {
    console.log({ currentUser })
    setCurrentUser(null)
    setShowModalConfirm(false)
  }

  const handleCopy = (row) => {
    try {
      console.log({ row })
      const copyText = `Usuário: ${row.userName}\nSenha: ${row.password}`
      navigator.clipboard.writeText(copyText)
      toast.success('Usuário e senha copiados')
    } catch (error) {
      console.log({ error })
      toast.error('Não foi possível copiar usuário e senha')
    }
  }

  const handlerAdd = (row) => {
    console.log({
      row
    })
    setCurrentUser(row)
    setShowModalAddEventToAppUser(true)
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    } else {
      getDados()
      getAppUsers()
    }
  }, [rendered])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <div className="page-content min-height-100vh profile-container">
        <MetaTags>
          <title>Perfil | Eventozz</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Eventozz" breadcrumbItem="Perfil" />
          {/* <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>Nome: {name}</h5>
                        <p style={{ fontSize: 14 }}>Email: {email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Dados pessoais</Accordion.Header>
              <Accordion.Body>
                {/* <Card>
                  <CardBody> */}
                <Row>
                  <Col xs={12} md={6} xl={4}>
                    <FormGroup className="mb-2">
                      <Label>Nome</Label>
                      <Input
                        value={dados.name}
                        onChange={(event) =>
                          handlerDados('name', event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <FormGroup className="mb-2">
                      <Label>E-mail</Label>
                      <Input
                        value={dados.email}
                        disabled
                        style={{ backgroundColor: '#494a5940' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <FormGroup className="mb-2">
                      <Label>CPF</Label>
                      <Input
                        value={dados.cpf}
                        disabled
                        style={{ backgroundColor: '#494a5940' }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4} xl={3}>
                    <FormGroup className="mb-2">
                      <Label>Telefone</Label>
                      <Input
                        value={dados.fone}
                        onChange={(event) =>
                          handlerDados('fone', event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4} xl={3}>
                    <FormGroup className="mb-2">
                      <Label>Nascimento</Label>
                      <ReactDatePicker
                        className="form-control"
                        selected={dados.nascimento}
                        onChange={(date) => {
                          handlerDados('nascimento', date)
                        }}
                        placeholderText="dd/mm/aaaa"
                        dateFormat="dd/MM/yyyy"
                        locale="pt-BR"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4} xl={3}>
                    <FormGroup className="mb-2">
                      <Label>CEP</Label>
                      <Input
                        value={dados.cep}
                        onChange={(event) =>
                          handlerDados('cep', event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4} xl={3}>
                    <FormGroup className="mb-2">
                      <Label>Estado</Label>
                      <Select
                        value={
                          stateList.find((a) => a.value === dados.state)
                            ? stateList.find((a) => a.value === dados.state)
                            : null
                        }
                        onChange={(event) => handlerDados('state', event.value)}
                        options={stateList}
                        classNamePrefix="select2-selection"
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} xl={3}>
                    <FormGroup className="mb-2">
                      <Label>Cidade</Label>
                      <Input
                        value={dados.city}
                        onChange={(event) =>
                          handlerDados('city', event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={6} xl={3}>
                    <FormGroup className="mb-2">
                      <Label>Bairro</Label>
                      <Input
                        value={dados.district}
                        onChange={(event) =>
                          handlerDados('district', event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <FormGroup className="mb-2">
                      <Label>Rua/Av</Label>
                      <Input
                        value={dados.street}
                        onChange={(event) =>
                          handlerDados('street', event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4} xl={2}>
                    <FormGroup className="mb-2">
                      <Label>N°</Label>
                      <Input
                        value={dados.number}
                        onChange={(event) =>
                          handlerDados('number', event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4} xl={3}>
                    <FormGroup className="mb-2">
                      <Label>Tipo de Pix</Label>
                      <Select
                        value={
                          pixList.find((a) => a.value === dados.type_pix)
                            ? pixList.find((a) => a.value === dados.type_pix)
                            : null
                        }
                        onChange={(event) =>
                          handlerDados('type_pix', event.value)
                        }
                        options={pixList}
                        classNamePrefix="select2-selection"
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4} xl={3}>
                    <FormGroup className="mb-2">
                      <Label>PIX</Label>
                      <Input
                        value={dados.pix}
                        onChange={(event) =>
                          handlerDados('pix', event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-center mt-4">
                  <Button
                    type="button"
                    variant="danger"
                    onClick={() => handlerSave()}
                  >
                    Salvar
                  </Button>
                </div>

                {/* <DatatableTables
                title={'Organizadores'}
                columns={columns}
                rows={organizerList}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
                handlerTrash={handlerTrash}
                actualEmail={dados.email}
                addOrganizer={addOrganizer}
              /> */}
                {/* </CardBody>
                </Card> */}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="mt-2">
              <Accordion.Header>Alterar senha</Accordion.Header>
              <Accordion.Body>
                {/* <Card>
                  <CardBody> */}
                <Row>
                  <Col xs={12} md={6} xl={4}>
                    <FormGroup className="mb-2">
                      <Label>Senha atual</Label>
                      <Form.Control
                        type="password"
                        value={currentPassword.value}
                        onChange={(event) =>
                          setCurrentPassword({
                            value: event.target.value,
                            error: null
                          })
                        }
                        isInvalid={!!currentPassword.error}
                      />
                      <Form.Control.Feedback type="invalid">
                        {currentPassword.error}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <FormGroup className="mb-2">
                      <Label>Nova senha</Label>
                      <Form.Control
                        type="password"
                        value={newPassword.value}
                        onChange={(event) => {
                          setNewPassword({
                            value: event.target.value,
                            error: null
                          })
                          setConfirmNewPassword({
                            ...confirmNewPassword,
                            error: null
                          })
                        }}
                        isInvalid={!!newPassword.error}
                      />
                      <Form.Control.Feedback type="invalid">
                        {newPassword.error}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <FormGroup className="mb-2">
                      <Label>Confirmar nova senha</Label>
                      <Form.Control
                        type="password"
                        value={confirmNewPassword.value}
                        onChange={(event) => {
                          setConfirmNewPassword({
                            value: event.target.value,
                            error: null
                          })
                          setNewPassword({
                            ...newPassword,
                            error: null
                          })
                        }}
                        isInvalid={!!confirmNewPassword.error}
                      />
                      <Form.Control.Feedback type="invalid">
                        {confirmNewPassword.error}
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-center mt-4">
                  <Button
                    type="button"
                    variant="danger"
                    onClick={() => handlerSavePassword()}
                  >
                    Salvar
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="mt-2">
              <Accordion.Header>Usuários do app</Accordion.Header>
              <Accordion.Body>
                <Row className="mb-2">
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="success"
                      onClick={() => setShowModalAddAppUser(true)}
                    >
                      Criar usuário
                    </Button>
                  </Col>
                </Row>
                {usersAppList?.length ? (
                  <DatatableTables
                    title={'Usuários cadastrados'}
                    columns={columns}
                    rows={usersAppList}
                    tableConfig={tableConfig}
                    setTableConfig={setTableConfig}
                    onDelete={handlerDeleteUser}
                    actualEmail={null}
                    onEdit={handlerUpdate}
                    handleCopy={handleCopy}
                    onAdd={handlerAdd}
                    CollapseComponent={AppUserCollapse}
                  />
                ) : (
                  <Card>
                    <Card.Body>
                      <div className="text-center p-4">
                        <h4>Nenhum usuário encontrado</h4>
                      </div>
                    </Card.Body>
                  </Card>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
      {/* <ModalTrashOrganizzer
        onClose={onCloseModal}
        showModal={showModal}
        idRow={idRow}
        loadDados={getDados}
      /> */}
      {/* <ModalAddOrganizzer
        onClose={onCloseModal}
        showModal={showModal2}
        loadDados={getDados}
      /> */}
      <ModalAddAppUser
        onClose={onCloseModal}
        onSubmit={onSuccessModal}
        show={showModalAddAppUser}
        currentUser={currentUser}
      />
      <ModalAddEventToAppUser
        onClose={onCloseModalAddEvent}
        onSubmit={onSuccessModalAddEvent}
        show={showModalAddEventToAppUser}
        currentUser={currentUser}
      />
      <ModalConfirm
        onClose={handlerCancelDeleteUser}
        onConfirm={handlerConfirmDeleteUser}
        showModal={showModalConfirm}
        text="Confirmar exclusão do usuário?"
        title="Excluir usuário"
      />
    </React.Fragment>
  )
}
