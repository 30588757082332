import Services from '../../services/payment'
import { logoutUser } from '../../store/actions'

export const resendTicket = async ({ eventId, ticketId, ticketData }) => {
  var response = await Services.resendTicket({
    eventId,
    ticketId,
    ticketData
  })
  if (response?.status === 200 && response?.data?.success) {
    const data = response?.data

    return {
      status: 200,
      msg: data?.msg ? data?.msg : 'Qr code enviado',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar enviar o qr code'
    }
  }
}
export const checkPhoneIsWhatsApp = async (params) => {
  var response = await Services.checkPhoneIsWhatsApp(params)
  if (response?.status === 200) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data?.exists
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar verificar se o telefone possui WhatsApp'
    }
  }
}

export const sendPurchaseQrCode = async (params) => {
  const response = await Services.sendPurchaseQrCode(params)
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: 'Ocorreu um erro ao tentar enviar os qr codes'
    }
  }
}

export const loadFinancial = async (params) => {
  const response = await Services.loadFinancial(params)
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: response?.response?.data?.msg
    }
  }
}

export const loadFinancialDetails = async (params) => {
  const response = await Services.loadFinancialDetails(params)

  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: response?.response?.data?.msg
    }
  }
}

export const createCheckout = async (params) => {
  const response = await Services.createCheckout(params)
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: 'Ocorreu um erro ao tentar listar as vendas'
    }
  }
}

export const sellerSelect = async (params) => {
  const response = await Services.sellerSelect(params)
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: 'Ocorreu um erro ao tentar listar as vendas'
    }
  }
}

export const eventzzSelect = async () => {
  const response = await Services.eventzzSelect()
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      isRoot: response?.isRoot,
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: 'Ocorreu um erro ao tentar listar as vendas'
    }
  }
}

export const userList = async ({ rowsPerPage, currentPage }) => {
  const response = await Services.userList({
    rowsPerPage,
    currentPage
  })
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: 'Ocorreu um erro ao tentar listar os usuários do Evento'
    }
  }
}
export const userListAll = async ({ rowsPerPage, currentPage, eventId }) => {
  const response = await Services.userListAll({
    rowsPerPage,
    currentPage,
    eventId
  })
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: 'Ocorreu um erro ao tentar listar os usuários do Evento'
    }
  }
}

export const eventzzList = async ({
  rowsPerPage,
  page,
  searchTerm,
  dateFrom,
  dateTo,
  aberto,
  ativo,
  category,
  type,
  isFree
}) => {
  var response = await Services.eventzzList({
    rowsPerPage,
    page,
    searchTerm,
    dateFrom,
    dateTo,
    aberto,
    ativo,
    category,
    type,
    isFree
  })
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: 'Ocorreu um erro ao tentar listar os Eventos'
    }
  }
}

export const eventzzListAll = async ({}) => {
  const response = await Services.eventzzListAll()
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: 'Ocorreu um erro ao tentar listar os Eventos'
    }
  }
}

export const cuponsList = async ({ event_id, rowsPerPage, currentPage }) => {
  console.log('-----------')
  console.log(event_id)
  const response = await Services.cuponsEventList({
    event_id,
    rowsPerPage,
    currentPage
  })
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: 'Ocorreu um erro ao tentar listar os cupons do Evento'
    }
  }
}

export const deleteCupomEvent = async ({ id }) => {
  const response = await Services.deleteCupomEvent({
    id
  })
  console.log('---------------')
  console.log(response)
  if (response?.status === 201 && response?.data?.success) {
    return {
      status: 201,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: 'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: 'Ocorreu um erro ao tentar deletar o cupom do Evento'
    }
  }
}
