import { api } from './axios'

export default {
  ServiceAddAfiliado: async (params) => {
    const response = await api
      .get('/afiliado/add', {
        params: {
         ...params
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getAfiliado: async () => {
    const response = await api
      .get('/afiliado/getDash')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
}
