import React, { useEffect, useState } from 'react'
import DatatableTables from '../index.js'
import { Collapse } from 'reactstrap'
import { Tr, Td } from 'react-super-responsive-table'

import styles from './styles.module.scss'
import { addHours } from 'date-fns'

export function TicketCollapseComponent({ open, row }) {
  const [ticketUsage, setTicketUsage] = useState([])

  const columns = [
    { label: 'Utilização', field: 'id' },
    { label: 'Data de utilização', field: 'userAt' }
  ]

  // eslint-disable-next-line no-unused-vars
  const [tableConfig, setTableConfig] = useState({
    options: {
      more: false,
      pagination: false
    },
    config: {
      resendTicketText: 'Reenviar Qr code do ingresso'
    },
    total: 0,
    currentPage: 1,
    rowsPerPage: 10
  })

  useEffect(() => {
    if (row?.log) {
      const log = row?.log?.map((item, index) => ({
        id: `${row?.log.length - index}ª`,
        userAt: Intl.DateTimeFormat('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(addHours(new Date(item.usedAt), 3))
      }))
      setTicketUsage(log)
    }
  }, [row])

  return (
    <Tr>
      <Td
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={columns.length + 1 + (tableConfig?.options?.more ? 1 : 0)}
      >
        <Collapse
          isOpen={open}
          timeout="auto"
          unmountOnExit
          className={styles.tableContainer}
        >
          <DatatableTables
            titleVariant="h6"
            title={'Histórico de utilização'}
            rows={ticketUsage}
            columns={columns}
            tableConfig={tableConfig}
            hideBackgroundHeader={true}
            layoutCollapse={true}
          />
        </Collapse>
      </Td>
    </Tr>
  )
}
