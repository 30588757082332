import React, { useCallback, useEffect, useState } from 'react'
import { formatCurrency, isValidEmail } from '../../util/string'
import { isValidCpf } from '../../util/function'
import ErrorImage from '../../assets/images/eventozz/checkout/erro.svg'
import SuccessImage from '../../assets/images/eventozz/checkout/success.svg'
import ReactModal from 'react-modal'
import { CardTicket } from './cardTicket.js'
import { InputsTicketInfo } from './inputsTicketInfo'
import styles from './styles.module.css'
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap'
import { toast } from 'react-toastify'
import { Loading } from '../Loading'
import { courtesySave, getTickets } from '../../store/actions'
import { X, ArrowRight } from 'react-feather'

export const ModalAddCourtesyTicket = ({
  showModal,
  onSuccess,
  onCancel,
  eventId,
  eventName
}) => {
  const [loading, setLoading] = useState(false)

  const [tickets, setTickets] = useState([])
  const [valorTotal, setTotalAmount] = useState(0)
  const [showInputErros, setShowInputErros] = useState(false)
  const [activeStep, setActiveStep] = useState({
    step: 0,
    description: 'Choose tickets'
  })
  const [ticketsData, setTicketsData] = useState([])
  const [showSuccessFeedback, setShowSuccessFeedback] = useState(true)
  const [showErrorFeedback, setShowErrorFeedback] = useState(false)
  const [paymentFeedback, setPaymentFeedback] = useState({
    title: '',
    message: '',
    image: null,
    qrCodeLink: ''
  })

  const loadTicketTypes = useCallback(async () => {
    if (!eventId) return
    try {
      const result = await getTickets(eventId)
      if (result.status == 200) {
        const ticketTypes = result?.data
        console.log(ticketTypes)
        const ticketsTemp = []
        ticketTypes.map((a) => {
          a.currentQtd = 0
          a.activeValue = a.valor
          ticketsTemp.push(a)
        })
        setTickets(ticketsTemp)
        console.log(ticketsTemp)
      } else {
        console.log(result)
        setTickets([])
      }
    } catch (error) {
      console.log(error)
      setTickets([])
    }
  }, [eventId])

  const handleChangeTicketQuantity = async (option, ticketId) => {
    console.log(tickets)
    const errorStatus = {
      negative: false,
      maxQuantity: false
    }
    const newTickets = await Promise.all(
      tickets.map((a, i) => {
        let activeValue = a.valor
        if (a.id === ticketId) {
          if (option === 'plus' && a.currentQtd + 1 > a.qtd_disponivel) {
            errorStatus.maxQuantity = true
          }
          if (option === 'minus' && a.currentQtd - 1 < 0) {
            errorStatus.negative = true
          }

          if (option === 'plus') {
            activeValue =
              a.qtd_promocional > 0 && a.currentQtd + 1 >= a.qtd_promocional
                ? a.valor_promocional
                : a.valor
          }
          if (option === 'minus') {
            activeValue =
              a.qtd_promocional > 0 && a.currentQtd - 1 >= a.qtd_promocional
                ? a.valor_promocional
                : a.valor
          }
          return {
            ...a,
            activeValue,
            currentQtd: option === 'minus' ? a.currentQtd - 1 : a.currentQtd + 1
          }
        } else {
          return {
            ...a
          }
        }
      })
    )

    if (errorStatus.negative) return

    if (errorStatus.maxQuantity)
      return toast.info('A quantidade informada não está mais disponível')

    let totalValue = 0
    newTickets.map((a) => {
      totalValue += a.currentQtd * a.activeValue
    })
    setTotalAmount(totalValue)

    setTickets(newTickets)
  }

  const handleClose = () => {
    setLoading(false)
    onCancel()
    setActiveStep({
      step: 0,
      description: 'Choose tickets'
    })
  }

  const handleNextStep = () => {
    const checkHasTickets = tickets?.find((item) => item.currentQtd > 0)
    if (!checkHasTickets) {
      return toast.error('Você precisa adicionar pelo menos 1 ingresso')
    }
    generateInputTicketsData()
    console.log(tickets)
    setActiveStep({
      step: 1,
      description: 'fill in ticket details'
    })
  }

  const handleBack = () => {
    setActiveStep({
      step: 0,
      description: 'Choose tickets'
    })
  }

  const handleSubmit = async () => {
    let showErrorTemp = false
    Object.values(ticketsData).map((ticketType, index) => {
      ticketType.map((ticket, i) => {
        if (
          ticket.name.length < 2 ||
          (ticket.cpf !== '' && !isValidCpf(ticket.cpf)) ||
          ticket.phone.length < 10 ||
          (ticket.email !== '' && !isValidEmail(ticket.email))
        ) {
          showErrorTemp = true
        }
      })
    })
    if (showErrorTemp) {
      setShowInputErros(true)
      return toast.error('Preencha todos os campos para cada ingresso')
    }
    setShowInputErros(false)

    if (!eventId) {
      return toast.error('Não conseguimos identificar o evento!')
    }
    setLoading(true)

    const ticketsDataTemp = []
    console.log()
    Object.values(ticketsData).map((ticketType, index) => {
      if (ticketType?.length > 0) {
        ticketType.map((ticket) => {
          let vector = {
            description: ticket?.description,
            idIngresso: ticket?.idIngresso,
            name: ticket?.name,
            cpf: ticket?.cpf,
            phone: ticket?.phone,
            email: ticket?.email
          }
          ticketsDataTemp.push(vector)
        })
      }
    })
    const ticketsQtd = tickets.map((item) => ({
      qtd: item.currentQtd,
      ticketId: item.id
    }))

    console.log(ticketsDataTemp)

    const body = {
      eventId,
      ticketsData: ticketsDataTemp,
      ticketsQtd
    }

    console.log(body)
    // return;
    try {
      const response = await courtesySave({ body })
      console.log(response)
      setLoading(false)
      if (response?.status === 200) {
        toast.success(response?.msg ? response.msg : 'Ingressos salvos')
        setShowErrorFeedback(false)
        setShowSuccessFeedback(true)
        setActiveStep({
          step: 2,
          description: 'Feedback'
        })
      } else {
        toast.error(
          response?.msg ? response.msg : 'Não foi possível salvar os ingressos'
        )
        setShowSuccessFeedback(false)
        setShowErrorFeedback(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const generateInputTicketsData = useCallback(async () => {
    // return;
    const ticketsDataTemp = {}
    await Promise.all(
      tickets.map((item) => {
        let ticketsDataItem = ticketsData[item.id] ? ticketsData[item.id] : []
        console.log('item.quantidade -> ', item.currentQtd)
        console.log('ticketsDataItem -> ', ticketsDataItem)
        if (item.currentQtd === 0) {
          ticketsDataItem = []
        } else if (item.currentQtd < ticketsDataItem?.length) {
          ticketsDataItem = ticketsDataItem.filter(
            (a, i) => item.currentQtd > i
          )
        } else if (item.currentQtd > ticketsDataItem?.length) {
          console.log(ticketsDataItem)
          const initialQuantity = item.currentQtd - ticketsDataItem?.length
          for (let i = 0; i < initialQuantity; i++) {
            ticketsDataItem.push({
              description: `${i + 1}º - ${item.nome}`,
              idIngresso: item.id,
              name: '',
              cpf: '',
              phone: '',
              email: ''
            })
          }
        }
        ticketsDataTemp[item.id] = ticketsDataItem
      })
    )

    setTicketsData(ticketsDataTemp)
    console.log(ticketsDataTemp)
  }, [tickets, ticketsData])

  const handleChangeTicketData = ({
    value,
    field,
    ticketsDataIndex,
    ticketIndex
  }) => {
    console.log(value)
    console.log(Object.values(ticketsData))
    const newTicketsData = {}
    Object.values(ticketsData).map((ticketType, index) => {
      const idIngresso = ticketType[0]?.idIngresso
      console.log(idIngresso)
      if (!idIngresso) {
        return
      }
      const newTicketData = ticketType.map((ticket, i) => {
        if (index === ticketsDataIndex && i === ticketIndex) {
          return {
            ...ticket,
            [field]: value
          }
        } else {
          return ticket
        }
      })
      newTicketsData[idIngresso] = newTicketData
    })
    setTicketsData(newTicketsData)
  }

  const handleTryAgain = () => {
    setShowErrorFeedback(false)
    setShowSuccessFeedback(false)
    setActiveStep({
      step: 1,
      description: 'fill in ticket details'
    })
  }

  const handleCloseSuccess = () => {
    onSuccess()
    setActiveStep({
      step: 0,
      description: 'Choose tickets'
    })
  }

  useEffect(() => {
    loadTicketTypes()
  }, [eventId])

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
    >
      <Container>
        <Loading open={loading} handleClose={handleClose} />
        <span className="closeButtom" onClick={handleClose}>
          <X />
        </span>
        <Card className={'modalBody'}>
          <form style={{ padding: '10px' }}>
            <Row className="modalText">
              <Col xs={12}>
                <h1 className={'titleRoot'}>
                  Criar ingressos de cortesia{' '}
                  {eventName ? ` - ${eventName}` : ''}
                </h1>
              </Col>
            </Row>

            {tickets?.length === 0 && !loading ? (
              <div className={styles.dataEmpty}>Nenhum ingresso disponível</div>
            ) : (
              <>
                {activeStep.step === 0 && (
                  <Row className={styles.marginBottom}>
                    {tickets.map((ticket, index) => {
                      return (
                        <CardTicket
                          ticket={ticket}
                          handleChangeTicketQuantity={
                            handleChangeTicketQuantity
                          }
                        />
                      )
                    })}

                    <Col xs={12} item className={styles.dFlexAlign}>
                      <div className={styles.margin}>
                        <span className="box-tickets-total-value-title">
                          <div className="d-block div-title">Valor Total</div>
                        </span>
                      </div>
                      <div className={styles.dFlexAlignCenter}>
                        <span className="box-tickets-total-value-text through">
                          {formatCurrency({ value: valorTotal })}
                        </span>
                        <ArrowRight />
                        <span className="box-tickets-total-value-text">
                          Gratuito
                        </span>
                      </div>
                    </Col>
                  </Row>
                )}

                {activeStep.step === 1 && (
                  <>
                    <div className="section-title">
                      <h2>Preencha as informações dos ingressos</h2>
                    </div>
                    {Object.values(ticketsData).length > 0 ? (
                      Object.values(ticketsData).map((ticketType, index) =>
                        ticketType.map((ticket, i) => (
                          <InputsTicketInfo
                            ticket={ticket}
                            handleChangeTicketData={handleChangeTicketData}
                            ticketsDataIndex={index}
                            ticketIndex={i}
                            showInputErros={showInputErros}
                          />
                        ))
                      )
                    ) : (
                      <Row>
                        <Col xs={12} item>
                          <h4>Nenhum ingresso adicionado</h4>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
                {activeStep.step === 2 && (
                  <>
                    {showSuccessFeedback && (
                      <Row className={styles.containerFeedback}>
                        <Col
                          className={[
                            styles.containerMsgFeedback,
                            styles.mAuto
                          ]}
                          xs={12}
                          md={6}
                        >
                          <div className={styles.containerTitleFeedback}>
                            <h2>
                              {paymentFeedback?.title
                                ? paymentFeedback.title
                                : 'Ingressos reservados!'}
                            </h2>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paymentFeedback?.message
                                ? paymentFeedback.message
                                : ''
                            }}
                          ></div>
                        </Col>
                        <Col
                          className="container-img-feedback success-feedback position-relative"
                          xs={12}
                          md={6}
                        >
                          <img src={SuccessImage} alt="Imagem sucesso" />
                        </Col>
                      </Row>
                    )}
                    {showErrorFeedback && (
                      <Row className={styles.containerFeedback}>
                        <Row
                          className={[
                            styles.containerMsgFeedback,
                            styles.mAuto
                          ]}
                          xs={12}
                          md={6}
                        >
                          <div
                            className={styles.containerTitleFeedback}
                            xs={12}
                          >
                            <h2>
                              {paymentFeedback?.title
                                ? paymentFeedback.title
                                : 'Compra não autorizada!'}
                            </h2>
                          </div>
                          <div></div>

                          <Col xs={12} sm={6}>
                            <a
                              class={[
                                styles.checkoutButton,
                                styles.minHeight45
                              ]}
                              type="button"
                              onClick={() => handleTryAgain()}
                            >
                              Tentar novamente
                            </a>
                          </Col>
                        </Row>
                        <Col
                          className="container-img-feedback position-relative"
                          xs={12}
                          md={6}
                        >
                          <img
                            src={ErrorImage}
                            layout="fill"
                            alt="Imagem erro"
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                )}

                <CardBody
                  className={styles.footerModalButtons}
                  display="flex"
                  alignItems="center"
                  justifyContent={
                    activeStep.step === 0 ? 'flex-end' : 'space-between'
                  }
                  mt={10}
                  mb={5}
                >
                  {activeStep.step > 0 && (
                    <Button
                      className={styles.margin}
                      variant="contained"
                      color={activeStep.step <= 1 ? 'secondary' : 'primary'}
                      onClick={() =>
                        activeStep.step <= 1
                          ? handleBack()
                          : handleCloseSuccess()
                      }
                    >
                      {activeStep.step <= 1 ? 'Voltar' : 'Fechar'}
                    </Button>
                  )}
                  {activeStep.step <= 1 && (
                    <Button
                      onClick={() =>
                        activeStep.step === 1
                          ? handleSubmit()
                          : handleNextStep()
                      }
                      variant="contained"
                      color="primary"
                    >
                      {activeStep.step === 1 ? 'Finalizar' : 'Próximo'}
                    </Button>
                  )}
                </CardBody>
              </>
            )}
          </form>
        </Card>
      </Container>
    </ReactModal>
  )
}
