import { api } from './axios'

type UpdateOrganizerPermissionsProps = {
  email: string
  permissions: number[]
  eventId: number
}

export const permissionsService = {
  list: async ({ userId = null, eventId = null }) => {
    const response = await api
      .get('/permissions', {
        params: {
          userId,
          eventId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateOrganizerPermissions: async ({
    email,
    permissions,
    eventId
  }: UpdateOrganizerPermissionsProps) => {
    const response = await api
      .put('/permissions', {
        email,
        permissions,
        eventId
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  listEventOrganizers: async (eventId: number) => {
    const response = await api
      .get(`event/permissions/${eventId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
