import React, { useCallback, useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { stateFromHTML } from 'draft-js-import-html'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { toast } from 'react-toastify'
import eventzz from 'services/eventzz'
import styles from './styles.module.scss'
import qrCodeImage from '../../../../assets/images/eventozz/qr-code.png'
import {
  CustomToolbarOption,
  CustomToolbarOptionsContainer,
  CustomToolbarOptionsTitle,
  DraggableContainer,
  DraggableHelper,
  QrcodeImg,
  StyledThumb,
  StyledTrack
} from './styledComponents'
import { useImageUpload } from 'hooks/useImageUpload'
import { CurrencyInput } from 'components/CurrencyInput'
import { format, isValid } from 'date-fns'
import { FileUpload } from 'components/fileUpload'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import ReactSlider from 'react-slider'
import { CustomButton } from 'components/customButton'
import { ModalCropImage } from 'components/modalCropImage'
import { ValidationErrorMessage } from 'components/validationError'

export const TabTickets = ({
  eventId,
  setLoading,
  eventData,
  setHasChanges,
  checkEvent
}) => {

  const { updateTickets } = eventzz

  const [qrCodeHeight, setQrCodeHeight] = useState(100)
  const [qrCodeWidth, setQrCodeWidth] = useState(100)
  const [qrCodePositionLeft, setQrCodePositionLeft] = useState(null)
  const [qrCodePositionTop, setQrCodePositionTop] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [qrCodeMsg, setQrCodeMsg] = useState(null)
  const [dropzoneError, setDropzoneError] = useState(false)
  const [msgError, setMsgError] = useState(false)

  const [showCropperModal, setShowCropperModal] = useState(false)
  const [croppedImageData, setCroppedImageData] = useState(null)
  const [croppedImageUrl, setCroppedImageFileUrl] = useState(null)

  const [ticketTypes, setTicketTypes] = useState([
    {
      name: null,
      updated: false,
      isNew: true,
      errorMessage: null,
      headerText: EditorState.createEmpty(),
      description: EditorState.createEmpty(),
      lotes: [
        {
          id: null,
          amount: null,
          availableQtd: null,
          promotionalAmount: null,
          promotionalQtd: null,
          showPromotionalFields: false,
          validFrom: null,
          errorMessage: null,
          validTo: null,
          active: false,
          updated: false,
          isNew: true
        }
      ]
    }
  ])

  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const {
    imageFile: qrCodeBackgroundFile,
    imageUrl: qrCodeBackground,
    initialImageUrl,
    setInitialImageUrl,
    onDrop,
    imageDimensions
  } = useImageUpload({
    imageHeight: 1080,
    imageWidth: 1080
  })

  const trackQrCodePosition = (data) => {
    setQrCodePositionLeft(data.x * 2)
    setQrCodePositionTop(data.y * 2)
  }

  const handleChangeQrCodeDimensions = useCallback(
    (value) => {
      setQrCodeWidth(value)
      setQrCodeHeight(value)
    },
    [setQrCodeWidth, setQrCodeHeight]
  )

  function fillTextQrCodeMsgEditor(currentMsg) {
    if (!currentMsg) return

    let formattedMsg = currentMsg.replace(
      /\${name}|\${eventName}|\${ticketType}/gi,
      function (variable) {
        switch (variable) {
          case '${name}':
            return '{Nome do participante}'
          case '${eventName}':
            return '{Nome do evento}'
          case '${ticketType}':
            return '{Tipo de ingresso}'
          case '${ticketType}':
            return '{Tipo de ingresso}'
          default:
            return ''
        }
      }
    )

    formattedMsg = formattedMsg.replace(/\*(.*)\*/g, function (variable) {
      variable = variable.replaceAll('*', '')
      return `<strong>${variable}</strong>`
    })

    formattedMsg = formattedMsg.replace(/\_(.*)\_/g, function (variable) {
      variable = variable.replaceAll('_', '')
      return `<em>${variable}</em>`
    })

    formattedMsg = formattedMsg.replace(/\~(.*)\~/g, function (variable) {
      variable = variable.replaceAll('~', '')
      return `<del>${variable}</del>`
    })

    setQrCodeMsg(formattedMsg)

    const blocksFromHtml = htmlToDraft(formattedMsg)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    setEditorState(EditorState.createWithContent(contentState))
  }

  function fillTextEditor(currentMsg) {
    if (!currentMsg) return null
    
    const blocksFromHtml = htmlToDraft(currentMsg)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    
    return EditorState.createWithContent(contentState)
  }

  function changePropertyIsNew() {
    const newTicketTypes = ticketTypes.map((item) => {
      const lotes = item.lotes?.map((itemLote) => {
        return {
          ...itemLote,
          updated: false,
          isNew: false,
          initialAvailableQtd: itemLote.availableQtd
        }
      })

      return {
        ...item,
        updated: false,
        lotes
      }
    })
    setTicketTypes(newTicketTypes)
  }

  async function handleSubmit() {
    
    try {
      setLoading(true)
      let currentMsgValue = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      )
      currentMsgValue = currentMsgValue.replaceAll('<p></p>', '')

      const formattedMsg = currentMsgValue.replace(
        /\&nbsp;|{Nome do participante}|{Nome do evento}|{Tipo de ingresso}|<br>|<\/?p[^>]*>|<\/?strong[^>]*>|<\/?em[^>]*>|<\/?del[^>]*>/g,
        function (variable) {
          switch (variable) {
            case '</p>':
              return '\r\n'
            case '<br>':
              return '\r\n'
            case '<strong>':
              return '*'
            case '</strong>':
              return '*'
            case '<em>':
              return '_'
            case '</em>':
              return '_'
            case '<del>':
              return '~'
            case '</del>':
              return '~'
            case '{Nome do participante}':
              return '${name}'
              break
            case '{Nome do evento}':
              return '${eventName}'
              break
            case '{Tipo de ingresso}':
              return '${ticketType}'
              break
            default:
              return ''
          }
        }
      )

      let hasErrors = false

      let ticketsTemp = [...ticketTypes]

      ticketsTemp = ticketsTemp?.map((item) => {
        
        if (item.deleted) {
          return item
        }
        item.errorMessage = item.errorMessage ? { ...item.errorMessage } : {}
        const headerText = item.headerText
          ? draftToHtml(convertToRaw(item.headerText?.getCurrentContent()))
          : ''

        const description = item.description
          ? draftToHtml(convertToRaw(item.description?.getCurrentContent()))
          : ''

        if (!item.name) {
          hasErrors = true
          item.errorMessage.name = 'O campo Nome é obrigatório'
        }

        const lotes = item.lotes.map((itemLote) => {
          itemLote.errorMessage = itemLote.errorMessage
            ? { ...itemLote.errorMessage }
            : {}

          let validFrom = null
          
          if (itemLote.validFrom && isValid(itemLote.validFrom)) {
            validFrom = itemLote.validFrom
            // validFrom = validFrom.setHours(validFrom.getHours())
            validFrom = format(validFrom, 'yyyy-MM-dd HH:mm')
          
          }

          let validTo = null
          if (itemLote.validTo && isValid(itemLote.validTo)) {
            validTo = itemLote.validTo
            // validTo = validTo.setHours(validTo.getHours())
            validTo = format(validTo, 'yyyy-MM-dd HH:mm')
          }

          if (itemLote.availableQtd === null) {
            hasErrors = true
            itemLote.errorMessage.availableQtd =
              'O campo Quantidade disponível é obrigatório'
          }
            
          if (itemLote.amount === null) {
            hasErrors = true
            itemLote.errorMessage.amount = 'O campo Valor é obrigatório'
          }

          if (!validFrom) {
            hasErrors = true
            itemLote.errorMessage.validFrom = 'O campo Válido de é obrigatório'
          }
          if (!validTo) {
            hasErrors = true
            itemLote.errorMessage.validTo = 'O campo Válido até é obrigatório'
          }
          if (
            itemLote.showPromotionalFields &&
            itemLote.promotionalQtd === null
          ) {
            hasErrors = true
            itemLote.errorMessage.promotionalQtd =
              'O campo Quantidade promocional é obrigatório quando "Campos de promoção" está marcado'
          }
          if (
            itemLote.showPromotionalFields &&
            itemLote.promotionalAmount === null
          ) {
            hasErrors = true
            itemLote.errorMessage.promotionalAmount =
              'O campo Valor promocional é obrigatório quando "Campos de promoção" está marcado'
          }
          return {
            ...itemLote,
            validFromFormatted: validFrom,
            validToFormatted: validTo
          }
        })

        return {
          ...item,
          lotes,
          headerTextFormatted: headerText,
          descriptionFormatted: description
        }
      })
      if (!currentMsgValue?.trim()) {
        hasErrors = true
        setMsgError(true)
      }

      if (!initialImageUrl && !croppedImageData) {
        hasErrors = true
        setDropzoneError(true)
      }

      if (!ticketTypes?.length) {
        toast.error('Adicione pelo menos um tipo de ingresso')
        setLoading(false)
        return
      }

      if (hasErrors) {
        setTicketTypes(ticketsTemp)
        setLoading(false)
        return
      }

      const formData = new FormData()
      formData.append(
        'qrCodeBackgroundFile',
        qrCodeBackgroundFile ? qrCodeBackgroundFile : ''
      )
      formData.append('croppData[left]', croppedImageData?.left || 0)
      formData.append('croppData[top]', croppedImageData?.top || 0)
      formData.append('croppData[width]', croppedImageData?.width || 0)
      formData.append('croppData[height]', croppedImageData?.height || 0)

      formData.append('qrCodeWidth', qrCodeWidth ? qrCodeWidth?.toString() : '')
      formData.append(
        'qrCodeHeight',
        qrCodeHeight ? qrCodeHeight.toString() : ''
      )
      formData.append(
        'qrCodePositionLeft',
        qrCodePositionLeft ? qrCodePositionLeft.toString() : ''
      )
      formData.append(
        'qrCodePositionTop',
        qrCodePositionTop ? qrCodePositionTop.toString() : ''
      )
      formData.append('eventId', eventId ? eventId.toString() : '')
      formData.append('qrCodeMsg', formattedMsg ? formattedMsg : '')
      formData.append('tickets', JSON.stringify(ticketsTemp))

      // setLoading(false)
      // return
      const response = await updateTickets({ formData })

      if (response.status === 200 && response?.data?.success) {
        toast.success('As alterações foram salvas')
        changePropertyIsNew()
        setHasChanges(false)
        setLoading(false)
        checkEvent()
      } else if (response.status === 401) {
        toast.error('Você não está logado ou sua sessão expirou')
        setLoading(false)
        return false
      } else if (
        response?.response?.status === 400 &&
        response?.response?.data?.errors
      ) {
        const errors = response.response.data.errors
        
        toast.error(<ValidationErrorMessage errorMessage={errors} />, {
          autoClose: errors.length * 3000
        })

        setLoading(false)
      } else if (response.status === 500) {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
        )
        setLoading(false)
        return false
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao salvar os dados do evento'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log(error)
    }
  }

  function handleChangeEditor(editorState) {
    setMsgError(false)
    setEditorState(editorState)
  }

  function handleModalClose() {
    setShowCropperModal(false)
  }

  function handleCropImage({ croppedData, croppedBase64 }) {
    
    setCroppedImageData(croppedData)
    setCroppedImageFileUrl(croppedBase64)
    setShowCropperModal(false)
    setHasChanges(true)
  }

  const VariablesOptions = (props) => {
    function addName() {
      const { editorState, onChange } = props
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        '{Nome do participante}', //${name}
        editorState.getCurrentInlineStyle()
      )
      onChange(EditorState.push(editorState, contentState, 'insert-characters'))
    }

    function addEventName() {
      const { editorState, onChange } = props
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        '{Nome do evento}', //${eventName}
        editorState.getCurrentInlineStyle()
      )
      onChange(EditorState.push(editorState, contentState, 'insert-characters'))
    }

    function addTicketType() {
      const { editorState, onChange } = props
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        '{Tipo de ingresso}', //${ticketType}
        editorState.getCurrentInlineStyle()
      )
      onChange(EditorState.push(editorState, contentState, 'insert-characters'))
    }

    return (
      <>
        <CustomToolbarOptionsTitle style={{ width: '100%', display: 'flex' }}>
          Variáveis disponíveis
        </CustomToolbarOptionsTitle>
        <CustomToolbarOptionsContainer
          style={{ width: '100%', display: 'flex' }}
        >
          <CustomToolbarOption onClick={addName}>
            Nome do participante
          </CustomToolbarOption>
          <CustomToolbarOption onClick={addEventName}>
            Nome do evento
          </CustomToolbarOption>
          <CustomToolbarOption onClick={addTicketType}>
            Tipo de ingresso
          </CustomToolbarOption>
        </CustomToolbarOptionsContainer>
      </>
    )
  }

  function handleCancel() {
    console.log('cancelado')
    window.location.href = '/eventozz'
  }

  function handleChangeTicketType({ value, ticketIndex, field, indexLote }) {
    let newTicketTypes = []

    console.log("===========================");
    console.log(value);
    console.log("===========================");

    if (indexLote >= 0) {
      newTicketTypes = ticketTypes.map((item, index) => {
        if (index === ticketIndex) {
          const lotes = item.lotes?.map((itemLote, iLote) => {
            if (iLote === indexLote) {
              return {
                ...itemLote,
                updated: true,
                errorMessage: itemLote?.errorMessage
                  ? {
                      ...itemLote.errorMessage,
                      [field]: null
                    }
                  : null,
                [field]: value
              }
            }
            return itemLote
          })

          // console.log(lotes)
          return {
            ...item,
            updated: true,
            lotes
          }
        }
        return item
      })
    } else {
      newTicketTypes = ticketTypes.map((item, index) => {
        if (index === ticketIndex) {
          const lotes = item.lotes?.map((itemLote) => {
            return {
              ...itemLote,
              updated: true
            }
          })
          return {
            ...item,
            lotes,
            updated: true,
            errorMessage: item?.errorMessage
              ? {
                  ...item.errorMessage,
                  [field]: null
                }
              : null,
            [field]: value
          }
        }
        return item
      })
    }
    
    setHasChanges(true)
    setTicketTypes(newTicketTypes)
  }

  function handleAddTicket() {
    const newTicketTypes = [...ticketTypes]
    newTicketTypes.push({
      name: null,
      updated: false,
      isNew: true,
      errorMessage: null,
      headerText: EditorState.createEmpty(),
      description: EditorState.createEmpty(),
      lotes: [
        {
          id: null,
          amount: null,
          availableQtd: null,
          promotionalAmount: null,
          promotionalQtd: null,
          showPromotionalFields: false,
          validFrom: null,
          errorMessage: null,
          validTo: null,
          active: true,
          updated: false,
          isNew: true
        }
      ]
    })
    setHasChanges(true)
    setTicketTypes(newTicketTypes)
  }

  function handleAddTicketLote(indexTicket) {
    const newTicketTypeLotes = ticketTypes.map((item, index) => {
      if (index === indexTicket) {
        return {
          ...item,
          lotes: [
            ...item.lotes,
            {
              id: null,
              amount: null,
              availableQtd: null,
              promotionalAmount: null,
              promotionalQtd: null,
              showPromotionalFields: false,
              validFrom: null,
              errorMessage: null,
              validTo: null,
              active: true,
              updated: false,
              isNew: true
            }
          ]
        }
      }
      return item
    })
    setHasChanges(true)
    setTicketTypes(newTicketTypeLotes)
  }

  function handleRemoveTicket(ticketIndex) {
    const ticket = ticketTypes?.find(
      (item, index) => ticketIndex === index && !item.isNew
    )
    if (ticket) {
      const ticketTypesTemp = ticketTypes.filter(
        (_, index) => index !== ticketIndex
      )
      setTicketTypes(ticketTypesTemp)
    } else {
      const ticketTypesTemp = ticketTypes.filter(
        (_, index) => index !== ticketIndex
      )
      setTicketTypes(ticketTypesTemp)
    }
    setHasChanges(true)
  }

  function handleRemoveTicketLote(ticketIndex, ticketLoteIndex) {
    const newTicketTypes = ticketTypes.map((item, index) => {
      if (index === ticketIndex) {
        const lote = item.lotes?.find(
          (itemLote, indexLote) =>
            ticketLoteIndex === indexLote && !itemLote.isNew
        )
        let lotes = []
        if (lote) {
          lotes = item.lotes?.filter(
            (_, indexLote) => indexLote !== ticketLoteIndex
          )
        } else {
          lotes = item.lotes?.filter(
            (_, indexLote) => indexLote !== ticketLoteIndex
          )
        }

        return {
          ...item,
          lotes
        }
      }
      return item
    })
    setHasChanges(true)
    setTicketTypes(newTicketTypes)
  }

  function fillInitialData() {
    
    if (eventData?.tickets && eventData?.tickets?.length > 0) {
      const currentTickets = eventData.tickets?.map((item) => {
        let lotes = item?.lotes?.map((itemLote) => {
          let validFrom = null
          let validTo = null
          if (isValid(new Date(itemLote.validFrom))) {
            validFrom = new Date(itemLote.validFrom)
            validFrom.setHours(validFrom.getHours() + 3)
          }
          if (isValid(new Date(itemLote.validTo))) {
            validTo = new Date(itemLote.validTo)
            validTo.setHours(validTo.getHours() + 3)
          }
          return {
            ...itemLote,
            showPromotionalFields: !!(
              itemLote?.promotionalQtd && itemLote?.promotionalQtd > 0
            ),
            validFrom,
            errorMessage: null,
            validTo,
            updated: false,
            isNew: false
          }
        })
        if (lotes.length === 0) {
          lotes = [
            {
              id: null,
              amount: null,
              availableQtd: null,
              promotionalAmount: null,
              promotionalQtd: null,
              showPromotionalFields: false,
              validFrom: null,
              errorMessage: null,
              validTo: null,
              active: false,
              updated: false,
              isNew: true
            }
          ]
        }
        return {
          name: item.name,
          updated: false,
          isNew: false,
          errorMessage: null,
          headerText: fillTextEditor(item.headerText),
          description: fillTextEditor(item.description),
          errorMessage: null,
          lotes
        }
      })

      setTicketTypes(currentTickets)
    }
    if (eventData?.qrCodeMsg) {
      setInitialImageUrl(
        eventData?.qrCodeBackground ? eventData.qrCodeBackground : null
      )
      setQrCodeHeight(eventData.qrCodeHeight ? eventData.qrCodeHeight : 100)
      setQrCodeWidth(eventData.qrCodeWidth ? eventData.qrCodeWidth : 100)
      setQrCodePositionTop(
        eventData.qrCodePositionTop ? eventData.qrCodePositionTop : 0
      )
      setQrCodePositionLeft(
        eventData.qrCodePositionLeft ? eventData.qrCodePositionLeft : 0
      )
      fillTextQrCodeMsgEditor(eventData.qrCodeMsg)
    }
  }

  const Thumb = (props, state) => (
    <StyledThumb {...props}>{state.valueNow}</StyledThumb>
  )

  const Track = (props, state) => <StyledTrack {...props} index={state.index} />

  useEffect(() => {
    if (eventData) {
      fillInitialData()
    }
  }, [eventData])

  useEffect(() => {
    if (qrCodeBackgroundFile) {
      setShowCropperModal(true)
    }
  }, [qrCodeBackgroundFile])

  return (
    <Container className="pt-4">
      <Row xs={12} md={6} justifyContent="flex-start" item>
        <h5 className={styles.titleRoot}>Tipos de Ingresso</h5>
      </Row>
      {ticketTypes?.map(
        (ticketType, index) =>
          !ticketType?.deleted && (
            <Container key={index} className={`${styles.gridContainer}`}>
              <Row container spacing={4} className="pt-2 pb-2">
                <h5 className={styles.titleRoot}>Ingresso {index + 1}</h5>
                <Col item xs={12} sm={6}>
                  <Form.Group>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      className={styles.inputEventozz}
                      variant="outlined"
                      label="Nome"
                      name="name"
                      value={ticketType.name}
                      onChange={(e) =>
                        handleChangeTicketType({
                          value: e?.target?.value,
                          field: 'name',
                          ticketIndex: index,
                          indexLote: -1
                        })
                      }
                      isInvalid={!!ticketType?.errorMessage?.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {ticketType?.errorMessage?.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row container spacing={4} className="pt-4">
                <Col xs={12} md={6}>
                  <Form.Label>Cabeçalho (Texto acima do preço)</Form.Label>
                  <Editor
                    editorState={ticketType.headerText}
                    wrapperClassName={styles.textEditorWrapper}
                    editorClassName={styles.textEditorContent}
                    toolbarClassName={styles.textEditorToolbar}
                    onEditorStateChange={(value) =>
                      handleChangeTicketType({
                        value,
                        field: 'headerText',
                        ticketIndex: index,
                        indexLote: -1
                      })
                    }
                    toolbar={{
                      options: ['inline', 'fontSize', 'list', 'textAlign'],
                      inline: {
                        options: ['bold', 'italic', 'strikethrough']
                      },

                      fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['unordered', 'ordered']
                      },
                      textAlign: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['left', 'center', 'right', 'justify']
                      }
                    }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label>Descrição (Texto abaixo do preço)</Form.Label>
                  <Editor
                    editorState={ticketType.description}
                    wrapperClassName={styles.textEditorWrapper}
                    editorClassName={styles.textEditorContent}
                    toolbarClassName={styles.textEditorToolbar}
                    onEditorStateChange={(value) =>
                      handleChangeTicketType({
                        value,
                        field: 'description',
                        ticketIndex: index,
                        indexLote: -1
                      })
                    }
                    toolbar={{
                      options: ['inline', 'fontSize', 'list', 'textAlign'],
                      inline: {
                        options: ['bold', 'italic', 'strikethrough']
                      },

                      fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['unordered', 'ordered']
                      },
                      textAlign: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['left', 'center', 'right', 'justify']
                      }
                    }}
                  />
                </Col>
              </Row>
              {ticketType.lotes?.map((lote, indexLote) => {
                return (
                  <Card key={indexLote.toString()}>
                    <Card.Body>
                      <Row container spacing={4} className="pt-2">
                        <Col item xs={12} sm={8}>
                          <h5 className={styles.titleRoot}>
                            Lote {indexLote + 1}
                          </h5>
                        </Col>
                        <Col
                          xs={12}
                          sm={4}
                          justifyContent="space-between"
                          alignItems="center"
                          container
                          className={styles.extraPadding}
                        >
                          <Form.Group switch>
                            <Form.Check
                              checked={lote.active}
                              label="Ativo"
                              onChange={(e) =>
                                handleChangeTicketType({
                                  value: e?.target?.checked,
                                  field: 'active',
                                  ticketIndex: index,
                                  indexLote
                                })
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col item xs={12} sm={6}>
                          <Form.Group>
                            <Form.Label>Quantidade disponível</Form.Label>
                            <Form.Control
                              className={styles.inputEventozz}
                              type="number"
                              variant="outlined"
                              label="Quantidade disponível"
                              name="availableQtd"
                              value={lote.availableQtd}
                              onChange={(e) =>
                                handleChangeTicketType({
                                  value: e?.target?.value,
                                  field: 'availableQtd',
                                  ticketIndex: index,
                                  indexLote
                                })
                              }
                              isInvalid={!!lote?.errorMessage?.availableQtd}
                            />
                            <Form.Control.Feedback type="invalid">
                              {lote?.errorMessage?.availableQtd}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col item xs={12} sm={6}>
                          <CurrencyInput
                            name="amount"
                            variant="outlined"
                            value={lote.amount}
                            label="Valor"
                            margin="none"
                            handleChange={(value) => {
                              handleChangeTicketType({
                                value: value === null ? 0 : value,
                                field: 'amount',
                                ticketIndex: index,
                                indexLote
                              })
                            }}
                            isInvalid={!!lote?.errorMessage?.amount}
                          />
                          {!!lote?.errorMessage?.amount && (
                            <>
                              <span className="is-invalid"></span>
                              <p className="invalid-feedback">
                                {lote?.errorMessage?.amount}
                              </p>
                            </>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col item xs={12} sm={6} className="pt-2">
                          <Form.Group>
                            <Form.Label>Válido de</Form.Label>

                            <ReactDatePicker
                              className="form-control"
                              selected={lote.validFrom ? lote.validFrom : null}
                              onChange={(date) =>
                                handleChangeTicketType({
                                  value: date,
                                  field: 'validFrom',
                                  ticketIndex: index,
                                  indexLote
                                })
                              }
                              showTimeSelect
                              dateFormat="dd/MM/yyyy HH:mm:ss"
                              locale="pt-BR"
                            />

                            {lote?.errorMessage?.validFrom && (
                              <p className={styles.helperText}>
                                {lote?.errorMessage?.validFrom}
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                        <Col item xs={12} sm={6} className="pt-2">
                          <Form.Group>
                            <Form.Label>Válido até</Form.Label>

                            <ReactDatePicker
                              className="form-control"
                              selected={lote.validTo ? lote.validTo : null}
                              onChange={(date) =>
                                handleChangeTicketType({
                                  value: date,
                                  field: 'validTo',
                                  ticketIndex: index,
                                  indexLote
                                })
                              }
                              showTimeSelect
                              dateFormat="dd/MM/yyyy HH:mm:ss"
                              locale="pt-BR"
                            />

                            {lote?.errorMessage?.validTo && (
                              <p className={styles.helperText}>
                                {lote?.errorMessage?.validTo}
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className={`${styles.extraPadding} pt-2`}>
                          <Form.Group switch>
                            <Form.Check
                              checked={lote.showPromotionalFields}
                              name="showPromotionalFields"
                              label="Campos de Promoção"
                              onChange={(e) =>
                                handleChangeTicketType({
                                  value: e?.target?.checked,
                                  field: 'showPromotionalFields',
                                  ticketIndex: index,
                                  indexLote
                                })
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {lote.showPromotionalFields && (
                        <Row>
                          <Col item xs={12} sm={6} md={4}>
                            <Form.Group>
                              <Form.Label>Quantidade promocional</Form.Label>
                              <Form.Control
                                className={styles.inputEventozz}
                                type="number"
                                name="promotionalQtd"
                                value={lote.promotionalQtd}
                                onChange={(e) =>
                                  handleChangeTicketType({
                                    value: e?.target?.value,
                                    field: 'promotionalQtd',
                                    ticketIndex: index,
                                    indexLote
                                  })
                                }
                                isInvalid={!!lote?.errorMessage?.promotionalQtd}
                              />

                              <Form.Control.Feedback type="invalid">
                                {lote?.errorMessage?.promotionalQtd}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col item xs={12} sm={6} md={4}>
                            <Form.Group>
                              <CurrencyInput
                                name="promotionalAmount"
                                variant="outlined"
                                value={lote.promotionalAmount}
                                margin="none"
                                label="Valor promocional"
                                handleChange={(value) =>
                                  handleChangeTicketType({
                                    value,
                                    field: 'promotionalAmount',
                                    ticketIndex: index,
                                    indexLote
                                  })
                                }
                                isInvalid={
                                  !!lote?.errorMessage?.promotionalAmount
                                }
                              />

                              {lote?.errorMessage?.promotionalAmount && (
                                <p className={styles.helperText}>
                                  {lote?.errorMessage?.promotionalAmount}
                                </p>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                      <Row className="pt-4 justify-content-end">
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex justify-content-end"
                        >
                          {lote.isNew &&
                            (ticketType.lotes?.length > 1 || indexLote > 0) && (
                              <Button
                                variant="danger"
                                type="button"
                                color="danger"
                                onClick={() =>
                                  handleRemoveTicketLote(index, indexLote)
                                }
                                style={{ marginTop: '30px' }}
                              >
                                Remover lote
                              </Button>
                            )}
                          {indexLote === ticketType?.lotes?.length - 1 && (
                            <Button
                              className="mx-3"
                              variant="primary"
                              type="button"
                              onClick={() => handleAddTicketLote(index)}
                              color="primary"
                              style={{ marginTop: '30px' }}
                            >
                              Adicionar Lote
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )
              })}
              <Row className="pt-4 justify-content-end">
                <Col xs={12} md={6} className="d-flex justify-content-end">
                  {ticketType.isNew &&
                    (ticketTypes?.length > 1 || index > 0) && (
                      <Button
                        variant="danger"
                        type="button"
                        color="danger"
                        onClick={() => handleRemoveTicket(index)}
                        style={{ marginTop: '30px' }}
                      >
                        Remover tipo de ingresso
                      </Button>
                    )}
                  {index === ticketTypes?.length - 1 && (
                    <Button
                      className="mx-3"
                      variant="primary"
                      type="button"
                      onClick={() => handleAddTicket()}
                      color="primary"
                      style={{ marginTop: '30px' }}
                    >
                      Adicionar tipo de ingresso
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          )
      )}

      <Col xs={12} md={6} justifyContent="flex-start" item className="mt-5">
        <h5>Texto e imagem do Qr code</h5>
      </Col>

      <Row container xs={12}>
        <Col xs={12} md={6}>
          {croppedImageUrl || initialImageUrl ? (
            <>
              <DraggableContainer
                qrCodeBackground={croppedImageUrl || initialImageUrl}
              >
                <Draggable
                  onDrag={(e, data) => trackQrCodePosition(data)}
                  defaultPosition={{
                    x: qrCodePositionLeft / 2,
                    y: qrCodePositionTop / 2
                  }}
                  bounds={{
                    left: 0,
                    top: 50,
                    right: 540 - (qrCodeWidth ? qrCodeWidth / 2 : 0),
                    bottom: 460 - (qrCodeHeight ? qrCodeHeight / 2 : 0)
                  }}
                >
                  <div className={styles.draggableArea}>
                    <DraggableHelper
                      width={qrCodeWidth ? qrCodeWidth / 2 : 0}
                      height={qrCodeHeight ? qrCodeHeight / 2 : 0}
                    />
                    <QrcodeImg
                      src={qrCodeImage}
                      alt="Imagem qrcode"
                      width={qrCodeWidth ? qrCodeWidth / 2 : 0}
                      height={qrCodeHeight ? qrCodeHeight / 2 : 0}
                    />
                  </div>
                </Draggable>
              </DraggableContainer>
              <div className={styles.containerSlider}>
                <ReactSlider
                  className="horizontal-slider"
                  marks
                  markClassName="example-mark"
                  min={100}
                  max={800}
                  defaultValue={qrCodeWidth}
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  renderThumb={Thumb}
                  renderTrack={Track}
                  onChange={(value) => {
                    handleChangeQrCodeDimensions(Number(value))
                  }}
                />

                <h5>
                  Largura do Qr code <b>{qrCodeWidth}px</b>
                </h5>
              </div>
              <FileUpload
                dropzoneError={false}
                text="Clique aqui para alterar a imagem de background do qr code"
                suggestion="(No máximo 3M. Recomendamos que a imagem tenha 1080x1080 px *.jpeg ou *.png)"
                onDrop={onDrop}
                className={styles.dropzoneAtFooter}
                maxSize={3000000}
              />
            </>
          ) : (
            <FileUpload
              dropzoneError={dropzoneError}
              text="Solte a imagem aqui, ou clique para selecionar a imagem de background do qr code"
              suggestion="(No máximo 3M. Recomendamos que a imagem tenha 1080x1080 px *.jpeg ou *.png)"
              onDrop={onDrop}
              className={styles.onlyDropzone}
              maxSize={3000000}
            />
          )}
        </Col>
        <Col xs={12} md={6}>
          {msgError && (
            <>
              <span className="is-invalid"></span>
              <p className={`invalid-feedback ${styles.helperTextEditor}`}>
                Esse campo é obrigatório
              </p>
            </>
          )}
          <Editor
            handlePastedText={(text, html, editorState, onChange) => {
              if (/<\/?[a-z][\s\S]*>/i.test(text)) {
                const blockMap = stateFromHTML(text).blockMap
                const newState = Modifier.replaceWithFragment(
                  editorState.getCurrentContent(),
                  editorState.getSelection(),
                  blockMap
                )
                onChange(
                  EditorState.push(editorState, newState, 'insert-fragment')
                )
                return true
              }
            }}
            editorState={editorState}
            wrapperClassName={`${styles.textEditorWrapper} ${
              msgError ? styles.errorValidation : ''
            }`}
            editorClassName={styles.textEditorContent}
            toolbarClassName={styles.textEditorToolbar}
            onEditorStateChange={handleChangeEditor}
            toolbarCustomButtons={[<VariablesOptions key={0} />]}
            toolbar={{
              options: ['inline'],
              inline: {
                options: ['bold', 'italic', 'strikethrough']
              }
            }}
          />
        </Col>
      </Row>

      <Row className="pt-4 justify-content-end mb-4">
        <Col xs={12} md={4} className="d-flex justify-content-end">
          <Button
            variant="danger"
            type="button"
            color="danger"
            onClick={() => handleCancel()}
            style={{ marginTop: '30px' }}
          >
            Voltar
          </Button>
          <CustomButton
            className="mx-3"
            variant="primary"
            type="button"
            disabled={!eventId}
            showTooltip={!eventId}
            title={
              !eventId
                ? "Salve o evento na sessão 'Detalhes' antes de salvar os ingressos"
                : ''
            }
            onClick={() => handleSubmit()}
            color="primary"
            style={{ marginTop: '30px' }}
            text="Salvar"
          />
        </Col>
      </Row>

      <ModalCropImage
        showModal={showCropperModal}
        aspectRatio={1}
        imgBase64={qrCodeBackground}
        onClose={handleModalClose}
        onConfirm={handleCropImage}
        height={1080}
        width={1080}
        imageDimensions={imageDimensions}
      />
    </Container>
  )
}
