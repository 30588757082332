import React, { useRef, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import JoditEditor from 'jodit-react'
import 'jodit/build/jodit.min.css'
import styles from './styles.module.scss'
// import { ContainerButtonForms } from 'components/containerButtonsForm'
import eventzzService from 'services/eventzz'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { format, isValid } from 'date-fns'
import { toast } from 'react-toastify'
import { categoryService } from 'services/category'
import { stateList } from 'util/mocks'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Select } from 'components/select'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { ptBR } from 'date-fns/locale'
import { Tooltip } from 'components/Tooltip'
import { apisExternasService } from 'services/apisExternas'
import { ValidationErrorMessage } from 'components/validationError'

registerLocale('pt-BR', ptBR)

const validationSchema = Yup.object().shape({
  eventName: Yup.string().required('O nome do evento é obrigatório'),
  eventCategory: Yup.string().required('Escolha a categoria do evento'),
  startDate: Yup.string()
    .required('Informe a data de início do evento')
    .nullable(),
  endDate: Yup.string()
    .required('Informe a data de encerramento do evento')
    .nullable(),
  fee: Yup.string().required('Informe o tipo de taxa'),
  type: Yup.string().required('Informe o tipo do evento'),
  isFree: Yup.string().required('Informe se o evento é gratuito'),
  startSaleDate: Yup.string()
    .required('Informe a data de inínio das vendas')
    .nullable(),
  endSaleDate: Yup.string()
    .required('Informe a data de fim das vendas')
    .nullable(),
  eventPlace: Yup.string().required('Informe o local do evento'),
  state: Yup.string()
    .required('Informe o estado onde ocorrerá o evento')
    .nullable(),
  city: Yup.string()
    .required('Informe a cidade onde ocorrerá o evento')
    .nullable(),
  description: Yup.string()
    .required('Informe a descrição do evento')
    .test('description', 'Informe a descrição do evento', (value) => {
      console.log(value)
      const string = value.replace(
        /\&nbsp;|<br>|<\/?p[^>]*>|<\/?strong[^>]*>|<\/?em[^>]*>|<\/?del[^>]*>|<\/?s[^>]*>/g,
        function () {
          return ''
        }
      )

      return string.length
    })
})

const tipoTaxa = [
  { value: 'cliente', label: 'Cliente Paga' },
  { value: 'organizador', label: 'Organizador Paga' }
]

const eventTypeList = [
  { value: 'Privado', label: 'Privado' },
  { value: 'Aberto', label: 'Aberto' }
]

const eventFreeList = [
  { value: 0, label: 'Não' },
  { value: 1, label: 'Sim' }
]

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  minHeight: 500,
  placeholder: 'Faça a descrição do seu evento'
}

export const TabDetails = ({
  eventId,
  setLoading,
  setEventId,
  eventData,
  setHasChanges
}) => {
  const editorRef = useRef(null)
  const descriptionControl = useRef(0)
  // const history = useHistory()

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    // getValues,
    watch
  } = useForm({
    resolver: yupResolver(validationSchema)
  })
  // console.log(getValues())
  // console.log(errors)
  // eslint-disable-next-line no-unused-vars
  const { select: selectCategory } = categoryService
  const { buscarCidades } = apisExternasService
  const [rendered, setRendered] = useState(false)

  const [categoryList, setCategoryList] = useState([])
  // const [stateList, setStateList] = useState(statesList)
  const [cityList, setCityList] = useState([])
  const [showCounterFields, setShowCounterFields] = useState(false)
  const { verifySlug, createEventozz, updateEventozz } = eventzzService

  async function loadCategories() {
    const response = await selectCategory()

    if (response?.status === 200 && response?.data?.success) {
      const data = response.data
      console.log(data)
      setCategoryList(data.data)
    } else if (response.status === 401) {
      console.log('here')
    } else if (response.status === 500) {
    } else {
    }
  }


  async function loadCities(state) {
    const response = await buscarCidades(state)
    console.log({ response })
    if (response?.status === 200 && response?.data) {
      let data = response.data
      data = data.sort((cityA, cityB) => {
        if (cityA.nome < cityB.nome) return -1
        if (cityA.nome > cityB.nome) return 1
        return 0
      })
      data = data.map((a) => ({
        label: a.nome,
        value: a.nome
      }))
      setCityList(data)
    } else if (response.status === 401) {
      console.log('here')
    } else if (response.status === 500) {
    } else {
    }
  }

  const handlerSetSlug = async (text) => {
    text = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()

    // Substitui os espaços em branco por hífens
    text = text.replace(/\s+/g, '-')
    const i = 0

    const result = await verifySlug(text)
    if (result.status === 200) {
      if (result?.data?.data) {
        setValue('slug', text)
      } else {
        i += 1
        text = `${text}-${i}`
        handlerSetSlug(text)
      }
    }
  }

  function fillInitialData() {
    console.log(eventData)

    if (eventData) {
      let startDate = null
      let endDate = null
      let startSaleDate = null
      let endSaleDate = null
      if (isValid(new Date(eventData.startDate))) {
        startDate = new Date(eventData.startDate)
        startDate.setHours(startDate.getHours() + 3)
      }
      if (isValid(new Date(eventData.endDate))) {
        endDate = new Date(eventData.endDate)
        endDate.setHours(endDate.getHours() + 3)
      }
      if (isValid(new Date(eventData.startSaleDate))) {
        startSaleDate = new Date(eventData.startSaleDate)
        startSaleDate.setHours(startSaleDate.getHours() + 3)
      }
      if (isValid(new Date(eventData.endSaleDate))) {
        endSaleDate = new Date(eventData.endSaleDate)
        endSaleDate.setHours(endSaleDate.getHours() + 3)
      }
      if (eventData.state) {
        loadCities(eventData.state)
      }

      if (eventData.hasCounter) {
        setShowCounterFields(true)
      } else {
        setShowCounterFields(false)
      }
      reset({
        ...eventData,
        eventCategory: Number(eventData.eventCategory),
        startDate,
        endDate,
        startSaleDate,
        endSaleDate,
        open: !Number(eventData.closed)
      })
    }
  }

  const onSubmit = async (data) => {
    try {
      const { endDate, endSaleDate, startSaleDate, startDate } = data

      // console.log(isValid(endSaleDate))
      let startDateTemp = null
      let endDateTemp = null
      let startSaleDateTemp = null
      let endSaleDateTemp = null
      if (isValid(new Date(startDate))) {
        startDateTemp = new Date(startDate)
        startDateTemp.setHours(startDateTemp.getHours() - 3)
        format(new Date(startDateTemp), 'yyyy-MM-dd HH:mm')
      }
      if (isValid(new Date(endDate))) {
        endDateTemp = new Date(endDate)
        endDateTemp.setHours(endDateTemp.getHours() - 3)
        format(new Date(endDateTemp), 'yyyy-MM-dd HH:mm')
      }
      if (isValid(new Date(startSaleDate))) {
        startSaleDateTemp = new Date(startSaleDate)
        startSaleDateTemp.setHours(startSaleDateTemp.getHours() - 3)
        format(new Date(startSaleDateTemp), 'yyyy-MM-dd HH:mm')
      }
      if (isValid(new Date(endSaleDate))) {
        endSaleDateTemp = new Date(endSaleDate)
        endSaleDateTemp.setHours(endSaleDateTemp.getHours() - 3)
      }

      const newDataEvent = {
        eventCategory: data.eventCategory,
        description: data.description,
        eventName: data.eventName,
        eventPlace: data.eventPlace,
        type: data.type,
        fee: data.fee,
        slug: data.slug,
        eventId: data.eventId,
        isFree: data.isFree,
        counterTitle: data.counterTitle,
        counterText: data.counterText,
        state: data.state,
        city: data.city,
        endDate: endDateTemp,
        endSaleDate: endSaleDateTemp,
        startSaleDate: startSaleDateTemp,
        startDate: startDateTemp,
        closed: !data.open,
        hasCounter: !!data.hasCounter
      }

      setLoading(true)
      let response = {}
      if (eventId) {
        response = await updateEventozz({ ...newDataEvent, eventId })
      } else {
        response = await createEventozz(newDataEvent)
      }
      

      if (
        response.status === 200 &&
        response?.data?.success &&
        response?.data?.eventId
      ) {
        setHasChanges(false)
        setEventId(response?.data?.eventId)
        toast.success('O evento foi criado')
        setLoading(false)
      } else if (response.status === 201 && response?.data?.success) {
        setHasChanges(false)
        toast.success('As alterações foram salvas')
        setLoading(false)
      } else if (response?.response?.status === 401) {
        toast.error('Você não está logado ou sua sessão expirou')
        setLoading(false)
        return false
      } else if (
        response?.response?.status === 400 &&
        response?.response?.data?.errors
      ) {
        const errors = response.response.data.errors
        console.log({ errors })
        toast.error(<ValidationErrorMessage errorMessage={errors} />, {
          autoClose: errors.length * 3000
        })

        setLoading(false)
      } else if (response?.response?.status === 500) {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
        )
        setLoading(false)
        return false
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao salvar os dados do evento'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log(error)
    }
  }

  function handleCancel() {
    window.location.href = '/eventozz'
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }

    if (rendered) {
      loadCategories()
      // loadStates()
    }
  }, [rendered])

  useEffect(() => {
    if (eventData && rendered) {
      fillInitialData()
    }
  }, [eventData, rendered])
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (!type) {
        return
      }
      if (
        type === 'change' &&
        name === 'description' &&
        descriptionControl.current === 0
      ) {
        descriptionControl.current = 1
        return
      }
      setHasChanges(true)
      
      if (type === 'change' && name === 'hasCounter') {
        if (value.hasCounter) {
          setShowCounterFields(true)
        } else {
          setShowCounterFields(false)
        }
      }
      if (type === 'change' && name === 'state' && value.state) {
        loadCities(value.state)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <Container className="pt-4">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row container spacing={4}>
          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group
            // className={}
            >
              <Form.Label>Nome do evento*</Form.Label>
              <Controller
                name="eventName"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Form.Control
                    onChange={onChange}
                    value={value}
                    onBlur={(e) => handlerSetSlug(e.target?.value)}
                    isInvalid={!!errors.eventName?.message}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.eventName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group>
              <Form.Label>Link do evento*</Form.Label>
              <Controller
                name="slug"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Form.Control
                    onChange={onChange}
                    value={
                      value ? `https://www.eventozz.com/evento/${value}/` : ''
                    }
                    disabled
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4} className="align-items-end d-flex pt-4">
            <Form.Group switch>
              <Controller
                name="open"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Form.Check
                    checked={value}
                    label={
                      <Form.Label>
                        Evento aberto?*
                        <Tooltip
                          title="Evento aberto"
                          description="Para que as vendas sejam disponibilizadas no site o evento precisa estar aberto.<br /> Caso não seja marcado o checkbox <b>Evento aberto?</b> não será possível realizar vendas"
                        />
                      </Form.Label>
                    }
                    onChange={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group>
              <Form.Label>Categoria*</Form.Label>
              <Controller
                name="eventCategory"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      options={categoryList}
                      placeholder="Escolha uma categoria"
                      onChange={(option) => onChange(option.value)}
                      value={value}
                      hasErrors={!!errors.eventCategory?.message}
                    />
                    {!!errors.eventCategory?.message && (
                      <>
                        <span className="is-invalid"></span>
                        <p className="invalid-feedback">
                          {errors.eventCategory?.message}
                        </p>
                      </>
                    )}
                  </>
                )}
              />
            </Form.Group>
          </Col>
          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group>
              <Form.Label>
                Tipo de taxa*
                <Tooltip
                  title="Tipo de taxa"
                  description="Ao selecionar <b>Cliente paga</b> a taxa será adicionada ao preço final da compra, fazendo com que o cliente pague tanto pelo ingresso quanto pela taxa.<br> Já na opção <b>Organizador paga</b>, a taxa é descontada do valor total da compra, de forma que o cliente paga apenas pelo ingresso e o organizador arca com o custo da taxa."
                />
              </Form.Label>
              <Controller
                name="fee"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      options={tipoTaxa}
                      placeholder="Taxa"
                      onChange={(option) => onChange(option.value)}
                      value={value}
                      hasErrors={!!errors.fee?.message}
                    />
                    {!!errors.fee?.message && (
                      <>
                        <span className="is-invalid"></span>
                        <p className="invalid-feedback">
                          {errors.fee?.message}
                        </p>
                      </>
                    )}
                  </>
                )}
              />
            </Form.Group>
          </Col>
          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group>
              <Form.Label>
                Tipo de evento*
                <Tooltip
                  title="Tipo de evento"
                  description="Ao selecionar <b>Privado</b> o evento não aparecerá no feed do site, poderá ser acessado apenas utilizando o link direto para a página do evento.<br>Já na opção <b>Aberto</b> o evento aparecerá no feed do site"
                />
              </Form.Label>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      options={eventTypeList}
                      placeholder="Tipo de evento"
                      onChange={(option) => onChange(option.value)}
                      value={value}
                      hasErrors={!!errors.type?.message}
                    />
                    {!!errors.type?.message && (
                      <>
                        <span className="is-invalid"></span>
                        <p className="invalid-feedback">
                          {errors.type?.message}
                        </p>
                      </>
                    )}
                  </>
                )}
              />
            </Form.Group>
          </Col>

          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group>
              <Form.Label>Local do Evento*</Form.Label>
              <Controller
                name="eventPlace"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Form.Control
                    onChange={onChange}
                    value={value}
                    isInvalid={!!errors.eventPlace?.message}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.eventPlace?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group>
              <Form.Label>Evento gratuito? *</Form.Label>
              <Controller
                name="isFree"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      options={eventFreeList}
                      placeholder="Evento gratuito"
                      onChange={(option) => onChange(option.value)}
                      value={value}
                      hasErrors={!!errors.type?.message}
                    />
                    {!!errors.type?.message && (
                      <>
                        <span className="is-invalid"></span>
                        <p className="invalid-feedback">
                          {errors.type?.message}
                        </p>
                      </>
                    )}
                  </>
                )}
              />
            </Form.Group>
          </Col>
          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group>
              <Form.Label className="d-flex">
                Data de início do evento*
              </Form.Label>
              <Controller
                name="startDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ReactDatePicker
                    className={`form-control ${
                      errors.startDate && 'is-invalid'
                    }`}
                    selected={value ? value : null}
                    onChange={onChange}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    locale="pt-BR"
                  />
                )}
              />
              {errors?.startDate && (
                <p className={styles.helperText}>{errors.startDate.message}</p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group>
              <Form.Label>Data de encerramento do evento*</Form.Label>
              <Controller
                name="endDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ReactDatePicker
                    className="form-control"
                    selected={value ? value : null}
                    onChange={onChange}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    locale="pt-BR"
                  />
                )}
              />
              {errors?.endDate && (
                <p className={styles.helperText}>{errors.endDate.message}</p>
              )}
            </Form.Group>
          </Col>
          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group>
              <Form.Label>
                Data de início das vendas
                <Tooltip
                  title="Data de início das vendas"
                  description="Para que essa configuração tenha efeito no site, o checkbox <b>Evento aberto?</b> precisa estar marcado!"
                />
              </Form.Label>
              <Controller
                name="startSaleDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ReactDatePicker
                    className="form-control"
                    selected={value ? value : null}
                    onChange={onChange}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    locale="pt-BR"
                  />
                )}
              />
              {errors?.startSaleDate && (
                <p className={styles.helperText}>
                  {errors.startSaleDate.message}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group>
              <Form.Label>Data de encerramento das vendas</Form.Label>
              <Controller
                name="endSaleDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ReactDatePicker
                    className="form-control"
                    selected={value ? value : null}
                    onChange={onChange}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    locale="pt-BR"
                  />
                )}
              />
              {errors?.endSaleDate && (
                <p className={styles.helperText}>
                  {errors.endSaleDate.message}
                </p>
              )}
            </Form.Group>
          </Col>

          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group>
              <Form.Label>Estado*</Form.Label>
              <Controller
                name="state"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      options={stateList}
                      placeholder="Selecione..."
                      onChange={(option) => onChange(option.value)}
                      value={value}
                      hasErrors={!!errors.fee?.message}
                    />
                    {!!errors.state?.message && (
                      <>
                        <span className="is-invalid"></span>
                        <p className="invalid-feedback">
                          {errors.state?.message}
                        </p>
                      </>
                    )}
                  </>
                )}
              />
            </Form.Group>
          </Col>
          <Col item xs={12} sm={4} className="pt-4">
            <Form.Group>
              <Form.Label>Cidade*</Form.Label>
              <Controller
                name="city"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      options={cityList}
                      placeholder="Selecione..."
                      onChange={(option) => onChange(option.value)}
                      value={value}
                      hasErrors={!!errors.fee?.message}
                    />
                    {!!errors.city?.message && (
                      <>
                        <span className="is-invalid"></span>
                        <p className="invalid-feedback">
                          {errors.city?.message}
                        </p>
                      </>
                    )}
                  </>
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row xs={12} className="pt-4">
          <Form.Group switch>
            <Controller
              name="hasCounter"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Form.Check
                  checked={value}
                  label={
                    <Form.Label>
                      Mostrar contagem regressiva?
                      <Tooltip
                        title="Contagem regressiva"
                        description="Se marcado, irá apresentar na página do evento, abaixo do banner principal, a contagem regressiva até a data informada no campo <b>Data de início do evento</b>"
                      />
                    </Form.Label>
                  }
                  onChange={onChange}
                />
              )}
            />
          </Form.Group>
        </Row>
        {showCounterFields && (
          <Row container spacing={4}>
            <Col item xs={12} sm={4} className="pt-4">
              <Form.Group>
                <Form.Label>
                  Título da contagem regressiva
                  <Tooltip
                    title="Título da contagem regressiva"
                    description="Esse texto estará na página do evento, acima da contagem regressiva"
                  />
                </Form.Label>
                <Controller
                  name="counterTitle"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      isInvalid={!!errors.eventName?.message}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col item xs={12} sm={4} className="pt-4">
              <Form.Group>
                <Form.Label>
                  Texto da contagem regressiva
                  <Tooltip
                    title="Texto da contagem regressiva"
                    description="Esse texto estará na página do evento, abaixo do título da contagem regressiva"
                  />
                </Form.Label>
                <Controller
                  name="counterText"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      isInvalid={!!errors.eventName?.message}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row className="pt-4">
          <Col item xs={12}>
            <Form.Group>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Form.Label>Descrição do evento *</Form.Label>
                    {errors?.description && (
                      <p className={styles.helperText}>
                        Esse campo é obrigatório
                      </p>
                    )}
                    <div
                      className={`${
                        errors?.description ? styles.errorValidation : ''
                      }`}
                    >
                      <JoditEditor
                        config={config}
                        ref={editorRef}
                        onChange={onChange}
                        value={value}
                      />
                    </div>
                  </>
                )}
              />
            </Form.Group>
          </Col>

          <Row className="pt-4 justify-content-end mb-4">
            <Col xs={12} md={4} className="d-flex justify-content-end">
              <Button
                variant="danger"
                type="button"
                color="danger"
                onClick={() => handleCancel()}
                style={{ marginTop: '30px' }}
              >
                Voltar
              </Button>
              <Button
                className="mx-3"
                variant="primary"
                type="submit"
                color="primary"
                style={{ marginTop: '30px' }}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Row>
      </Form>
    </Container>
  )
}
