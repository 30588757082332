import React, { useCallback, useState, useRef } from 'react';
import { onlyNumbers, cpfMask, phoneMask } from '../../util/string';
import ReactModal from 'react-modal';
import {
  Form,
  FormGroup,
  Label,
  FormText,
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form'

import { FileUpload } from '../fileUpload'
import eventzz from 'services/eventzz'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';

import { Loading } from '../Loading';
import './styles.css';
import styles from './styles.module.scss'
import { useImageUpload } from '../../hooks/useImageUpload'


export const ModalPatrocinadores = ({ onClose, eventId, showModal, reload, setLoading, info }) => {
  console.log("============")
  console.log(info)

  const { CreatePatrocinador, CreatePalestrante } = eventzz

  const [dropzoneError, setDropzoneError] = useState(false)
  const { imageFile, imageUrl, setImageUrl, onDrop } = useImageUpload({
    imageHeight: 600,
    imageWidth: 600
  })
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [msgError, setMsgError] = useState(false);


  const [dados, setDados] = useState({
    name: null,
    instituicao: null
  })

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    minHeight: 500,
    placeholder: 'Faça a descrição do seu evento'
  }

  const handleClose = () => {
    setLoading(false);
    onClose();
  }
  const clearFields = () => {
    setDados({
      name: null,
      instituicao: null,
      descricao: null
    });
  }

  const handleSubmitAdd = async () => {
    onClose();
    try {
    setLoading(true);
    if (!imageFile) {
      setDropzoneError(true)
      return
    }
    const editorRef = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const formData = new FormData()
    formData.append('banner', imageFile ? imageFile : '')
    formData.append('eventId', eventId ? eventId.toString() : '')
    formData.append('name', dados.name ? dados.name : '')
    formData.append('instituicao', dados.instituicao ? dados.instituicao : '')
    formData.append('descricao', editorRef ? editorRef : '')

    if (info === 'palestrante') {
      var response = await CreatePalestrante({ formData })
    } else {
      var response = await CreatePatrocinador({ formData })
    }

    if (response.status === 200) {
      toast.success(response?.msg ? response?.msg : "Adicionado");
      clearFields();
      setLoading(false);
      reload();
    
    } else {
      toast.error(response?.msg || "Ocorreu um erro ao tentar adicionar");
      setLoading(false);
      return false;
    }
  } catch (error) {}
  }

  const handleCancelAdd = () => {
    clearFields();
    onClose();
  }
  const handleChange = ({value, field}) => {
    setDados(prev => ({
      ...prev,
      [field]:value
    }))
  }
  function handleChangeEditor(editorState) {
    setMsgError(false);
    setEditorState(editorState);
  }

  return (
    <ReactModal
      isOpen={showModal}
      size='extra-large'
      contentLabel={`Minimal Modal`}
      className="reactModal"
    >
      <Container>
            <Row className='modalText'>
              <Col xs={12}>
                <h1 className={'titleRoot'}>Adicionar {info === 'palestrante' ? ("Palestrante") : ("Patrocinador/Parceiro")}</h1>
              </Col>
              <Col item xs={12} md={6} style={{marginBottom:20}}>
                  <FormGroup>
                      <Label for="exampleEmail">Nome</Label>
                      <Input type="text" name="name" id="name" value={dados.name}
                          onChange={(event) => handleChange({ value: event.target.value, field: 'name' })} />
                  </FormGroup>
                  <FormGroup>
                      <Label for="exampleEmail">Instituição</Label>
                      <Input type="text" name="instituicao" id="instituicao" value={dados.instituicao}
                          onChange={(event) => handleChange({ value: event.target.value, field: 'instituicao' })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">Foto</Label>
                    <FileUpload
                      dropzoneError={dropzoneError}
                      text="Solte a imagem aqui, ou clique para selecionar a imagem"
                      suggestion="(Imagem 600 x 600 px *.jpeg ou *.png)"
                      onDrop={onDrop}
                      maxSize={1000000}
                      className={styles.onlyDropzone}
                    />
                   </FormGroup>
              </Col>
              <Col item xs={12} md={6} style={{padding:30}}>
                  {imageUrl && (
                    <img
                    src={imageUrl}
                    alt="Imagem do evento"
                    className={styles.previewBanner}
                    />
                  )
                  }
              </Col>
              <Col item xs={12} md={12} style={{marginBottom:20}}>
                <FormGroup>
                  <Label for="exampleEmail">Descrição</Label>
                  <Editor
                    editorState={editorState}
                    wrapperClassName={`${styles.textEditorWrapper} ${msgError ? styles.errorValidation : ''}`}
                    editorClassName={styles.textEditorContent}
                    toolbarClassName={styles.textEditorToolbar}
                    onEditorStateChange={handleChangeEditor}
                    toolbar={{
                      options: ['inline'],
                      inline: {
                        options: ['bold', 'italic', 'strikethrough']
                      }
                    }}
                  />
                  </FormGroup>
              </Col>
            </Row>
            <Row className='footerModalButtons'>
              <Col xs={6}>
                <Button className='buttonsModal' onClick={handleCancelAdd} variant="contained" color="secondary">
                  Cancelar
                </Button>
              </Col>
              <Col xs={6}>
                <Button className='buttonsModal' onClick={handleSubmitAdd} variant="contained" color="primary">
                  Adicionar
                </Button>
              </Col>
            </Row>
      </Container>
    </ReactModal>
  )
}