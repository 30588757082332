/* eslint-disable react/jsx-key */
/* eslint-disable array-bracket-newline */
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Card } from 'reactstrap'
import { BarChart } from './GraphIdade'
import { ToastContainer, toast } from 'react-toastify'
///MODAIS
import { userList } from '../../store/actions'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
import { Loading } from 'components/Loading'
import {CardIndicador} from '../../components/CardIndicador'

const UsuarioDash = () => {
  const [loading, setLoading] = useState(false)
  const [root, setRoot] = useState(false)
  const [user, setUser] = useState({
    total: 0,
    afiliados: 0,
    organizadores: 0,
  })
  const [estado, setEstado] = useState({
    label: [],
    data: [],
    respostas: 0
  })
  const [idade, setIdade] = useState({
    label: [],
    data: [],
    respostas: 0
  })
  const [cidade, setCidade] = useState({
    label: [],
    data: [],
    respostas: 0
  })
  const handleClose = () => {
    setLoading(false)
  }

  const loadDados = async () => {
    setLoading(true)

    const response = await userList({
      rowsPerPage: 5000,
      currentPage: 0
    })
    
    if (response.status === 200) {
      const responseData = response?.data
   
      const total = responseData?.total
      const afiliados = responseData?.afiliado
      const organizadores = responseData?.organizadores

      setRoot(responseData?.root)
 
      setUser({ 
        total,
        afiliados,
        organizadores
      })

      let labelEstado = [];
      let dataEstado = [];
      let respostaEstado = 0;
      let totalRespostaEstado = 0;

      let labelIdade = [];
      let dataIdade = [];
      let respostaIdade = 0;
      let totalResposta = 0;

      let labelCidade = [];
      let dataCidade = [];
      let respostaCidade = 0;
      let totalRespostaCidade = 0;


      console.log("====================")
      let cidades = await agruparCidades(responseData?.cidade)
      console.log(cidades)
      console.log("====================")


      responseData?.estado?.map((item) => {
          labelEstado.push(item.estado)
          dataEstado.push(item.quantidade)
          totalRespostaEstado += Number(item.quantidade)
      })

      cidades?.map((item) => {
        if (item.cidade === null) {
          return;
        }
        labelCidade.push(item.cidade)
        dataCidade.push(item.total)
        totalRespostaCidade += Number(item.total)
    })

      responseData?.idade?.map((item) => {
        labelIdade.push(item.faixa_de_idade)
        dataIdade.push(item.total)
        totalResposta += Number(item.total)
      })

      respostaIdade = (totalResposta / total) * 100
      respostaEstado = (totalRespostaEstado / total) * 100
      respostaCidade = (totalRespostaCidade / total) * 100


      setIdade({
        label: labelIdade,
        data: dataIdade,
        resposta: respostaIdade
      })

      setEstado({
        label: labelEstado,
        data: dataEstado,
        resposta: respostaEstado
      })

      setCidade({
        label: labelCidade,
        data: dataCidade,
        resposta: respostaCidade
      })

      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }

  async function agruparCidades(cidades) {
    const mapaCidades = new Map();
  
    cidades.forEach(cidade => {
      let nomeNormalizado = cidade.cidade.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      nomeNormalizado = nomeNormalizado.split("-")[0].trim();
  
      // Verifica se a diferença entre o nome normalizado e o nome da cidade é de apenas um caractere
      for (const [nomeAgrupado, cidadeAgrupada] of mapaCidades.entries()) {
        if (Math.abs(nomeAgrupado.length - nomeNormalizado.length) <= 1) {
          const diferenca = [];
          for (let i = 0; i < nomeAgrupado.length; i++) {
            if (nomeAgrupado[i] !== nomeNormalizado[i]) {
              diferenca.push(i);
            }
          }
  
          if (diferenca.length <= 1) {
            nomeNormalizado = nomeAgrupado;
            cidadeAgrupada.total += parseInt(cidade.total);
  
            // Verifica se a cidade atual tem maior número de aparições
            if (parseInt(cidade.total) > cidadeAgrupada.total) {
              cidadeAgrupada.cidade = cidade.cidade;
            } else if (cidade.cidade.length > cidadeAgrupada.cidade.length) {
              cidadeAgrupada.cidade = cidade.cidade;
            }
  
            break;
          }
        }
      }
  
      if (!mapaCidades.has(nomeNormalizado)) {
        mapaCidades.set(nomeNormalizado, {
          cidade: cidade.cidade,
          total: parseInt(cidade.total)
        });
      }
    });
  
    let resultCidades = await Array.from(mapaCidades.values()).map(cidade => {
      cidade.cidade = cidade.cidade.split("-")[0].trim();
      cidade.cidade = cidade.cidade.toUpperCase();
      return cidade;
    });

    resultCidades.sort((a, b) => b.total - a.total);
    resultCidades = resultCidades.filter(item => item.cidade && item.cidade !== "");
    return resultCidades.slice(0, 20);
  }
   
     
  useEffect(() => {
    loadDados()
  }, [])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Indicadores de Usuários | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Indicadores de Usuários | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo aos Indicadores de Usuários 
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Card>
              <Row>
                <Col xl={12} md={12} style={{ padding: 20 }}>
                  <Row>
                  <CardIndicador 
                      icon={'GRAPH'} value={user?.total} 
                      type={'number'} xl={2} md={4} xs={4}
                      text={'Usuários Total'} color='primary' 
                    />
                    {
                      root && (
                        <>
                        <CardIndicador 
                            icon={'GRAPH'} value={user?.organizadores} 
                            type={'number'} xl={2} md={4} xs={4}
                            text={'Organizadores'} color='primary' 
                          />
                        <CardIndicador 
                            icon={'GRAPH'} value={user?.afiliados} 
                            type={'number'} xl={2} md={4} xs={4}
                            text={'Afiliados'} color='primary' 
                          />
                        </>
                      )
                    }
                    <Row>
                    </Row>
                    <Col xl={6} md={8} xs={12}>
                      <BarChart 
                        data={idade?.data}
                        label={'Idade'}
                        labels={idade?.label}
                        respostas={idade?.resposta}
                      />
                    </Col>
                    <Col xl={6} md={8} xs={12}>
                      <BarChart
                        data={estado?.data}
                        label={'Estados'}
                        labels={estado?.label}
                        respostas={estado?.resposta}
                      />
                    </Col>
                    <Col xl={8} md={12} xs={12}>
                      <BarChart
                        data={cidade?.data}
                        label={'Top 20 Cidades'}
                        labels={cidade?.label}
                        respostas={cidade?.resposta}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UsuarioDash.propTypes = {
  t: PropTypes.any
}

export default UsuarioDash
