import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export function AlertConfirm({
  text,
  title,
  closeButton = 'Cancelar',
  confirmButton = 'Confirmar',
  onCancel,
  onConfirm
}) {
  return (
    <Modal show={true} onHide={onCancel} centered>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={onCancel}>
          {closeButton}
        </Button>
        <Button variant="secondary" onClick={onConfirm}>
          {confirmButton}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
