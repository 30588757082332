import Services from "../../services/select";
import { logoutUser } from "../../store/actions"


export const organizerSelect = async () => {
  const response = await Services.organizerSelect();
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data:response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem("authUser");
    logoutUser();
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: response?.response?.data?.msg || "Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte"
    }
  } else {
    return {
      status: 400,
      msg: response?.response?.data?.msg || "Ocorreu um erro ao tentar listar os organizadores"
    }
  }
}

