import React from 'react'
import ReactSelect from 'react-select'
import styles from './styles.module.scss'

const customStyles = (isMulti = false) => ({
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    paddingTop: 0,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    height: isMulti ? 'auto' : 38
  })
  // valueContainer: (provided) => ({
  //   ...provided,
  //   height: 38
  // })
})

export function Select({
  options,
  placeholder,
  value,
  hasErrors,
  onChange,
  ...rest
}) {

  return (
    <ReactSelect
      options={options}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      placeholder={placeholder}
      styles={customStyles(rest?.isMulti)}
      menuPortalTarget={document.body}
      value={
        rest?.isMulti
          ? options?.filter((item) => value.includes(item.value))
          : options?.find((item) => item.value === value)
      }
      onChange={onChange}
      className={hasErrors ? styles.hasErrors : ''}
      {...rest}
    />
  )
}
