/* eslint-disable array-bracket-newline */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Input,
  FormGroup,
  Label
} from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import DatatableTables from '../../components/Table'
///MODAIS
import {CardIndicador} from '../../components/CardIndicador'

import { ModalMarkTicketAsUsed } from '../../components/modalConfirmMarkTicketAsUsed'
import Select from 'react-select'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import {
  listTickets,
  exportTickets,
  listUsed,
  sellerSelect,
  eventzzSelect
} from '../../store/actions'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
import { debounce } from 'lodash'
import { Loading } from 'components/Loading'
import { TicketCollapseComponent } from 'components/Table/TicketCollapse'
import { addHours } from 'date-fns'
import { formatDateHourT } from '../../util/string'


const Ingressos = () => {
  const [filteredRows, setFilteredRows] = useState([])
  // const [indicadores, setIndicadores] = useState(true)

  const [loading, setLoading] = useState(false)
  const [currentRow, setCurrentRow] = useState()
  const [showModalMarkAsUsed, setShowModalMarkAsUsed] = useState(false)

  const [ticketsTypeResume, setTicketsTypeResume] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [salesPaymentTypeResume, setSalesPaymentTypeResume] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [ticketsTypeByPaymentTypeResume, setTicketsTypeByPaymentTypeResume] =
    useState(null)
  // eslint-disable-next-line no-unused-vars
  const [sellerList, setSellerList] = useState([])

  const [eventzzList, setEventzzList] = useState([])
  const [columns, setColumns] = useState([
    { label: 'Data', field: 'created_at'},
    { label: 'Id', field: 'id' },
    { label: 'Código', field: 'code', hide: true },
    { label: 'Nome', field: 'name' },
    { label: 'Tipo de ingresso', field: 'ticketType' },
    { label: 'Status', field: 'status' },
    { label: 'Telefone', field: 'phone' },
    { label: 'Id Compra', field: 'purchaseId' },
    { label: 'Campos adicionais', field: 'additionalFields' },
    { label: 'Já usado', field: 'used' },
    { label: 'Data utilização', field: 'useDate' }
  ])

  const [ticketTypeList, setTicketTypeList] = useState([
    { value: null, label: 'TODOS' }
  ])

  const [searchTerm, setSearchTerm] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [statusTransactionList, setStatusTransactionList] = useState([
    { label: 'Todos', value: null },
    { label: 'Pago', value: 'paid' },
    { label: 'Pendente', value: 'pending' },
    { label: 'Falha', value: 'failed' }
  ])

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: true,
      tickets: true,
      more: true,
      pagination: true,
      markAsUsed: true
    },
    config: {
      markAsUsedText: 'Confirmar utilização',
      markAsUsedDisabledText: 'Ingresso já utilizado'
    },
    filters: {
      searchTerm: null,
      status: 'paid',
      eventId: null,
      ticketType: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const handleClose = () => {
    setLoading(false)
  }

  const getUsedTicketsNumber = useCallback(async () => {
    try {
      const response = await listUsed({
        eventId: tableConfig?.filters?.eventId,
        statusDescription: tableConfig?.filters?.status,
        searchTerm: tableConfig?.filters?.searchTerm,
        ticketType: tableConfig?.filters?.ticketType
      })

      if (response.status === 200) {
        const data = response.data
        if (data?.length > 0) {
          if (ticketTypeList?.length === 1) {
            setTicketTypeList([
              ...ticketTypeList,
              ...data.map((item) => ({ value: item.name, label: item.name }))
            ])
          }
          setTicketsTypeResume(data)
        }
        setLoading(false)
        return true
      } else {
        toast.error(
          response?.msg || 'Ocorreu um erro ao tentar listar as ingressos'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log(error)
    }
  }, [tableConfig.filters])

  const loadTickets = useCallback(async () => {
    if (!tableConfig?.filters?.eventId) {
      toast('Selecione um evento para buscar os ingressos vendidos', {
        autoClose: 10000
      })
      setLoading(false)
      return
    }
    setLoading(true)
    getUsedTicketsNumber()
    // eventId, status, rowsPerPage, page, searchTerm
    const response = await listTickets({
      rowsPerPage: tableConfig.rowsPerPage,
      page: tableConfig.currentPage,
      searchTerm: tableConfig?.filters?.searchTerm,
      status: tableConfig?.filters?.status,
      eventId: tableConfig?.filters?.eventId,
      ticketType: tableConfig?.filters?.ticketType
    })
    console.log(response)
    if (response.isRoot) {
      const columnsTemp = columns.map(item => {
        if (item.field === 'code') {
          return { ...item, hide: false}
        } else {
          return item
        }
      })
      setColumns(columnsTemp);
    }

    if (response.status === 200) {
      const responseData = response?.data
      let data = responseData?.items
      const total = responseData?.total

      data = data?.sort((a, b) => {
        if (a.id > b.id) {
          return -1
        }
        return 1
      })
      console.log(data)

      const formattedData = data?.map((item) => ({
        ...item,
        created_at: formatDateHourT(item.created_at),
        status: item.status === 'paid' ? 'Pago' : 
          item.status === 'pending' ? 'Pendente' :
            item.status === 'failed' ? 'Falha' : 
              item.status === 'cancelled' ? 'Cancelado' : '----',
        useDate: item.useDate === null ? ('') : (Intl.DateTimeFormat('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(addHours(new Date(item.useDate), 3)))
      }))

      setFilteredRows(formattedData)
      setTableConfig((prev) => ({
        ...prev,
        total
      }))

      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  function handleSearch(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        searchTerm: value
      }
    }))
  }
  function handleMarkAsUsed(row) {
    if (row?.id && row?.code) {
      setCurrentRow(row)
      setShowModalMarkAsUsed(true)
    }
  }

  const loadSellers = useCallback(async (eventId) => {
    const response = await sellerSelect(eventId)

    if (response.status === 200) {
      const responseData = response?.data

      setSellerList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      setSellerList([])
      return false
    }
  }, [])

  function handleClearFilters() {
    setSearchTerm('')
    setTableConfig((prev) => ({
      ...prev,
      total: 0,
      currentPage: 0,
      rowsPerPage: 20,
      filters: {
        searchTerm: null,
        status: 'paid',
        eventId: null,
        ticketType: null
      }
    }))
    setTicketsTypeResume(null)
    setSalesPaymentTypeResume(null)
    setTicketsTypeByPaymentTypeResume(null)
    setFilteredRows([])
  }

  function handleChangeTransaction(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        status: value?.value
      }
    }))
  }

  function handleChangeEvent(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        eventId: Number(value?.value) === 0 ? null : value?.value
      }
    }))
  }

  function handleChangeTicketType(value) {
    //console.log(value);
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        ticketType: Number(value?.value) === 0 ? null : value?.value
      }
    }))
  }

  const exportExcel = async () => {
    if (!tableConfig?.filters?.eventId) {
      toast('Selecione um evento para exportar os dados', {
        autoClose: 10000
      })
    } else {
      setLoading(true)
      try {
        const response = await exportTickets({
          searchTerm: tableConfig?.filters?.searchTerm,
          status: tableConfig?.filters?.status,
          eventId: tableConfig?.filters?.eventId,
          ticketType: tableConfig?.filters?.ticketType
        })

          
        if (response.status === 200) {
          const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          const fileExtension = '.xlsx'
          const ws = XLSX.utils.json_to_sheet(response?.data)
          const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
          const excelBuffer = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array'
          })
          const data = new Blob([excelBuffer], { type: fileType })
          FileSaver.saveAs(data, `Dados do evento${fileExtension}`)

          setLoading(false)
        } else {
          toast.error(response?.msg)
          setLoading(false)
          return false
        }
      } catch (error) {
        setLoading(false)
      }
    }
  }

  const deboundChange = useCallback(debounce(handleSearch, 1000), [])

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()
    if (response.status === 200) {
      const responseData = response?.data

      setEventzzList(responseData)
      if (responseData?.length === 1) {
        loadSellers(responseData[0].value)
      }
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  useEffect(() => {
    loadTickets()
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  useEffect(() => {
    loadEventzz()
  }, [])

  function handleCancelMarkAsUsed() {
    setCurrentRow(null)
    setShowModalMarkAsUsed(false)
  }

  function handleSubmitMarkAsUsed() {
    setCurrentRow(null)
    setShowModalMarkAsUsed(false)
    loadTickets()
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Ingressos | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Ingressos | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Ingressos
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Card>
              <Row>
                <Col xl={12} md={12}  style={{ padding: 20 }}>
                  <Row>
                    {ticketsTypeResume &&
                      ticketsTypeResume?.length > 0 &&
                      ticketsTypeResume.map((item, index) => (
                        <CardIndicador 
                          icon={'TICKETS'} value={`${item.totalUsed} de ${item.quantity}`} type={'number'}
                          text={item.name} color='primary' xl={3} md={6} xs={6}
                        />
                      ))}
                  </Row>
                  <Row>
                    <Col xl={3} md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Eventos</Label>
                        <Select
                          value={
                            eventzzList.find(
                              (a) => a.value === tableConfig?.filters?.eventId
                            )
                              ? eventzzList.find(
                                  (a) =>
                                    a.value === tableConfig?.filters?.eventId
                                )
                              : null
                          }
                          onChange={(value) => handleChangeEvent(value)}
                          options={eventzzList}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Pesquisar</Label>
                        <Input
                          type="text"
                          name="search"
                          id="search"
                          value={searchTerm}
                          onChange={(e) => {
                            setSearchTerm(e.target.value)
                            deboundChange(e.target.value)
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Status</Label>
                        <Select
                          value={
                            statusTransactionList.find(
                              (a) => a.value === tableConfig?.filters?.status
                            )
                              ? statusTransactionList.find(
                                  (a) =>
                                    a.value === tableConfig?.filters?.status
                                )
                              : null
                          }
                          onChange={(value) => handleChangeTransaction(value)}
                          options={statusTransactionList}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Tipo de ingresso</Label>

                        <Select
                          value={
                            ticketTypeList.find(
                              (a) =>
                                a.value === tableConfig?.filters?.ticketType
                            )
                              ? ticketTypeList.find(
                                  (a) =>
                                    a.value === tableConfig?.filters?.ticketType
                                )
                              : ''
                          }
                          onChange={(value) => handleChangeTicketType(value)}
                          options={ticketTypeList}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>

                    <Col
                      xl={12}
                      md={12}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleClearFilters()}
                        style={{ marginTop: '30px' }}
                      >
                        Limpar filtros
                      </Button>

                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => exportExcel()}
                        style={{ marginTop: '30px', marginLeft: '30px' }}
                      >
                        Exportar Excel
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <DatatableTables
                title={'Ingressos'}
                columns={columns}
                rows={filteredRows}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
                CollapseComponent={TicketCollapseComponent}
                onMarkAsUsed={handleMarkAsUsed}
              />
            </Card>
          </Row>
        </Container>
      </div>
      <ModalMarkTicketAsUsed
        onCancel={handleCancelMarkAsUsed}
        onSubmit={handleSubmitMarkAsUsed}
        row={currentRow}
        showModal={showModalMarkAsUsed}
      />
    </React.Fragment>
  )
}

Ingressos.propTypes = {
  t: PropTypes.any
}

export default Ingressos
