import { ActivityForm } from 'components/atividades/form'
import React from 'react'
import { MetaTags } from 'react-meta-tags'

import { Container, Row } from 'reactstrap'

export const CadastroAtividade = ({ match }) => {
  const {eventId, activityId} = match?.params

  return (
    <Container>
      <MetaTags>
        <title>{activityId ? 'Edição' : 'Cadastro'} de atividade | Eventozz</title>
      </MetaTags>
      <Row item xs={12}>
        <ActivityForm activityId={activityId} eventId={eventId} />
      </Row>
    </Container>
  )
}
