import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify'
import eventzz from 'services/eventzz'
import styles from './styles.module.scss'
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row
} from 'react-bootstrap'
import { CustomButton } from 'components/customButton'
import { Select } from 'components/select'

const typesList = [
  { value: 'texto', label: 'Texto' },
  { value: 'numero', label: 'Número' },
  { value: 'telefone', label: 'Telefone' },
  { value: 'select', label: 'Lista de opções' },
  { value: 'cnpj', label: 'CNPJ' },
  { value: 'data', label: 'Data' }
]

const installmentOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' }
]

export const TabCheckoutInfo = ({
  eventId,
  setLoading,
  eventData,
  setHasChanges
}) => {
  const { updateEventExtraFields } = eventzz

  const [hideCpf, setHideCpf] = useState(false)
  const [hideEmail, setHideEmail] = useState(false)
  const [useBoleto, setUseBoleto] = useState(false)
  const [useCredito, setUseCredito] = useState(false)
  const [maximumInstallments, setMaximumInstallments] = useState(null)

  const [extraFields, setExtraFields] = useState([
    {
      type: '',
      name: '',
      required: false,
      errorMsg: ''
    }
  ])

  async function handleSubmit() {
    try {
      setLoading(true)

      let hasErrors = false

      let extraFieldsTemp = []

      if (
        extraFields?.length === 1 &&
        !extraFields[0].name &&
        !extraFields[0].type
      ) {
      } else {
        extraFieldsTemp = extraFields?.map((item) => {
          item.errorMessage = item.errorMessage ? { ...item.errorMessage } : {}
          if (!item.type) {
            hasErrors = true
            item.errorMessage.type = 'Tipo de campo é obrigatório'
          }
          if (!item.name) {
            hasErrors = true
            item.errorMessage.name = 'O campo Descrição é obrigatório'
          }

          if (item.type === 'select') {
            item.options?.map((itemOption) => {
              if (!itemOption?.value) {
                hasErrors = true
              }
            })
          }
          return item
        })
        if (hasErrors) {
          setExtraFields(extraFieldsTemp)
          setLoading(false)
          return
        }
      }

      const response = await updateEventExtraFields({
        eventId,
        extraFields: extraFieldsTemp,
        hideCpf,
        hideEmail,
        useBoleto,
        useCredito,
        maximumInstallments
      })
      console.log(response)
      if (response.status === 201 && response?.data?.success) {
        toast.success('As alterações foram salvas')
        setHasChanges(false)
        setLoading(false)
      } else if (response.status === 401) {
        toast.error('Você não está logado ou sua sessão expirou')
        setLoading(false)
        return false
      } else if (response.status === 500) {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
        )
        setLoading(false)
        return false
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao salvar os dados do evento'
        )
        setLoading(false)
        return false
      }
    } catch (error) {}
  }

  function handleCancel() {
    console.log('cancelado')
    window.location.href = '/eventozz'
  }

  function handleChangeExtraField({ value, index, field, indexOption = -1 }) {
    console.log(field, value)

    if (indexOption >= 0) {
      const newTicketTypes = extraFields.map((item, i) => {
        if (i === index) {
          let options = item.options
          if (field === 'type' && value === 'select') {
            options = options ? options : [{ value: '' }]
          }
          options = options.map((itemOption, iOption) => {
            if (indexOption === iOption) {
              return { value }
            }
            return itemOption
          })
          console.log(options)
          return {
            ...item,
            options,
            errorMessage: item?.errorMessage
              ? {
                  ...item.errorMessage,
                  [field]: null
                }
              : null
          }
        }
        return item
      })
      setHasChanges(true)
      console.log(newTicketTypes)
      setExtraFields(newTicketTypes)
    } else {
      const newTicketTypes = extraFields.map((item, i) => {
        if (i === index) {
          let options = item.options
          if (field === 'type' && value === 'select') {
            options = options ? options : [{ value: '' }]
          }
          console.log(options)
          return {
            ...item,
            options,
            errorMessage: item?.errorMessage
              ? {
                  ...item.errorMessage,
                  [field]: null
                }
              : null,
            [field]: value
          }
        }
        return item
      })
      setHasChanges(true)
      setExtraFields(newTicketTypes)
    }
  }

  function handleAddExtraField(index = -1) {
    if (index >= 0) {
      const newExtraFields = extraFields?.map((item, i) => {
        if (i === index) {
          const options = item?.options ? item.options : []
          options.push({ value: '' })
          return {
            ...item,
            options
          }
        }
        return item
      })

      setHasChanges(true)
      setExtraFields(newExtraFields)
    } else {
      const newExtraFields = [...extraFields]
      newExtraFields.push({
        type: '',
        name: '',
        required: false,
        errorMsg: ''
      })
      setHasChanges(true)
      setExtraFields(newExtraFields)
    }
  }

  function handleRemoveExtraField(index, indexOption = false) {
    if (indexOption >= 0) {
      const newExtraFields = extraFields?.map((item, i) => {
        if (i === index) {
          const options = item?.options?.filter(
            (_, iOption) => indexOption !== iOption
          )
          return {
            ...item,
            options
          }
        }
        return item
      })

      setHasChanges(true)
      setExtraFields(newExtraFields)
    } else {
      if (extraFields?.length === 1) {
        setExtraFields([
          {
            type: '',
            name: '',
            required: false,
            errorMsg: ''
          }
        ])
        setHasChanges(true)
        return
      }
      const newExtraFields = extraFields?.filter((item, i) => i !== index)

      setHasChanges(true)
      setExtraFields(newExtraFields)
    }
  }

  function fillInitialData() {
    console.log(eventData)

    setHideCpf(eventData.hideCpf)
    setHideEmail(eventData.hideEmail)
    setUseBoleto(eventData.useBoleto)
    setUseCredito(eventData.useCredito)
    setMaximumInstallments(
      eventData.maximumInstallments ? eventData.maximumInstallments : null
    )
    if (eventData?.extraFields?.length > 0) {
      const newExtraFields = eventData.extraFields.map((item) => {
        console.log(item)
        return {
          ...item
        }
      })
      setExtraFields(newExtraFields)
    }
  }

  useEffect(() => {
    if (eventData) {
      fillInitialData()
    }
  }, [eventData])

  return (
    <Container className="pt-4">
      <Row xs={12} justifyContent="flex-start" item className="mt-5">
        <h5>Formas de pagamento</h5>
      </Row>
      <Row>
        <Alert variant="secondary">
          As seguintes formas de pagamento estão disponíveis
          <div className={styles.containerAlert}>
            <div>
              Obrigatório
              <ul>
                <li>PIX</li>
              </ul>
            </div>
            <div>
              Opcionais
              <ul>
                <li>Cartão de crédito</li>
                <li>Boleto</li>
              </ul>
            </div>
          </div>
        </Alert>
      </Row>
      <Row xs={12} justifyContent="flex-start" item className="mt-5">
        <h5>Ativar formas de pagamento opcionais</h5>
      </Row>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={12} md={4} className={styles.extraPadding}>
              <Form.Group switch>
                <Form.Check
                  checked={useBoleto}
                  label="Usar boleto?"
                  onChange={(e) => {
                    setUseBoleto(e?.target?.checked)
                    setHasChanges(true)
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4} className={styles.extraPadding}>
              <Form.Group switch>
                <Form.Check
                  checked={useCredito}
                  label="Usar cartão de crédito?"
                  onChange={(e) => {
                    setUseCredito(e?.target?.checked)
                    setHasChanges(true)
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <>
                <Form.Label>Máximo de parcelas *</Form.Label>
                <Select
                  options={installmentOptions}
                  placeholder="Máximo de parcelas"
                  value={maximumInstallments}
                  isDisabled={!useCredito}
                  onChange={(value) => setMaximumInstallments(value.value)}
                />
              </>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row xs={12} justifyContent="flex-start" item className="mt-5">
        <h5>Informações do checkout</h5>
      </Row>
      <Row>
        <Alert variant="secondary">
          No checkout o cliente já preenche os seguintes campos:
          <div className={styles.containerAlert}>
            <div>
              Obrigatórios
              <ul>
                <li>Nome completo</li>
                <li>Telefone</li>
              </ul>
            </div>
            <div>
              Opcionais
              <ul>
                <li>CPF</li>
                <li>Email</li>
              </ul>
            </div>
          </div>
        </Alert>
      </Row>
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Campos obrigatórios</Accordion.Header>
          <Accordion.Body>
            <Card>
              <Card.Body>
                <Row container xs={12}>
                  <Col xs={12} md={6}>
                    <>
                      <Form.Label>Tipo de campo *</Form.Label>
                      <Select
                        options={typesList}
                        placeholder="Tipo de campo"
                        value="texto"
                        isDisabled={true}
                      />
                    </>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label>Descrição *</Form.Label>
                      <Form.Control value="Nome completo" disabled />
                    </Form.Group>
                  </Col>
                </Row>
                <Row container xs={12} className="pt-4">
                  <Col xs={12} md={6}>
                    <Form.Group switch>
                      <Form.Check
                        checked={true}
                        name="required"
                        label="Preenchimento obrigatório?"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label>
                        Aviso de preenchimento obrigatório
                      </Form.Label>
                      <Form.Control
                        value="Informe o nome completo da pessoa que irá utilizar o ingresso."
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Row container xs={12}>
                  <Col xs={12} md={6}>
                    <>
                      <Form.Label>Tipo de campo *</Form.Label>
                      <Select
                        options={typesList}
                        placeholder="Tipo de campo"
                        value="telefone"
                        isDisabled={true}
                      />
                    </>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label>Descrição *</Form.Label>
                      <Form.Control value="Telefone (WhatsApp)" disabled />
                    </Form.Group>
                  </Col>
                </Row>
                <Row container xs={12} className="pt-4">
                  <Col xs={12} md={6}>
                    <Form.Group switch>
                      <Form.Check
                        checked={true}
                        name="required"
                        label="Preenchimento obrigatório?"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label>
                        Aviso de preenchimento obrigatório
                      </Form.Label>
                      <Form.Control
                        value="Informe um número de WhatsApp válido (Enviaremos o qr code do
                    ingresso por WhatsApp)"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Campos opcionais</Accordion.Header>
          <Accordion.Body>
            <Card>
              <Card.Body>
                <Row container xs={12}>
                  <Col xs={12} md={6}>
                    <>
                      <Form.Label>Tipo de campo *</Form.Label>
                      <Select
                        options={typesList}
                        placeholder="Tipo de campo"
                        value="texto"
                        isDisabled={true}
                      />
                    </>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label>Descrição *</Form.Label>
                      <Form.Control value="CPF (Não obrigatório)" disabled />
                    </Form.Group>
                  </Col>
                </Row>
                <Row container xs={12} className="pt-4">
                  <Col xs={12} md={6}>
                    <Form.Group switch>
                      <Form.Check
                        checked={false}
                        name="required"
                        label="Preenchimento obrigatório?"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label>
                        Aviso de preenchimento obrigatório
                      </Form.Label>
                      <Form.Control
                        value="Informe um CPF válido ou deixe o campo em branco."
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row container className="pt-4">
                  <Col xs={12} md={6}>
                    <Form.Group switch>
                      <Form.Check
                        checked={hideCpf}
                        onChange={(e) => setHideCpf(e.target.checked)}
                        name="required"
                        label="Ocutar campo no checkout?"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Row container xs={12}>
                  <Col xs={12} md={6}>
                    <>
                      <Form.Label>Tipo de campo *</Form.Label>
                      <Select
                        options={typesList}
                        placeholder="Tipo de campo"
                        value="texto"
                        isDisabled={true}
                      />
                    </>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label>Descrição *</Form.Label>
                      <Form.Control value="Email (Não obrigatório)" disabled />
                    </Form.Group>
                  </Col>
                </Row>
                <Row container xs={12} className="pt-4">
                  <Col xs={12} md={6}>
                    <Form.Group switch>
                      <Form.Check
                        checked={false}
                        name="required"
                        label="Preenchimento obrigatório?"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label>
                        Aviso de preenchimento obrigatório
                      </Form.Label>
                      <Form.Control
                        value="Informe um email válido ou deixe o campo em branco (Enviaremos o
                    qr code do ingresso)."
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row container className="pt-4">
                  <Col xs={12} md={6}>
                    <Form.Group switch>
                      <Form.Check
                        checked={hideEmail}
                        onChange={(e) => setHideEmail(e.target.checked)}
                        name="required"
                        label="Ocutar campo no checkout?"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* <Row xs={12} justifyContent="flex-start" item className="mt-5">
        <h5>Campos opcionais</h5>
      </Row> */}
      <Row xs={12} justifyContent="flex-start" item className="mt-5">
        <h5>Incluir campos adicionais ao checkout</h5>
      </Row>
      {extraFields.map((item, index) => (
        <Card key={index}>
          <Card.Body>
            <Row container xs={12}>
              <Col xs={12} md={6}>
                <>
                  <Form.Label>Tipo de campo *</Form.Label>
                  <Select
                    options={typesList}
                    placeholder="Tipo de campo"
                    onChange={(option) =>
                      handleChangeExtraField({
                        value: option.value,
                        field: 'type',
                        index
                      })
                    }
                    value={item.type}
                    hasErrors={!!item.errorMessage?.type}
                  />
                  {!!item.errorMessage?.type && (
                    <>
                      <span className="is-invalid"></span>
                      <p className="invalid-feedback">
                        {item.errorMessage?.type}
                      </p>
                    </>
                  )}
                </>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>Descrição *</Form.Label>
                  <Form.Control
                    value={item.name}
                    onChange={(e) =>
                      handleChangeExtraField({
                        value: e.target?.value,
                        field: 'name',
                        index
                      })
                    }
                    isInvalid={!!item.errorMessage?.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {item.errorMessage?.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row container xs={12} className="pt-4">
              <Col xs={12} md={6}>
                <Form.Group switch>
                  <Form.Check
                    checked={item.required}
                    name="required"
                    label="Preenchimento obrigatório?"
                    onChange={(e) =>
                      handleChangeExtraField({
                        value: e.target?.checked,
                        field: 'required',
                        index
                      })
                    }
                  />
                </Form.Group>
              </Col>
              {item.required ? (
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>Aviso de preenchimento obrigatório</Form.Label>
                    <Form.Control
                      value={item.errorMsg}
                      onChange={(e) =>
                        handleChangeExtraField({
                          value: e.target?.value,
                          field: 'errorMsg',
                          index
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              ) : null}
            </Row>
            {item.type === 'select' && (
              <Row>
                <Col xs={12}>Lista de opções</Col>
                {item.options?.map((itemOption, indexOption) => (
                  <>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Label>Opção {indexOption + 1}*</Form.Label>
                        <Form.Control
                          value={itemOption.value}
                          onChange={(e) =>
                            handleChangeExtraField({
                              value: e.target?.value,
                              field: 'value',
                              index,
                              indexOption
                            })
                          }
                          isInvalid={!itemOption.value}
                        />
                        <Form.Control.Feedback type="invalid">
                          {itemOption.value}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      {indexOption > 0 && (
                        <Button
                          variant="danger"
                          type="button"
                          color="danger"
                          onClick={() =>
                            handleRemoveExtraField(index, indexOption)
                          }
                          style={{ marginTop: '30px', marginRight: 10 }}
                        >
                          Remover opção
                        </Button>
                      )}
                      {indexOption === item.options?.length - 1 && (
                        <Button
                          variant="primary"
                          type="button"
                          color="primary"
                          onClick={() => handleAddExtraField(index)}
                          style={{ marginTop: '30px' }}
                        >
                          Adicionar opção
                        </Button>
                      )}
                    </Col>
                  </>
                ))}
              </Row>
            )}
            <Row className="pt-4 justify-content-end">
              <Col xs={12} md={4} className="d-flex justify-content-end">
                <Button
                  variant="danger"
                  type="button"
                  color="danger"
                  onClick={() => handleRemoveExtraField(index, -1)}
                  style={{ marginTop: '30px' }}
                >
                  Remover
                </Button>
                {index === extraFields?.length - 1 && (
                  <CustomButton
                    className="mx-3"
                    variant="primary"
                    type="button"
                    onClick={() => handleAddExtraField(-1)}
                    color="primary"
                    style={{ marginTop: '30px' }}
                    text="Adicionar campo"
                  />
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}

      <Row className="pt-4 justify-content-end mb-4">
        <Col xs={12} md={4} className="d-flex justify-content-end">
          <Button
            variant="danger"
            type="button"
            color="danger"
            onClick={() => handleCancel()}
            style={{ marginTop: '30px' }}
          >
            Voltar
          </Button>
          <CustomButton
            className="mx-3"
            variant="primary"
            type="button"
            disabled={!eventId}
            showTooltip={!eventId}
            title={
              !eventId
                ? "Salve o evento na sessão 'Detalhes' antes de salvar as configurações"
                : ''
            }
            onClick={() => handleSubmit()}
            color="primary"
            style={{ marginTop: '30px' }}
            text="Salvar"
          />
        </Col>
      </Row>
    </Container>
  )
}
