import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify'
import eventzz from 'services/eventzz'
import styles from './styles.module.scss'
import { useImageUpload } from 'hooks/useImageUpload'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FileUpload } from 'components/fileUpload'
import { CustomButton } from 'components/customButton'
import { ModalCropImage } from 'components/modalCropImage'
import { ValidationErrorMessage } from 'components/validationError'

export const TabImages = ({
  eventId,
  setLoading,
  eventData,
  setHasChanges,
  checkEvent
}) => {
  console.log('eventId -> ', !!eventId)

  const { updateEventImages } = eventzz

  const [dropzoneError, setDropzoneError] = useState(false)

  const [showCropperModal, setShowCropperModal] = useState(false)
  const [croppedImageData, setCroppedImageData] = useState(null)
  const [croppedImageUrl, setCroppedImageFileUrl] = useState(null)

  const { imageFile, imageUrl, onDrop, initialImageUrl, setInitialImageUrl, imageDimensions } =
    useImageUpload({
      imageHeight: 1080,
      imageWidth: 1920
    })

  async function handleSubmit() {
    try {
      if (!croppedImageData) {
        setDropzoneError(true)
        return
      }
      console.log({ imageFile, croppedImageData, eventId })
      const formData = new FormData()
      formData.append('banner', imageFile ? imageFile : '')
      formData.append('croppData[left]', croppedImageData.left)
      formData.append('croppData[top]', croppedImageData.top)
      formData.append('croppData[width]', croppedImageData.width)
      formData.append('croppData[height]', croppedImageData.height)
      formData.append('eventId', eventId ? eventId.toString() : '')

      setLoading(true)
      const response = await updateEventImages({ formData })
      console.log(response)
      if (response.status === 200 && response?.data?.success) {
        toast.success('As alterações foram salvas')
        setHasChanges(false)
        checkEvent()
        setLoading(false)
      } else if (response.status === 401) {
        toast.error('Você não está logado ou sua sessão expirou')
        setLoading(false)
        return false
      } else if (
        response?.response?.status === 400 &&
        response?.response?.data?.errors
      ) {
        const errors = response.response.data.errors
        console.log({ errors })
        toast.error(<ValidationErrorMessage errorMessage={errors} />, {
          autoClose: errors.length * 3000
        })

        setLoading(false)
      } else if (response.status === 500) {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
        )
        setLoading(false)
        return false
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao salvar os dados do evento'
        )
        setLoading(false)
        return false
      }
    } catch (error) {}
  }

  function handleCancel() {
    console.log('cancelado')
    window.location.href = '/eventozz'
  }

  function fillInitialData() {
    console.log(eventData)

    if (eventData) {
      setInitialImageUrl(eventData?.bannerUrl ? eventData.bannerUrl : null)
    }
  }

  function handleModalClose() {
    setShowCropperModal(false)
  }

  function handleCropImage({ croppedData, croppedBase64 }) {
    console.log({ croppedData })
    console.log(croppedBase64)
    setCroppedImageData(croppedData)
    setCroppedImageFileUrl(croppedBase64)
    setShowCropperModal(false)
    setHasChanges(true)
  }

  useEffect(() => {
    if (eventData) {
      fillInitialData()
    }
  }, [eventData])

  useEffect(() => {
    if (imageFile) {
      setShowCropperModal(true)
    }
  }, [imageFile])

  return (
    <Container className="pt-4">
      <Row xs={12} justifyContent="flex-start" item className="mt-5">
        <h5>Banner principal do evento</h5>
      </Row>

      <Row container xs={12}>
        <Col xs={12}>
          {croppedImageUrl || initialImageUrl ? (
            <>
              <img
                src={croppedImageUrl || initialImageUrl}
                alt="Imagem do evento"
                className={styles.previewBanner}
              />
              <FileUpload
                dropzoneError={false}
                text="Clique aqui para alterar a imagem principal do evento"
                suggestion="(No máximo 3M. Recomendamos que a imagem tenha 1920 x 1080 px *.jpeg ou *.png)"
                onDrop={onDrop}
                maxSize={3000000}
              />
            </>
          ) : (
            <FileUpload
              dropzoneError={dropzoneError}
              text="Solte a imagem aqui, ou clique para selecionar a imagem principal do evento"
              suggestion="(No máximo 3M. Recomendamos que a imagem tenha 1920 x 1080 px *.jpeg ou *.png)"
              onDrop={onDrop}
              maxSize={3000000}
              className={styles.onlyDropzone}
            />
          )}
        </Col>
      </Row>

      <Row className="pt-4 pb-4 justify-content-end">
        <Col xs={12} md={4} className="d-flex justify-content-end">
          <Button
            variant="danger"
            type="button"
            color="danger"
            onClick={() => handleCancel()}
            style={{ marginTop: '30px' }}
          >
            Voltar
          </Button>
          <CustomButton
            className="mx-3"
            variant="primary"
            type="button"
            disabled={!eventId}
            showTooltip={!eventId}
            title={
              !eventId
                ? "Salve o evento na sessão 'Detalhes' antes de salvar as imagens"
                : ''
            }
            onClick={() => handleSubmit()}
            style={{ marginTop: '30px' }}
            text="Salvar"
          />
        </Col>
      </Row>

      <ModalCropImage
        showModal={showCropperModal}
        aspectRatio={16 / 9}
        imgBase64={imageUrl}
        onClose={handleModalClose}
        onConfirm={handleCropImage}
        imageDimensions={imageDimensions}
      />
    </Container>
  )
}
