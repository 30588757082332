import { api } from './axios'

export default {
  courtesySave: async ({ body }) => {
    const response = await api
      .post('/courtesy/save', {
        ...body
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })
    return response
  },
  pdvSave: async (body) => {
    const response = await api
      .post('/pdv/save', {
        ...body
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })
    return response
  },
  cartSave: async ({ cart, cartId, eventId }) => {
    const response = await api
      .post('/pdv/save-cart', {
        cart,
        cartId,
        eventId
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })
    return response
  }
}
