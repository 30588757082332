import React, { useCallback, useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import Select from 'react-select'
import {
  Container,
  Row,
  Col,
  Button,
  //Card,
  //CardBody,
  //Input,
  //Dropdown,
  // DropdownToggle,
  // DropdownItem,
  // DropdownMenu,
  FormGroup,
  Label
} from 'reactstrap'
import { PdvTicketsContainer } from '../../components/pdvTicketsContainer'
import { toast } from 'react-toastify'
import { Loading } from 'components/Loading'
import styles from './styles.module.css'
//import { PdvTicketsContainer } from 'components/pdvTicketsContainer'
//import select from 'services/select'
import {
  listOrders,
  createCheckout,
  loadFinancialDetails,
  sellerSelect,
  eventzzSelect
} from '../../store/actions'

const columns = [
  { title: 'Id', id: 'id', hideOnMobile: true },
  { title: 'Nome', id: 'eventName' },
  { title: 'Slug', id: 'slug', hide: true },
  { title: 'Categoria', id: 'eventCategory' },
  { title: 'Descrição', id: 'description', hide: true },
  { title: 'Inicio', id: 'startDate' },
  { title: 'Fim', id: 'endDate' },
  { title: 'Tipo', id: 'type' },
  { title: 'Tipo de taxa', id: 'fee' },
  { title: 'Data em breve', id: 'commingSoon', hide: true, hideOnMobile: true },
  {
    title: 'Inicio das vendas',
    id: 'startSaleDate',
    hide: true,
    hideOnMobile: true
  },
  {
    title: 'Fim das vendas',
    id: 'endSaleDate',
    hide: true,
    hideOnMobile: true
  },
  { title: 'Gratuito', id: 'isFree', hideOnMobile: true },
  { title: 'Ativo', id: 'active', hideOnMobile: true },
  { title: 'Data de criação', id: 'createdAt', hideOnMobile: true }
]

const Pdv = () => {
  const [loading, setLoading] = useState(false)
  const [eventId, setEventId] = useState(null)
  const [organizerId, setOrganizerId] = useState(null)
  const [eventzzList, setEventzzList] = useState([])

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: true,
      eventzz: true,
      more: true,
      pagination: true,
      add: true,
      addCourtesyTicket: true
    },
    config: {
      addText: 'Adicionar vendedor',
      addCourtesyTicketText: 'Criar ingresso de cortesia'
    },
    filters: {
      searchTerm: null,
      dateFrom: null,
      dateTo: null,
      active: true,
      category: null,
      type: null,
      eventId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 10
  })

  const [showFilters, setShowFilters] = useState(true)

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()
    console.log(response)
    if (response.status === 200) {
      const responseData = response?.data
      console.log(responseData)
      setEventzzList(responseData)
      setLoading(false)
      return true
    } else if (response.status === 401) {
      //console.log('here');
      toast.error('Você não está logado ou sua sessão expirou')
      setLoading(false)
      return false
    } else if (response.status === 500) {
      toast.error(
        response?.msg ||
          'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
      )
      setLoading(false)
      return false
    } else {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro ao tentar listar as vendas'
      )
      setLoading(false)
      return false
    }
  }, [])

  useEffect(() => {
    loadEventzz()
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  const handleClose = () => {
    setLoading(false)
  }

  function handleClearFilters() {
    setTableConfig((prev) => ({
      ...prev,
      filters: {
        eventId: null
      }
    }))
    setEventId(null)
    setOrganizerId(null)
    setLoading(true)
  }

  function handleChangeEvent({ eventId, organizerId }) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        eventId
      }
    }))
    setEventId(eventId)
    setOrganizerId(organizerId)
    setLoading(true)
  }

  return (
    <React.Fragment>
      <Loading open={loading} handleClose={handleClose} />
      <div className="page-content">
        <MetaTags>
          <title>PDV Eventozz</title>
        </MetaTags>

        <Container className={styles.fluid}>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">PDV Eventos</h6>
              </Col>
            </Row>
          </div>
          <Row className={styles.divRow}>
            <Col xl={12} md={12} className={styles.divCol}>
              <FormGroup>
                <Select
                  options={eventzzList}
                  value={
                    eventzzList.find((a) => a.value === eventId)
                      ? eventzzList.find((a) => a.value === eventId)
                      : null
                  }
                  onChange={(value) =>
                    handleChangeEvent({
                      eventId: value?.value,
                      organizerId: value?.organizerId
                    })
                  }
                  placeholder="Evento"
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleClearFilters()}
                className={styles.btn}
              >
                Limpar filtros
              </Button>
            </Col>
          </Row>

          <PdvTicketsContainer eventId={eventId} organizerId={organizerId} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Pdv
