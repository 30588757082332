import { api } from './axios'

export const activityService = {
  list: async (eventId) => {
    const response = await api
      .get('/activity/list', {
        params: {
          eventId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  find: async ({ activityId }) => {
    const response = await api
      .get(`/activity/${activityId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  createActivity: async ({ data, eventId }) => {
    const response = await api
      .post('/activity/create', {
        ...data,
        eventId
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })
    return response
  },
  updateActivity: async ({ id, data, eventId }) => {
    const response = await api
      .put(`/activity/${id}`, {
        ...data,
        eventId
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })
    return response
  },
  updateActivitySettings: async ({ formData, eventId }) => {
    const response = await api
      .put(`/activity/settings/${eventId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })
    return response
  },
  findSettings: async ({ eventId }) => {
    const response = await api
      .get(`/activity/settings/${eventId}`)
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  activityPurchaseList: async ({
    rowsPerPage,
    page,
    searchTerm,
    startSaleDate,
    endSaleDate,
    eventId,
    statusTransaction
  }) => {
    const response = await api
      .get('/activity-purchase/list', {
        params: {
          rowsPerPage,
          page,
          searchTerm,
          startSaleDate,
          endSaleDate,
          eventId,
          statusTransaction
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  loadActivityFinancialDetails: async ({
    eventId,
    statusTransaction,
    searchTerm,
    startSaleDate,
    endSaleDate
  }) => {
    const response = await api
      .get('/activities/financial-details', {
        params: {
          eventId,
          statusTransaction,
          searchTerm,
          startSaleDate,
          endSaleDate
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  sendActivityPurchaseQrCode: async ({ eventId, purchaseId }) => {
    const response = await api
      .post('/activities/send-purchase-qrcode', {
        eventId,
        purchaseId
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  resendActivityQrcode: async ({ eventId, activityId, ticketData }) => {
    const response = await api
      .put('/activity/send-qrcode', {
        eventId,
        activityId,
        ticketData
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
