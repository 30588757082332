import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import { Loading } from '../Loading'
import './styles.css'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { userService } from 'services/user'

export const ModalAddAppUser = ({ show, onSubmit, onClose, currentUser }) => {
  const { createUserApp, updateUserApp } = userService
  const [loading, setLoading] = useState(false)

  const [newUser, setNewUser] = useState({
    userName: null,
    password: null,
    errorMessage: {
      userName: null,
      password: null
    }
  })

  const handleChange = ({ value, field }) => {
    setNewUser((prev) => ({
      ...prev,
      errorMessage: {
        ...prev.errorMessage,
        [field]: null
      },
      [field]: value
    }))
  }

  const handleClose = () => {
    setLoading(false)
  }

  const clearFields = () => {
    setNewUser({
      userName: null,
      password: null
    })
  }

  const handleSubmitAddUser = async () => {
    if (newUser?.userName?.length < 4 || newUser?.password?.length < 4) {
      setNewUser({
        ...newUser,
        errorMessage: {
          userName:
            newUser?.userName?.length < 4
              ? 'O nome do usuário precisa ter, no mínimo, 5 caracteres'
              : null,
          password:
            newUser?.password?.length < 4
              ? 'A senha precisa ter, no mínimo, 5 caracteres'
              : null
        }
      })
      return
    }

    setLoading(true)
    let response = null
    if (currentUser?.id) {
      response = await updateUserApp({
        id: currentUser.id,
        userName: newUser.userName,
        password: newUser.password
      })
    } else {
      response = await createUserApp({
        userName: newUser.userName,
        password: newUser.password
      })
    }
    if (response.status === 200) {
      toast.success(
        response?.msg
          ? response?.msg
          : 'usuário adicionado adicionado/atualizado'
      )
      onSubmit()
      clearFields()
      setLoading(false)
    } else if (
      response.status === 400 &&
      response?.response?.data?.unavailable
    ) {
      toast.success('Usuário já em uso')
      setNewUser({
        ...newUser,
        errorMessage: {
          userName: 'Usuário já em uso',
          password: null
        }
      })
    } else {
      console.log('=============')
      toast.error(
        response?.msg ||
          'Ocorreu um erro ao tentar adicionar/atualizar o usuário'
      )
      setLoading(false)
      return false
    }
  }

  const handleCancelAddUser = () => {
    clearFields()
    onClose()
  }

  useEffect(() => {
    if (currentUser && show) {
      setNewUser({
        userName: currentUser.userName,
        password: currentUser.password
      })
    }
  }, [currentUser])
  return (
    <ReactModal
      isOpen={show}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container>
        <Loading open={loading} handleClose={handleClose} />

        <form autoComplete="off">
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>
                Adicionar usuário para o aplicativo
              </h1>
            </Col>
          </Row>
          <Row>
            <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
              <FormGroup>
                <Form.Label>Usuário</Form.Label>
                <Form.Control
                  type="text"
                  name="userName"
                  value={newUser.userName}
                  autoComplete="off"
                  placeholder="Ex.: user2023"
                  onChange={(event) =>
                    handleChange({
                      value: event.target.value,
                      field: 'userName'
                    })
                  }
                  isInvalid={!!newUser?.errorMessage?.userName}
                />
                <Form.Control.Feedback type="invalid">
                  {newUser?.errorMessage?.userName}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Qualquer nome com no mínimo 5 caracteres
                </Form.Text>
              </FormGroup>
            </Col>
            <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
              <FormGroup>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  autoComplete="off"
                  value={newUser.password}
                  onChange={(event) =>
                    handleChange({
                      value: event.target.value,
                      field: 'password'
                    })
                  }
                  isInvalid={!!newUser?.errorMessage?.password}
                />
                <Form.Control.Feedback type="invalid">
                  {newUser?.errorMessage?.password}
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>

          <Row className="footerModalButtons">
            <Col xs={6}>
              <Button onClick={handleCancelAddUser} variant="secondary">
                Cancelar
              </Button>
            </Col>
            <Col xs={6}>
              <Button onClick={handleSubmitAddUser} variant="primary">
                {currentUser?.id ? 'Atualizar' : 'Adicionar'}
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
