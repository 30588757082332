import React from 'react'
import { Collapse } from 'reactstrap'
import { Tr, Td } from 'react-super-responsive-table'

import styles from './styles.module.scss'
import { Card, Col, Row } from 'react-bootstrap'

export function FinancialAdminCollapseComponent({ open, row, columns }) {

  return (
    <Tr>
      <Td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse
          isOpen={open}
          timeout="auto"
          unmountOnExit
          className={styles.tableContainer}
        >
          <Card className={styles.cardColuns}>
            <Card.Body>
              <Row>
                {columns
                  .filter((item) => item.hide)
                  .map((col, index) => (
                    <Col key={index} xs={6} sm={3}>
                      <div className={styles.label}>{col.label}</div>
                      <div className={styles.value}>{row[col.field]}</div>
                    </Col>
                  ))}
              </Row>
            </Card.Body>
          </Card>
        </Collapse>
      </Td>
    </Tr>
  )
}
