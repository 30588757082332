import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify'
import eventzz from 'services/eventzz'
import styles from './styles.module.scss'
import { CurrencyInput } from 'components/CurrencyInput'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { CustomButton } from 'components/customButton'
import { Select } from 'components/select'

const gatewayOptions = [
  { value: 'mpays', label: 'MPays' },
  { value: 'pagarme', label: 'Pagarme' }
]

export const TabAdmSettings = ({
  eventId,
  setLoading,
  eventData,
  setHasChanges
}) => {
  const { updateEventSettings } = eventzz

  const [customFee, setCustomFee] = useState(false)
  const [useWhatsapp, setUseWhatsapp] = useState(false)
  const [gateway, setGateway] = useState(false)
  const [externalLink, setExternalLink] = useState(null)
  const [minimumFee, setMinimumFee] = useState(null)
  const [customPDVFee, setCustomPDVFee] = useState(null)
  const [active, setActive] = useState(false)

  const [customFees, setCustomFees] = useState([])

  async function handleSubmit() {
    try {
      setLoading(true)

      let hasErrors = false

      let customFeesTemp = [...customFees]
      if (customFee) {
        customFeesTemp = customFeesTemp?.map((item) => {
          item.errorMessage = item.errorMessage ? { ...item.errorMessage } : {}
          if (!item.installmentFee) {
            hasErrors = true
            item.errorMessage.installmentFee = 'O campo Taxa é obrigatório'
          }

          return item
        })
      } else {
        customFeesTemp = []
      }

      console.log(customFeesTemp)
      if (hasErrors) {
        setCustomFees(customFeesTemp)
        setLoading(false)
        return
      }

      const response = await updateEventSettings({
        eventId,
        customFees: customFeesTemp,
        useWhatsapp,
        externalLink,
        gateway,
        minimumFee,
        active,
        customPDVFee
      })
      console.log(response)
      if (response.status === 200 && response?.data?.success) {
        toast.success('As alterações foram salvas')
        setHasChanges(false)
        setLoading(false)
      } else if (response.status === 401) {
        toast.error('Você não está logado ou sua sessão expirou')
        setLoading(false)
        return false
      } else if (response.status === 500) {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
        )
        setLoading(false)
        return false
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao salvar os dados do evento'
        )
        setLoading(false)
        return false
      }
    } catch (error) {}
  }

  function handleCancel() {
    console.log('cancelado')
    window.location.href = '/eventozz'
  }

  function handleChangeCustomFee({ value, feeIndex, field }) {
    console.log(field, value)

    const newTicketTypes = customFees.map((item, index) => {
      if (index === feeIndex) {
        return {
          ...item,
          errorMessage: item?.errorMessage
            ? {
                ...item.errorMessage,
                [field]: null
              }
            : null,
          [field]: value
        }
      }
      return item
    })
    setHasChanges(true)
    setCustomFees(newTicketTypes)
  }

  function handleAddFee() {
    const newTicketTypes = [...customFees]
    newTicketTypes.push({
      description: 'credito',
      installments: customFees.length,
      installmentFee: null,
      organizerPaysFee: false
    })
    setHasChanges(true)
    setCustomFees(newTicketTypes)
  }

  function handleRemoveFee(feeIndex) {
    let newTicketTypes = customFees?.filter((item, index) => index !== feeIndex)

    newTicketTypes = newTicketTypes.map((item, index) => {
      if (item.description === 'credito') {
        return {
          ...item,
          installments: index
        }
      }
      return item
    })
    setHasChanges(true)
    setCustomFees(newTicketTypes)
  }

  function handleChangeHasCustomFee(checked) {
    if (checked && !customFees?.length) {
      setCustomFees([
        {
          description: 'pix',
          installments: 1,
          installmentFee: null,
          organizerPaysFee: false
        }
      ])
    }
    setHasChanges(true)
    setCustomFee(checked)
  }

  function fillInitialData() {
    console.log(eventData)

    if (eventData?.customFees && eventData?.customFees?.length > 0) {
      const newCustomFees = eventData.customFees.map((item) => {
        console.log(item)

        return {
          ...item
        }
      })
      setCustomFees(newCustomFees)
      setCustomFee(true)
    }
    setExternalLink(eventData?.externalLink ? eventData.externalLink : null)
    setUseWhatsapp(!!eventData?.useWhatsapp)
    setGateway(eventData?.useGateway?.toLowerCase())
    setMinimumFee(eventData?.minimumFee ? eventData.minimumFee : null)
    setActive(!!eventData?.active)
    setCustomPDVFee(eventData?.customPdvFee)
  }

  useEffect(() => {
    if (eventData) {
      fillInitialData()
    }
  }, [eventData])

  return (
    <Container className="pt-4">
      <Row>
        <Col
          xs={12}
          md={3}
          className={`${styles.extraPadding} ${styles.flexAlignEnd}`}
        >
          <Form.Group switch>
            <Form.Check
              checked={active}
              label="Ativo"
              onChange={(e) => {
                setActive(e?.target?.checked)
                setHasChanges(true)
              }}
            />
          </Form.Group>
        </Col>
        <Col
          xs={12}
          md={3}
          className={`${styles.extraPadding} ${styles.flexAlignEnd}`}
        >
          <Form.Group switch>
            <Form.Check
              checked={useWhatsapp}
              label="Vendas pelo WhatsApp"
              onChange={(e) => {
                setUseWhatsapp(e?.target?.checked)
                setHasChanges(true)
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={3} className={styles.extraPadding}>
          <Form.Group switch>
            <Form.Label>Gateway *</Form.Label>
            <Select
              options={gatewayOptions}
              placeholder="Gateway"
              value={gateway}
              onChange={(value) => setGateway(value.value)}
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={3} className={styles.extraPadding}>
          <CurrencyInput
            variant="outlined"
            value={minimumFee}
            label="Taxa mínima (R$)"
            margin="none"
            handleChange={(value) => {
              setMinimumFee(value)
              setHasChanges(true)
            }}
          />
        </Col>

        <Col xs={12} md={3} className={styles.extraPadding}>
          <CurrencyInput
            variant="outlined"
            value={customPDVFee}
            label="Taxa customizada PDV (%)"
            margin="none"
            handleChange={(value) => {
              setCustomPDVFee(value)
              setHasChanges(true)
            }}
          />
        </Col>
      </Row>
      <Row xs={12} className={styles.extraPadding}>
        <Form.Label>Link externo</Form.Label>
        <Form.Control
          className={styles.inputEventozz}
          variant="outlined"
          value={externalLink}
          onChange={(e) => {
            setExternalLink(e?.target?.value)
            setHasChanges(true)
          }}
        />
      </Row>
      <Row xs={12} className={`${styles.extraPadding} ${styles.flexAlignEnd}`}>
        <Form.Group switch>
          <Form.Check
            checked={customFee}
            label="Taxas Customizadas"
            onChange={(e) => handleChangeHasCustomFee(e?.target?.checked)}
          />
        </Form.Group>
      </Row>

      {customFee &&
        customFees?.map((fee, index) => (
          <Row
            key={index.toString()}
            container
            spacing={4}
            className={`${styles.gridContainer}`}
          >
            <Col item xs={12} sm={6} md={3}>
              <Form.Control
                className={styles.inputEventozz}
                variant="outlined"
                label="Forma de pagamento"
                value={fee.description}
                disabled
              />
              {/* <FieldLabel label={`Forma de pagamento: ${fee.description}`} /> */}
            </Col>
            <Col item xs={12} sm={6} md={3}>
              <Form.Control
                className={styles.inputEventozz}
                type="number"
                variant="outlined"
                label="Parcelas"
                value={fee.installments}
                disabled
              />
              {/* <FieldLabel label={`Parcelas: ${fee?.installments ? fee.installments.toString() : ''}`} /> */}
            </Col>
            <Col item xs={12} sm={6} md={3}>
              <CurrencyInput
                name="installmentFee"
                variant="outlined"
                value={fee.installmentFee}
                placeholder="Taxa"
                margin="none"
                handleChange={(value) => {
                  handleChangeCustomFee({
                    value,
                    field: 'installmentFee',
                    feeIndex: index
                  })
                }}
                error={!!fee?.errorMessage?.installmentFee}
                helperText={fee?.errorMessage?.installmentFee}
              />
            </Col>
            <Col
              xs={12}
              sm={6}
              md={3}
              justifyContent="space-between"
              alignItems="center"
              container
              className={styles.extraPadding}
            >
              <Form.Group switch>
                <Form.Check
                  checked={fee.organizerPaysFee}
                  label="Organizador paga a taxa"
                  onChange={(e) =>
                    handleChangeCustomFee({
                      value: e?.target?.checked,
                      field: 'organizerPaysFee',
                      feeIndex: index
                    })
                  }
                />
              </Form.Group>
            </Col>
            {(customFees?.length === 1 ||
              (customFees?.length > 1 && index > 0)) && (
              // <ContainerButtonForms
              //   confirmVariant="outlined"
              //   confirmText="Adicionar parcela"
              //   cancelText="Remover"
              //   showConfirm={index === customFees?.length - 1}
              //   showCancel={index > 0}
              //   callbackConfirm={handleAddFee}
              //   callbackCancel={() => handleRemoveFee(index)}
              // />
              <Row className="pt-4 justify-content-end">
                <Col xs={12} md={4} className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    type="button"
                    color="danger"
                    onClick={() => handleRemoveFee(index)}
                    style={{ marginTop: '30px' }}
                  >
                    Remover
                  </Button>
                  {index === customFees?.length - 1 && (
                    <Button
                      className="mx-3"
                      variant="primary"
                      type="button"
                      onClick={() => handleAddFee()}
                      color="primary"
                      style={{ marginTop: '30px' }}
                    >
                      Adicionar parcela
                    </Button>
                  )}
                </Col>
              </Row>
            )}
          </Row>
        ))}

      <Row className="pt-4 justify-content-end mb-4">
        <Col xs={12} md={4} className="d-flex justify-content-end">
          <Button
            variant="danger"
            type="button"
            color="danger"
            onClick={() => handleCancel()}
            style={{ marginTop: '30px' }}
          >
            Voltar
          </Button>
          <CustomButton
            className="mx-3"
            variant="primary"
            type="button"
            disabled={!eventId}
            showTooltip={!eventId}
            title={
              !eventId
                ? "Salve o evento na sessão 'Detalhes' antes de salvar as configurações"
                : ''
            }
            onClick={() => handleSubmit()}
            color="primary"
            style={{ marginTop: '30px' }}
            text="Salvar"
          />
        </Col>
      </Row>
    </Container>
  )
}
