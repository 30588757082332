import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Button, Card, FormGroup, Label } from 'reactstrap'

import 'chartist/dist/scss/chartist.scss'
import DatatableTables from '../../components/Table'
import { Loading } from '../../components/Loading'
import { toast } from 'react-toastify'
import { Select } from 'components/select'
import Services from 'services/select'
import { activityService } from 'services/activity'
import { formatCurrency } from 'util/string'
import { CollapseActivity } from 'components/Table/ActicityCollapse'
import { Settings } from 'react-feather'

const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Evento', field: 'eventName' },
  { label: 'Atividade', field: 'nome' },
  { label: 'Qtd disponível', field: 'availableQtd' },
  { label: 'Válido de', field: 'validFrom' },
  { label: 'Válido até', field: 'validTo' },
  { label: 'Valor', field: 'price' },
  { label: 'Gratuito', field: 'isFree' },
  { label: 'Ativo', field: 'activityStatus' },
  { label: 'Data de criação', field: 'createdAt' }
]

export const AtividadesEvento = () => {
  const { eventzz: selectEventozz } = Services
  const { list: activityList } = activityService
  const [filteredRows, setFilteredRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [eventzzList, setEventzzList] = useState([])

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: true,
      more: true,
      pagination: true,
      edit: true
    },
    config: {
      handleEditText: 'Editar atividade'
    },
    filters: {
      eventId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 10
  })

  const handleClose = () => {
    setLoading(false)
  }

  function handleCreateEvent() {
    if (tableConfig.filters.eventId) {
      window.location.href = `/atividades-do-evento/${tableConfig.filters.eventId}/nova`
    } else {
      toast.error('Selecione um evento')
    }
  }

  function handleSettings() {
    if (tableConfig.filters.eventId) {
      window.location.href = `/atividades-do-evento/configuracoes/${tableConfig.filters.eventId}`
    } else {
      toast.error('Selecione um evento')
    }
  }

  function handleChangeEvent(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        eventId: Number(value?.value) === 0 ? null : value?.value
      }
    }))
    setLoading(true)
  }

  const loadEventzz = useCallback(async () => {
    const response = await selectEventozz()
    if (response.status === 200) {
      const responseData = response?.data
      console.log('responseData = ', responseData)
      setEventzzList(responseData.data)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  const loadActivities = useCallback(async () => {
    const response = await activityList(tableConfig.filters.eventId)
    if (response.status === 200) {
      const responseData = response?.data?.data
      console.log('responseData = ', responseData)
      const formattedData = responseData.map((item) => {
        return {
          ...item,
          price: formatCurrency({ value: item.valor })
        }
      })
      console.log(formattedData)
      setFilteredRows(formattedData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [tableConfig.filters.eventId])

  const handleEdit = (row) => {
    console.log(row)
    window.location.href = `/atividades-do-evento/${tableConfig.filters.eventId}/editar/${row.id}`
  }

  useEffect(() => {
    if (tableConfig.filters.eventId) {
      loadActivities()
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  useEffect(() => {
    loadEventzz()
  }, [])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}

      <div className="page-content">
        <MetaTags>
          <title>Atividade do evento | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col xs={12} md={6}>
                <h6 className="page-title">Atividades | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Gestão de atividades do evento
                  </li>
                </ol>
              </Col>
              <Col xs={12} md={6} className="text-end">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleSettings()}
                  style={{ marginTop: '30px', marginRight: '20px' }}
                >
                  <Settings />
                  Configurações
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleCreateEvent()}
                  style={{ marginTop: '30px' }}
                >
                  + Nova atividade
                </Button>
              </Col>
            </Row>
          </div>
          <Row>
            <Card>
              <Row>
                <Col xl={12} md={12} style={{ padding: 20 }}>
                  <Row>
                    <Col xl={3} md={6}>
                      <FormGroup>
                        <Label>Evento</Label>
                        <Select
                          value={
                            eventzzList.find(
                              (a) => a.value === tableConfig?.filters?.eventId
                            )
                              ? eventzzList.find(
                                  (a) =>
                                    a.value === tableConfig?.filters?.eventId
                                )
                              : null
                          }
                          onChange={(value) => handleChangeEvent(value)}
                          options={eventzzList}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <DatatableTables
                title={'Atividades do evento'}
                columns={columns}
                rows={filteredRows}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
                CollapseComponent={CollapseActivity}
                onEdit={handleEdit}
              />
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
