import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {
    Row,
    Col,
    Button,
    Card
} from "reactstrap"
import { Loading } from '../Loading';
import { ToastContainer } from 'react-toastify'
import QRCode from "qrcode";

import './styles.css';
import { Document, Page, Text, View, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer';
import { styles } from './styles';

export const ModalEtiqueta = ({ showModal, onClose, etiquetas, eventName }) => {

    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState(false);
    const [name, setName] = useState("Nome do Participante");

    const handleClose = () => {
        setLoading(false);
    }

    const gerarQrCode = async (info) => {
        console.log("----------------------")
        console.log(info)
        const generatedQrCode = await QRCode.toDataURL(info);
        setCode(generatedQrCode)
        console.log(generatedQrCode)
        return generatedQrCode;
    }

    useEffect(() => {
        setName(eventName)
        gerarQrCode(etiquetas[0]?.code);
      }, [etiquetas])


    const PDFExport = () => (
    <Document >
        {
            <Page size="A4" style={styles.page} wrap>
                {/* <View style={{display:'flex', flexDirection: 'row'}} wrap> */}
                    {etiquetas.map((item, index) => {
                        return (
                            // eslint-disable-next-line react/jsx-key
                                <View 
                                    style={styles.background}
                                    absolute
                                    zIndex={10}
                                    wrap={false}
                                >
                                    <View style={styles.div}>
                                        <Image style={styles.logo}
                                            src={!item?.code ? (
                                                '/images/eventozz/logoCertificado.png'
                                                ) : (
                                                    async () => await QRCode.toDataURL(item.code)
                                                )}
                                        />
                                    </View>
                                    <View style={styles.div2}>
                                        <Text style={styles.titulo}>{name}</Text>
                                        <Text style={styles.subtitle}>{(`#${item.id} | ${item.name}`).substring(0, 30)}</Text>
                                        <Text style={styles.label}>Gerado através de eventozz.com</Text>
                                    </View>
                                </View>
                        )
                    })}
                {/* </View> */}
            </Page>
        }
    </Document>
    );
      
    return (
        <Modal show={showModal} fullscreen={true} onHide={() => onClose()}>
        {loading && <Loading open={loading} handleClose={handleClose} />}
        <ToastContainer />
        <Modal.Header closeButton>
          <Modal.Title>Gerar Etiquetas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                    <Form>
                        <Row>
                            <Col xl={3} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoFocus
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl={3} md={6}>
                                <PDFDownloadLink document={<PDFExport />} fileName={`etiquetas-${name}.pdf`}>
                                    {({ blob, url, loading, error }) => {
                                        return loading ? 'Gerando Etiquetas...' :  <Button
                                                variant="outlined"
                                                color="primary"
                                                type='button'
                                                style={{ marginTop: '30px' }}
                                            >
                                                Gerar Etiquetas
                                            </Button>
                                    }
                                    }
                                    </PDFDownloadLink>
                            </Col>
                        </Row>
                        <Row>
                            <div className='etiquetaCard'>
                                <Row style={{width:'100%'}}>
                                    <Col xl={3} md={3}>
                                        <img 
                                            src={!code ? ('/images/eventozz/logoCertificado.png') : (code)}
                                        />
                                    </Col>
                                    <Col xl={9} md={9}>
                                        <h1>{name}</h1>
                                        <span>#000 | Nome do Participante</span>
                                        <label>Gerado através de eventozz.com</label>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                    </Form>
             
        </Modal.Body>
      </Modal>
    )
}