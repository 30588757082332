import React, { useCallback, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { Loading } from '../../../Loading'
import 'react-toastify/dist/ReactToastify.css'
import DatatableTables from '../../index.js'
import { permissionsService } from 'services/permissions'

interface IEventOrganizer {
  id: number
  name: string
  email: string
}

export const TabEventOrganizers = ({ currentValue, row }) => {
  const { listEventOrganizers } = permissionsService
  const [isLoadingOrganizers, setIsLoadingOrganizers] = useState(false)
  const [organizersList, setOrganizersList] = useState([])

  const loadEventOrganizers = useCallback(async () => {
    try {
      setIsLoadingOrganizers(true)

      const response = await listEventOrganizers(row?.id)
      console.log(response)
      if (response.status === 200) {
        const data = response?.data?.data

        console.log(data)
        setOrganizersList(data)
        setIsLoadingOrganizers(false)
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao tentar listar os tipos de ingresso'
        )
        setIsLoadingOrganizers(false)
        return false
      }
    } catch (error) {
      console.log({ error })
      setIsLoadingOrganizers(false)
    }
  }, [row])

  function handlerEditPermissions(rowOrganizer: IEventOrganizer) {
    console.log(row)
    window.location.href = `/eventozz/${row.id}/editar-permissoes-organizador/${rowOrganizer.id}`
  }

  useEffect(() => {
    console.log(row)
    console.log('currentValue', currentValue)
    if (Number(currentValue) === 4) {
      loadEventOrganizers()
    }
  }, [currentValue])

  return (
    <>
      <ToastContainer />
      <Loading open={isLoadingOrganizers} />

      <DatatableTables
        title={'Organizadores com permissão para esse evento'}
        columns={[
          { label: 'Usuário', field: 'id' },
          { label: 'Nome', field: 'name' },
          { label: 'Email', field: 'email' }
        ]}
        rows={organizersList}
        onEdit={handlerEditPermissions}
        applyPadding={false}
        tableConfig={{
          options: {
            more: true,
            edit: true
          },
          config: {
            handleEditText: 'Editar permissões'
          }
        }}
      />
    </>
  )
}
