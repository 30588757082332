/* eslint-disable react/jsx-key */
/* eslint-disable array-bracket-newline */
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import DatatableTables from '../../components/Table'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col } from 'reactstrap'

import { ToastContainer, toast } from 'react-toastify'
///MODAIS
import { userList } from '../../store/actions'
import { formatDateT, cpfMask, phoneMask } from '../../util/string'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
import { Loading } from 'components/Loading'
import { ModalAddEditUsers } from 'components/modalAddEditUsers'

const columns = [
  { label: 'Id', field: 'id' },
  { label: 'CPF', field: 'cpf' },
  { label: 'Nome', field: 'name' },
  { label: 'Email', field: 'email' },
  { label: 'Telefone', field: 'fone' }
]

const ListaUsuariosAdmin = () => {
  const [loading, setLoading] = useState(false)
  const [showModalAddEditUsers, setShowModalAddEditUsers] = useState(false)
  const [updatedTable, setUpdatedTable] = useState(false)
  const [userEdit, setUserEdit] = useState(null)

  const [filteredRows, setFilteredRows] = useState([])

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      tickets: false,
      more: true,
      pagination: true,
      markAsUsed: false,
      delete: false,
      userEdit: true
    },
    config: {
      handleUserEditText: 'Editar Usuário'
    },
    filters: {
      searchTerm: null,
      status: 'paid',
      eventId: null,
      ticketType: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const handleClose = () => {
    setLoading(false)
  }

  const loadDados = async () => {
    setLoading(true)
    const response = await userList({
      rowsPerPage: tableConfig.rowsPerPage,
      currentPage: tableConfig.currentPage
    })

    if (response.status === 200) {
      console.log(response)
      console.log('----------------')

      const responseData = response?.data
      const total = responseData?.total
      console.log(total)
      const formattedData = responseData.items.map((item) => ({
        ...item,
        created_at: formatDateT(item.created_at),
        cpf: cpfMask(item.cpf),
        fone: phoneMask(item.fone)
      }))

      setTableConfig((prev) => ({
        ...prev,
        total
      }))

      setFilteredRows(formattedData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }

  function handleOpenUserEdit(row) {
    setUserEdit(row)
    setShowModalAddEditUsers(true)
  }

  function handleSuccessAddEditUsers() {
    setShowModalAddEditUsers(false)
    setUpdatedTable(!updatedTable)
    setUserEdit(null)
  }

  function handleCancelAddEditUsers() {
    setShowModalAddEditUsers(false)
    setUserEdit(null)
  }

  useEffect(() => {
    loadDados()
  }, [
    tableConfig.currentPage,
    tableConfig.rowsPerPage,
    tableConfig.filters,
    updatedTable
  ])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Usuários do sistema | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Usuários do sistema| Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Usuários
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row></Row>
        </Container>
        <DatatableTables
          title={'Usuários'}
          columns={columns}
          rows={filteredRows}
          tableConfig={tableConfig}
          setTableConfig={setTableConfig}
          onUserEdit={handleOpenUserEdit}
        />
        <ModalAddEditUsers
          showModal={showModalAddEditUsers}
          onSuccess={handleSuccessAddEditUsers}
          onCancel={handleCancelAddEditUsers}
          userEdit={userEdit}
          setUserEdit={setUserEdit}
        />
      </div>
    </React.Fragment>
  )
}

ListaUsuariosAdmin.propTypes = {
  t: PropTypes.any
}

export default ListaUsuariosAdmin
