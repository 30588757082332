import { api } from './axios'

const Services = {
  resendTicket: async ({ eventId, ticketId, ticketData }) => {
    const response = await api
      .put('/tickets/send-qrcode', {
        eventId,
        ticketId,
        ticketData
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  checkPhoneIsWhatsApp: async (phone) => {
    const response = await api
      .get('/customer/check-phone-is-whatsapp', { params: { phone } })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  sendPurchaseQrCode: async ({ eventId, purchaseId }) => {
    const response = await api
      .post('/tickets/send-purchase-qrcode', {
        eventId,
        purchaseId
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  createCheckout: async (params) => {
    const response = await api
      .get('/payment/create-checkout', {
        params
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  loadFinancialDetails: async ({
    eventId,
    statusTransaction,
    sellerId,
    searchTerm,
    startSaleDate,
    endSaleDate,
    plataforma,
    incluirCortesias
  }) => {
    const response = await api
      .get('/eventzz/financial-details', {
        params: {
          eventId,
          statusTransaction,
          sellerId,
          searchTerm,
          startSaleDate,
          endSaleDate,
          plataforma,
          incluirCortesias
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  loadFinancial: async ({
    eventId,
    statusTransaction,
    startSaleDate,
    endSaleDate
  }) => {
    const response = await api
      .get('/eventzz/financial-details-general', {
        params: {
          eventId,
          statusTransaction,
          startSaleDate,
          endSaleDate
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  sellerSelect: async (eventId) => {
    const response = await api
      .get('/seller/select', {
        params: {
          eventId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  eventzzSelect: async () => {
    const response = await api
      .get('/eventzz/select')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  userList: async ({ rowsPerPage, currentPage }) => {
    const response = await api
      .get('/ListaUsuarios-root', {
        params: {
          rowsPerPage,
          currentPage
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  userListAll: async ({ rowsPerPage, currentPage, eventId }) => {
    const response = await api
      .get('/ListaUsuarios', {
        params: {
          rowsPerPage,
          currentPage,
          eventId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  eventzzList: async ({
    rowsPerPage,
    page,
    searchTerm,
    dateFrom,
    dateTo,
    aberto,
    ativo,
    category,
    type,
    isFree
  }) => {
    const response = await api
      .get('/eventzz/list', {
        params: {
          rowsPerPage,
          page,
          searchTerm,
          dateFrom,
          dateTo,
          aberto,
          ativo,
          category,
          type,
          isFree
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  eventzzListAll: async () => {
    const response = await api
      .get('/eventzz/list-all')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  cuponsEventList: async ({ event_id, rowsPerPage, currentPage }) => {
    console.log('-----------')
    console.log(event_id)
    const response = await api
      .get('/list/cupons-event', {
        params: {
          eventId: event_id,
          rowsPerPage,
          currentPage
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },

  deleteCupomEvent: async ({ id }) => {
    console.log('-----------')
    console.log(id)

    const response = await api
      .delete(`/cupom/delete-cupom/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  }
}

export default Services
