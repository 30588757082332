import PropTypes from 'prop-types'
import React from 'react'

import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

// Import menuDropdown
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'

import logosmImg from '../../assets/images/eventozz/Logo_Nome_Branco.svg'

//i18n
import { withTranslation } from 'react-i18next'

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType
} from '../../store/actions'

const Header = () => {
  function tToggle() {
    const body = document.body
    if (window.screen.width <= 992) {
      body.classList.toggle('sidebar-enable')
    } else {
      body.classList.toggle('vertical-collpsed')
      body.classList.toggle('sidebar-enable')
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logosmImg} style={{ height: '60px' }} />
                </span>
                <span className="logo-lg">
                  <img src={logosmImg} style={{ height: '60px' }} />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logosmImg} style={{ height: '60px' }} />
                </span>
                <span className="logo-lg">
                  <img src={logosmImg} style={{ height: '60px' }} />
                </span>
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={() => {
                tToggle()
              }}
              data-target="#topnav-menu-content"
            >
              <i className="mdi mdi-menu"></i>
            </button>

            <div className="d-none d-sm-block"></div>
          </div>

          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType
})(withTranslation()(Header))
