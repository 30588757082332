import React, { useState } from 'react'
import DatatableTables from '../index.js'
import { Collapse } from 'reactstrap'
import { Tr, Td } from 'react-super-responsive-table'

import styles from './styles.module.scss'

export default function FinancialCollapse({
  open,
  row
}) {

  const columns = [
    { label: 'Id Compra', field: 'idCompra' },
    { label: 'Cliente', field: 'nomeCliente' },
    { label: 'Valor', field: 'valor' },
    { label: 'Liberação', field: 'data_liberacao' },
    { label: 'Nº da parcela', field: 'parcela' }
  ]

  // eslint-disable-next-line no-unused-vars
  const [tableConfig, setTableConfig] = useState({
    options: {
      pagination: false
    },
    config: {
    },
    total: 0,
    currentPage: 1,
    rowsPerPage: 10
  })


  return (
    <Tr>
      <Td
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={columns.length + 1 + (tableConfig?.options?.more ? 1 : 0)}
      >
        <Collapse
          isOpen={open}
          timeout="auto"
          unmountOnExit
          className={styles.tableContainer}
        >
          <DatatableTables
            rows={row.items || []}
            columns={columns}
            tableConfig={tableConfig}
            onChangePage={() => { }}
            onChangeRowsPerPage={() => { }}
            hideBackgroundHeader={true}
            layoutCollapse={true}
          />
        </Collapse>
      </Td>
    </Tr>
  )
}
