import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({ family: 'Sarabun', src: '/fonts/Sarabun-Bold.ttf' });
Font.register({ family: 'Sarabun-Regular', src: '/fonts/Sarabun-Medium.ttf' });


export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    position: 'relative'
  },
  img: {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      position: 'absolute'
  },
  background:  {
      top: 0,
      left: 0,
      width: '80%',
      marginLeft: '10%',
      height: '100%',
      position: 'absolute'
  },
  logo: {
      position: 'absolute',
      width: 80,
      top: 10
  },
  div: {
      flexDirection: "row",
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
  },
  titulo:{
      color: '#254452',
      fontFamily: 'Sarabun',
      fontWeight: 'bold',
      fontSize: 65,
      marginTop: 80,
      textAlign: 'center'
  },
  subtitle: {
      color: '#254452',
      fontWeight: 600,
      marginTop: -15,
      textAlign: 'center',
      fontSize: 20,
      fontFamily: 'Sarabun-Regular'
  },
  p: {
      color: '#254452',
      fontWeight: 500,
      marginTop: 20,
      textAlign: 'center',
      fontSize: 15
  },
  name: {
    color: '#254452',
    fontWeight: 500,
    textAlign: 'center',
    marginTop: 15,
    fontSize: 38
  },
  cpf: {
    color: '#254452',
    fontWeight: 300,
    textAlign: 'center',
    marginTop: 5,
    fontSize: 15
  },
  text: {
    color: '#254452',
    fontWeight: 300,
    textAlign: 'center',
    marginTop: 20,
    width: '80%',
    marginLeft: '10%'
  },
  data: {
    color: '#254452',
    fontWeight: 300,
    textAlign: 'center',
    width:'80%',
    marginTop: 10,
    marginLeft: '10%'
  },
  footerCertificado:{
    position: 'absolute',
    alignItems: 'center',
    textAlign: 'center',
    width: '80%',
    marginLeft: '10%',
    bottom: 0,
    backgroundColor: '#f2f2f500'
  },
  assign: {
    borderBottom: 'solid 1px #f2f2f500',
    marginLeft: '2.5%', 
    marginRight: '2.5%',
    width: '45%'
  },
  textFooter: {
    bottom: 5,
    color: '#516c78',
    fontWeight: 300,
    fontSize: 8,
    marginTop: 40
  },
  assignText: {
    color: '#516c78',
    fontSize: 12,
    marginBottom: 0,
    fontFamily: 'Sarabun-Regular',
    marginTop: 5
  },
  assignTextSub: {
    color: '#516c78',
    fontSize: 10,
    marginBottom: 0,
    marginTop: 2
  },
  line:{
    backgroundColor: '#516c78',
    height: 1,
    width: '100%'
  }
});