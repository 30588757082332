import React from 'react'
import { Plus, Minus } from 'react-feather'
import { formatCurrency } from '../../util/string'
import styles from './styles.module.css'
import {
  Container,
  Row,
  Col,
  Button,
  //Card,
  //CardBody,
  //Input,
  //Dropdown,
  // DropdownToggle,
  // DropdownItem,
  // DropdownMenu,
  FormGroup,
  Label
} from 'reactstrap'

export const CardTicket = ({ ticket, handleChangeTicketQuantity }) => {
  return (
    <Container xs={12} sm={10} md={6} lg={4} item className={styles.card}>
      <div className={styles.padding}>
        <h3>{ticket.name}</h3>
        <div className="price-box-ticket">
          {formatCurrency({ value: ticket.activeValue })}
        </div>
        <ul></ul>
        <Row className={styles.mtp3rem}>
          <Col xs={4}>
            <a
              style={{ fontSize: 40 }}
              className="btn btn-outline-danger"
              onClick={() => handleChangeTicketQuantity('minus', ticket.id)}
            >
              <span>
                <Minus width="1rem" height="1rem" />
              </span>
            </a>
          </Col>
          <Col xs={4} className={styles.mAuto}>
            <span id="quantidade_ingressos2">{ticket.currentQtd}</span>
          </Col>
          <Col xs={4}>
            <a
              style={{ fontSize: 40 }}
              className="btn btn-outline-success"
              onClick={() => handleChangeTicketQuantity('plus', ticket.id)}
            >
              <span>
                <Plus />
              </span>
            </a>
          </Col>
        </Row>
      </div>
    </Container>
  )
}
