import { api } from './axios'

const Services = {
  LoginNative: async (cpfEmail, senha) => {
    const response = await api
      .post('/signin', {
        cpfEmail,
        password: senha,
        organizer: true
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  checkPhoneIsWhatsApp: async (phone) => {
    const response = await api
      .get('/customer/check-phone-is-whatsapp', { params: { phone } })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  passwordRecovery: async ({ email }) => {
    const response = await api
      .post('/signin/recovery', {
        email
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },

  CreateLoginNative: async ({
    email,
    cpf,
    nomeCompleto,
    telefone,
    senha,
    nascimento,
    organizer
  }) => {
    console.log(nomeCompleto)
    const response = await api
      .post('/signup', {
        email,
        cpf,
        name: nomeCompleto,
        fone: telefone,
        nascimento,
        password: senha,
        organizer
      })
      .then(({ ...response }) => {
        console.log(response)
        return response
      })
      .catch(({ ...response }) => {
        console.log(response)
        return response
      })
    return response
  },
  getInfoUserDash: async () => {
    const response = await api
      .get('/get-user-dash')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  updateUserDashboard: async ({
    name,
    address_cep,
    address_city,
    address_district,
    address_number,
    address_state,
    pix,
    type_pix,
    address_street,
    cpf,
    fone,
    nascimento
  }) => {
    const response = await api
      .post('/user/update', {
        name,
        address_cep,
        address_city,
        address_district,
        address_number,
        address_state,
        pix,
        type_pix,
        address_street,
        cpf,
        fone,
        nascimento
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  checkIsRoot: async () => {
    const response = await api
      .get('/auth/check-user')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateUserPassword: async ({ password, newPassword }) => {
    const response = await api
      .post('/user/update-password', {
        password,
        newPassword
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
export default Services
