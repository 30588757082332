import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Card, FormGroup, Label } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import DatatableTables from '../../components/Table'
import { formatCurrency } from '../../util/string'
import Form from 'react-bootstrap/Form'
import { CardIndicador } from '../../components/CardIndicador'

///MODAIS
import Select from 'react-select'
import { organizerSelect, getFinancialSummary } from '../../store/actions'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
import { Loading } from 'components/Loading'
import { FinancialAdminCollapseComponent } from 'components/Table/FinancialAdminCollapse'

const columns = [
  { label: 'Id', field: 'id_evento' },
  { label: 'Evento', field: 'nome_evento' },
  { label: 'Organizador', field: 'name' },
  { label: 'Valor Bruto', field: 'formattedTotalAmount' },
  { label: 'Taxa do Evento', field: 'formattedFeeAmount' },
  { label: 'Valor Líquido', field: 'formattedOrganizerAmount' },
  { label: 'Taxa Pagarme', field: 'feePagarme' },
  { label: 'Taxa Extra', field: 'feeExtra' },
  { label: 'Lucro Real', field: 'lucro' },
  { label: 'Valor Repassado', field: 'transfer' },
  { label: 'Falta Passar', field: 'transferOpen' },
  { label: 'Valor Bruto Atividades', field: 'totalActivities', hide: true },
  { label: 'Taxa das Atividades', field: 'feeActivities', hide: true },
  {
    label: 'Valor Líquido Atividades',
    field: 'netValueActivities',
    hide: true
  },
  {
    label: 'Taxa Pagarme Atividades',
    field: 'feePagarmeActivities',
    hide: true
  },
  { label: 'Taxa Extra Atividades', field: 'extraFeeActivities', hide: true },
  { label: 'Lucro Real Atividades', field: 'profitActivities', hide: true },
  { label: 'Valor Bruto Ingresso', field: 'totalTickets', hide: true },
  { label: 'Taxa das Ingresso', field: 'feeTickets', hide: true },
  { label: 'Valor Líquido Ingresso', field: 'netValueTickets', hide: true },
  { label: 'Taxa Pagarme Ingresso', field: 'feePagarmeTickets', hide: true },
  { label: 'Taxa Extra Ingresso', field: 'extraFeeTickets', hide: true },
  { label: 'Lucro Real Ingresso', field: 'profitTickets', hide: true }
]

const FinanceiroAdmin = () => {
  const [loading, setLoading] = useState(false)
  const [filteredRows, setFilteredRows] = useState([])
  const [indicadores, setIndicadores] = useState({
    lucroRealTotal: 0,
    taxaPagarmeTotal: 0,
    taxaExtraTotal: 0,
    taxaTotal: 0,
    faltaRepassarTotal: 0
  })


  const [organizerList, setOrganizerList] = useState([])

  const [tableConfig, setTableConfig] = useState({
    options: {
      pagination: true,
      collapse: true
    },
    filters: {
      searchTerm: null,
      organizerId: null,
      restMoney: true
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 10
  })

  const handleClose = () => {
    setLoading(false)
  }

  const loadSummary = useCallback(async () => {
    const response = await getFinancialSummary({
      organizerId: tableConfig?.filters?.organizerId,
      restMoney: tableConfig?.filters?.restMoney
    })

    if (response.status === 200 && response?.data?.success) {
      const responseData = response?.data?.data
      const data = responseData?.items
      setIndicadores({
        lucroRealTotal: responseData?.lucroRealTotal,
        taxaPagarmeTotal: responseData?.taxaPagarmeTotal,
        taxaExtraTotal: responseData?.taxaExtraTotal,
        taxaTotal: responseData?.taxaTotal,
        faltaRepassarTotal: responseData?.faltaRepassarTotal
      })

      console.log({
        lucroRealTotal: responseData?.lucroRealTotal,
        taxaPagarmeTotal: responseData?.taxaPagarmeTotal,
        taxaExtraTotal: responseData?.taxaExtraTotal,
        taxaTotal: responseData?.taxaTotal,
        faltaRepassarTotal: responseData?.faltaRepassarTotal
      })

      const formattedData = data.map((item) => ({
        ...item,
        formattedTotalAmount: formatCurrency({
          value: item.valorTotal + item.valorBrutoAtividades
        }),
        formattedFeeAmount: formatCurrency({
          value: item.valorTaxa + item.taxaAtividades
        }),
        feePagarme: formatCurrency({
          value: item.taxaPagarme + item.taxaPagarmeAtividades
        }),
        feeExtra: formatCurrency({
          value: Number(item.taxaExtra) + Number(item.taxaExtraAtividades)
        }),
        lucro: formatCurrency({
          value: item.lucroReal + item.lucroRealAtividades
        }),
        transferOpen: item.faltaRepassar,
        transfer: formatCurrency({
          value: item.valorRepassado
        }),
        formattedOrganizerAmount: formatCurrency({
          value: item.valorLiquido + item.valorRestante
        }),

        totalActivities: formatCurrency({ value: item.valorBrutoAtividades }),
        feeActivities: formatCurrency({ value: item.taxaAtividades }),
        netValueActivities: formatCurrency({ value: item.valorLiquido }),
        feePagarmeActivities: formatCurrency({
          value: item.taxaPagarmeAtividades
        }),
        extraFeeActivities: formatCurrency({ value: item.taxaExtraAtividades }),
        profitActivities: formatCurrency({ value: item.lucroRealAtividades }),
        totalTickets: formatCurrency({ value: item.valorTotal }),
        feeTickets: formatCurrency({ value: item.valorTaxa }),
        netValueTickets: formatCurrency({ value: item.valorRestante }),
        feePagarmeTickets: formatCurrency({ value: item.taxaPagarme }),
        extraFeeTickets: formatCurrency({ value: item.taxaExtra }),
        profitTickets: formatCurrency({ value: item.lucroReal })
      }))

      setFilteredRows(formattedData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg || 'Ocorreu um erro ao tentar listar as vendas')
      setLoading(false)
      return false
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  const loadOrganizers = useCallback(async () => {
    const response = await organizerSelect()
    if (response.status === 200) {
      const responseData = response?.data
      setOrganizerList(responseData === undefined ? [] : responseData)
      setLoading(false)
      return true
    } else {
      toast.error(
        response?.msg || 'Ocorreu um erro ao tentar listar os organizadores'
      )
      setLoading(false)
      return false
    }
  }, [])

  function handleChangeOrganizer(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        organizerId: value?.value === 0 ? null : value?.value
      }
    }))
    setLoading(true)
  }

  useEffect(() => {
    loadSummary()
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  useEffect(() => {
    loadOrganizers()
  }, [])

  function handlerActive(checked) {
    setTableConfig((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        restMoney: checked ? true : null
      }
    }))
    setLoading(true)
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Financeiro Admin | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Financeiro Admin | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão financeira
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Card>
              <Row>
                <Col xl={12} md={12} style={{ padding: 20 }}>
                  <Row>
                    <CardIndicador
                        icon={'GRAPH'} xl={3} md={6} xs={6}
                        value={indicadores?.lucroRealTotal}
                        type={'money'} text={'Lucro Real'} color="primary"
                      />
                      <CardIndicador
                        icon={'LINE'} xl={3} md={6} xs={6}
                        value={indicadores?.taxaExtraTotal + indicadores?.taxaPagarmeTotal}
                        type={'money'} text={'Taxa'} color="primary"
                      />
                    <CardIndicador
                        icon={'GRAPH'} xl={3} md={6} xs={6}
                        value={indicadores?.faltaRepassarTotal}
                        type={'money'} text={'Repasse Aberto'} color="warning"
                      />
                  </Row>
                  <Row>
                    <Col xl={4} md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Organizador</Label>
                        <Select
                          value={
                            organizerList.find(
                              (a) =>
                                a.value === tableConfig?.filters?.organizerId
                            )
                              ? organizerList.find(
                                  (a) =>
                                    a.value ===
                                    tableConfig?.filters?.organizerId
                                )
                              : null
                          }
                          onChange={(value) => handleChangeOrganizer(value)}
                          options={organizerList}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={4}>
                      <Form style={{ marginTop: 30 }}>
                        <Form.Check
                          type="switch"
                          label="Repasse em Aberto"
                          id="custom-switch"
                          checked={Boolean(tableConfig?.filters?.restMoney)}
                          onChange={(event) =>
                            handlerActive(event.target.checked)
                          }
                        />
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <DatatableTables
                title={'Financeiro'}
                columns={columns}
                rows={filteredRows}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
                CollapseComponent={FinancialAdminCollapseComponent}
              />
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

FinanceiroAdmin.propTypes = {
  t: PropTypes.any
}

export default FinanceiroAdmin
