import Services from '../../services/vendas'
import { logoutUser } from '../../store/actions'

export const solicitaSaque = async (params) => {
  const response = await Services.solicitaSaque(params)
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: response?.response?.data?.msg || 'Ocorreu um erro ao solicitar Saque'
    }
  }
}

export const exportTickets = async (params) => {
  const response = await Services.exportTickets(params)
  console.log('=================')
  console.log(response)
  console.log('=================')

  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg: response?.response?.data?.msg || 'Ocorreu um erro ao exportar o PDF'
    }
  }
}

export const getFinancialSummary = async (params) => {
  const response = await Services.getFinancialSummary(params)
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar listar as vendas'
    }
  }
}

export const verify = async ({ cpf, eventId }) => {
  const response = await Services.verify({ cpf, eventId })
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar buscar o vendedor'
    }
  }
}

/* chama a função GET do back */
export const listFinancial = async ({
  rowsPerPage,
  page,
  startSaleDate,
  endSaleDate,
  eventId
}) => {
  const response = await Services.listFinancial({
    rowsPerPage,
    page,
    startSaleDate,
    endSaleDate,
    eventId
  })

  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar adicionar o vendedor'
    }
  }
}

/* função de futuras liberações */
export const listFutureFinancial = async ({ rowsPerPage, page, eventId }) => {
  const response = await Services.listFutureFinancial({
    rowsPerPage,
    page,
    eventId
  })

  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar adicionar o vendedor'
    }
  }
}

export const listFinancialEvent = async ({ rowsPerPage, page, eventId }) => {
  const response = await Services.listFinancialEvent({
    rowsPerPage,
    page,
    eventId
  })

  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar adicionar o vendedor'
    }
  }
}

export const create_cupom = async (cupom) => {
  const response = await Services.create_cupom(cupom)

  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar adicionar o cupom'
    }
  }
}

export const create = async ({ eventId, name, phone }) => {
  const response = await Services.create({
    create,
    eventId,
    name,
    phone
  })

  if (response?.status === 200 && response?.data?.success) {
    return {
      success: true,
      data: response?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar adicionar o vendedor'
    }
  }
}

export const atualizarUser = async (user) => {
  const response = await Services.atualizarUser(user)

  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar adicionar o cupom'
    }
  }
}

export const listSellersInEvent = async (eventId) => {
  const response = await Services.listSellersInEvent(eventId)

  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar listar os vendedores'
    }
  }
}

export const markAsUsed = async ({ eventId, code }) => {
  const response = await Services.markAsUsed({ eventId, code })

  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar atualizar o ingresso'
    }
  }
}

export const listOrders = async ({
  rowsPerPage,
  page,
  searchTerm,
  startSaleDate,
  endSaleDate,
  eventId,
  statusTransaction,
  sellerId,
  plataforma,
  incluirCortesias
}) => {
  const response = await Services.listOrders({
    rowsPerPage,
    page,
    searchTerm,
    startSaleDate,
    endSaleDate,
    eventId,
    statusTransaction,
    sellerId,
    plataforma,
    incluirCortesias
  })

  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else {
    return response
  }
}

export const listTickets = async ({
  rowsPerPage,
  page,
  searchTerm,
  eventId,
  status,
  ticketType
}) => {
  const response = await Services.listTickets({
    rowsPerPage,
    page,
    searchTerm,
    eventId,
    status,
    ticketType
  })
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data,
      isRoot: response.isRoot
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar listar as ingressos'
    }
  }
}

export const listUsed = async ({
  eventId,
  statusDescription,
  searchTerm,
  ticketType
}) => {
  const response = await Services.listUsed({
    eventId,
    statusDescription,
    searchTerm,
    ticketType
  })

  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
    }
  } else {
    return {
      status: 400,
      msg:
        response?.response?.data?.msg ||
        'Ocorreu um erro ao tentar listar as ingressos'
    }
  }
}

export const courtesySave = async ({ body }) => {
  const response = await Services.courtesySave({ body })
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: response?.data?.msg
        ? response.data.msg
        : 'Não foi possível salvar os ingressos'
    }
  } else {
    return {
      status: 400,
      msg: response?.data?.msg
        ? response.data.msg
        : 'Não foi possível salvar os ingressos'
    }
  }
}

export const getTickets = async (id) => {
  const response = await Services.getTickets(id)
  if (response?.status === 200 && response?.data?.success) {
    return {
      status: 200,
      msg: 'Sucesso',
      data: response?.data?.data
    }
  } else if (response.status === 401) {
    window.localStorage.removeItem('authUser')
    logoutUser()
  } else if (response.status === 500) {
    return {
      status: 500,
      msg: response?.data?.msg
        ? response.data.msg
        : 'Não foi possível pegar os ingressos'
    }
  } else {
    return {
      status: 400,
      msg: response?.data?.msg
        ? response.data.msg
        : 'Não foi possível pegar os ingressos'
    }
  }
}
