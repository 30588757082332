import React from 'react'
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap'

function TooltipBadge(props) {
  console.log(props.text)
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover id="popover-basic">
          <Popover.Header as="h3" style={{ color: '#000' }}>
            {props.title}
          </Popover.Header>
          <Popover.Body>
            <props.text />
          </Popover.Body>
        </Popover>
      }
    >
      <span className="d-inline-block">
        <Badge {...props}>{props?.value}</Badge>
      </span>
    </OverlayTrigger>
  )
}

export function CustomBadge({ text, value, title, showTooltip, ...rest }) {
  console.log(text)
  if (showTooltip) {
    return <TooltipBadge text={text} value={value} title={title} {...rest} />
  }
  return <Badge {...rest}>{value}</Badge>
}
