import React from 'react'
import { Card } from 'react-bootstrap'
import { Tr, Td } from 'react-super-responsive-table'

import { Collapse, Row, Col } from 'reactstrap'

import styles from './styles.module.scss'

export const CollapseActivity = ({ open, tableConfig, row, columns }) => {
  return (
    <Tr>
      <Td
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={columns.length + 1 + (tableConfig?.options?.more ? 1 : 0)}
      >
        <Collapse
          isOpen={open}
          timeout="auto"
          unmountOnExit
          className={styles.tableContainer}
        >
          <Col style={{ margin: 20 }}>
            <Row>
              <Col xl={6} md={6} xs={12}>
                <Card>
                  <Card.Body>
                    <p>
                      <b>Cabeçalho (Texto acima do preço)</b>
                    </p>
                    <div
                      dangerouslySetInnerHTML={{ __html: row?.texto_de_por }}
                    ></div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={6} md={6} xs={12}>
                <Card>
                  <Card.Body>
                    <p>
                      <b>Descrição (Texto abaixo do preço)</b>
                    </p>
                    <div
                      dangerouslySetInnerHTML={{ __html: row?.descricao }}
                    ></div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12}>
                <Card>
                  <Card.Body>
                    <p>
                      <b>
                        Descrição geral (Texto exibido abaixo do banner
                        principal)
                      </b>
                    </p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: row?.activitiesDescription
                      }}
                    ></div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Collapse>
      </Td>
    </Tr>
  )
}
