import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({ family: 'Sarabun', src: '/fonts/Sarabun-Bold.ttf' });
Font.register({ family: 'Sarabun-Regular', src: '/fonts/Sarabun-Medium.ttf' });


export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    position: 'relative',
    padding: 20
  },
  div: {
    width: '25%'
  },
  div2: {
    width: '75%'
  },
  background:  {
      borderColor: '#254452',
      borderWidth: 1,
      borderStyle: 'solid',
      width: '40%',
      marginLeft: '1%',
      marginRight: '1%',
      height: 80,
      maxHeight: 80,
      padding: 5,
      justifyContent: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      display: 'flex'
  },
  logo: {
      width: '95%'
  },
  titulo:{
      color: '#254452',
      fontFamily: 'Sarabun',
      fontWeight: 'bold',
      fontSize: 15,
      marginTop: 5,
      marginLeft: 2,
      textAlign: 'flex-start',
      justifyContent: 'flex-start',
      display: 'flex'
  },
  subtitle: {
      color: '#254452',
      fontWeight: 600,
      marginTop: 4,
      marginLeft: 2,
      textAlign: 'center',
      fontSize: 11,
      fontFamily: 'Sarabun-Regular',
      textAlign: 'flex-start',
      justifyContent: 'flex-start',
      display: 'flex'

  },
  label: {
      color: '#254452',
      justifyContent: 'flex-start',
      textAlign: 'flex-start',
      display: 'flex',
      fontWeight: 200,
      marginTop: 4,
      fontSize: 8,
      marginLeft: 2
  }
});