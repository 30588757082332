import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  FormFeedback,
  FormGroup,
  Input
} from 'reactstrap'
import { cpfMask, formatDate } from '../../util/string'
import Services from '../../services/auth'
// import Input from '../../components/Input'
import { toast } from 'react-toastify'
import {
  isValidEmail,
  isValidDate,
  isValidCpf,
  dateMask,
  onlyUnsignedNumbers,
  phoneMaskForList
} from '../../util/function'
// availity-reactstrap-validation
import { AvForm } from 'availity-reactstrap-validation'

// action
import { registerUser, apiError, registerUserFailed } from '../../store/actions'

// Redux
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

// import images
import logoSm from '../../assets/images/logos/Icon_Com_Fundo.svg'

const Register = (props) => {
  //criar data de nascimento
  const { checkPhoneIsWhatsApp, CreateLoginNative } = Services
  const [nomeCompleto, setNomeCompleto] = useState('')
  const [cpf, setCpf] = useState(null)
  const [email, setEmail] = useState('')
  const [dataNascimento, setDataNascimento] = useState(null)
  const [senha, setSenha] = useState(null)
  const [confirmeSenha, setConfirmeSenha] = useState(null)
  const [errorCpf, setErrorCpf] = useState('')
  const [errorEmail, setErrorEmail] = useState('')
  const [errorDataNascimento, setErrorDataNascimento] = useState('')
  const [errorSenha, setErrorSenha] = useState('')
  const [errorNome, setErrorNome] = useState('')
  const [errorTelefone, setErrorTelefone] = useState('')
  // telefone
  const [telefone, setTelefone] = useState(null)
  const [IsvalidWhatsApp, setIsValidWhatsApp] = useState('')
  const [loading, setLoading] = useState('')
  const [login, setLogin] = useState(false)

  // handleValidSubmit
  const handleValidSubmit = async (e, value) => {
    const response = await CreateLoginNative({
      email,
      cpf: onlyUnsignedNumbers(cpf),
      nomeCompleto,
      telefone: onlyUnsignedNumbers(telefone),
      senha,
      nascimento: formatDate(dataNascimento),
      organizer: true
    })

    if (response.status === 200) {
      if (response?.data?.token) {
        window.localStorage.setItem(
          'authUser',
          JSON.stringify({
            accessToken: response?.data?.token,
            root: false,
            displayName: nomeCompleto,
            email,
            uid: false,
            username: nomeCompleto
          })
        )
        window.localStorage.setItem('userName', nomeCompleto)
        setLogin(true)
      } else {
        toast.error('Falha no login')
        setLogin(false)
      }
    } else {
      console.log(response)
      toast.error(response?.response?.data?.msg)
      setLogin(false)
    }
  }
  // handlerTelefone

  const handlerNascimento = (e) => {
    console.log(e.target.value)
    setDataNascimento(dateMask(e.target.value))
    if (isValidDate(e.target.value) === true) {
      setErrorDataNascimento('')
    } else {
      console.log('data errada')
      setErrorDataNascimento('data errada')
    }
  }

  const handlerCpf = (e) => {
    //if tamanho menor de 14 e se for 14 se ele é valido (strings is valids)
    setCpf(cpfMask(e.target.value))
    console.log(e.target.value)

    if (e.target.value.length === 14) {
      if (isValidCpf(e.target.value) === true) {
        setErrorCpf('')
      } else {
        setErrorCpf('CPF invalido')
        console.log('CPF invalido')
      }
    } else {
      setErrorCpf('CPF incompleto')
      console.log('CPF incompleto')
    }
  }

  const handlerConfirmeSenha = (e) => {
    console.log(e.target.value)
    setConfirmeSenha(e.target.value)
    if (senha !== e.target.value) {
      setErrorSenha('Senha diferente')
      console.log('senha diferente')
    } else {
      setErrorSenha('')
      console.log('deu certo')
    }
  }
  const handlernome = (e) => {
    if (e.target.value === '') {
      console.log(e.target.value)
      setNomeCompleto(e.target.value)
      setErrorNome('Preencha o campo por favor')
    } else {
      setNomeCompleto(e.target.value)
      setErrorNome('')
    }
  }

  const handleremail = (e) => {
    console.log(e.target.value)
    setEmail(e.target.value)
    if (isValidEmail(e.target.value) === true) {
      setErrorEmail('')
    } else {
      console.log('email invalido')
      setErrorEmail('Email Invalido')
    }
  }

  const checkNumberPhone = useCallback(
    async (phoneNumber) => {
      const response = await checkPhoneIsWhatsApp(phoneNumber)
      console.log(response?.data?.data?.exists)
      // return;
      if (response.status === 200) {
        if (!response?.data?.data?.exists) {
          setIsValidWhatsApp(false)
          toast.error(
            'O número de telefone informado não é um número de whatsapp válido'
          )
        } else {
          setIsValidWhatsApp(true)
        }
        setLoading(false)
      } else if (response.status === 401) {
        toast.error('Você não está logado ou sua sessão expirou')
        setLoading(false)
        return false
      } else if (response.status === 500) {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
        )
        setLoading(false)
        return false
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao tentar verificar se o telefone possui WhatsApp'
        )
        setLoading(false)
        return false
      }
    },
    [setIsValidWhatsApp, checkPhoneIsWhatsApp, setLoading]
  )

  const handlerNumeroTelefone = async (e) => {
    let value = e.target.value
    if (onlyUnsignedNumbers(value)?.length === 11) {
      checkNumberPhone(onlyUnsignedNumbers(value))
      setErrorTelefone('')
      console.log('deu certo')
    } else {
      setIsValidWhatsApp(false)
      setErrorTelefone('número incorreto, verifique novamente por favor')
      console.log('deu ruim')
    }

    value = await phoneMaskForList(value)
    setTelefone(value)
  }
  useEffect(() => {
    props.apiError('')
  }, [])

  async function loadingNative() {}

  return login ? (
    <Redirect to="/dashboard" />
  ) : (
    <React.Fragment>
      <MetaTags>
        <title>Criar Conta | Eventozz</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">Criar Conta</h5>
                    <p className="text-white-50">
                      Criar conta em Eventozz de forma gratuita
                    </p>
                    <a href="index.html" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </a>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">
                    <AvForm
                      className="mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.user && props.user ? (
                        <Alert color="success">Conta criada com sucesso</Alert>
                      ) : null}

                      {props.registrationError && props.registrationError ? (
                        <Alert color="danger">{props.registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <FormGroup>
                          <label>Nome Completo</label>
                          <Input
                            id="nome"
                            name="nome"
                            placeHolder="Entrar com nome completo"
                            type="text"
                            value={nomeCompleto}
                            onChange={(e) => handlernome(e)}
                            invalid={errorNome}
                          />
                          <FormFeedback>{errorNome}</FormFeedback>

                          <label>CPF</label>
                          <Input
                            id="cpf"
                            name="cpf"
                            placeholder="Entrar com CPF"
                            maxLength={14}
                            value={cpf}
                            onChange={(e) => handlerCpf(e)}
                            invalid={!!errorCpf}
                          />
                          <FormFeedback>{errorCpf}</FormFeedback>

                          <label>Email</label>
                          <Input
                            id="email"
                            name="email"
                            placeholder="Entre com seu email valido"
                            type="text"
                            value={email}
                            onChange={(e) => handleremail(e)}
                            invalid={!!errorEmail}
                          />
                          <FormFeedback>{errorEmail}</FormFeedback>

                          <label>Telefone</label>
                          <Input
                            id="telefone"
                            name="telefone"
                            placeholder="Entrar com telefone"
                            inputMode="numeric"
                            value={telefone}
                            required
                            maxLength={15}
                            onChange={(e) => handlerNumeroTelefone(e)}
                            invalid={!!errorTelefone}
                          />
                          <FormFeedback>{errorTelefone}</FormFeedback>

                          <label>Data de Nascimento</label>
                          <Input
                            id="Data de nascimento"
                            inputMode="numeric"
                            placeholder="Entrar com data de nascimento"
                            value={dataNascimento}
                            maxLength={10}
                            onChange={(e) => handlerNascimento(e)}
                            invalid={!!errorDataNascimento}
                          />
                          <FormFeedback>{errorDataNascimento}</FormFeedback>

                          <label>Senha</label>
                          <Input
                            id="password"
                            type="password"
                            required
                            value={senha}
                            placeHolder="Entrar com a senha"
                            onChange={(e) => setSenha(e.target.value)}
                            invalid={!!errorSenha}
                          />

                          <label>Confirme Senha</label>
                          <Input
                            id="passwordConfirm"
                            type="password"
                            required
                            value={confirmeSenha}
                            placeHolder="Entrar com a senha"
                            onChange={(e) => handlerConfirmeSenha(e)}
                            invalid={!!errorSenha}
                          />
                          <FormFeedback>{errorSenha}</FormFeedback>
                        </FormGroup>
                      </div>

                      <div className="mb-3 row">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Criar conta
                          </button>
                        </div>
                      </div>

                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <p className="mb-0">
                            Criando a conta você concorda com{' '}
                            <Link to="/termo-de-uso" className="text-primary">
                              Termo de uso
                            </Link>
                          </p>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Você já possui uma conta ?{' '}
                  <Link to="/login" className="fw-medium text-primary">
                    {' '}
                    Login
                  </Link>{' '}
                </p>
                <p>
                  © {new Date().getFullYear()} Eventozz. Desenvolvido por Ctech
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed
})(Register)
