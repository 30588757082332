import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import './styles.css'
import { Container, Row, Col, Button, Card } from 'reactstrap'
import { Loading } from '../Loading'
import { financialServices } from '../../services/financial'

export const ModalTrashLancamento = ({
  showModal,
  onClose,
  idRow,
  loadDados
}) => {
  const { deleteFluxo } = financialServices

  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setLoading(false)
  }

  const handlerSubmit = async () => {
    setLoading(true)

    const response = await deleteFluxo({ id: idRow })
    console.log('===========')
    console.log(response)
    console.log('===========')

    if (response.status === 200 && response?.data?.success) {
      toast.success('Excluido com sucesso')
      setLoading(false)
      loadDados()
      onClose()
    } else if (response.status === 401) {
      toast.error('Você não está logado ou sua sessão expirou')
      setLoading(false)
      return false
    } else if (response.status === 500) {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
      )
      setLoading(false)
      return false
    } else {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro ao Excluir o lançamento'
      )
      setLoading(false)
      return false
    }
  }

  const handleCancelSendQrcodes = () => {
    onClose()
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
    >
      <Container>
        <Loading open={loading} handleClose={handleClose} />
        <Card className={'modalBody'}>
          <form>
            <Row className="modalText">
              <Col xs={12}>
                <h1 className={'titleRoot'}>Deseja Excluit o Lançamento?</h1>
              </Col>
            </Row>

            <Row className="footerModalButtons">
              <Col xs={6}>
                <Button
                  className="buttonsModal"
                  onClick={handlerSubmit}
                  variant="contained"
                  color="primary"
                >
                  Confirmar
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  className="buttonsModal"
                  onClick={handleCancelSendQrcodes}
                  variant="contained"
                  color="secondary"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </form>
        </Card>
      </Container>
    </ReactModal>
  )
}
