import React, { useCallback, useEffect, useState } from 'react'
import { formatCurrency, isValidEmail } from '../../util/string'
import { isValidCpf } from '../../util/function'
import ErrorImage from '../../assets/images/eventozz/checkout/erro.svg'
import SuccessImage from '../../assets/images/eventozz/checkout/success.svg'
import ReactModal from 'react-modal'
import { CardTicket } from './cardTicket.js'
import { InputsTicketInfo } from './inputsTicketInfo'
import styles from './styles.module.css'
import { Container, Row, Col, Button, Card, Input } from 'reactstrap'
import { toast } from 'react-toastify'
import { Loading } from '../Loading'
import { courtesySave, getTickets } from '../../store/actions'
import { X, ArrowRight } from 'react-feather'


export const ModalAddAfiliado = ({
  showModal,
  onSuccess,
  onCancel,
  eventId
}) => {
  const [loading, setLoading] = useState(false)
  const [idAfiliado, setIdAfiliado] = useState(null)

  const handleClose = () => {
    setLoading(false)
    onCancel()
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
    >
      <Container>
        <Loading open={loading} handleClose={handleClose} />
        <span className="closeButtom" onClick={handleClose}>
          <X />
        </span>
        
          <form style={{ padding: '10px' }}>
            <Row className="modalText">
              <Col xs={12}>
                <h1 className={'titleRoot'}>
                  Adicionar Indicação
                </h1>
              </Col>
            </Row>
                  <Row className={styles.marginBottom}>
                    <Col xs={12} item className={styles.dFlexAlign}>
                    <Input
                        type="number"
                        name="idAfiliado"
                        placeholder="Código do Afiliado"
                        id="idAfiliado"
                        value={idAfiliado}
                        onChange={(event) =>
                          setIdAfiliado(event.target.value)
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="footerModalButtons" style={{marginTop: 20}}>
          <Col xs={6}>
            <Button
              className="buttonsModal"
              onClick={() => onCancel()}
              variant="contained"
              color="secondary"
            >
              Cancelar
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              className="buttonsModal"
              onClick={() => onSuccess(eventId, idAfiliado)}
              variant="contained"
              color="primary"
            >
              Adicionar
            </Button>
          </Col>
        </Row>
          </form>
      </Container>
    </ReactModal>
  )
}
