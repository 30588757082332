import React, { useCallback, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { Loading } from '../../../Loading'
import 'react-toastify/dist/ReactToastify.css'
import DatatableTables from '../../index.js'
import { ticketsService } from 'services/tickets'
import { ModalConfirm } from 'components/modalConfirm'

export const TabSpecialTickets = ({ currentValue, row }) => {
  const { listSpecialTickets, changeTicketTypeStatus } = ticketsService

  const [isLoadingTickets, setIsLoadingTickets] = useState(false)
  const [textChangeText, setTextChangeText] = useState(null)
  const [titleChangeText, setTitleChangeText] = useState(null)
  const [currentRow, setCurrentRow] = useState(null)
  const [showModalChangeStatus, setShowModalChangeStatus] = useState(false)
  const [ticketList, setTicketList] = useState([])

  const loadSpecialTickets = useCallback(async () => {
    setIsLoadingTickets(true)

    const response = await listSpecialTickets({ eventId: row?.id })
    console.log(response)
    if (response.status === 200) {
      const data = response?.data?.data
      console.log(data)

      const formattedData = data?.map((item) => ({
        ...item,
        status: item?.status ? 1 : 0,
        createdAt: Intl.DateTimeFormat('pt-br', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(new Date(item.createdAt)),
        ticketPrice: Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(item.ticketPrice)
      }))
      console.log(formattedData)
      setTicketList(formattedData)
      setIsLoadingTickets(false)
    } else {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro ao tentar listar os tipos de ingresso'
      )
      setIsLoadingTickets(false)
      return false
    }
  }, [row])

  const handleClick = (rowSpecialTicket) => {
    const path = row.leaders ? 'leaders' : 'evento'

    console.log("+++++++++++++++++++++++")
    console.log({
      rowSpecialTicket,
      row
    })
    console.log(`LINK: ${process.env.REACT_APP_SITE_URL}${path}/${row?.slug}/?ingresso=${rowSpecialTicket?.ticketHash}`)
    console.log("+++++++++++++++++++++++")

    const link = `${process.env.REACT_APP_SITE_URL}${path}/${row?.slug}/?ingresso=${rowSpecialTicket?.ticketHash}`

    navigator.clipboard.writeText(link ? link : `${process.env.REACT_APP_SITE_URL}`)

    toast.success('O link do ingresso foi copiado')
  }

  function handleChangeTicketType(row) {
    console.log(row)
    setTitleChangeText('Confirmar alteração de status')
    setTextChangeText(
      `O ingresso <i>${row.name}</i> ficará ${
        row.status ? 'indisponível' : 'disponível'
      } para venda`
    )
    setShowModalChangeStatus(true)
    setCurrentRow(row)
  }

  function handleCancelChangeTicketType() {
    setShowModalChangeStatus(false)
    setTitleChangeText(null)
    setTextChangeText(null)
  }

  async function handleConfirmChangeTicketType() {
    const response = await changeTicketTypeStatus({
      specialTicketId: currentRow.id,
      status: currentRow.status === 0 ? 1 : 0
    })
    if (response?.data?.success) {
      toast.success(response?.data?.msg || 'Status atualizado')
      handleCancelChangeTicketType()
      loadSpecialTickets()
    } else {
      toast.error(response?.data?.msg || 'Não foi possível atualizar')
      handleCancelChangeTicketType()
    }
  }

  useEffect(() => {
    console.log(row)
    console.log('currentValue', currentValue)
    if (Number(currentValue) === 3) {
      loadSpecialTickets()
    }
  }, [currentValue])

  return (
    <>
      <ToastContainer />
      {isLoadingTickets ? (
        <Loading />
      ) : (
        <>
          <DatatableTables
            title={'Ingressos especiais'}
            columns={[
              { label: 'Id', field: 'id' },
              { label: 'Nome', field: 'name' },
              { label: 'Valor', field: 'ticketPrice' },
              { label: 'Qtd. Disponível', field: 'availableQtd' },
              { label: 'Tipo de ingresso', field: 'ticketName' },
              {
                label: 'Status',
                field: 'status',
                badge: true,
                info: {
                  0: { color: 'secondary', text: 'Inativo' },
                  1: { color: 'success', text: 'Ativo' }
                }
              },
              { label: 'Data de criação', field: 'createdAt' }
            ]}
            rows={ticketList}
            handleCopy={handleClick}
            onUpdateStatus={handleChangeTicketType}
            applyPadding={false}
            tableConfig={{
              options: {
                collapse: false,
                order: true,
                more: true,
                pagination: false,
                copy: true,
                changeStatus: true
              },
              config: {
                handleCopyText: 'Copiar link do ingresso',
                handleChangeText: {
                  0: 'Ativar ingresso',
                  1: 'Desativar ingresso'
                }
              }
            }}
          />
          <ModalConfirm
            onClose={handleCancelChangeTicketType}
            onConfirm={handleConfirmChangeTicketType}
            showModal={showModalChangeStatus}
            text={textChangeText}
            title={titleChangeText}
          />
        </>
      )}
    </>
  )
}
