import React, { useCallback, useEffect, useState } from 'react'
import { cpfMask, phoneMask } from '../../util/string'
import ReactModal from 'react-modal'
import {
  Form,
  FormGroup,
  Label,
  Container,
  Row,
  Col,
  Button,
  Input
} from 'reactstrap'
import { toast } from 'react-toastify'
import { Loading } from '../Loading'
import { onlyUnsignedNumbers } from '../../util/function'
import './styles.css'
import { checkPhoneIsWhatsApp, resendTicket } from '../../store/actions'
import { activityService } from 'services/activity'

export const ModalConfirmResendTicket = ({
  showModal,
  onClose,
  eventId,
  row,
  callback,
  type = 'ticket'
}) => {
  const [loading, setLoading] = useState(false)

  const { resendActivityQrcode } = activityService

  const [ticketData, setTicketData] = useState({
    cpf: null,
    name: null,
    phone: null,
    email: null
  })
  const [isValidWhatsApp, setIsValidWhatsApp] = useState(false)

  const checkNumberPhone = useCallback(
    async (phone) => {
      const response = await checkPhoneIsWhatsApp(phone)

      if (response.status === 200) {
        if (!response?.data) {
          setIsValidWhatsApp(false)
          toast.error(
            'O número de telefone informado não é um número de whatsapp válido'
          )
        } else {
          setIsValidWhatsApp(true)
        }
        setLoading(false)
      } else {
        toast.error(
          response?.msg ||
            'Ocorreu um erro ao tentar verificar se o telefone possui WhatsApp'
        )
        setLoading(false)
        return false
      }
    },
    [eventId, ticketData?.phone]
  )

  const handleChange = ({ value, field }) => {
    if (field === 'phone') {
      setIsValidWhatsApp(false)
    }
    if (field === 'cpf' && onlyUnsignedNumbers(value)?.length > 11) {
      return
    }
    if (field === 'phone' && onlyUnsignedNumbers(value)?.length === 11) {
      checkNumberPhone(value)
    }
    if (field === 'phone' && onlyUnsignedNumbers(value)?.length > 11) {
      return
    }
    setTicketData((prev) => ({
      ...prev,
      [field]: value
    }))
  }

  const handleClose = () => {
    setLoading(false)
  }

  const clearFields = () => {
    setTicketData({
      cpf: null,
      name: null,
      phone: null,
      email: null
    })
  }

  const handleSubmit = async () => {
    setLoading(true)

    let response = null

    if (type === 'ticket') {
      response = await resendTicket({
        eventId,
        ticketId: row?.id,
        ticketData
      })
    } else if (type === 'activity') {
      response = await resendActivityQrcode({
        eventId,
        activityId: row?.id,
        ticketData
      })
    }
    if (response.status === 200) {
      toast.success(response?.msg ? response?.msg : 'Qr code enviado')
      onClose()
      clearFields()
      setLoading(false)
      callback()
    } else {
      toast.error(response?.msg || 'Ocorreu um erro ao tentar enviar o qr code')
      setLoading(false)
      return false
    }
  }

  const handleCancelAddSeller = () => {
    clearFields()
    onClose()
  }

  useEffect(() => {
    if (row) {
      setTicketData({
        name: row?.customerName,
        cpf: onlyUnsignedNumbers(row?.customerCPF),
        phone: onlyUnsignedNumbers(row?.customerPhoneNumber),
        email: row?.customerEmail
      })
      checkNumberPhone(onlyUnsignedNumbers(row?.customerPhoneNumber))
    }
  }, [row])

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container>
        <Loading open={loading} handleClose={handleClose} />
          <Form>
            <Row className="modalText">
              <Col xs={12} justifyContent="flex-start" item>
                <h1 className={'titleRoot'}>
                  Confirme os dados do ingresso antes de enviar
                </h1>
              </Col>
            </Row>
            <Col className="mb40">
              <Row>
                <Col item xs={12} md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Telefone</Label>
                    <Input
                      type="text"
                      name="phone"
                      id="phone"
                      value={phoneMask(ticketData.phone)}
                      onChange={(event) =>
                        handleChange({
                          value: onlyUnsignedNumbers(event.target.value),
                          field: 'phone'
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col item xs={12} md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Nome</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      value={ticketData.name}
                      onChange={(event) =>
                        handleChange({
                          value: event.target.value,
                          field: 'name'
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col item xs={12} md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={ticketData.email}
                      onChange={(event) =>
                        handleChange({
                          value: event.target.value,
                          field: 'email'
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col item xs={12} md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">CPF</Label>
                    <Input
                      type="text"
                      name="cpf"
                      id="cpf"
                      value={cpfMask(ticketData.cpf)}
                      onChange={(event) =>
                        handleChange({
                          value: onlyUnsignedNumbers(event.target.value),
                          field: 'cpf'
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Row className="footerModalButtons">
              <Col xs={6}>
                <Button
                  disabled={!isValidWhatsApp}
                  className="buttonsModal"
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  Enviar qrcode
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  className="buttonsModal"
                  onClick={handleCancelAddSeller}
                  variant="contained"
                  color="secondary"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
      </Container>
    </ReactModal>
  )
}
