import { ActivitySettings } from 'components/atividades/settings'
import React from 'react'
import { MetaTags } from 'react-meta-tags'

import { Container, Row } from 'reactstrap'

export const ConfiguracaoAtividades = ({ match }) => {
  const { eventId } = match?.params

  return (
    <Container>
      <MetaTags>
        <title>Configuração de atividade | Eventozz</title>
      </MetaTags>
      <Row item xs={12}>
        <ActivitySettings eventId={eventId} />
      </Row>
    </Container>
  )
}
