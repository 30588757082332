import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

// //Import Scrollbar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { withRouter, Link } from 'react-router-dom'
import './styles.css'

import { withTranslation } from 'react-i18next'
const obj = JSON.parse(localStorage.getItem('authUser'))

const SidebarContent = (props) => {
  const ref = useRef()

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add('active')
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show')
    }

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show') // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add('mm-show') // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add('mm-show') // li
              parent5.childNodes[0].classList.add('mm-active') // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu('#side-menu')
      let matchingMenuItem = null
      const ul = document.getElementById('side-menu')
      const items = ul.getElementsByTagName('a')
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect">
                <span>{props.t('Dashboard')}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-success float-end">
                  +
                </span>
                <span>{props.t('Eventozz')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/eventozz" className="waves-effect">
                    <span className="submenu">{props.t('Meus Eventos')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/extra-eventozz" className="waves-effect">
                    <span className="submenu">{props.t('Dados Extras')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/cupons-eventozz" className="waves-effect">
                    <span className="submenu">
                      {props.t('Cupom de Desconto')}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/sorteio" className="waves-effect">
                    <span className="submenu">{props.t('Sorteio')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/atividades-do-evento" className="waves-effect">
                    <span className="submenu">
                      {props.t('Atividades do Evento')}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/certificados" className="waves-effect">
                    <span className="submenu">{props.t('Certificados')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/etiqueta" className="waves-effect">
                    <span className="submenu">{props.t('Etiqueta')}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-success float-end">
                  +
                </span>
                <span>{props.t('Vendas')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/vendas" className="waves-effect">
                    <span className="submenu">
                      {props.t('Dados de Vendas')}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/vendas-atividades" className="waves-effect">
                    <span className="submenu">
                      {props.t('Atividades do Evento')}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/ingressos" className="waves-effect">
                    <span className="submenu">{props.t('Ingressos')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/pdv" className="waves-effect">
                    <span className="submenu">{props.t('PDV')}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-success float-end">
                  +
                </span>
                <span>{props.t('Financeiro')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/financeiro" className="waves-effect">
                    <span className="submenu">
                      {props.t('Gestão dos Eventozz')}
                    </span>
                  </Link>
                </li>
                {obj?.root ? (
                  <li>
                    <Link to="/financeiro-root" className="waves-effect">
                      <i className="ti-lock"></i>
                      <span className="submenu">{props.t('Resumo')}</span>
                    </Link>
                  </li>
                ) : null}
                <li>
                  <Link to="/gestao-de-fluxo" className="waves-effect">
                    <span className="submenu">
                      {props.t('Gestão de Fluxo')}
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              {obj?.root ? (
                <>
                  <li>
                    <Link to="/#" className="waves-effect">
                      <i className="ti-lock"></i>
                      <span className="badge rounded-pill bg-success float-end">
                        +
                      </span>
                      <span>{props.t('Usuário')}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li>
                        <Link to="/dash-usuario" className="waves-effect">
                          <i className="ti-lock"></i>
                          <span className="submenu">
                            {props.t('Análise Usuários')}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/ListaUsuarios-root" className="waves-effect">
                          <i className="ti-lock"></i>
                          <span className="submenu">
                            {props.t('Lista de Usuários')}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/userGeral" className="waves-effect">
                          <span className="submenu">
                            {props.t('Usuários do sistema')}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </>
              ) : null}
            </li>
            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-success float-end">
                  +
                </span>
                <span>{props.t('Afiliados')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/painel-afiliado" className="waves-effect">
                    <span className="submenu">
                      {props.t('Painel do Afiliado')}
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SidebarContent))
