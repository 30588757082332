import { api } from './axios'

export default {
  create: async ({ name, fone, obs }) => {
    const response = await api
      .post('/customer/create', { name, fone, obs })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  list: async () => {
    const response = await api
      .get('/customer/list')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  checkPhoneIsWhatsApp: async (phone) => {
    const response = await api
      .get('/customer/check-phone-is-whatsapp', { params: { phone } })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
