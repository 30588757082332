import React from 'react'
//@ts-ignore
import styles from './styles.module.scss'
import { Button } from 'reactstrap'

interface TableHeaderProps {
  title: string
  onClick: () => Promise<boolean>
}

export const TableHeader = ({ title, onClick }: TableHeaderProps) => {
  return (
    <div className={styles.tableHeaderWithdraw}>
      <span>{title}</span>
      <Button
        variant="outlined"
        color="success"
        onClick={() => onClick()}
      >
        Exportar xlsx
      </Button>
    </div>
  )
}
