/* eslint-disable react/jsx-key */
/* eslint-disable array-bracket-newline */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap'
import classnames from 'classnames'

import { ModalPatrocinadores } from '../../components/ModalPatrocinadores'
import { ModaConfirmTrashPatrocinador } from '../../components/modaConfirmTrashPatrocinador'

import { ToastContainer, toast } from 'react-toastify'
///MODAIS
import Select from 'react-select'
import {
  eventzzSelect
} from '../../store/actions'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
import servicesIcon1 from '../../assets/images/services-icon/01.png'
import { Loading } from 'components/Loading'
import eventzz from '../../services/eventzz'
import { setWeekYear } from 'date-fns/esm'

const Extra = () => {
  const { listPatrocinadores, deletePatrocinador, deletePalestrante, listPalestrante } = eventzz

  const [loading, setLoading] = useState(false)
  const [ticketsTypeResume, setTicketsTypeResume] = useState(null)
  const [eventzzList, setEventzzList] = useState([])
  const [toggle, setToggle] = useState('1')
  const [patrocinadores, setPatrocinadores] = useState([])
  const [palestrantes, setPalestrantes] = useState([])

  const [modalPatrocinadores, setModalPatrocinadores] = useState(false)
  const [modalPatrocinadoresTrash, setModalPatrocinadoresTrash] = useState(false)
  const [idPatrocinador, setIdPatrocinador] = useState(null)
  const [info, setInfo] = useState(null)


  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      tickets: true,
      more: localStorage.getItem('root@session'),
      pagination: true,
      markAsUsed: true
    },
    config: {
      markAsUsedText: 'Confirmar utilização'
    },
    filters: {
      searchTerm: null,
      status: 'paid',
      eventId: null,
      ticketType: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const handleChange = useCallback(
    (newValue = '1') => {
      setToggle(newValue)
    },
    [tableConfig?.filters?.eventId]
  )


  const handleClose = () => {
    setLoading(false)
  }

  function handleChangeEvent(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        eventId: Number(value?.value) === 0 ? null : value?.value
      }
    }))
    setLoading(true)
  }

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()
    if (response.status === 200) {
      const responseData = response?.data
      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])


  const loadDados = async () => {
    setLoading(true);
    const promisseResult = await Promise.all([
      listPatrocinadores({eventId: tableConfig?.filters?.eventId}),
      listPalestrante({eventId: tableConfig?.filters?.eventId})
    ]).then(async response => {
      const result = response[0];
      const result2 = response[1];
      console.log("==================")
      console.log(result?.data?.data?.items)
      console.log(result2?.data?.data?.items)

      if (result?.status === 200) {
        setPatrocinadores(result?.data?.data?.items)
      } else {
        toast.error(result?.msg)
      }

      if (result2?.status === 200) {
        setPalestrantes(result2?.data?.data?.items)
      } else {
        toast.error(result2?.msg)
      }

      setLoading(false)
      return true

    })
  }

  useEffect(() => {
    if (tableConfig?.filters?.eventId) {
      loadDados();
    }
  }, [tableConfig?.filters?.eventId])


  useEffect(() => {
    loadEventzz()
  }, [])

  const handlerAddPatrocinador = (text) => {
 
    setInfo(text)

    if (tableConfig?.filters?.eventId) {
      setModalPatrocinadores(true)
    } else {
      toast.error("Selecione um Evento");
    }
  }

  const onCloseModal = () => {
    setModalPatrocinadores(false)
    setModalPatrocinadoresTrash(false)
  }

  const trashPatrocinador = (item, key) => {
    console.log(item)
    item = {
      ...item,
      key
    }
    setIdPatrocinador(item)
    setModalPatrocinadoresTrash(true)
  }

  const trashDados = async (id) => {
    console.log(id)
    if(id.key==='patrocinador'){
      var result = await deletePatrocinador({id: id?.id})
    }else{
      var result = await deletePalestrante({id: id?.id})
    }

    if (result.status === 200) {
      console.log("+==================")
      console.log(result)
      toast('Deletado com Sucesso', {
        autoClose: 10000
      })
      loadDados();
      return true
    } else {
      toast.error(result?.msg)
      setLoading(false)
      return false
    }
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Dados Extra | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dados Extra | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Dados Extra
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Card style={{padding:20}}>
           
                <Col xl={10} md={10}>
                  <Row>
                    {ticketsTypeResume &&
                      ticketsTypeResume?.length > 0 &&
                      ticketsTypeResume.map((item, index) => (
                        <Col xl={3} md={3} key={`${index}_key`}>
                          <Card className="mini-stat bg-primary text-white">
                            <CardBody>
                              <div className="mb-4">
                                <div className="float-start mini-stat-img me-4">
                                  <img src={servicesIcon1} alt="" />
                                </div>
                                <h5 className="font-size-12 text-uppercase mt-0 text-white-50">
                                  {item.name}
                                </h5>
                                <h4 className="fw-medium font-size-20">
                                  <b>{item.totalUsed}</b> de {item.quantity}
                                </h4>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                  <Row>
                    <Col xl={3} md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Eventos</Label>
                        <Select
                          value={
                            eventzzList.find(
                              (a) => a.value === tableConfig?.filters?.eventId
                            )
                              ? eventzzList.find(
                                  (a) =>
                                    a.value === tableConfig?.filters?.eventId
                                )
                              : null
                          }
                          onChange={(value) => handleChangeEvent(value)}
                          options={eventzzList}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{padding:20}}>

                    <Nav tabs style={{ justifyContent: 'center' }}>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: toggle === '1' })}
                          onClick={() => {
                            handleChange('1')
                          }}
                        >
                          Patrocinadores/Parceiros
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: toggle === '2' })}
                          onClick={() => {
                            handleChange('2')
                          }}
                        >
                          Palestrantes
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={toggle}>
                      <TabPane tabId="1">
                        <Row>
                        <Col xl={12} md={12} className="text-end" style={{justifyContent: 'flex-end', display: 'flex'}}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handlerAddPatrocinador('patrocinador')}
                            style={{ marginTop: '30px' }}
                          >
                            + Novo Patrocinador/Parceiro
                          </Button>
                        </Col>
                        </Row>
                       
                          {patrocinadores?.length === 0 ? (
                            <span>Esse Evento não possui Patrocinador/Parceiro</span>
                            ) : (
                              <Row style={{marginTop: 20}}>
                                {
                                  patrocinadores.map((item, index) => {
                                    return (
                                      <Col xl={4} md={4} >
                                        <div className="boxPatrocinador">
                                          <i className="ti-close" onClick={()=> trashPatrocinador(item, 'patrocinador')}></i>
                                          <img src={item.foto} alt="" style={{ 
                                            maxWidth:'100%',
                                            borderRadius:'10px 10px 0px 0px'
                                          }} />
                                          <p>{item.name}</p>
                                          <p>{item.instituicao}</p>
                                          <p dangerouslySetInnerHTML={{ __html: item.descricao }} />
                                        </div>
                                      </Col>
                                    )
                                  })
                                }
                                </Row>
                            )
                          }
                      </TabPane>
                      <TabPane tabId="2">
                      <Row>
                        <Col xl={12} md={12} className="text-end" style={{justifyContent: 'flex-end', display: 'flex'}}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handlerAddPatrocinador('palestrante')}
                            style={{ marginTop: '30px' }}
                          >
                            + Novo Palestrantes
                          </Button>
                        </Col>
                        </Row>
                        <Row>
                        {palestrantes?.length === 0 ? (
                            <span>Esse Evento não possui Palestrantes</span>
                            ) : (
                              <Row style={{marginTop: 20}}>
                                {
                                  palestrantes.map((item, index) => {
                                    return (
                                      <Col xl={4} md={4} >
                                        <div className="boxPatrocinador">
                                          <i className="ti-close" onClick={()=> trashPatrocinador(item, 'palestrante')}></i>
                                          <img src={item.foto} alt="" style={{ 
                                            maxWidth:'100%',
                                            borderRadius:'10px 10px 0px 0px'
                                          }} />
                                          <p>{item.name}</p>
                                          <p>{item.instituicao}</p>
                                          <p dangerouslySetInnerHTML={{ __html: item.descricao }} />
                                        </div>
                                      </Col>
                                    )
                                  })
                                }
                                </Row>
                            )
                          }
                        </Row>
                      </TabPane>
                    </TabContent>
                  </Row>
                </Col>
            </Card>
          </Row>
        </Container>
        <ModalPatrocinadores
          onClose={onCloseModal}
          eventId={tableConfig?.filters?.eventId}
          showModal={modalPatrocinadores}
          reload={loadDados}
          setLoading={setLoading}
          info={info}
        />
        <ModaConfirmTrashPatrocinador
          onClose={onCloseModal}
          id={idPatrocinador}
          showModal={modalPatrocinadoresTrash}
          setLoading={setLoading}
          loading={loading}
          callback={trashDados}
        />
      </div>
    </React.Fragment>
  )
}

Extra.propTypes = {
  t: PropTypes.any
}

export default Extra
