import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Label,
  Button,
  Input
} from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import { Loading } from '../../../components/Loading'
///MODAIS
import Select from 'react-select'
import { eventzzSelect } from '../../../store/actions'
import '../styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import styles from './styles.module.scss'
import { ptBR } from 'date-fns/locale'
import { CurrencyInput } from 'components/CurrencyInput'
import { financialServices } from 'services/financial'
import { Redirect } from 'react-router-dom'
import {
  Plus,
  Trash
} from 'react-feather'
registerLocale('pt-BR', ptBR)

const transferTypeOptions = [
  { value: 1, label: 'Entrada' },
  { value: 0, label: 'Saida' }
]
export const NovoFluxo = (props) => {
  console.log(props)

  const { createFluxo } = financialServices

  const [tipo, setTipo] = useState(1)
  const [data, setData] = useState(null)
  const [dataInfo, setDataInfo] = useState(null)
  const [valor, setValor] = useState(null)
  const [desc, setDesc] = useState('')

  const [loading, setLoading] = useState(false)
  const [eventzzList, setEventzzList] = useState([])

  const [showErrors, setShowErrors] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [transferValue, setTransferValue] = useState(null)

  const [dados, setDados] = useState([])


  const handleClose = () => {
    setLoading(false)
  }

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()
 
    if (response.status === 200) {
      const responseData = response?.data

      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])



  function handleSetTransferValue(value) {
    console.log(value)
    setValor(value)
  }



  function handleGoBack() {
    window.location.href = '/gestao-de-fluxo'
  }

  async function handleSubmit() {

    setLoading(true)

    const response = await createFluxo({dados, eventId: props.match.params.eventId});
    console.log("===========")
    console.log(response)
    console.log("===========")

    if (response.status === 200 && response?.data?.success) {
      toast.success('As alterações foram salvas')
      setLoading(false)
      setTimeout(() => {
        handleGoBack()
      }, 1500)
    } else if (response.status === 401) {
      toast.error('Você não está logado ou sua sessão expirou')
      setLoading(false)
      return false
    } else if (response.status === 500) {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
      )
      setLoading(false)
      return false
    } else {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro ao salvar o lançamento'
      )
      setLoading(false)
      return false
    }
  }

  function handleChangeTransferDate(date) {
    console.log(date)
    const formattedDate = new Intl.DateTimeFormat('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(date)
    
    console.log(date)
    console.log(formattedDate)

    setData(formattedDate)
    setDataInfo(date)
  }

  useEffect(() => {
    loadEventzz()
  }, [])

  const handlerDados = async () => {

    let check = true;
    let msg = '';
    if (data === null || data === undefined) { msg = "Data é obrigatorio"; check = false }
    if (valor === null || valor === undefined) { msg = "Valor é obrigatorio"; check = false }
    if (desc === null || desc === undefined) { msg = "Descrição é obrigatorio"; check = false }

    if (check) {
      const info = [...dados];
      info.push({
        tipo,
        data,
        valor,
        desc
      })
      setDados(info)
      console.log(info)
    } else {
      toast.error(msg)
    }
 
  }

  const removeFluxo = async (i) => {
    
    const info = [...dados];
    let infoTemp = [];

    info.map((item, index) => {
      if (index !== i) {
        infoTemp.push(item);
      }
    })

    setDados(infoTemp)
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Financeiro | Novo Lançamento</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Financeiro | Novo Lançamento</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Informe um novo lançamento
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Card className={`${styles.p200} pt-2`}>
            <Row style={{margin:20}}>
               <Col md={12} className="m-auto">
                    <Row>
                      <Col xs={12} md={6} xl={2}>
                        <FormGroup>
                          <Label>Tipo</Label>
                          <Select
                            value={
                              transferTypeOptions.find(
                                (a) => a.value === tipo
                              )
                                ? transferTypeOptions.find(
                                    (a) => a.value === tipo
                                  )
                                : null
                            }
                            onChange={(value) => setTipo(value.value)}
                            options={transferTypeOptions}
                            classNamePrefix="select2-selection"
                            placeholder="Selecione..."
                          />
                          {showErrors && !tipo && (
                            <small className="invalid-feedback d-block">
                              *Campo obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6} xl={2}>
                        <FormGroup>
                          <Label>Data*</Label>
                          <ReactDatePicker
                            className="form-control"
                            selected={dataInfo}
                            onChange={(date) => handleChangeTransferDate(date)}
                            placeholderText="dd/mm/aaaa"
                            dateFormat="dd/MM/yyyy"
                            locale="pt-BR"
                          />
                          {showErrors && !dataInfo && (
                            <small className="invalid-feedback d-block">
                              *Campo obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6} xl={3}>
                        <CurrencyInput
                          variant="outlined"
                          label="Valor *"
                          value={valor}
                          handleChange={handleSetTransferValue}
                        />
                        {showErrors && !valor && (
                          <small className="invalid-feedback d-block">
                            *Campo obrigatório
                          </small>
                        )}
                      </Col>
                      <Col xs={12} md={6} xl={4}>
                        <Label>Descrição</Label>
                        <Input
                          value={desc}
                          onChange={(event) =>
                            setDesc(event.target.value)
                          }
                        />
                      </Col>

                    <Col xs={12} md={6} xl={1} style={{cursor:'pointer', justifyContent: 'center', alignItems:'center', display:'flex'}}>
                     <Plus onClick={() => handlerDados()} size={25} />
                      </Col>
                    </Row>
                  </Col>
                </Row>   
                <Row>
            {dados.map((item, index) => {
                return (
                // eslint-disable-next-line react/jsx-key
                <Col xs={4} md={4} xl={4}>
                  <Card className={item?.tipo === 1 ? (styles.cardFluxoIn) : (styles.cardFluxoOut)}>
                    <Row>
                      <div style={{display: 'flex', justifyContent: 'space-between', marginBottom:10}}>
                        <span>{index + 1}</span>
                        <Trash onClick={() => removeFluxo(index)} />
                      </div>
                    </Row>
                    <Row>
                        <Col xs={6} md={6} xl={6}>
                          <Label><b>Data:</b> {item?.data}</Label>
                        </Col>
                        <Col xs={6} md={6} xl={6}>
                          <Label><b>Valor:</b> {item?.valor}</Label>
                        </Col>
                        <Col xs={12} md={12} xl={12}>
                          <Label><b>Descrição:</b> {item?.desc}</Label>
                        </Col>
                    </Row>
                  </Card>
                </Col>
                )
            })}
            </Row>  
               <Row className="pt-4 justify-content-end">
                      <Col xs={12} md={4} className="d-flex justify-content-end">
                        <Button
                          variant="outlined"
                          type="button"
                          color="danger"
                          onClick={() => handleGoBack()}
                          style={{ marginTop: '30px' }}
                        >
                          Voltar
                        </Button>
                        <Button
                          className="mx-3"
                          variant="outlined"
                          type="button"
                          color="primary"
                          onClick={() => handleSubmit()}
                          style={{ marginTop: '30px' }}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
