/* eslint-disable no-confusing-arrow */
import styled from 'styled-components'

export const DraggableContainer = styled.div`
  width: 540px;
  height: 540px;
  background-image: url(${(props) => props?.qrCodeBackground});
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
`

export const DraggableHelper = styled.span`
  width: ${(props) => props?.width}px;
  height: ${(props) => props?.height}px;
  position: absolute;
`

export const QrcodeImg = styled.img`
  width: ${(props) => props?.width}px;
  height: ${(props) => props?.height}px;
`

export const CustomToolbarOption = styled.span`
  border: 1px solid #f1f1f1;
  padding: 5px;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
`

export const CustomToolbarOptionsTitle = styled.div`
  padding: 5px;
`

export const CustomToolbarOptionsContainer = styled.div`
  margin-bottom: 10px;
`

export const StyledThumb = styled.div`
  height: 50px;
  line-height: 50px;
  width: 50px;
  text-align: center;
  background-color: #2c0874;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  /* padding: 5px; */
`
export const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  // eslint-disable-next-line no-confusing-arrow
  background: ${(props) =>
    props.index === 2 ? '#f00' : props.index === 1 ? '#ddd' : '#0f0'};
  border-radius: 999px;
  height: 5px;
  top: 25px;
`
