import React, { useState } from 'react'
import ReactModal from 'react-modal'
import {
  FormGroup,
  Label,
  Container,
  Row,
  Col,
  Button,
  FormFeedback,
  Card,
  Input
} from 'reactstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { create_cupom } from '../../store/actions'
import { Loading } from '../Loading'
import styles from './styles.module.css'

export const ModalAddCupomToEvent = ({
  showModal,
  onSuccess,
  onCancel,
  eventId
}) => {
  console.log('-----------------')
  console.log(eventId)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [newCupom, setNewCupom] = useState({
    id_evento: eventId,
    nome_cortesia: null,
    tipo_desconto: null,
    valor_cortesia: null,
    quantidade_disponivel: null,
    expiration: null
  })
  const [statusDesconto, setStatusDesconto] = useState([
    { label: 'Porcentagem', value: 'porcentagem' },
    { label: 'Real', value: 'real' }
  ])
  const [cupomVerified, setCupomVerified] = useState(false)

  const handleChange = ({ value, field }) => {
    console.log({ value, field })
    setNewCupom((prev) => ({
      ...prev,
      [field]: value
    }))
    if (value !== null || value !== undefined) {
      setError('')
      console.log(value, 'deu certo')
    } else {
      setError('O campo está nulo')
      console.log('O campos está nulo')
    }
  }

  const handleClose = () => {
    setLoading(false)
  }
  const clearFields = () => {
    setNewCupom({
      nome_cortesia: null,
      tipo_desconto: null,
      valor_cortesia: null,
      quantidade_disponivel: null,
      expiration: null
    })
    setCupomVerified(false)
  }

  const handleSubmitAddCupom = async () => {
    setLoading(true)
    console.log({
      eventId,
      nome_cortesia: newCupom.nome_cortesia,
      quantidade_disponivel: newCupom.quantidade_disponivel,
      tipo_desconto: newCupom.tipo_desconto,
      expiration: newCupom.expiration
    })

    if (
      (eventId ||
        newCupom.nome_cortesia ||
        newCupom.quantidade_disponivel ||
        newCupom.tipo_desconto ||
        newCupom.expiration) === null
    ) {
      toast.error('Os campos não estão preenchidos')
      setLoading(false)

      return false
    } else {
      let payload = {
        ...newCupom,
        id_evento: eventId
      }
      console.log(payload)
      const response = await create_cupom(payload)
      if (response.status === 200) {
        toast.success(response?.msg ? response?.msg : 'Cupom Adicionado')
        onSuccess()
        clearFields()
        setLoading(false)
      } else {
        console.log('=============')
        toast.error(
          response?.msg || 'Ocorreu um erro ao tentar adicionar o cupom'
        )
        setLoading(false)
        return false
      }
    }
  }

  const handleCancelAddCupom = () => {
    onCancel()
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
    >
      <Container>
        <Loading open={loading} handleClose={handleClose} />

        <Row className="modalText">
          <Col xs={12}>
            <h1 className={'titleRoot'}>Adicionar Cupom do Evento</h1>
          </Col>
        </Row>
        <Row>
          <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
            <FormGroup>
              <Label>Nome do Cupom</Label>
              <Input
                type="text"
                name="nomeCortesia"
                placeholder="Nome do cupom"
                id="nomeCortesia"
                invalid={!!error}
                value={newCupom.nome_cortesia}
                onChange={(event) =>
                  handleChange({
                    value: event.target.value,
                    field: 'nome_cortesia'
                  })
                }
              />
              <FormFeedback>{error}</FormFeedback>
            </FormGroup>
          </Col>

          <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
            <Label> Quantidade</Label>
            <FormGroup>
              <Input
                placeholder="Quantidade"
                fullWidth
                type="number"
                invalid={!!error}
                onChange={(event) =>
                  handleChange({
                    value: event.target.value,
                    field: 'quantidade_disponivel'
                  })
                }
                value={newCupom.quantidade_disponivel}
                margin="normal"
                variant="outlined"
                className={styles.textFieldRoot}
              />
              <FormFeedback>{error}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
            <Label>Valor Cortesia</Label>
            <FormGroup>
              <Input
                placeholder="Valor de venda"
                fullWidth
                type="number"
                invalid={!!error}
                value={newCupom.valor_cortesia}
                className={[styles.inputCurrency, styles.textFieldRoot]}
                name="valorCortesia"
                margin="normal"
                variant="outlined"
                onChange={(event) =>
                  handleChange({
                    value: event.target.value,
                    field: 'valor_cortesia'
                  })
                }
              />
              <FormFeedback>{error}</FormFeedback>
            </FormGroup>
          </Col>
          <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
            <FormGroup>
              <Label>Select</Label>
              <Select
                value={
                  statusDesconto.find(
                    (event) => event.value === newCupom?.tipo_desconto
                  )
                    ? statusDesconto.find(
                        (event) => event.value === newCupom?.tipo_desconto
                      )
                    : null
                }
                onChange={(event) =>
                  handleChange({
                    value: event.value,
                    field: 'tipo_desconto'
                  })
                }
                options={statusDesconto}
                classNamePrefix="select2-selection"
                placeholder="Selecione ..."
              />
              <FormFeedback>{error}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
            <FormGroup>
              <Label for="exampleEmail">Data de expiração</Label>
              <Input
                type="date"
                name="inicio"
                id="inicio"
                invalid={!!error}
                value={newCupom.expiration}
                format="DD/MM/yyyy"
                onChange={(event) => {
                  handleChange({
                    value: event.target.value,
                    field: 'expiration'
                  })
                }}
              />
              <FormFeedback>{error}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row className="footerModalButtons">
          <Col xs={6}>
            <Button
              className="buttonsModal"
              onClick={() => handleCancelAddCupom()}
              variant="contained"
              color="secondary"
            >
              Cancelar
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              className="buttonsModal"
              onClick={() => handleSubmitAddCupom()}
              variant="contained"
              color="primary"
            >
              Adicionar
            </Button>
          </Col>
        </Row>
      </Container>
    </ReactModal>
  )
}
