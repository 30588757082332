/* eslint-disable react/jsx-key */
/* eslint-disable array-bracket-newline */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label
} from 'reactstrap'

import { ToastContainer, toast } from 'react-toastify'
///MODAIS
import Select from 'react-select'
import DatatableTables from '../../components/Table'

import {
  eventzzSelect
} from '../../store/actions'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
import servicesIcon1 from '../../assets/images/services-icon/01.png'
import { Loading } from 'components/Loading'
import eventzz from '../../services/eventzz'
import { phoneMask, cpfMask, formatDateHourT } from '../../util/string'


const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Nome', field: 'name' },
  { label: 'Telefone', field: 'telefone' },
  { label: 'E-mail', field: 'email' },
  { label: 'CPF', field: 'cpf' },
  { label: 'Prêmio', field: 'premio' },
  { label: 'Data', field: 'created_at' }
]

const Sorteio = () => {
  const { dadosSorteio, saveSorteio } = eventzz

  const [loading, setLoading] = useState(false)
  const [checkSorteio, setCheckSorteio] = useState(false)

  const [ticketsTypeResume, setTicketsTypeResume] = useState(null)
  const [eventzzList, setEventzzList] = useState([])
  const [toggle, setToggle] = useState('1')
  const [dados, setDados] = useState([])
  const [palestrantes, setPalestrantes] = useState([])
  const [filteredRows, setFilteredRows] = useState([])

  const [premio, setPremio] = useState('')
  const [ganhador, setGanhador] = useState(false)


  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      tickets: true,
      more: localStorage.getItem('root@session'),
      pagination: true,
      markAsUsed: true
    },
    config: {
      markAsUsedText: 'Confirmar utilização'
    },
    filters: {
      searchTerm: null,
      status: 'paid',
      eventId: null,
      ticketType: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

 
  const handleClose = () => {
    setLoading(false)
  }

  function handleChangeEvent(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        eventId: Number(value?.value) === 0 ? null : value?.value
      }
    }))
    setLoading(true)
  }

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()
    if (response.status === 200) {
      const responseData = response?.data
      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])


  const loadDados = async () => {
    setLoading(true);
    const promisseResult = await Promise.all([
      dadosSorteio({eventId: tableConfig?.filters?.eventId})
    ]).then(async response => {
      const result = response[0];
      if (result?.status === 200) {
        setDados(result?.data?.data)
       
        const formattedData = result?.data?.data?.sorteios.map((item) => ({
          ...item,
          telefone: phoneMask(item.telefone),
          cpf: cpfMask(item.cpf),
          created_at: formatDateHourT(item.created_at)
        }))
        setFilteredRows(formattedData)
      } else {
        toast.error(result?.msg)
      }
      setLoading(false)
      return true
    })
  }

  useEffect(() => {
    if (tableConfig?.filters?.eventId) {
      loadDados();
    }
  }, [tableConfig?.filters?.eventId])


  useEffect(() => {
    loadEventzz()
  }, [])


  const handlerSorteio = async () => {
    setLoading(true)
    console.log("==================")
    const sorteado = Math.floor(Math.random() * (dados.total - 0) + 0);
    const userSorteado = dados.items[sorteado];
    await saveSorteio({
      user: userSorteado.id,
      premio,
      idEvento: tableConfig?.filters?.eventId
    });

    await setTimeout(() => {
      //SALVAR NO BACK
      setGanhador(userSorteado)
      setLoading(false)
      loadDados();
    }, 3600)
  }

  const feharSorteio = () => {
    setGanhador(false)
    setPremio()
    setCheckSorteio(false)
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Sorteios | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Sorteios | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Sorteios
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Card style={{padding:20}}>
                <Col xl={12} md={12}>
                  <Row>
                    <Col xl={6} md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Eventos</Label>
                        <Select
                          value={
                            eventzzList.find(
                              (a) => a.value === tableConfig?.filters?.eventId
                            )
                              ? eventzzList.find(
                                  (a) =>
                                    a.value === tableConfig?.filters?.eventId
                                )
                              : null
                          }
                          onChange={(value) => handleChangeEvent(value)}
                          options={eventzzList}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{padding:20}}>
                    {tableConfig?.filters?.eventId ? (
                      <>
                          <Row>
                          <Col xl={4} md={4}>
                            <Card className="mini-stat bg-primary text-white">
                              <CardBody>
                                <div className="mb-4">
                                  <div className="float-start mini-stat-img me-4">
                                    <img src={servicesIcon1} alt="" />
                                  </div>
                                  <h5 className="font-size-12 text-uppercase mt-0 text-white-50">
                                    Participantes Presentes
                                  </h5>
                                  <h4 className="fw-medium font-size-20">
                                    {dados.total}
                                  </h4>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xl={6} md={6}>
                          </Col>
                          <Col xl={2} md={6}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => setCheckSorteio(true)}
                              style={{ marginTop: '30px' }}
                            >
                              Novo Sorteio
                            </Button>
                          </Col>
                        </Row>
                        {checkSorteio && (
                          <Row className='boxSorteio'>
                            <Col xs={12} md={12} xl={12} style={{marginTop:20}}>
                            <span style={{fontSize: 30, marginBottom:20}}>Novo Sorteio</span>
                            </Col>
                            <Col xs={12} md={6} xl={4} style={{marginBottom:20}}>
                              <Label>Prêmio</Label>
                              <Input
                                value={premio}
                                onChange={(event) =>
                                  setPremio(event.target.value)
                                }
                              />
                            </Col>
                            <Col xs={12} md={6} xl={4} style={{marginBottom:20}}>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={premio?.length === 0}
                                onClick={() => handlerSorteio()}
                                style={{ marginTop: '30px' }}
                              >
                                Efetuar Sorteio
                              </Button>
                            </Col>
                          <Col xs={12} md={12} xl={12} className='novoSorteio'>
                            {ganhador && (
                              <div>
                              <span>GANHADOR</span>
                              <p>
                                {`${ganhador?.id} - ${ganhador?.name} | ${ganhador?.telefone}`}
                              </p>
                              <Col xs={12} md={12} xl={12} className='center'>
                                <Button
                                  variant="outlined"
                                  color="warning"
                                  onClick={() => feharSorteio()}
                                  style={{ marginBottom: '10px' }}
                                >
                                  Fechar
                                </Button>
                              </Col>
                              </div>
                            )}
                          </Col>
                        </Row>
                          )}
                          <Row>
                            <Col xl={12} md={12}>
                              <DatatableTables
                                title={'Eventozz'}
                                columns={columns}
                                rows={filteredRows}
                                tableConfig={tableConfig}
                                setTableConfig={setTableConfig}
                              />
                            </Col>
                          </Row>
                        </>
                    ) : (
                      <Col xl={12} md={12} style={{
                        padding: 150, display: 'flex', justifyContent: 'center'
                      }}>
                        <span>Nenhum Evento Selecionado</span>
                      </Col>
                    )}
                  </Row>
                </Col>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Sorteio.propTypes = {
  t: PropTypes.any
}

export default Sorteio
