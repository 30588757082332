import { api } from './axios'

export const categoryService = {
  select: async () => {
    const response = await api
      .get('/eventzz/category/select')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
