import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Label,
  Button,
  Input
} from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import { Loading } from '../../../components/Loading'
///MODAIS
import Select from 'react-select'
import '../styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'
import styles from './styles.module.scss'
import { CurrencyInput } from 'components/CurrencyInput'
import { ticketsService } from 'services/tickets'
import eventzz from 'services/eventzz'
import { Editor } from 'react-draft-wysiwyg'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

export const NovoIngressoEspecial = ({ match }) => {
  const eventId = match.params.eventId
  const { createSpecialTicket, selectTickets, findTicketType } = ticketsService
  const { eventzzSelect } = eventzz

  const [loading, setLoading] = useState(false)
  const [copyLink, setCopyLink] = useState(false)
  const [ticketTypeList, setTicketTypeList] = useState([])
  const [eventzzList, setEventzzList] = useState([])

  const [showErrors, setShowErrors] = useState(false)

  const [ticketType, setTicketType] = useState(null)
  const [ticketName, setTicketName] = useState(null)
  const [ticketValue, setTicketValue] = useState(null)
  const [availableQuantity, setAvailableQuantity] = useState(null)
  const [headerEditorState, setHeaderEditorState] = useState(
    EditorState.createEmpty()
  )
  const [descriptionEditorState, setDescriptionEditorState] = useState(
    EditorState.createEmpty()
  )

  const handleClose = () => {
    setLoading(false)
  }

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()
    if (response.status === 200) {
      const responseData = response?.data?.data
      console.log(responseData)

      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  const loadTicketTypes = useCallback(async () => {
    const response = await selectTickets({ eventId })

    if (response.status === 200) {
      const responseData = response?.data?.data
      console.log(responseData)
      setTicketTypeList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  function fillTextEditor(currentMsg, setEditorState) {
    if (!currentMsg) return

    const blocksFromHtml = htmlToDraft(currentMsg)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    setEditorState(EditorState.createWithContent(contentState))
    console.log(currentMsg)
  }

  async function handleChangeTicketType(value) {
    try {
      setTicketType(value.value)

      setLoading(true)
      const response = await findTicketType({ ticketTypeId: value.value })

      if (response.status === 200) {
        const responseData = response?.data?.data
        console.log(responseData)

        if (responseData?.length) {
          const { texto_de_por: headerText, descricao: description } =
            responseData[0]

          if (description) {
            fillTextEditor(description, setDescriptionEditorState)
          }

          if (headerText) {
            fillTextEditor(headerText, setHeaderEditorState)
          }
        }
        // setEventzzList(responseData)
        setLoading(false)
        return true
      } else {
        toast.error(response?.msg)
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  function handleSetTicketValue(value) {
    console.log(value)
    setTicketValue(value)
  }

  function handleGoBack() {
    window.location.href = '/eventozz'
  }

  const handleCopyLink = () => {
    console.log(copyLink)
    const path = copyLink.leaders ? 'leaders' : 'evento'
    const link = `${process.env.REACT_APP_SITE_URL}${path}/${copyLink?.slug}/?ingresso=${copyLink?.ticketHash}`
    navigator.clipboard.writeText(link)
    toast.success('O link do ingresso foi copiado')
  }

  async function handleSubmit() {
    let hasError = false

    if (!ticketType) {
      hasError = true
    }
    if (!ticketValue) {
      hasError = true
    }
    if (!availableQuantity) {
      hasError = true
    }
    if (!ticketName) {
      hasError = true
    }
    console.log(hasError)
    if (hasError) {
      setShowErrors(hasError)
      return null
    }

    let headerText = draftToHtml(
      convertToRaw(headerEditorState.getCurrentContent())
    )
    let description = draftToHtml(
      convertToRaw(descriptionEditorState.getCurrentContent())
    )
    if (description?.trim() === '<p></p>') {
      console.log('there')
      description = null
    }
    if (headerText?.trim() === '<p></p>') {
      console.log('here')
      headerText = null
    }
    console.log(headerText)
    console.log(description)
    console.log(ticketType)
    console.log(ticketValue)
    console.log(availableQuantity)
    console.log(ticketName)
    // return
    setLoading(true)

    const response = await createSpecialTicket({
      ticketId: ticketType,
      name: ticketName,
      ticketPrice: ticketValue,
      qtd: availableQuantity,
      description,
      headerText
    })
    console.log(response)
    if (response.status === 200 && response?.data?.success) {
      toast.success(response.data.msg || 'O novo tipo de ingresso foi salvo')
      setLoading(false)
      if (response?.data?.data?.length) {
        const data = response?.data?.data[0]
        setCopyLink(data)
      }
    } else if (response.status === 401) {
      toast.error('Você não está logado ou sua sessão expirou')
      setLoading(false)
      return false
    } else if (response.status === 500) {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
      )
      setLoading(false)
      return false
    } else {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro ao salvar o novo ingresso'
      )
      setLoading(false)
      return false
    }
  }

  useEffect(() => {
    loadEventzz()
    loadTicketTypes()
  }, [])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Ingressos | Novo ingresso especial</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">
                  Financeiro | Novo ingresso especial
                </h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Tipo de ingresso que só pode ser acessado pelo link
                    compartilhado
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Card className={`${styles.p200} pt-2`}>
              <Row>
                <Col md={10} className="m-auto">
                  <Row>
                    <Col xs={12} md={6} xl={4}>
                      <FormGroup>
                        <Label>Evento*</Label>
                        <Input
                          disabled={true}
                          value={
                            eventzzList.find((a) => a.value === Number(eventId))
                              ? eventzzList.find(
                                  (a) => a.value === Number(eventId)
                                )?.label
                              : null
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6} xl={4}>
                      <FormGroup>
                        <Label>Tipo de ingresso*</Label>
                        <Select
                          value={
                            ticketTypeList.find((a) => a.value === ticketType)
                              ? ticketTypeList.find(
                                  (a) => a.value === ticketType
                                )
                              : null
                          }
                          onChange={(value) => handleChangeTicketType(value)}
                          placeholder="Selecione..."
                          options={ticketTypeList}
                          classNamePrefix="select2-selection"
                        />
                        {showErrors && !ticketType && (
                          <small className="invalid-feedback d-block">
                            *Campo obrigatório
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6} xl={4}>
                      <FormGroup>
                        <Label>Nome*</Label>
                        <Input
                          onChange={(e) => setTicketName(e.target.value)}
                          value={ticketName}
                        />
                      </FormGroup>
                      {showErrors && !ticketName && (
                        <small className="invalid-feedback d-block">
                          *Campo obrigatório
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col xs={12} md={6} xl={4}>
                      <CurrencyInput
                        variant="outlined"
                        label="Valor unitário*"
                        value={ticketValue}
                        handleChange={handleSetTicketValue}
                      />
                      {showErrors && !ticketValue && (
                        <small className="invalid-feedback d-block">
                          *Campo obrigatório
                        </small>
                      )}
                    </Col>
                    <Col xs={12} md={6} xl={4}>
                      <Label>Quantidade disponível*</Label>
                      <Input
                        min={1}
                        type="number"
                        value={availableQuantity}
                        onChange={(e) => setAvailableQuantity(e.target.value)}
                      />
                      {showErrors && !availableQuantity && (
                        <small className="invalid-feedback d-block">
                          *Campo obrigatório
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col xs={12} md={6} xl={4}>
                      <Label>Cabeçalho (Texto acima do preço)</Label>
                      <Editor
                        editorState={headerEditorState}
                        wrapperClassName={styles.textEditorWrapper}
                        editorClassName={styles.textEditorContent}
                        toolbarClassName={styles.textEditorToolbar}
                        onEditorStateChange={setHeaderEditorState}
                        toolbar={{
                          options: ['inline', 'fontSize', 'list', 'textAlign'],
                          inline: {
                            options: ['bold', 'italic', 'strikethrough']
                          },

                          fontSize: {
                            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined
                          },
                          list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['unordered', 'ordered']
                          },
                          textAlign: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['left', 'center', 'right', 'justify']
                          }
                        }}
                      />
                    </Col>
                    <Col xs={12} md={6} xl={4}>
                      <Label>Descrição (Texto abaixo do preço)</Label>
                      <Editor
                        editorState={descriptionEditorState}
                        wrapperClassName={styles.textEditorWrapper}
                        editorClassName={styles.textEditorContent}
                        toolbarClassName={styles.textEditorToolbar}
                        onEditorStateChange={setDescriptionEditorState}
                        toolbar={{
                          options: ['inline', 'fontSize', 'list', 'textAlign'],
                          inline: {
                            options: ['bold', 'italic', 'strikethrough']
                          },

                          fontSize: {
                            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined
                          },
                          list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['unordered', 'ordered']
                          },
                          textAlign: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['left', 'center', 'right', 'justify']
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="pt-4 justify-content-end">
                    <Col xs={12} md={4} className="d-flex justify-content-end">
                      <Button
                        variant="outlined"
                        type="button"
                        color="danger"
                        onClick={() => handleGoBack()}
                        style={{ marginTop: '30px' }}
                      >
                        Voltar
                      </Button>
                      {copyLink ? (
                        <Button
                          className="mx-3"
                          variant="outlined"
                          type="button"
                          color="success"
                          onClick={() => handleCopyLink()}
                          style={{ marginTop: '30px' }}
                        >
                          Copiar link do ingresso
                        </Button>
                      ) : (
                        <Button
                          className="mx-3"
                          variant="outlined"
                          type="button"
                          color="primary"
                          onClick={() => handleSubmit()}
                          style={{ marginTop: '30px' }}
                        >
                          Salvar
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
