import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { ToastContainer, toast } from 'react-toastify'
import { markAsUsed } from '../../store/actions'
import { Loading } from '../Loading'
import { Form, Container, Row, Col, Button, Card } from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css'

export const ModalMarkTicketAsUsed = ({
  showModal,
  onSubmit,
  onCancel,
  row
}) => {
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setLoading(false)
  }

  const handleSubmitMarkAsUsed = async () => {
    console.log(row)
    setLoading(true)
    const response = await markAsUsed({
      code: row?.code,
      eventId: row?.eventId
    })
    if (response.status === 200) {
      const data = response?.data
      console.log(data)
      toast.success(data?.msg ? data?.msg : 'Ingresso atualizado')
      onSubmit()
      setLoading(false)
    } else {
      toast.error(
        response?.msg || 'Ocorreu um erro ao tentar atualizar o ingresso'
      )
      setLoading(false)
      return false
    }
  }

  const handleCancelMarkAsUsed = () => {
    onCancel()
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
    >
      <ToastContainer />

      <Container>
        <Loading open={loading} handleClose={handleClose} />
        <Card className={'modalBody'}>
          <Form>
            <Row className="modalText">
              <Col xs={12} justifyContent="flex-start" item>
                <h1 className={'titleRoot'}>
                  Confirmar Utilização do ingresso?
                </h1>
              </Col>
              <Col xs={12}>
                <h5 className={'titleRoot'}>
                  O ingresso não poderá ser utilizado novamente
                </h5>
              </Col>
            </Row>
            <Row className="footerModalButtons">
              <Col xs={6}>
                <Button
                  className="buttonsModal"
                  onClick={handleSubmitMarkAsUsed}
                  variant="contained"
                  color="primary"
                >
                  Confirmar
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  className="buttonsModal"
                  onClick={handleCancelMarkAsUsed}
                  variant="contained"
                  color="secondary"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    </ReactModal>
  )
}
