import React, { useCallback, useEffect, useState } from 'react'
import DatatableTables from '../index.js'
import { Collapse } from 'reactstrap'
import { Tr, Td } from 'react-super-responsive-table'

//@ts-ignore
import styles from './styles.module.scss'

import { toast } from 'react-toastify'
import { userService } from 'services/user.js'
import { SelectOption } from 'types/global.js'

export function AppUserCollapse({ open, row }) {
  const { getUserAppEvents } = userService

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)

  const columns = [
    { label: 'Evento', field: 'value' },
    { label: 'Nome', field: 'label' }
  ]

  // eslint-disable-next-line no-unused-vars
  const [tableConfig, setTableConfig] = useState({
    options: {
      pagination: false
    },
    config: {}
  })

  const loadUserEvents = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getUserAppEvents(row?.id)
      if (response.status === 200) {
        const responseData: SelectOption[] = response?.data
        console.log({
          responseData,
          here: responseData.map((item) => item.value)
        })

        setEvents(responseData)
        setLoading(false)

        return true
      } else {
        toast.error(response?.msg)
        return false
      }
    } catch (error) {
      setLoading(false)
    }
  }, [row])

  useEffect(() => {
    console.log({ row, open })

    if (row?.id && open) {
      loadUserEvents()
    }
  }, [row, open])

  return (
    <Tr>
      <Td
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={columns.length + 1}
      >
        <Collapse
          isOpen={open}
          timeout="auto"
          unmountOnExit
          className={styles.tableContainer}
        >
          {events?.length ? (
            <DatatableTables
              title="Eventos liberados para esse usuário"
              rows={events}
              columns={columns}
              tableConfig={tableConfig}
              layoutCollapse={true}
            />
          ) : (
            <div className="text-center p-4">
              {loading ? (
                <h4>Buscando eventos liberados para esse usuário...</h4>
              ) : (
                <h4>Nenhum evento liberado para esse usuário</h4>
              )}
            </div>
          )}
        </Collapse>
      </Td>
    </Tr>
  )
}
