import React, { useState, useCallback, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'
import { Loading } from 'components/Loading'
import 'jodit/build/jodit.min.css'
import { TabDetails } from './tabDetails'
import { TabTickets } from './tabTickets'
import { TabImages } from './tabImages'
// import { TabPanel } from 'components/tabPanel'
import { TabAdmSettings } from './tabAdmSettings'
import eventzz from 'services/eventzz'
import { toast } from 'react-toastify'
import classnames from 'classnames'
import authService from 'services/auth'
import { AlertConfirm } from 'components/alertConfirm'
import { CustomBadge } from 'components/customBadge'
import { Button } from 'react-bootstrap'
import { ModalConfirm } from 'components/modalConfirm'
import { TabCheckoutInfo } from './tabCheckoutInfo'

const tabs = [
  {
    id: '1',
    nome: 'Detalhes'
  },
  {
    id: '2',
    nome: 'Imagens'
  },
  {
    id: '3',
    nome: 'Ingressos'
  },
  {
    id: '4',
    nome: 'Checkout'
  },
  {
    id: '5',
    nome: 'Configurações'
  }
]
export const EventForm = ({ eventId: eventIdParams, ticketsPage = false }) => {
  const [eventId, setEventId] = useState(
    eventIdParams ? Number(eventIdParams) : null
  )

  const { find, checkEventRegistration, requestEventActivation } = eventzz
  const { checkIsRoot } = authService
  const [rendered, setRendered] = useState(false)

  const [eventData, setEventData] = useState(null)
  const [showModalChangeStatus, setShowModalChangeStatus] = useState(false)
  const [eventReady, setEventReady] = useState(false)
  const [isRoot, setIsRoot] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [showAlertConfirm, setShowAlertConfirm] = useState(false)
  const [alertConfirmText, setAlertConfirmText] = useState('')
  const [confirmButtonText, setConfirmButtonText] = useState('')
  const [eventPendencies, setEventPendencies] = useState({
    images: [],
    tickets: []
  })

  const [loading, setLoading] = useState(false)
  const [toggle, setToggle] = useState(ticketsPage ? '3' : '1')
  const [toggleTemp, setToggleTemp] = useState('1')
  const handleClose = () => {
    setLoading(false)
  }

  function handleConfirmAlert() {
    setToggle(toggleTemp)
    setShowAlertConfirm(false)
    setHasChanges(false)
  }

  function handleCancelAlert() {
    setShowAlertConfirm(false)
    setAlertConfirmText('')
    setConfirmButtonText('')
    window.scrollTo(0, document.body.scrollHeight)
  }

  const handleChange = useCallback((newValue = '1') => {
    console.log(hasChanges)
    console.log(newValue)
    if (newValue === toggle) {
      return
    }
    if (hasChanges) {
      const activeTab = tabs.find((item) => item.id === toggle)?.nome
      const nextTab = tabs.find((item) => item.id === newValue)?.nome
        ? tabs.find((item) => item.id === newValue)?.nome
        : ''
      console.log(nextTab)
      setShowAlertConfirm(true)
      setAlertConfirmText(
        `Você tem alterações <b>não salvas</b> em <i>${activeTab}</i>. Caso queira salvar agora, clique no botão <b>Salvar</b> no fim dessa sessão`
      )
      setConfirmButtonText(`Ir para "${nextTab}"`)
      setToggleTemp(newValue)
      return
    }
    setToggle(newValue)
  })

  const findEvent = useCallback(async () => {
    const response = await find({
      eventId: eventIdParams ? eventIdParams : null
    })
    console.log(response)
    if (response.status === 200 && response?.data?.success) {
      console.log('there')
      setLoading(false)
      setEventData(response.data.data)
    } else if (response.status === 401) {
      toast.error('Você não está logado ou sua sessão expirou')
      setLoading(false)
      return false
    } else if (response.status === 500) {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
      )
      setLoading(false)
      return false
    } else {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro ao buscar os dados do evento'
      )
      setLoading(false)
      return false
    }
  }, [eventId])

  const checkEvent = useCallback(async () => {
    const response = await checkEventRegistration({
      eventId
    })
    console.log(response)

    if (response.status === 200 && response?.data?.success) {
      console.log('there')
      if (response?.data) {
        if (
          response.data?.data?.images?.length === 0 &&
          response.data?.data?.tickets?.length === 0
        ) {
          setEventReady(true)
        }
        setEventPendencies(response?.data?.data)
      }
    }
  }, [eventId])

  const checkUser = useCallback(async () => {
    const response = await checkIsRoot()
    if (response.isRoot) {
      setIsRoot(true)
    } else {
      setIsRoot(false)
    }
  }, [])

  function handleChangeStatus() {
    setShowModalChangeStatus(true)
  }

  function handleCancelActiveEvent() {
    setShowModalChangeStatus(false)
  }

  async function handleConfirmActiveEvent() {
    setLoading(true)

    const response = await requestEventActivation({ eventId })

    if (response.status === 200 && response?.data?.success) {
      setLoading(false)
      handleCancelActiveEvent()
      toast.success(
        response?.data?.msg ||
          'Recebemos sua solicitação. Entraremos em contato em breve!'
      )
    } else if (response.status === 401) {
      toast.error('Você não está logado ou sua sessão expirou')
      setLoading(false)
      return false
    } else if (response.status === 500) {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
      )
      setLoading(false)
      return false
    } else {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro ao buscar os dados do evento'
      )
      setLoading(false)
      return false
    }
  }

  useEffect(() => {
    if (!!eventIdParams && rendered) {
      setLoading(true)
      findEvent()
    }
  }, [eventIdParams, rendered])

  useEffect(() => {
    if (!!eventId && rendered) {
      checkEvent()
    }
  }, [eventId, rendered])

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }

    if (rendered) {
      checkUser()
    }
  }, [rendered])

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Eventos</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Gestão de Eventos</li>
                </ol>
              </Col>
              {eventReady && !eventData?.active && (
                <Col md={4} className="text-end">
                  <Button
                    variant="success"
                    onClick={() => handleChangeStatus()}
                    style={{ marginTop: '30px' }}
                  >
                    Ativar evento
                  </Button>
                </Col>
              )}
            </Row>
          </div>

          <Loading open={loading} handleClose={handleClose} />
          <Row variant={null}>
            <Nav tabs style={{ justifyContent: ticketsPage ? 'flex-start' : 'center' }}>
              {!ticketsPage ? (
                <NavItem>
                  <NavLink
                    className={classnames({ active: toggle === '1' })}
                    onClick={() => {
                      handleChange('1')
                    }}
                  >
                    Detalhes
                  </NavLink>
                </NavItem>
              ) : null}
              {!ticketsPage ? (
                <NavItem>
                  <NavLink
                    className={classnames({ active: toggle === '2' })}
                    onClick={() => {
                      handleChange('2')
                    }}
                  >
                    Imagens{' '}
                    {eventPendencies?.images?.length ? (
                      <CustomBadge
                        showTooltip
                        bg="danger"
                        title="Pendências no cadastro"
                        value={eventPendencies?.images?.length}
                        text={() => (
                          <ul>
                            {eventPendencies?.images?.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        )}
                      />
                    ) : null}
                  </NavLink>
                </NavItem>
              ) : null}
              <NavItem>
                <NavLink
                  className={classnames({ active: toggle === '3' })}
                  onClick={() => {
                    handleChange('3')
                  }}
                >
                  Ingressos
                  {eventPendencies?.tickets?.length ? (
                    <CustomBadge
                      showTooltip
                      bg="danger"
                      title="Pendências no cadastro"
                      value={eventPendencies?.tickets?.length}
                      text={() => (
                        <ul>
                          {eventPendencies?.tickets?.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      )}
                    />
                  ) : null}
                </NavLink>
              </NavItem>

              {!ticketsPage ? (
                <NavItem>
                  <NavLink
                    className={classnames({ active: toggle === '4' })}
                    onClick={() => {
                      handleChange('4')
                    }}
                  >
                    Checkout
                  </NavLink>
                </NavItem>
              ) : null}
              {isRoot && !ticketsPage ? (
                <NavItem>
                  <NavLink
                    className={classnames({ active: toggle === '5' })}
                    onClick={() => {
                      handleChange('5')
                    }}
                  >
                    Configurações (R)
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
            <TabContent activeTab={toggle}>
              <TabPane tabId="1">
                <TabDetails
                  setHasChanges={setHasChanges}
                  eventId={eventId}
                  setLoading={setLoading}
                  setEventId={setEventId}
                  isEditing={!!eventIdParams}
                  eventData={eventData}
                />
              </TabPane>
              <TabPane tabId="2">
                <TabImages
                  setHasChanges={setHasChanges}
                  eventId={eventId}
                  setLoading={setLoading}
                  isEditing={!!eventIdParams}
                  eventData={eventData}
                  checkEvent={checkEvent}
                />
              </TabPane>
              <TabPane tabId="3">
                <TabTickets
                  setHasChanges={setHasChanges}
                  eventId={eventId}
                  setLoading={setLoading}
                  isEditing={!!eventIdParams}
                  eventData={eventData}
                  checkEvent={checkEvent}
                />
              </TabPane>
              <TabPane tabId="4">
                <TabCheckoutInfo
                  setHasChanges={setHasChanges}
                  eventId={eventId}
                  setLoading={setLoading}
                  isEditing={!!eventIdParams}
                  eventData={eventData}
                />
              </TabPane>
              {isRoot && (
                <TabPane tabId="5">
                  <TabAdmSettings
                    setHasChanges={setHasChanges}
                    eventId={eventId}
                    setLoading={setLoading}
                    isEditing={!!eventIdParams}
                    eventData={eventData}
                  />
                </TabPane>
              )}
            </TabContent>
          </Row>
        </Container>
      </div>
      {showAlertConfirm && (
        <AlertConfirm
          text={alertConfirmText}
          title="Ir para próxima sessão?"
          closeButton="Ficar nesta sessão"
          confirmButton={confirmButtonText}
          onConfirm={handleConfirmAlert}
          onCancel={handleCancelAlert}
        />
      )}

      <ModalConfirm
        onClose={handleCancelActiveEvent}
        onConfirm={handleConfirmActiveEvent}
        showModal={showModalChangeStatus}
        title="Ativar evento?"
        text="Vamos revisar as informações do seu evento e avisaremos assim que o evento for ativado"
      />
    </>
  )
}
