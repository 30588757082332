import React from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { FaRegQuestionCircle } from 'react-icons/fa'
//@ts-ignore
import './styles.scss'

export function Tooltip({ title, description }) {
  return (
    <OverlayTrigger
      overlay={
        <Popover id="popover-contained" className="customPopover">
          <Popover.Header as="h3" dangerouslySetInnerHTML={{ __html: title }} />
          <Popover.Body dangerouslySetInnerHTML={{ __html: description }} />
        </Popover>
      }
    >
      <Button className="overlayButton">
        <FaRegQuestionCircle color="#000" />
      </Button>
    </OverlayTrigger>
  )
}
