import { api } from './axios'

export const userService = {
  listUsersApp: async () => {
    const response = await api
      .get('/list/user-app')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  createUserApp: async ({ userName, password }) => {
    const response = await api
      .post('/user-app/create', { userName, password })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateUserApp: async ({ userName, password, id }) => {
    const response = await api
      .put(`/user-app/${id}`, { userName, password })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  deleteUserApp: async (id) => {
    const response = await api
      .delete(`/user-app/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updatePermissionsUserApp: async ({ events, id }) => {
    const response = await api
      .put(`/user-app/permissions/${id}`, { events })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getUserAppEvents: async (id) => {
    const response = await api
      .get(`/user-app/events/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  checkUserEmail: async ({ email }) => {
    const response = await api
      .get('/user/check-email', {
        params: {
          email
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getUserEmail: async (userId) => {
    const response = await api
      .get(`/user/${userId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
