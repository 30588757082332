import { api } from './axios'

export const financialServices = {
  createTransfer: async ({ formData }) => {
    const response = await api
      .post('/financial/create-transfer', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ response }) => {
        return response
      })
    return response
  },
  createFluxo: async ({ dados, eventId }) => {
    console.log({ dados, eventId })

    const response = await api
      .post('/financial/create-fluxo', {
        dados,
        eventId
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  addOrganizzer: async ({ email }) => {
    const response = await api
      .post('/financial/create-organizzer', {
        email
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  deleteOrganizer: async ({ id }) => {
    const response = await api
      .get('/financial/delete-organizzer', {
        params: {
          id
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  deleteFluxo: async ({ id }) => {
    const response = await api
      .get('/financial/delete-fluxo', {
        params: {
          id
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
