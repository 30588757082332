import PageBanner from '../../components/Common/PageBanner'

const Termo = () => {
  return (
    <>
      <PageBanner
        pageTitle="Termos de Uso"
        pageSubTitle="Os Termos de Uso regem nosso relacionamento com os Usuários, sejam eles Organizadores de eventos, Compradores ou outras pessoas que interagem com a Eventozz através do domínio www.eventozz.com, de todas as suas páginas e subdomínios, através do aplicativo Eventozz e dos pontos de venda autorizados. Ao usar ou acessar os serviços da Eventozz você concorda com esta declaração, que poderá ser eventualmente atualizada."
      />

      <div className="contact-area ptb-100">
        <div className="container">
          <p>Ultima Atualização: 14/09/2022 às 20:45min</p>
          <div className="row">
            <div>
              <h3 className="uppercase">1. SUAS RESPONSABILIDADES</h3>
              <h4>Seus dados cadastrados:</h4>
              <ul>
                <li>
                  Ao criar uma conta na Eventozz, você concorda em fornecer
                  informações verdadeiras, completas e atualizadas sobre si
                  mesmo. Caso a Eventozz identifique inconsistências ou
                  eventuais riscos em relação ao seu Usuário ou às suas
                  atividades na Eventozz e considere necessário validar as
                  informações cadastradas, você se compromete a fornecer as
                  comprovações que lhe forem requisitadas;
                </li>
                <li>
                  Se tivermos motivos que indiquem que quaisquer informações
                  cadastradas não são verdadeiras, teremos o direito de
                  suspender ou encerrar a sua conta, bem como nos recusar a
                  prover quaisquer de nossos serviços;
                </li>
                <li>
                  Qualquer erro, atraso, prejuízo ou dano causado devido ao
                  cadastramento de informações incorretas, incompletas ou
                  desatualizadas é de total responsabilidade do Usuário.
                </li>
              </ul>
              <hr></hr>
              <h4>Usuário e senha:</h4>
              <ul>
                <li>
                  Você é responsável por manter a confidencialidade de seus
                  dados de Usuário e sua senha, criados no ato de registro com a
                  Eventozz. Você é única e exclusivamente responsável por todas
                  as atividades que ocorram sob sua conta e se compromete a
                  notificar-nos imediatamente acerca de quaisquer usos não
                  autorizados de sua conta ou quaisquer outras violações de
                  segurança. A Eventozz não será responsável por quaisquer
                  perdas e danos resultantes de acessos não autorizados ou uso
                  de sua conta;
                </li>
                <li>
                  A titularidade do cadastro é pessoal. Apenas o titular da
                  conta na Eventozz poderá ter acesso aos dados relativos à
                  mesma. Ainda, alterações em dados cadastrados somente poderão
                  ser feitas pelo próprio Usuário em sua área logada. A Eventozz
                  não tem prerrogativa para fazer esse tipo de alteração,
                  independentemente da razão alegada ou circunstância.
                </li>
              </ul>
              <hr></hr>
              <h4>Publicação e gestão de eventos:</h4>
              <p>
                A Eventozz criou a plataforma. O Organizador cadastra o evento e
                publica seu conteúdo. Ao fazê-lo, o Organizador se compromete a:
              </p>
              <ul>
                <li>
                  Atender os termos das leis anticorrupção brasileiras, em
                  especial a Lei 12.846/2013, comprometendo-se a se abster de
                  qualquer atividade que constitua uma violação das disposições
                  destas leis;
                </li>
                <li>
                  Publicar eventos ou quaisquer conteúdos que sejam verdadeiros
                  e com informações completas. Assim, é de responsabilidade do
                  Organizador comunicar com exatidão informações como local,
                  data, horário, valores, atrações, serviços oferecidos,
                  contato, política de cancelamento, política de meia-entrada
                  para fins de atendimento do contido na Lei 12.933/13 e Decreto
                  8.537/2015, restrição de faixa etária e qualquer outra
                  informação que seja relevante para os Compradores ou, ainda,
                  para a validação da realização dos eventos;
                </li>
                <li>
                  Publicar eventos ou quaisquer conteúdos que o Organizador
                  tenha o direito e capacidade de cumprir. Assim, o Organizador
                  assume que os eventos ou conteúdos publicados têm todos os
                  seus trâmites finalizados junto a atrações, local,
                  patrocinadores, fornecedores, órgãos públicos e qualquer outra
                  parte interessada e que é capaz e irá entregar tudo o que foi
                  anunciado. Além disso, o Organizador confirma que tem direitos
                  sobre o evento ou conteúdo publicado e que não está
                  infringindo nenhuma patente, marca registrada ou direito
                  autoral de qualquer pessoa ou entidade;
                </li>
                <li>
                  Autorizar desde já que a Eventozz entre em contato e solicite
                  junto ao local do evento, fornecedores ou demais partes
                  interessadas informações e documentações que considere
                  necessárias;
                </li>
                <li>
                  Não agir como qualquer outra pessoa ou entidade. Isso inclui
                  não publicar eventos ou conteúdos caso o Usuário da plataforma
                  seja um promoter, comissário ou afins. Para os promoters
                  oficiais do evento (prévia e devidamente autorizados pelo
                  Organizador) fazerem suas vendas, a Eventozz disponibiliza a
                  ferramenta “Afiliados”. Essa ferramenta deve ser utilizada
                  nesses casos desde que o evento oficial seja cadastrado na
                  Eventozz pelo Organizador responsável;
                </li>
                <li>
                  Não publicar eventos ou quaisquer conteúdos que sejam ilegais,
                  racistas, ameaçadores, difamatórios, obscenos, vulgares ou
                  invasivos da privacidade alheia;
                </li>
                <li>
                  Ter total responsabilidade sobre os links e redirecionamentos
                  postados na descrição da página pública do evento. A Eventozz
                  não tem responsabilidade sobre os mesmos e não atesta sua
                  veracidade ou confiabilidade;
                </li>
                <li>
                  Ser responsável por instruir os Compradores, bem como
                  disponibilizar e divulgar links de acesso em casos de eventos
                  que venham a ocorrer virtualmente;
                </li>
                <li>
                  Cadastrar corretamente e antes do início das vendas os dados
                  para o repasse dos valores arrecadados com as vendas de
                  ingressos. Qualquer equívoco ou atraso no cadastramento desses
                  dados é de inteira responsabilidade do Organizador;
                </li>
                <li>
                  Zelar pela não ocorrência de fraude nas compras e auxiliar a
                  Eventozz na apuração de eventual suspeita de fraude;
                </li>
                <li>
                  Comunicar imediatamente à Eventozz casos de cancelamento de
                  evento, cancelamento de atração, alteração de data, alteração
                  de local ou qualquer outra alteração relevante referente ao
                  seu evento;
                </li>
                <li>
                  Arcar com o reembolso de todos os Compradores caso o evento,
                  independentemente do motivo, não ocorra, ou mesmo quando este
                  não for entregue em sua plenitude, conforme anunciado. De
                  igual modo é assegurado à Eventozz o direito de regresso
                  contra o Organizador por quaisquer valores que seja compelida
                  a pagar aos Compradores em virtude de ação, reclamação ou
                  processo administrativo, seja judicial ou extrajudicial;
                </li>
                <li>
                  Ser o único e exclusivo responsável pelo evento e conteúdo
                  cadastrado, isentando a Eventozz de qualquer responsabilidade
                  sobre o mesmo, bem como por todo e qualquer ato ou fato
                  jurídico que deles decorram, por todos os tributos (em
                  especial Imposto Sobre Serviço de Qualquer Natureza), direitos
                  autorais, contribuições ao ECAD e demais encargos ligados aos
                  eventos, assim como pelo pagamento total do local,
                  funcionários e fornecedores contratados para execução do
                  evento. A Eventozz não é uma Organizadora de eventos.
                  Fornecemos a plataforma para que os Organizadores vendam seus
                  ingressos/inscrições/contribuições e gerenciem seus
                  participantes;
                </li>
                <li>
                  Isentar a Eventozz de qualquer dano causado no caso de
                  paralisações, sejam parciais ou totais, dos seus serviços,
                  decorrentes de falta temporária de energia elétrica, de pane
                  nos serviços das concessionárias, de falhas nos serviços
                  oferecidos pela redes telefônicas e outros provedores,
                  inclusive provedores de hospedagem, de greves, tumultos ou
                  quaisquer outros fatos que não estejam diretamente ligados ao
                  serviço. A Eventozz também não será responsável por qualquer
                  dano causado por motivo de caso fortuito ou força maior, bem
                  como fato de terceiro;
                </li>
                <li>
                  Isentar a Eventozz de qualquer responsabilidade civil, de
                  natureza moral ou material, inclusive lucros cessantes e danos
                  emergentes, advinda dos atos próprios da produção de eventos.
                  O Organizador fica ciente desde já que, havendo eventual
                  condenação judicial contra a Eventozz, essa poderá efetuar
                  imediatamente a cobrança do valor que for obrigada a pagar,
                  abrindo o Produtor mão da necessidade de ajuizamento de ação
                  de regresso. Caso não haja composição amigável e a Eventozz
                  seja obrigada a arcar com prejuízos decorrentes de situações
                  advindas de processos judiciais, a Eventozz ajuizará ação de
                  regresso contra o Organizador, e esse restará obrigado a arcar
                  com as taxas judiciais, custas e honorários de advogado no
                  valor de 20% (vinte por cento) sobre o valor da causa.
                </li>
              </ul>
              <p>
                Se esses compromissos forem desrespeitados, a Eventozz poderá
                suspender ou encerrar a conta do Organizador e recusar a prover
                quaisquer de nossos serviços.
              </p>
              <hr></hr>
              <h4>
                Compra e utilização de ingressos/inscrições/contribuições:
              </h4>
              <p>
                A Eventozz criou a plataforma e o Comprador a utiliza para
                comprar os ingressos/inscrições/contribuições. Ao fazê-lo, o
                Comprador se compromete a:
              </p>
              <ul>
                <li>
                  Inserir dados corretos, completos e atualizados. Caso
                  contrário, a compra poderá ser invalidada e o Comprador, caso
                  queira, terá que fazer uma nova compra, estando sujeito às
                  condições e disponibilidade de
                  ingressos/inscrições/contribuições nesse segundo momento;
                </li>
                <li>
                  Arcar com os valores cobrados na fatura de cartão de crédito,
                  caso essa seja a forma de pagamento escolhida pelo Comprador.
                  A cobrança poderá vir em nome da Eventozz ou da Pagarme, nosso
                  processador de pagamentos;
                </li>
                <li>
                  Arcar com o valor total e integral do boleto e paga-lo até a
                  data do seu vencimento, caso o Comprador opte por esse método
                  de pagamento. Após a data limite de pagamento, o boleto deixa
                  de ser válido para a compra e o
                  ingresso/inscrição/contribuição não estará mais reservado para
                  o Comprador. Além disso, esclarecemos que a Eventozz não faz
                  retenção de tributos na fonte e, assim, a cobrança gerada deve
                  ser paga sem nenhum desconto por esse ou qualquer outro
                  motivo;
                </li>
                <li>
                  Comprar os ingressos/inscrições/contribuições apenas através
                  do domínio{' '}
                  <a href="https://www.eventozz.com">www.eventozz.com</a>, suas
                  páginas e subdomínios, através do aplicativo Eventozz ou em
                  pontos de venda autorizados. A Eventozz não se responsabiliza
                  e não atesta a veracidade ou confiabilidade de
                  ingressos/inscrições/contribuições adquiridos via terceiros,
                  outros sites ou qualquer ponto de venda não autorizado;
                </li>
                <li>
                  Declarar-se ciente que o processador de pagamentos parceiro da
                  Eventozz fará a apuração de eventuais fraudes nas transações
                  processadas através de sistema de análise de risco próprio e
                  de parceiros. A análise de um eventual risco da transação é
                  feita com base em padrões históricos, conferência dos dados
                  cadastrais e bases externas, podendo resultar numa
                  probabilidade de que determinada transação seja fraudulenta.
                  Feita a avaliação de risco transacional, a compra poderá ser
                  aprovada ou não, segundo critérios estabelecidos pelo
                  processador de pagamentos. A não aprovação não gerará
                  expectativa de direito, tampouco indenização de qualquer
                  espécie;
                </li>
                <li>
                  Fornecer todas as informações e documentos que forem
                  solicitados, caso seja necessária alguma validação da compra
                  por parte da Eventozz ou de nosso processador de pagamentos;
                </li>
                <li>
                  Promover a segurança do ingresso a partir do momento em que
                  ele está em sua posse, não o divulgando ou permitindo o
                  acesso, de nenhuma maneira, de terceiros a ele;
                </li>
                <li>
                  Arcar com a taxa eventualmente cobrada pela processadora de
                  pagamentos para fins de saque do crédito decorrente do estorno
                  no caso de cancelamento da compra por parte do Comprador;
                </li>
                <li>
                  Isentar a Eventozz de qualquer responsabilidade sobre a
                  realização dos eventos para os quais foram adquiridos
                  ingressos/inscrições/contribuições. A Eventozz não é uma
                  Organizadora de eventos. Fornecemos a plataforma para que os
                  Organizadores vendam seus ingressos/inscrições/contribuições e
                  gerenciem seus participantes.
                </li>
              </ul>
              <p>
                Se esses compromissos forem desrespeitados, a Eventozz poderá
                suspender ou encerrar a conta do Comprador e se recusar a prover
                quaisquer de nossos serviços. Os
                ingressos/inscrições/contribuições comprados também poderão ser
                invalidados.
              </p>
              <hr></hr>
              <h3 className="uppercase">2. FORMA DE ENTREGA DOS INGRESSOS</h3>
              <ul>
                <li>
                  Os ingressos/inscrições/contribuições comprados através da
                  plataforma Eventozz são enviados em formato eletrônico (PNG)
                  ao telefone (Whatsapp) e e-mail indicado pelo Comprador tão
                  logo o pagamento é aprovado. Pagamentos aprovados em tempo
                  real habilitam que os ingressos/inscrições/contribuições
                  também sejam baixados na própria tela de confirmação de
                  compra. O Comprador deve ficar atento, pois o ingresso poderá
                  ser direcionado à caixas aleatórias como “spam”, “social” ou
                  “promoções” do endereço de e-mail utilizado, o que não gerará
                  qualquer tipo de indenização ao Comprador;
                </li>
                <li>
                  Além das formas de entrega descritas acima, os
                  ingressos/inscrições/contribuições também podem ser acessados
                  na área autenticada do site ou aplicativo. Para isso, o
                  Comprador deve criar um Usuário gratuitamente na Eventozz,
                  fornecendo o mesmo e-mail utilizado no ato da compra. O
                  ingresso pode ser acessado na área “Minhas Compras” do site ou
                  pelo aplicativo da Eventozz.
                </li>
              </ul>
              <hr></hr>
              <h3 className="uppercase">
                3. DIREITOS AUTORAIS SOBRE O CONTEÚDO ENVIADO
              </h3>
              <ul>
                <li>
                  Ao disponibilizar qualquer conteúdo na nossa plataforma, o
                  Organizador nos concede uma licença não exclusiva e
                  irrevogável para a publicação deste conteúdo;
                </li>
                <li>
                  O Organizador declara que tem todos os direitos necessários
                  para outorgar esta licença e que o seu conteúdo não viola
                  direitos autorais, patentes, marcas, segredo industrial,
                  direito de propriedade, de representação e autoral ou
                  propriedade intelectual de terceiros, visto que o Organizador
                  é o único e exclusivo responsável pelo evento e pela
                  publicação deste conteúdo. Responsabiliza-se, dessa maneira,
                  perante a Eventozz ou eventuais terceiros interessados pelas
                  obrigações assumidas no momento em que passa a utilizar a
                  plataforma Eventozz;
                </li>
                <li>
                  A Eventozz poderá monitorar, editar ou remover conteúdos, no
                  todo ou em parte, que não sigam os critérios acima e poderá
                  divulgar o seu conteúdo por qualquer motivo – seja para
                  responder a reclamações de violação de direitos de terceiros,
                  pela segurança de nossos Usuários, por exigência da lei ou por
                  quaisquer outros motivos.
                </li>
              </ul>
              <hr></hr>
              <h3 className="uppercase">4. PROCESSAMENTO DE VENDAS</h3>
              <h4>Processamento de Pagamentos:</h4>
              <ul>
                <li>
                  O processamento de pagamentos referentes às vendas de
                  ingressos, inscrições e/ou contribuições para eventos
                  inicia-se na página do evento publicado pelo Organizador.
                  Nela, o Comprador indica os ingressos/inscrições/contribuições
                  desejados, escolhe um método de pagamento dentre as opções
                  disponíveis e procede com a finalização da compra. Ao receber
                  a confirmação de aprovação do pagamento, e através de um
                  intermediário financeiro, a Eventozz irá recolher o valor
                  correspondente, deduzir a taxa de serviço e repassar ao
                  Organizador o valor restante, de acordo com o modelo de
                  recebimento escolhido. Todas as transações realizadas através
                  da Eventozz passam por processos internos, automatizados, de
                  análise de risco que são complementados pelo nosso processador
                  de pagamentos;
                </li>
                <li>
                  Por segurança, algumas compras realizadas utilizando cartão de
                  crédito e que necessitem de alguma confirmação ou validação
                  adicional poderão passar pelo processo de análise aprofundada
                  do nosso processador de pagamentos. A aprovação da compra
                  poderá ficar pendente até o término dessa análise. Em até no
                  máximo 48h após a compra, haverá uma resposta sobre a
                  aprovação ou não da transação.
                </li>
              </ul>
              <hr></hr>
              <h4>Cancelamento e reembolso de transações:</h4>
              <ul>
                <li>
                  Nossa plataforma permite que o Organizador solicite à
                  Eventozz, até o 2º dia útil após o término do evento, o
                  cancelamento de uma venda e o consequente reembolso do valor
                  pago pelo Comprador. É de responsabilidade do Organizador
                  comunicar a seus potenciais Compradores sua própria política
                  de cancelamento, que deve ser compatível com o período máximo
                  de reembolso permitido pela Eventozz (e de acordo com o
                  previsto no Código de Defesa do Consumidor) e solicitar,
                  quando considerar necessário, o cancelamento da transação
                  através das ferramentas presentes na plataforma;
                </li>
                <li>
                  A Eventozz é totalmente responsável por quaisquer disputas
                  abertas pelo compradores, como chargebacks, reclamações
                  diversas, cancelamento e estornos, e irá iniciar e processar
                  cancelamentos sem a necessidade da intervenção ou aprovação do
                  Organizador, nos casos de:
                  <ul>
                    <li>
                      não cumprimento da política de reembolsos do evento por
                      parte do Organizador;
                    </li>
                    <li>
                      de detecção de indícios de fraude em compras realizadas,
                      estejam elas pendentes de aprovação ou já aprovadas, sejam
                      os indícios da parte do Comprador ou do Organizador;
                    </li>
                    <li>cancelamento do evento;</li>
                    <li>erro técnico no processamento da transação;</li>
                    <li>
                      verificarmos um número elevado de queixas referentes a um
                      determinado evento por parte dos Compradores;
                    </li>
                  </ul>
                  A Eventozz reserva o direito de deduzir estes valores do
                  repasse de vendas ao Organizador e/ou realizar posteriores
                  cobranças ao mesmo podendo, inclusive, ocasionar retenção de
                  valores do repasse de vendas de outros eventos deste mesmo
                  organizador e ou ser emitido um boleto bancário referente a
                  dívida.
                </li>
                <li>
                  Se identificarmos que algum evento não foi realizado ou foi
                  realizado de maneira distinta ao que foi anunciado, nos
                  resguardamos o direito de reembolsar todas as compras ou mesmo
                  suspender provisoriamente o valor do repasse até que a
                  situação esteja regularizada entre todas as partes: Eventozz,
                  Organizador e Compradores;
                </li>
                <li>
                  Especificamente em casos de compras com indícios de fraude, o
                  setor de Análise de Risco da Eventozz poderá solicitar ao
                  Comprador a cópia digitalizada dos documentos do titular do
                  cartão de crédito utilizado nas compras, visando legitimá-las.
                  O prazo de envio de tal documentação será estabelecido pela
                  Eventozz, de acordo com a data e horário em que o evento
                  ocorrerá, de forma que, remetido o documento pelo Comprador, a
                  Eventozz fará a análise do mesmo e dará um retorno através do
                  mesmo e-mail utilizado para a compra. Caso os documentos não
                  sejam enviados pelo Comprador no prazo determinado, ficará a
                  cargo da Eventozz cancelar/suspender ou não a transação, de
                  acordo com o contexto da situação. A Eventozz ainda poderá
                  solicitar ao Comprador que seja apresentado na portaria do
                  evento um documento de identidade com foto do titular do
                  cartão ou o próprio cartão utilizado na compra para fins de
                  validação da compra;
                </li>
                <li>
                  Em caso de arrependimento da compra, o reembolso do valor pago
                  somente será efetuado acaso a solicitação seja feita ao
                  Organizador no prazo de até 7 (sete) dias a contar da data
                  compra, e desde que realizado o pedido de devolução com pelo
                  menos 48 (quarenta e oito) horas de antecedência do horário de
                  início do evento;
                </li>
                <li>
                  Acaso o Comprador venha a desistir da compra efetuada via
                  débito online ou boleto bancário dentro dos termos expostos no
                  item anterior, fica desde já ciente da necessidade de entrar
                  em contato com o suporte para que o reembolso seja efetuado;
                </li>
                <li>
                  Cada evento vendido através da Eventozz tem sua própria
                  política de cancelamento e reembolso, a critério do
                  Organizador. Para verificar a política específica para os
                  ingressos/inscrições/contribuições adquiridos, o Comprador
                  deve procurar essa informação na página de venda do evento ou
                  entrar em contato com o Organizador do evento. É de
                  responsabilidade do Comprador informar-se sobre a política
                  específica do evento que deseja participar e, caso necessário,
                  entrar em contato com a produção para solicitar o cancelamento
                  de sua compra e reembolso do pagamento;
                </li>
                <li>
                  Caso seja necessário reembolsar Compradores, o Organizador
                  deverá necessariamente fazê-lo através da Plataforma Eventozz,
                  ou seja, não poderá realizar o reembolso por conta própria.
                </li>
              </ul>
              <hr></hr>
              <h3 className="uppercase">5. RECEBIMENTO DAS VENDAS</h3>
              <h4>Modelos de recebimento:</h4>
              <p>
                O organizador recebe o valor das vendas realizadas através de
                sua conta bancária
              </p>
              <ul>
                <li>
                  Repasse via conta bancária: nesse modelo, o valor total
                  vendido, decrescido da taxa de serviço cobrada pela Eventozz,
                  será repassado no 3º dia útil após o término real do evento,
                  por transferência bancária, a uma conta indicada pelo
                  Organizador através da plataforma. Fica desde já consignado
                  que o Organizador do evento poderá incluir e/ou autorizar o
                  repasse para contas diferentes das já cadastradas, desde que
                  realize tal solicitação com pelo menos cinco dias úteis de
                  antecedência da data do repasse final. A Eventozz esclarece
                  que o repasse leva em conta a data em que o evento irá
                  realmente ocorrer, e não a data cadastrada na plataforma. Caso
                  a data indicada para o evento na Eventozz não corresponda à
                  data real do evento, o repasse poderá ser postergado até o 3º
                  dia útil após a data em que o evento, de fato, for finalizado;
                </li>
                <li>
                  O prazo de repasse pode ser negociado, podendo assim ser
                  antecipado caso seja economicamente viavel para a Eventozz. A
                  aprovação do adiantamento é exclusiva de analise e decisão da
                  Eventozz
                </li>
                <li>
                  Em caso de reembolsos pendentes, reclamações de clientes,
                  cancelamento, adiamento, não realização, dificuldade ou
                  impedimento de acesso ao evento ou qualquer alteração
                  substancial das características do evento, ou alegações de
                  fraude, a Eventozz se reserva o direito de reter estes fundos
                  pelo tempo considerado necessário ou de solicitar à Pagarme
                  que os valores repassados à conta do Organizador junto a eles
                  sejam retidos para a adoção das medidas cabíveis. Caso o
                  Organizador não tome as medidas necessárias para reembolsar os
                  Compradores, a Eventozz se reserva no direito de fazê-lo, sem
                  necessidade de comunicação prévia;
                </li>
                <li>
                  Caso a Eventozz necessite fazer reembolsos de compras após o
                  recebimento de valores por parte do Organizador, esses
                  reembolsos serão posteriormente cobrados do mesmo podendo,
                  inclusive, ocasionar retenção de valores de vendas de outros
                  eventos deste mesmo Organizador.
                </li>
              </ul>
              <hr></hr>
              <h4>Contestações e estornos:</h4>
              <p>
                A comercialização de produtos e serviços via internet tem como
                característica principal propulsionar vendas, ganhar escala,
                reduzir custos fixos, estar disponível 24 h/dia, além de
                proporcionar comodidade aos clientes. Entretanto, a presença
                online também incorre alguns riscos, principalmente de fraude
                cibernética.
              </p>
              <ul>
                <li>
                  A contestação é uma reclamação de cobrança indevida,
                  solicitada pelo titular do cartão de crédito junto à operadora
                  de seu cartão. Ela pode ser iniciada por diversas razões,
                  como, por exemplo, o esquecimento de que a compra foi
                  realizada, a utilização do cartão por outros membros da
                  família, ou pode também ser resultado de uma compra
                  fraudulenta realizada por terceiros. Contestações reportadas
                  são analisadas pela própria operadora do cartão e pelo
                  processador de pagamentos utilizado pela Eventozz;
                </li>
                <li>
                  Estornos ocorridos nas vendas realizadas de quaisquer eventos
                  vendidos via Eventozz ficarão a cargo do Organizador,
                  independentemente da razão inicial para a contestação. É de
                  responsabilidade da Eventozz e de nosso processador de
                  pagamentos tomar as devidas precauções para minimizar
                  potenciais fraudes nas compras realizadas para quaisquer
                  eventos, além de participar ativamente na disputa das
                  contestações iniciadas, mas diferentes perfis de eventos
                  atraem maior ou menor número de contestações, além da
                  abordagem implementada pela organização de um evento no
                  controle de entrada de participantes ser um fator fundamental
                  no valor deste índice.
                </li>
              </ul>
              <hr></hr>
              <h4>Taxa de serviço e emissão de nota fiscal:</h4>
              <ul>
                <li>
                  A Eventozz cobra uma taxa de serviço para o licenciamento e
                  uso da plataforma, calculada a partir das vendas online
                  processadas através da Eventozz. A taxa cobrada é de 6% (seis
                  por cento) sobre o valor do ingresso para pagamentos via PIX e
                  8% (oito por cento) + juros de parcelamento para cartão de
                  crédito, com um valor mínimo de taxa de R$ 2,00 por ingresso.
                  O Organizador poderá, através da plataforma, optar se esta
                  taxa será repassada ao Comprador ou absorvida por ele;
                </li>
                <li>
                  Para os eventos em que houver incidência de taxa de serviço,
                  será emitida uma nota fiscal na primeira semana do mês
                  seguinte à data de pagamento do evento em nome do Organizador
                  do evento, tomador do serviço, no valor referente ao total
                  cobrado em taxas. É responsabilidade do Organizador cadastrar
                  na plataforma os dados necessários para que a Eventozz possa
                  emitir a nota fiscal em tempo hábil;
                </li>
                <li>
                  Os impostos referentes ao montante total vendido pelo
                  Organizador do evento através da plataforma às devidas esferas
                  públicas são responsabilidade única e exclusiva do Organizador
                  do evento.
                </li>
              </ul>
              <hr></hr>
              <h4 className="uppercase">6. DIREITOS DE MARCA</h4>
              <p>
                A marca Eventozz® e os logos de nossa empresa exibidos no site,
                em seus subdomínios e no aplicativo Eventozz são marcas
                registradas. A oferta de nossos serviços não implica nenhuma
                concessão de direitos de usar nossas marcas em conexão com
                qualquer marca comercial sem o nosso consentimento prévio e por
                escrito.
              </p>
              <hr></hr>
              <h4 className="uppercase">7. CANCELAMENTO</h4>
              <p>
                A Eventozz, a seu exclusivo critério, poderá cancelar sua conta
                e remover qualquer e/ou todo o conteúdo criado por você através
                da plataforma, a qualquer momento e por qualquer motivo,
                incluindo, sem limitação, se verificarmos que você violou estes
                Termos de Uso.
              </p>
              <hr></hr>
              <h4 className="uppercase">8. LIMITAÇÃO DE RESPONSABILIDADE</h4>
              <ul>
                <li>
                  A Eventozz não produz eventos. Os eventos vendidos através da
                  nossa plataforma são disponibilizados por Organizadores e
                  casas de eventos que utilizam a Eventozz como tecnologia
                  facilitadora para a venda de seus próprios
                  ingressos/inscrições/contribuições e gestão de seus
                  participantes;
                </li>
                <li>
                  Não participamos da produção dos eventos cujos
                  ingressos/inscrições/contribuições são vendidos através de
                  nossa plataforma e não somos responsáveis, sob quaisquer
                  circunstâncias, por eventuais perdas e danos relacionados à
                  compra de ingressos/inscrições/contribuições e à participação
                  em eventos que utilizam os nossos serviços;
                </li>
                <li>
                  A Eventozz não será responsável, sob quaisquer circunstâncias,
                  por eventuais perdas e danos relacionados ao uso de nossos
                  serviços. Nos reservamos o direito de modificar, suspender ou
                  descontinuar temporariamente os serviços que prestamos.
                </li>
              </ul>
              <hr></hr>
              <h4 className="uppercase">9. ISENÇÃO</h4>
              <p>
                Você concorda em isentar a Eventozz e seus diretores,
                funcionários, parceiros e colaboradores, de todos e quaisquer
                danos, perdas, responsabilidades e despesas resultantes de
                disputas entre você e terceiros em conexão com a utilização de
                nossos serviços.
              </p>
              <hr></hr>
              <h4 className="uppercase">10. INDENIZAÇÃO</h4>
              <p>
                Você concorda em indenizar a Eventozz e seus diretores,
                funcionários, parceiros e colaboradores por todos e quaisquer
                danos, perdas, responsabilidades e despesas resultantes de
                reivindicações ou investigações feitas por terceiros e que
                estejam relacionadas ao conteúdo por você divulgado na
                plataforma da Eventozz ou à violação destes Termos de Uso.
              </p>
              <hr></hr>
              <h4 className="uppercase">
                11. DA POLÍTICA DE DIVULGAÇÃO DE INFORMAÇÕES
              </h4>
              <ul>
                <li>
                  Recebendo a Eventozz notificação ou petição extrajudicial
                  requerendo que seja revelada a identidade do Organizador em
                  razão de suposto uso da Internet para fins ilegais ou
                  ilegítimos, a Eventozz comunicará o ​Organizador da existência
                  do pedido de revelação, ficando desde já autorizada a
                  responder a notificação ou petição extrajudicial, prestando as
                  informações requeridas, sejam elas atinentes ou não às vendas
                  de ingressos/inscrições/contribuições. A Eventozz se
                  resguarda, inclusive, no direito de enviar o “borderô” das
                  vendas que fica disponível para acesso em sua plataforma;
                </li>
                <li>
                  A Eventozz obedecerá qualquer ordem judicial ou de órgãos
                  públicos de revelar identidade do Organizador independente de
                  comunicação prévia ao Organizador ou obediência ao
                  procedimento contido no item anterior. O Organizador desde
                  logo autoriza a Eventozz a prestar quaisquer informações a
                  respeito do uso de Internet do Organizador requisitada por
                  qualquer órgão ou autoridade pública, independente de
                  comunicação prévia;
                </li>
                <li>
                  A plataforma da Eventozz é constantemente aprimorada com novas
                  funcionalidades e novos serviços prestados - e às vezes será
                  necessário alterar nossos Termos de Uso. A cada vez que isto
                  ocorrer atualizaremos a data da última modificação, disponível
                  no começo da página. É de sua responsabilidade verificar os
                  Termos de Uso periodicamente. O uso continuado da plataforma
                  após quaisquer alterações destes Termos de Uso indica sua
                  concordância com os termos revistos.
                </li>
              </ul>
              <hr></hr>
              <h4 className="uppercase">12. DISPOSIÇÕES GERAIS</h4>
              <ul>
                <li>
                  As negociações feitas pessoalmente pela Eventozz com os
                  Compradores ou Organizadores, presencialmente ou a distância,
                  prevalecem sobre estes Termos de Uso, restando ele como fonte
                  subsidiária de interpretação e correção de lacunas;
                </li>
                <li>
                  Todas as opiniões expressadas nos comentários deste site não
                  expressam a opinião da Eventozz, mas tão somente a opinião de
                  quem os escreveu;
                </li>
                <li>
                  A Eventozz não se responsabiliza por qualquer dano ou prejuízo
                  com envio de informações ou de conteúdo publicitário que não
                  tenham sido disparados pela própria Eventozz, sejam esses
                  últimos legítimos ou indesejados, e também não se
                  responsabiliza pelo conteúdo das opiniões postadas na página
                  dos eventos;
                </li>
                <li>
                  Os Termos de Uso Eventozz não geram qualquer direito de
                  exclusividade, a menos que as partes venham a firmar acordo
                  escrito nesse sentido, bem como nenhum outro direito ou
                  obrigação diverso daqueles aqui expressamente previstos,
                  ficando afastada qualquer relação, ostensiva ou remota, de
                  sociedade, joint venture ou associação;
                </li>
                <li>
                  A eventual tolerância quanto a qualquer violação dos termos e
                  condições do contido neste domínio será considerada mera
                  liberalidade e não será interpretada como novação, precedente
                  invocável, renúncia a direitos, alteração tácita dos termos
                  contratuais, direito adquirido ou alteração contratual;
                </li>
                <li>
                  A comunicação entre a Eventozz e o Usuário deverá ser
                  realizada pelos canais de atendimento indicados e
                  disponibilizados na Plataforma Eventozz.
                </li>
              </ul>
              <p>
                Violações aos Termos de Uso devem ser relatadas ao e-mail{' '}
                <a href="mailto:contato@eventozz.com">contato@eventozz.com</a>.
              </p>
              <hr></hr>
              <h4 className="uppercase">13. CONSENTIMENTO</h4>
              <p>
                Ao utilizar nossa plataforma, você concorda com os Termos de Uso
                contidos neste documento.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Termo
