import React from 'react'
import { Redirect } from 'react-router-dom'

// Authentication related pages
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'
import TermoUso from '../pages/Authentication/termos-de-uso'
import ForgetPwd from '../pages/Authentication/ForgetPassword'
// Dashboard
import Dashboard from '../pages/Dashboard/index'
import Eventos from '../pages/Eventos/index'

import Pdv from '../pages/PDV/index'

import Vendas from '../pages/Vendas/index'
import Ingressos from '../pages/Ingressos/index'
import Extra from '../pages/Extra/index'
import Cupons from '../pages/Cupons/index'
import Sorteio from '../pages/Sorteio/index'
import Etiqueta from '../pages/Etiqueta/index'
import Certificados from '../pages/Certificado/index'
import Financeiro from '../pages/Financeiro/index'
import FluxoDeCaixa from '../pages/FluxoDeCaixa/index'
import PainelAfiliado from '../pages/PainelAfiliado/index'
import UserGeral from 'pages/UserGeral'

import FinanceiroAdmin from '../pages/FinanceiroAdmin/index'
import ListaUsuariosAdmin from '../pages/ListaUsuarios/index'
import UsuarioDash from '../pages/UsuarioDash/index'

import { NovoRepasse } from 'pages/Financeiro/novo-repasse'
import { NovoIngressoEspecial } from 'pages/Ingressos/novo-ingresso-especial'
import { NovoFluxo } from 'pages/FluxoDeCaixa/novo'
import { EventozzNovo } from 'pages/Eventos/novo'
import { AtividadesEvento } from 'pages/Atividades'
import { CadastroAtividade } from 'pages/Atividades/novo'
import { ConfiguracaoAtividades } from 'pages/Atividades/configuracoes'
import { VendasAtividades } from 'pages/VendasAtividades'
import { UserProfile } from 'pages/Authentication/user-profile'
import { PermissionsOrganizer } from 'pages/Eventos/permissionsOrganizer'
import { TicketTypes } from 'pages/Eventos/tickets'

const userRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/eventozz', component: Eventos },
  { path: '/eventozz/novo', component: EventozzNovo },
  { path: '/eventozz/:eventId', component: EventozzNovo },
  { path: '/eventozz/:eventId/ingressos-disponiveis', component: TicketTypes },
  {
    path: '/eventozz/:eventId/adicionar-permissoes-organizador',
    component: PermissionsOrganizer
  },
  {
    path: '/eventozz/:eventId/editar-permissoes-organizador/:userId',
    component: PermissionsOrganizer
  },
  { path: '/extra-eventozz', component: Extra },
  { path: '/cupons-eventozz', component: Cupons },
  { path: '/certificados', component: Certificados },
  { path: '/sorteio', component: Sorteio },
  { path: '/atividades-do-evento', component: AtividadesEvento },
  {
    path: '/atividades-do-evento/:eventId/nova/',
    component: CadastroAtividade
  },
  {
    path: '/atividades-do-evento/:eventId/editar/:activityId',
    component: CadastroAtividade
  },
  {
    path: '/atividades-do-evento/configuracoes/:eventId',
    component: ConfiguracaoAtividades
  },
  {
    path: '/eventozz/:eventId/novo-ingresso-especial',
    component: NovoIngressoEspecial
  },
  { path: '/perfil', component: UserProfile },
  { path: '/vendas', component: Vendas },
  { path: '/vendas-atividades', component: VendasAtividades },
  { path: '/pdv', component: Pdv },
  { path: '/ingressos', component: Ingressos },

  { path: '/etiqueta', component: Etiqueta },
  { path: '/financeiro', component: Financeiro },
  { path: '/financeiro/novo-repasse', component: NovoRepasse },
  { path: '/gestao-de-fluxo/novo/:eventId', component: NovoFluxo },
  { path: '/financeiro-root', component: FinanceiroAdmin },
  { path: '/ListaUsuarios-root', component: ListaUsuariosAdmin },
  { path: '/dash-usuario', component: UsuarioDash },
  { path: '/painel-afiliado', component: PainelAfiliado },
  { path: '/gestao-de-fluxo', component: FluxoDeCaixa },
  { path: '/userGeral', component: UserGeral },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> }
]

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
  { path: '/termo-de-uso', component: TermoUso }
]

export { userRoutes, authRoutes }
