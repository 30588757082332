import React from 'react'
import ReactModal from 'react-modal'
import { Container, Row, Col, Button, Card } from 'reactstrap'
import styles from './styles.module.scss'

export const ModalConfirm = ({
  showModal,
  onClose,
  title,
  text,
  onConfirm
}) => {
  const handleCancelSendQrcodes = () => {
    onClose()
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className={styles.reactModal}
    >
      <Container>
        <Row className={styles.modalText}>
          <Col xs={12}>
            <h1
              className={styles.titleRoot}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Col>
          <Col xs={12}>
            <h5
              className={styles.titleRoot}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </Col>
        </Row>

        <Row className={styles.footerModalButtons}>
          <Col xs={6}>
            <Button
              className={styles.buttonsModal}
              onClick={onConfirm}
              variant="contained"
              color="primary"
            >
              Confirmar
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              className={styles.buttonsModal}
              onClick={handleCancelSendQrcodes}
              variant="contained"
              color="secondary"
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </Container>
    </ReactModal>
  )
}
