import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import React, { useState } from 'react'
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap'
import { Link, Redirect } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

// actions
import { loginUser } from '../../store/actions'

// import images
import logoSm from '../../assets/images/eventozz/Icon_Branco.svg'

const Login = () => {
  const [login, setLogin] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    const result = await loginUser(values)
    if (result.status === 200) {
      setError(false)
      setMessage('')
      console.log(result.message)
      setLogin(true)
    } else {
      setLogin(false)
      setError(true)
      setMessage(result.message)
    }
  }

  return login ? (
    <Redirect to="/dashboard" />
  ) : (
    <React.Fragment>
      <MetaTags>
        <title>Login | Eventozz Dashboard</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">Bem Vindo!</h5>
                    <p className="text-white-50">
                      Faça login e use o Eventozz.
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {error && <Alert color="danger">{message}</Alert>}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value=""
                          className="form-control"
                          placeholder="Insira o email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Senha"
                          value=""
                          type="password"
                          required
                          placeholder="Insira a Senha"
                        />
                      </div>

                      <Row className="mb-3">
                        <Col sm={6}></Col>
                        <Col sm={6} className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Entrar
                          </button>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password">
                            <i
                              className="mdi mdi-lock"
                              // onClick={() => setShowModalRecovery(true)}
                            ></i>{' '}
                            Esqueceu sua senha?
                          </Link>
                        </div>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Não Possui uma conta?{' '}
                  <Link to="register" className="fw-medium text-primary">
                    {' '}
                    Inscreva-se{' '}
                  </Link>{' '}
                </p>
                <p>
                  © {new Date().getFullYear()} Eventozz. Desenvolvido por Ctech
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func
}
