import React, { useState, useCallback, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Col, Container, Row } from 'reactstrap'
import { Loading } from 'components/Loading'
import 'jodit/build/jodit.min.css'
// import { TabPanel } from 'components/tabPanel'
import { toast } from 'react-toastify'
import { Button, Card, Form } from 'react-bootstrap'
import styles from './styles.module.scss'
import { activityService } from 'services/activity'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { CurrencyInput } from 'components/CurrencyInput'
import ReactDatePicker from 'react-datepicker'
import { format, isValid } from 'date-fns'
import htmlToDraft from 'html-to-draftjs'
import { CustomButton } from 'components/customButton'
import draftToHtml from 'draftjs-to-html'

export const ActivityForm = ({ activityId, eventId }) => {
  console.log('activityId', activityId)
  const { find, createActivity, updateActivity } = activityService

  const [loading, setLoading] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [activityData, setActivityData] = useState({
    name: null,
    headerText: EditorState.createEmpty(),
    description: EditorState.createEmpty(),
    lotes: [
      {
        name: null,
        amount: null,
        availableQtd: null,
        validFrom: null,
        validTo: null,
        isFree: false,
        active: true,
        showPromotionalFields: false,
        promotionalQtd: null,
        promotionalAmount: null,
        isNew: true,
        updated: false
      }
    ]
  })
  const handleClose = () => {
    setLoading(false)
  }

  const handleChangeTicketType = async ({ value, field, indexLote }) => {
    console.log('alsdkfslkdflaskdflaskdfj')
    if (indexLote === -1) {
      setActivityData((prev) => ({
        ...prev,
        updated: true,
        errorMessage: prev?.errorMessage
          ? {
              ...prev.errorMessage,
              [field]: null
            }
          : null,
        [field]: value
      }))
    } else {
      const newActivityData = {
        ...activityData,
        lotes: activityData.lotes.map((item, index) => {
          if (index === indexLote) {
            return {
              ...item,
              updated: true,
              errorMessage: item?.errorMessage
                ? {
                    ...item.errorMessage,
                    [field]: null
                  }
                : null,
              [field]: value
            }
          }
          return item
        })
      }
      setActivityData(newActivityData)
    }
  }

  function handleAddActivityLote() {
    const newActivityData = {
      ...activityData,
      lotes: [
        ...activityData.lotes,
        {
          amount: null,
          availableQtd: null,
          validFrom: null,
          validTo: null,
          isFree: false,
          active: true,
          showPromotionalFields: false,
          promotionalQtd: null,
          promotionalAmount: null,
          isNew: true,
          updated: false
        }
      ]
    }
    setActivityData(newActivityData)
  }

  function handleRemoveLote(ticketIndex) {
    const activity = activityData?.lotes?.find(
      (item, index) => ticketIndex === index && !item.isNew
    )
    console.log(activity)
    if (activity) {
      const ticketTypesTemp = activityData.filter(
        (_, index) => index !== ticketIndex
      )
      setActivityData(ticketTypesTemp)
    } else {
      const lotesTemp = activityData.lotes.filter(
        (_, index) => index !== ticketIndex
      )
      setActivityData({ ...activityData, lotes: lotesTemp })
    }
  }

  function fillTextEditor(currentMsg) {
    if (!currentMsg) return null
    console.log(currentMsg)
    const blocksFromHtml = htmlToDraft(currentMsg)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    console.log(contentState)
    return EditorState.createWithContent(contentState)
  }

  function fillInitialData(data) {
    console.log(data)
    if (data?.lotes && data?.lotes?.length > 0) {
      const lotes = data.lotes.map((item) => {
        return {
          ...item,
          showPromotionalFields: !!(
            item?.promotionalQtd && item?.promotionalQtd > 0
          ),
          validFrom: isValid(new Date(item.validFrom))
            ? new Date(item.validFrom)
            : null,
          errorMessage: null,
          validTo: isValid(new Date(item.validTo))
            ? new Date(item.validTo)
            : null,
          updated: false,
          isNew: false
        }
      })
      console.log(lotes)
      setActivityData({
        name: data.name,
        headerText: fillTextEditor(data.headerText),
        description: fillTextEditor(data.description),
        lotes
      })
    }
  }

  const findActivity = useCallback(async () => {
    const response = await find({
      activityId
    })
    console.log(response)
    if (response.status === 200 && response?.data?.success) {
      console.log(response.data.data)
      setLoading(false)
      fillInitialData(response.data.data)
    } else if (response.status === 401) {
      toast.error('Você não está logado ou sua sessão expirou')
      setLoading(false)
      return false
    } else if (response.status === 500) {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
      )
      setLoading(false)
      return false
    } else {
      toast.error(
        response?.response?.data?.msg ||
          'Ocorreu um erro ao buscar os dados do evento'
      )
      setLoading(false)
      return false
    }
  }, [activityId])

  function handleCancel() {
    console.log('cancelado')
    window.location.href = '/atividades-do-evento'
  }

  async function handleSubmit() {
    // console.log(ticketTypes);
    // return
    try {
      setDisableButton(true)
      setLoading(true)

      let hasErrors = false

      let activityDataTemp = { ...activityData }

      const headerText = activityDataTemp.headerText
        ? draftToHtml(
            convertToRaw(activityDataTemp.headerText?.getCurrentContent())
          )
        : ''

      const description = activityDataTemp.description
        ? draftToHtml(
            convertToRaw(activityDataTemp.description?.getCurrentContent())
          )
        : ''

      if (!activityDataTemp.name) {
        hasErrors = true
        activityDataTemp.errorMessage = activityDataTemp.errorMessage
          ? {
              ...activityDataTemp.errorMessage,
              name: 'O campo Nome é obrigatório'
            }
          : {
              name: 'O campo Nome é obrigatório'
            }
      }

      const lotes = activityDataTemp.lotes?.map((item) => {
        console.log(item)
        if (item.deleted) {
          return item
        }
        item.errorMessage = item.errorMessage ? { ...item.errorMessage } : {}

        item.errorMessage = item.errorMessage ? { ...item.errorMessage } : {}

        const validFrom =
          item.validFrom && isValid(item.validFrom)
            ? format(item.validFrom, 'yyyy-MM-dd HH:mm')
            : null

        const validTo =
          item.validTo && isValid(item.validTo)
            ? format(item.validTo, 'yyyy-MM-dd HH:mm')
            : null

        if (item.availableQtd === null) {
          hasErrors = true
          item.errorMessage.availableQtd =
            'O campo Quantidade disponível é obrigatório'
        }
        if (item.amount === null) {
          if (item.isFree) {
            item.amount = 0
          } else {
            hasErrors = true
            item.errorMessage.amount = 'O campo Valor é obrigatório'
          }
        }
        if (!validFrom) {
          hasErrors = true
          item.errorMessage.validFrom = 'O campo Válido de é obrigatório'
        }
        if (!validTo) {
          hasErrors = true
          item.errorMessage.validTo = 'O campo Válido até é obrigatório'
        }
        if (
          !item.isFree &&
          item.showPromotionalFields &&
          item.promotionalQtd === null
        ) {
          hasErrors = true
          item.errorMessage.promotionalQtd =
            'O campo Quantidade promocional é obrigatório quando "Campos de promoção" está marcado'
        }
        if (
          !item.isFree &&
          item.showPromotionalFields &&
          item.promotionalAmount === null
        ) {
          hasErrors = true
          item.errorMessage.promotionalAmount =
            'O campo Valor promocional é obrigatório quando "Campos de promoção" está marcado'
        }
        return {
          ...item,
          validFromFormatted: validFrom,
          validToFormatted: validTo,
          headerTextFormatted: headerText,
          descriptionFormatted: description
        }
      })

      if (!activityData.lotes?.length) {
        toast.error('Adicione pelo menos um lote')
        setLoading(false)
        setDisableButton(false)
        return
      }
      console.log('here')
      if (hasErrors) {
        console.log('there')
        console.log(activityDataTemp)
        setActivityData(activityDataTemp)
        setLoading(false)
        setDisableButton(false)
        return
      }

      activityDataTemp = {
        ...activityDataTemp,
        headerText,
        description,
        lotes
      }

      // setLoading(false)
      console.log(activityDataTemp)
      let response = ''
      if (activityId) {
        response = await updateActivity({
          id: activityId,
          data: activityDataTemp,
          eventId
        })
      } else {
        response = await createActivity({ data: activityDataTemp, eventId })
      }

      if (response.status === 200 && response?.data?.success) {
        toast.success('As alterações foram salvas', {
          autoClose: 2000,
          onClose: () => {
            window.location.href = '/atividades-do-evento'
          }
        })
        setDisableButton(false)
        setLoading(false)
      } else if (response.status === 401) {
        toast.error('Você não está logado ou sua sessão expirou')
        setLoading(false)
        setDisableButton(false)
        return false
      } else if (response.status === 500) {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro na requisição ao servidor. Entre em contato com o suporte'
        )
        setLoading(false)
        setDisableButton(false)
        return false
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Ocorreu um erro ao salvar os dados da atividade'
        )
        setDisableButton(false)
        setLoading(false)
        return false
      }
    } catch (error) {
      setDisableButton(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (!!activityId) {
      setLoading(true)
      findActivity()
    }
  }, [activityId])

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Atividades | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    {activityId ? 'Editar' : 'Cadastrar'} atividades do evento
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Loading open={loading} handleClose={handleClose} />
          <Row variant={null}>
            <Container className="pt-4">
              <Container className={`${styles.gridContainer}`}>
                <Row container spacing={4} className="pt-2 pb-2">
                  <Col item xs={12} sm={6}>
                    <Form.Group>
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        className={styles.inputEventozz}
                        variant="outlined"
                        label="Nome"
                        name="name"
                        value={activityData.name}
                        onChange={(e) =>
                          handleChangeTicketType({
                            value: e?.target?.value,
                            field: 'name',
                            indexLote: -1
                          })
                        }
                        isInvalid={!!activityData?.errorMessage?.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {activityData?.errorMessage?.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row container spacing={4} className="pt-4">
                  <Col xs={12} md={6}>
                    <Form.Label>Cabeçalho (Texto acima do preço)</Form.Label>
                    <Editor
                      editorState={activityData.headerText}
                      wrapperClassName={styles.textEditorWrapper}
                      editorClassName={styles.textEditorContent}
                      toolbarClassName={styles.textEditorToolbar}
                      onEditorStateChange={(value) =>
                        handleChangeTicketType({
                          value,
                          field: 'headerText',
                          indexLote: -1
                        })
                      }
                      toolbar={{
                        options: ['inline', 'fontSize', 'list', 'textAlign'],
                        inline: {
                          options: ['bold', 'italic', 'strikethrough']
                        },

                        fontSize: {
                          options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined
                        },
                        list: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ['unordered', 'ordered']
                        },
                        textAlign: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ['left', 'center', 'right', 'justify']
                        }
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Label>Descrição (Texto abaixo do preço)</Form.Label>
                    <Editor
                      editorState={activityData.description}
                      wrapperClassName={styles.textEditorWrapper}
                      editorClassName={styles.textEditorContent}
                      toolbarClassName={styles.textEditorToolbar}
                      onEditorStateChange={(value) =>
                        handleChangeTicketType({
                          value,
                          field: 'description',
                          indexLote: -1
                        })
                      }
                      toolbar={{
                        options: ['inline', 'fontSize', 'list', 'textAlign'],
                        inline: {
                          options: ['bold', 'italic', 'strikethrough']
                        },

                        fontSize: {
                          options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined
                        },
                        list: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ['unordered', 'ordered']
                        },
                        textAlign: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ['left', 'center', 'right', 'justify']
                        }
                      }}
                    />
                  </Col>
                </Row>
                {activityData.lotes?.map((lote, indexLote) => {
                  return (
                    <Card key={indexLote.toString()}>
                      <Card.Body>
                        <Row container spacing={4} className="pt-2">
                          <Col item xs={12} sm={6}>
                            <h5 className={styles.titleRoot}>
                              Lote {indexLote + 1}
                            </h5>
                          </Col>
                          <Col
                            xs={12}
                            sm={3}
                            justifyContent="space-between"
                            alignItems="center"
                            container
                            className={styles.extraPadding}
                          >
                            <Form.Group switch>
                              <Form.Check
                                checked={lote.isFree}
                                label="Gratuito"
                                onChange={(e) =>
                                  handleChangeTicketType({
                                    value: e?.target?.checked,
                                    field: 'isFree',
                                    indexLote
                                  })
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col
                            xs={12}
                            sm={3}
                            justifyContent="space-between"
                            alignItems="center"
                            container
                            className={styles.extraPadding}
                          >
                            <Form.Group switch>
                              <Form.Check
                                checked={lote.active}
                                label="Ativo"
                                onChange={(e) =>
                                  handleChangeTicketType({
                                    value: e?.target?.checked,
                                    field: 'active',
                                    indexLote
                                  })
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col item xs={12} sm={6}>
                            <Form.Group>
                              <Form.Label>Quantidade disponível</Form.Label>
                              <Form.Control
                                className={styles.inputEventozz}
                                type="number"
                                variant="outlined"
                                min={0}
                                label="Quantidade disponível"
                                name="availableQtd"
                                value={lote.availableQtd}
                                onChange={(e) =>
                                  handleChangeTicketType({
                                    value: e?.target?.value,
                                    field: 'availableQtd',
                                    indexLote
                                  })
                                }
                                isInvalid={!!lote?.errorMessage?.availableQtd}
                              />
                              <Form.Control.Feedback type="invalid">
                                {lote?.errorMessage?.availableQtd}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          {!lote.isFree && (
                            <Col item xs={12} sm={6}>
                              <CurrencyInput
                                name="amount"
                                variant="outlined"
                                value={lote.amount}
                                label="Valor"
                                margin="none"
                                handleChange={(value) => {
                                  handleChangeTicketType({
                                    value,
                                    field: 'amount',
                                    indexLote
                                  })
                                }}
                                isInvalid={!!lote?.errorMessage?.amount}
                              />
                              {!!lote?.errorMessage?.amount && (
                                <>
                                  <span className="is-invalid"></span>
                                  <p className="invalid-feedback">
                                    {lote?.errorMessage?.amount}
                                  </p>
                                </>
                              )}
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col item xs={12} sm={6} className="pt-2">
                            <Form.Group>
                              <Form.Label>Válido de</Form.Label>

                              <ReactDatePicker
                                className="form-control"
                                selected={
                                  lote.validFrom ? lote.validFrom : null
                                }
                                onChange={(date) =>
                                  handleChangeTicketType({
                                    value: date,
                                    field: 'validFrom',
                                    indexLote
                                  })
                                }
                                showTimeSelect
                                dateFormat="dd/MM/yyyy HH:mm:ss"
                                locale="pt-BR"
                              />

                              {lote?.errorMessage?.validFrom && (
                                <p className={styles.helperText}>
                                  {lote?.errorMessage?.validFrom}
                                </p>
                              )}
                            </Form.Group>
                          </Col>
                          <Col item xs={12} sm={6} className="pt-2">
                            <Form.Group>
                              <Form.Label>Válido até</Form.Label>

                              <ReactDatePicker
                                className="form-control"
                                selected={lote.validTo ? lote.validTo : null}
                                onChange={(date) =>
                                  handleChangeTicketType({
                                    value: date,
                                    field: 'validTo',
                                    indexLote
                                  })
                                }
                                showTimeSelect
                                dateFormat="dd/MM/yyyy HH:mm:ss"
                                locale="pt-BR"
                              />

                              {lote?.errorMessage?.validTo && (
                                <p className={styles.helperText}>
                                  {lote?.errorMessage?.validTo}
                                </p>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        {!lote.isFree && (
                          <Row>
                            <Col
                              xs={12}
                              className={`${styles.extraPadding} pt-2`}
                            >
                              <Form.Group switch>
                                <Form.Check
                                  checked={lote.showPromotionalFields}
                                  name="showPromotionalFields"
                                  label="Campos de Promoção"
                                  onChange={(e) =>
                                    handleChangeTicketType({
                                      value: e?.target?.checked,
                                      field: 'showPromotionalFields',
                                      indexLote
                                    })
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        )}
                        {!lote.isFree && lote.showPromotionalFields && (
                          <Row>
                            <Col item xs={12} sm={6} md={4}>
                              <Form.Group>
                                <Form.Label>Quantidade promocional</Form.Label>
                                <Form.Control
                                  className={styles.inputEventozz}
                                  type="number"
                                  name="promotionalQtd"
                                  value={lote.promotionalQtd}
                                  onChange={(e) =>
                                    handleChangeTicketType({
                                      value: e?.target?.value,
                                      field: 'promotionalQtd',
                                      indexLote
                                    })
                                  }
                                  isInvalid={
                                    !!lote?.errorMessage?.promotionalQtd
                                  }
                                />

                                <Form.Control.Feedback type="invalid">
                                  {lote?.errorMessage?.promotionalQtd}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col item xs={12} sm={6} md={4}>
                              <Form.Group>
                                <CurrencyInput
                                  name="promotionalAmount"
                                  variant="outlined"
                                  value={lote.promotionalAmount}
                                  margin="none"
                                  label="Valor promocional"
                                  handleChange={(value) =>
                                    handleChangeTicketType({
                                      value,
                                      field: 'promotionalAmount',
                                      indexLote
                                    })
                                  }
                                  isInvalid={
                                    !!lote?.errorMessage?.promotionalAmount
                                  }
                                />

                                {lote?.errorMessage?.promotionalAmount && (
                                  <p className={styles.helperText}>
                                    {lote?.errorMessage?.promotionalAmount}
                                  </p>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                        )}
                        <Row className="pt-4 justify-content-end">
                          <Col
                            xs={12}
                            md={4}
                            className="d-flex justify-content-end"
                          >
                            {lote.isNew &&
                              (activityData.lotes?.length > 1 ||
                                indexLote > 0) && (
                                <Button
                                  variant="danger"
                                  type="button"
                                  color="danger"
                                  onClick={() => handleRemoveLote(indexLote)}
                                  style={{ marginTop: '30px' }}
                                >
                                  Remover lote
                                </Button>
                              )}
                            {indexLote === activityData?.lotes?.length - 1 && (
                              <Button
                                className="mx-3"
                                variant="primary"
                                type="button"
                                onClick={() => handleAddActivityLote()}
                                color="primary"
                                style={{ marginTop: '30px' }}
                              >
                                Adicionar Lote
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )
                })}
              </Container>

              <Row className="pt-4 pb-4 justify-content-end">
                <Col xs={12} md={4} className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    type="button"
                    color="danger"
                    onClick={() => handleCancel()}
                    style={{ marginTop: '30px' }}
                  >
                    Voltar
                  </Button>
                  <CustomButton
                    className="mx-3"
                    variant="primary"
                    type="button"
                    onClick={() => handleSubmit()}
                    color="primary"
                    style={{ marginTop: '30px' }}
                    text="Salvar"
                    disabled={disableButton}
                  />
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  )
}
