import React from 'react'
import { Placeholder } from 'react-bootstrap'

type SkeletonProps = {
  height: number
  xs?: number
}

export const Skeleton = ({ height, xs = 12 }: SkeletonProps) => {
  return (
    <Placeholder as="div" style={{ height: height }} animation="wave">
      <Placeholder
        xs={xs}
        style={{ height: '100%', borderRadius: '0.375rem' }}
      />
    </Placeholder>
  )
}
