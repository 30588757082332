import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button
} from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
import CollapseComponent from '../../components/Table/OrderCollapse'
import { ModalTrashLancamento } from '../../components/modalTrashLancamento'
import {CardIndicador} from '../../components/CardIndicador'

import { 
  formatCurrency,
  formatDateT 
} from '../../util/string'

import servicesIcon1 from '../../assets/images/services-icon/01.png'
import servicesIcon3 from '../../assets/images/services-icon/03.png'

///MODAIS
import Select from 'react-select'
import { listFinancialEvent, eventzzSelect } from '../../store/actions'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import 'react-toastify/dist/ReactToastify.css'

const columns = [
  { label: 'Cod', field: 'id' },
  { label: 'Data', field: 'created_at' },
  { label: 'Descrição', field: 'descricao' },
  { label: 'Valor', field: 'valor_fluxo' }
]

const FluxoDeCaixa = () => {
  const [loading, setLoading] = useState(false)
  const [isRoot, setIsRoot] = useState(false)
  const [idRow, setIdRow] = useState(false)
  const [currentRow, setCurrentRow] = useState([])
  const [showModal, setShowModal] = useState(false)


  const [eventzzList, setEventzzList] = useState([])
  const [listResume, setListResume] = useState({
    totalAmount: 0,
    totalReceiver: 0,
    totalAvailable: 0,
    itsMoney: 0,
    entradas: 0,
    saidas: 0
  })


  const [tableConfig, setTableConfig] = useState({
    options: {
      trash: true,
      more: false,
      download: true
    },
    config: {
      handleTrashButton: 'Excluir'
    },
    filters: {
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 10
  })

  const handleClose = () => {
    setLoading(false)
  }

  const loadDados = async () => {
    if (tableConfig?.filters?.eventId) {
      const response = await listFinancialEvent({
        rowsPerPage: tableConfig.rowsPerPage,
        page: tableConfig.currentPage,
        eventId: tableConfig?.filters?.eventId
      })
      if (response.status === 200) {
        const responseData = response?.data
        const data = responseData?.items
        const total = responseData?.total
        const totalAmount = responseData?.totalAmount
        const totalReceiver = Number(responseData?.totalReceiver)
        const totalAvailable = Number(responseData?.totalAvailable)
        const itsMoney = Number(responseData?.itsMoney)
        const entradas = Number(responseData?.entrada)
        const saidas = Number(responseData?.saida)

        const formattedData = data.map((item) => ({
          ...item,
          valor_fluxo: formatCurrency({ value: item.valor }),
          created_at: formatDateT(item.created_at)
        }))

        setCurrentRow(formattedData)
        setTableConfig((prev) => ({
          ...prev,
          total
        }))
        setListResume({
          totalAmount,
          totalReceiver,
          totalAvailable,
          itsMoney,
          entradas,
          saidas
        })

        console.log("===============")
        console.log({
          totalAmount,
          totalReceiver,
          totalAvailable,
          itsMoney,
          entradas,
          saidas
        })
        console.log("===============")

        setLoading(false)
        return true
      } else {
        toast.error(response?.msg)
        setLoading(false)
        return false
      }
    } else {
      toast.info('Selecione um Evento')
    }
  }

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()

    if (response.isRoot) {
      setIsRoot(true)
    } else {
      setIsRoot(false)
    }
    if (response.status === 200) {
      const responseData = response?.data

      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  function handleChangeEvent(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        eventId: value?.value === 0 ? null : value?.value
      }
    }))
    setLoading(true)
  }

  function handlerAdd() {
    if (tableConfig?.filters?.eventId) {
      window.location.href = `/gestao-de-fluxo/novo/${tableConfig?.filters?.eventId}`;
    } else {
      toast.info('Selecione um Evento')
    }
  }


  async function handlerTrash (row) {
    setIdRow(row?.id)
    setShowModal(true)
  }

  useEffect(() => {
    loadEventzz()
  }, [])

  useEffect(() => {
    loadDados()
  }, [tableConfig?.filters?.eventId])

  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />

      <div className="page-content">
        <MetaTags>
          <title>Fluxo de Caixa | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Fluxo de Caixa | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Fluxo de Caixa
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Card>
              <Row>
                <Col xl={12} md={12} style={{ padding: 20 }}>
                <Row>
                    <CardIndicador 
                      icon={'MONEY'} value={listResume?.totalAmount} type={'money'}
                      text={'Total de Vendas'} color='primary' xl={3} md={6} xs={6}
                    />
                    <CardIndicador 
                      icon={'MONEY'} value={listResume?.entradas} type={'money'}
                      text={'Demais Entradas'} color='primary' xl={3} md={6} xs={6}
                    />
                     <CardIndicador 
                      icon={'MONEY'} value={listResume?.saidas} type={'money'}
                      text={'Saidas'} color='warning' xl={3} md={6} xs={6}
                    />
                      <CardIndicador 
                      icon={'MONEY'} value={listResume.itsMoney + listResume.totalReceiver} type={'money'}
                      text={'Valor Recebido (Eventozz)'} color='primary' xl={3} md={6} xs={6}
                    />
                    <CardIndicador 
                      icon={'MONEY'} value={(listResume.totalAmount + listResume.entradas) - listResume.saidas} type={'money'}
                      text={'Saldo Projetado'} color='primary' xl={3} md={6} xs={6}
                    />
                      <CardIndicador 
                      icon={'MONEY'} value={
                        (listResume.itsMoney + listResume.totalReceiver + listResume.entradas) - listResume.saidas
                      } type={'money'}
                      text={'Saldo Real'} color='success' xl={3} md={6} xs={6}
                    />
                  </Row>
                  <Row>
                    <Col xl={4} md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Eventos</Label>
                        <Select
                          value={
                            eventzzList.find(
                              (a) => a.value === tableConfig?.filters?.eventId
                            )
                              ? eventzzList.find(
                                  (a) =>
                                    a.value === tableConfig?.filters?.eventId
                                )
                              : null
                          }
                          onChange={(value) => handleChangeEvent(value)}
                          options={eventzzList}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={4} md={6}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handlerAdd()}
                        style={{ marginTop: '30px' }}
                      >
                        + Entrada/Saida
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <DatatableTables
                title={'Financeiro'}
                columns={columns}
                rows={currentRow}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
                CollapseComponent={CollapseComponent}
                handlerTrash={handlerTrash}
              />
            </Card>
          </Row>
        </Container>
      </div>
      <ModalTrashLancamento
        onClose={onCloseModal}
        showModal={showModal}
        idRow={idRow}
        loadDados={loadDados}
      />
    </React.Fragment>
  )
}

FluxoDeCaixa.propTypes = {
  t: PropTypes.any
}

export default FluxoDeCaixa
