import React, { useCallback, useState } from 'react'
import { Tr, Td } from 'react-super-responsive-table'

import {
  Collapse,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap'
import classnames from 'classnames'
import { TabSellers } from './tabSellers'

import styles from './styles.module.scss'
import { TabSpecialTickets } from './specialTickets'
import { TabEventOrganizers } from './eventOrganizers'

export const CollapseEventozz = ({ open, tableConfig, row, columns }) => {
  const [toggle, setToggle] = useState('1')

  const handleChange = useCallback(
    (newValue = '1') => {
      setToggle(newValue)
    },
    [row?.id]
  )

  return (
    <Tr>
      <Td
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={columns.length + 1 + (tableConfig?.options?.more ? 1 : 0)}
      >
        <Collapse
          isOpen={open}
          timeout="auto"
          unmountOnExit
          className={styles.tableContainer}
        >
          <Col style={{ margin: 20 }}>
            <Nav tabs style={{ justifyContent: 'center' }}>
              <NavItem>
                <NavLink
                  className={classnames({ active: toggle === '1' })}
                  onClick={() => {
                    handleChange('1')
                  }}
                >
                  Detalhes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: toggle === '2' })}
                  onClick={() => {
                    handleChange('2')
                  }}
                >
                  Vendedores
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: toggle === '3' })}
                  onClick={() => {
                    handleChange('3')
                  }}
                >
                  Ingressos especiais
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: toggle === '4' })}
                  onClick={() => {
                    handleChange('4')
                  }}
                >
                  Organizadores do evento
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={toggle}>
              <TabPane tabId="1">
                <Row>
                  <Col xl={6} md={6} style={{ padding: 10 }}>
                    <Col xl={12} md={12}>
                      <div>
                        <b>SLUG: </b>
                        {row?.slug}
                      </div>
                    </Col>
                    <Col xl={12} md={12}>
                      <div>
                        <b>Data em breve: </b>
                        {row?.commingSoon}
                      </div>
                    </Col>
                    <Col xl={12} md={12}>
                      <div>
                        <b>Inicio das vendas: </b>
                        {row?.startSaleDate}
                      </div>
                    </Col>
                    <Col xl={12} md={12}>
                      <div>
                        <b>Fim das vendas: </b>
                        {row?.endSaleDate}
                      </div>
                    </Col>
                  </Col>
                  <Col xl={6} md={6}>
                    <p>
                      <b>Descrição</b>
                    </p>
                    <div
                      dangerouslySetInnerHTML={{ __html: row?.description }}
                    ></div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col xl={12} md={12} style={{ padding: 10 }}>
                    <TabSellers currentValue={toggle} open={open} row={row} />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col xl={12} md={12} style={{ padding: 10 }}>
                    <TabSpecialTickets
                      currentValue={toggle}
                      open={open}
                      row={row}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col xl={12} md={12} style={{ padding: 10 }}>
                    <TabEventOrganizers
                      currentValue={toggle}
                      open={open}
                      row={row}
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Collapse>
      </Td>
    </Tr>
  )
}
