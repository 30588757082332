import React from 'react'
import { MetaTags } from 'react-meta-tags'

import { Container, Row } from 'reactstrap'
import { EventForm } from '../../../components/eventozz/eventForm'

export const TicketTypes = ({ match }) => {
  const eventId = match?.params?.eventId

  return (
    <Container>
      <MetaTags>
        <title>Ingressos disponíveis | Eventozz</title>
      </MetaTags>
      <Row item xs={12}>
        <EventForm eventId={eventId} ticketsPage={true} />
      </Row>
    </Container>
  )
}
