import axios from 'axios'

async function getToken() {
  let data = window.localStorage.getItem('authUser')
  data = JSON.parse(data)
  return data?.accessToken
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(async (config) => {
  const token = await getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  config.headers['Current-Path'] = window.location.pathname
  return config
})

api.interceptors.response.use(async (response) => {
  // const token = await getToken()
  // if (token) {
  //   response.headers.Authorization = `Bearer ${token}`
  // }
  if (response.headers['is-root'] === '1') {
    response.isRoot = true
  }
  return response
})

// window.localStorage.setItem('isRoot', response?.data?.user?.root)

export { api }
