import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Input,
  FormGroup,
  Label
} from 'reactstrap'
import { toast } from 'react-toastify'

import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
import { debounce } from 'lodash'
///MODAIS
import { ModalConfirmResendPurchaseQrcodes } from '../../components/modalConfirmResendPurchaseQrcodes'
import Select from 'react-select'
import { CardIndicador } from '../../components/CardIndicador'
// import images
import { eventzzSelect } from '../../store/actions'
import { formatCurrency } from '../../util/string'
import './styles.css'

import 'chartist/dist/scss/chartist.scss'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { activityService } from 'services/activity'
import { OrderActivityCollapse } from 'components/Table/OrderActivityCollapse'

registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Cod', field: 'id' },
  { label: 'Data', field: 'saleData' },
  { label: 'Cliente', field: 'customerName' },
  { label: 'Valor', field: 'formattedValue' },
  { label: 'Valor Bruto', field: 'formattedValueBruto' },
  { label: 'Parcelas', field: 'installments' },
  { label: 'Evento', field: 'eventName' },
  { label: 'Forma Pagamento', field: 'paymentMethod' },
  { label: 'Status', field: 'statusDescription' }
]

const statusTransactionList = [
  { label: 'Todos', value: null },
  { label: 'Pago', value: 'paid' },
  { label: 'Pendente', value: 'pending' },
  { label: 'Falha', value: 'failed' }
]

export const VendasAtividades = () => {
  const { activityPurchaseList, loadActivityFinancialDetails } = activityService

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const [
    showModalConfirmResendPurchaseQrcodes,
    setShowModalConfirmResendPurchaseQrcodes
  ] = useState(false)
  const [ticketsTypeResume, setTicketsTypeResume] = useState([])
  const [salesPaymentTypeResume, setSalesPaymentTypeResume] = useState([])

  const [eventzzList, setEventzzList] = useState([])
  const [startSaleDate, setStartSaleDate] = useState(null)
  const [endSaleDate, setEndSaleDate] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')

  const [listResume, setListResume] = useState({
    count: 0,
    countTickets: 0,
    totalAmount: 0,
    totalBruto: 0,
    ticketMedio: 0,
    openValue: 0
  })

  const handleClose = () => {
    setLoading(false)
  }
  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: true,
      order: true,
      more: true,
      pagination: true,
      resendPurchase: true
    },
    config: {
      resendPurchaseText: 'Reenviar Qr codes da compra'
    },
    filters: {
      searchTerm: null,
      statusTransaction: 'paid',
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const loadEventFinancialDetails = useCallback(
    async (eventId) => {
      const response = await loadActivityFinancialDetails({
        eventId,
        statusTransaction: tableConfig?.filters?.statusTransaction,
        searchTerm: tableConfig?.filters?.searchTerm,
        startSaleDate: tableConfig?.filters?.startSaleDate,
        endSaleDate: tableConfig?.filters?.endSaleDate
      })

      if (response.status === 200) {
        const responseData = response?.data?.data
        console.log('---------------------------')
        console.log(responseData)

        const ticketsByType = responseData?.ticketsByType
        const salesByPaymentType = responseData?.salesByPaymentType

        setTicketsTypeResume(ticketsByType)
        setSalesPaymentTypeResume(salesByPaymentType)

        return true
      } else {
        toast.error(response?.msg)
        return false
      }
    },
    [tableConfig?.filters, eventzzList]
  )

  const loadOrders = useCallback(async () => {
    setLoading(true)

    loadEventFinancialDetails(tableConfig?.filters?.eventId)

    const response = await activityPurchaseList({
      rowsPerPage: tableConfig.rowsPerPage,
      page: tableConfig.currentPage,
      searchTerm: tableConfig?.filters?.searchTerm,
      startSaleDate: tableConfig?.filters?.startSaleDate,
      endSaleDate: tableConfig?.filters?.endSaleDate,
      eventId: tableConfig?.filters?.eventId,
      statusTransaction: tableConfig?.filters?.statusTransaction
    })

    if (response?.status === 200) {
      const responseData = response?.data?.data
      console.log('---------------------------')
      console.log(responseData)

      const total = responseData?.total
      const totalAmount = responseData?.totalAmount
      const totalBruto = responseData?.totalBruto
      const ticketMedio = responseData?.ticketMedio
      const openValue = responseData?.openValue
      const totalTickets = Number(responseData?.totalTickets)
      //////////////////////////////////////////
      const formattedData = responseData.items.map((item) => ({
        ...item,
        installments: item.installments ? item.installments : 1,
        formattedValue: formatCurrency({ value: item.value }),
        formattedValueBruto: formatCurrency({ value: item.bruto }),
        statusDescription:
          item.statusDescription === 'paid'
            ? 'Pago'
            : item.statusDescription === 'failed'
            ? 'Falha'
            : item.statusDescription === 'pending'
            ? 'Pendente'
            : '-',
        seller: item.seller ? item.seller : ''
      }))
      setRows(formattedData)

      setTableConfig((prev) => ({
        ...prev,
        total
      }))

      setListResume({
        count: total,
        countTickets: totalTickets,
        totalAmount,
        totalBruto,
        ticketMedio,
        openValue
      })
      setLoading(false)
    } else {
      toast.error(response)
      setLoading(false)
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()
    if (response.status === 200) {
      const responseData = response?.data

      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  const onCloseModalConfirmResendPurchaseQrcodes = () => {
    setCurrentRow(null)
    setShowModalConfirmResendPurchaseQrcodes(false)
  }

  function handleSearch(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        searchTerm: value
      }
    }))
  }

  function handleClearFilters() {
    setSearchTerm('')
    setStartSaleDate('')
    setEndSaleDate('')
    setTableConfig((prev) => ({
      ...prev,
      total: 0,
      currentPage: 0,
      rowsPerPage: 20,
      filters: {
        ...prev.filters,
        searchTerm: null,
        statusTransaction: 'paid',
        paidSale: false,
        startSaleDate: null,
        endSaleDate: null,
        sellerId: null,
        eventId: null,
        plataforma: null
      }
    }))
    setTicketsTypeResume(null)
    setSalesPaymentTypeResume(null)
    setRows([])
  }

  function handleResendPurchaseTickets(row) {
    if (row?.id && row?.eventId && Number(row?.status) === 1) {
      setCurrentRow(row)
      setShowModalConfirmResendPurchaseQrcodes(true)
    } else {
      toast.error('Não foi possível reenviar os qr codes desta compra')
    }
  }

  function callbackSendTicketQrcode() {
    loadOrders()
  }

  function handleChangeTransaction(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        statusTransaction: value?.value
      }
    }))
  }

  function handleChangeEvent(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        eventId: value?.value === 0 ? null : value?.value
      }
    }))
  }

  function handleChangeDate(date, field) {
    console.log(date)

    const formattedDate = new Intl.DateTimeFormat('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(date)

    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        [field]: date ? formattedDate : null
      }
    }))
  }

  const deboundChange = useCallback(debounce(handleSearch, 1000), [])

  const deboundDateChange = useCallback(debounce(handleChangeDate, 2000), [])

  useEffect(() => {
    if (tableConfig.filters.eventId) {
      loadOrders()
    }
  }, [tableConfig.rowsPerPage, tableConfig.currentPage, tableConfig.filters])

  useEffect(() => {
    loadEventzz()
  }, [])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}

      <div className="page-content">
        <MetaTags>
          <title>Atividades do evento | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Atividades do evento | Eventozz</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Vendas de atividades do evento
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Card>
              <Row>
                <Col xl={12} md={12} style={{ padding: 20 }}>
                  <Row>
                    <CardIndicador
                      icon={'LINE'}
                      value={listResume?.count}
                      type={'number'}
                      text={'Vendas'}
                      color="primary"
                      xl={3}
                      md={6}
                      xs={6}
                    />
                    <CardIndicador
                      icon={'LINE'}
                      value={listResume?.countTickets}
                      type={'number'}
                      text={'Inscrições'}
                      color="primary"
                      xl={3}
                      md={6}
                      xs={6}
                    />
                    <CardIndicador
                      icon={'MONEY'}
                      value={listResume?.ticketMedio}
                      type={'money'}
                      text={'Ticket Médio'}
                      color="primary"
                      xl={3}
                      md={6}
                      xs={6}
                    />
                    <CardIndicador
                      icon={'MONEY'}
                      value={listResume?.totalBruto}
                      type={'money'}
                      text={'Valor Bruto'}
                      color="primary"
                      xl={3}
                      md={6}
                      xs={6}
                    />
                    <CardIndicador
                      icon={'MONEY'}
                      value={listResume?.totalAmount}
                      type={'money'}
                      text={'Valor Liquido'}
                      color="primary"
                      xl={3}
                      md={6}
                      xs={6}
                    />
                    <CardIndicador
                      icon={'MONEY'}
                      value={listResume?.openValue}
                      type={'money'}
                      text={'Valor Liberado'}
                      color="primary"
                      xl={3}
                      md={6}
                      xs={6}
                    />
                  </Row>
                  <Row>
                    {salesPaymentTypeResume?.map((item) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <CardIndicador
                          icon={item?.name}
                          value={item?.totalAmount}
                          type={'money'}
                          text={`${
                            item?.name === 'cc'
                              ? 'Cartão de Crédito'
                              : item?.name
                          }`}
                          color="secondary"
                          xl={3}
                          md={6}
                          xs={6}
                          value2={item?.quantity}
                        />
                      )
                    })}
                  </Row>
                  <Row>
                    {ticketsTypeResume?.map((item) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <CardIndicador
                          icon={'TICKETS'}
                          value={item?.quantity}
                          type={'number'}
                          text={item?.name || ''}
                          color="secondary"
                          xl={3}
                          md={6}
                          xs={6}
                        />
                      )
                    })}
                  </Row>
                  <Row>
                    <Col xl={3} md={4}>
                      <FormGroup>
                        <Label for="exampleEmail">Evento</Label>
                        <Select
                          value={
                            eventzzList.find(
                              (a) => a.value === tableConfig?.filters?.eventId
                            )
                              ? eventzzList.find(
                                  (a) =>
                                    a.value === tableConfig?.filters?.eventId
                                )
                              : null
                          }
                          onChange={(value) => handleChangeEvent(value)}
                          options={eventzzList}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={4}>
                      <FormGroup>
                        <Label for="exampleEmail">Início</Label>
                        <ReactDatePicker
                          className="form-control"
                          selected={startSaleDate}
                          onChange={(date) => {
                            deboundDateChange(date, 'startSaleDate')
                            setStartSaleDate(date)
                          }}
                          placeholderText="dd/mm/aaaa"
                          dateFormat="dd/MM/yyyy"
                          locale="pt-BR"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={4}>
                      <FormGroup>
                        <Label for="exampleEmail">Fim</Label>
                        <ReactDatePicker
                          className="form-control"
                          selected={endSaleDate}
                          onChange={(date) => {
                            deboundDateChange(date, 'endSaleDate')
                            setEndSaleDate(date)
                          }}
                          placeholderText="dd/mm/aaaa"
                          dateFormat="dd/MM/yyyy"
                          locale="pt-BR"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={4}>
                      <FormGroup>
                        <Label for="exampleEmail">Pesquisar</Label>
                        <Input
                          type="text"
                          name="search"
                          id="search"
                          value={searchTerm}
                          onChange={(e) => {
                            setSearchTerm(e.target.value)
                            deboundChange(e.target.value)
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={4}>
                      <FormGroup>
                        <Label for="exampleEmail">Status</Label>
                        <Select
                          value={
                            statusTransactionList.find(
                              (a) =>
                                a.value ===
                                tableConfig?.filters?.statusTransaction
                            )
                              ? statusTransactionList.find(
                                  (a) =>
                                    a.value ===
                                    tableConfig?.filters?.statusTransaction
                                )
                              : null
                          }
                          onChange={(value) => handleChangeTransaction(value)}
                          options={statusTransactionList}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      xl={6}
                      md={8}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleClearFilters()}
                        style={{ marginTop: '30px' }}
                      >
                        Limpar filtros
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <DatatableTables
                title={'Ultimas Compras'}
                columns={columns}
                rows={rows}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
                CollapseComponent={OrderActivityCollapse}
                onResendPurchaseTickets={handleResendPurchaseTickets}
                callback={callbackSendTicketQrcode}
              />
            </Card>
          </Row>
        </Container>
      </div>
      <ModalConfirmResendPurchaseQrcodes
        onClose={onCloseModalConfirmResendPurchaseQrcodes}
        row={currentRow}
        showModal={showModalConfirmResendPurchaseQrcodes}
        type="activity"
      />
    </React.Fragment>
  )
}
