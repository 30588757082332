import React, { useState } from 'react'
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input,
  Label,
  FormFeedback,
  FormGroup
} from 'reactstrap'

import { Link } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm } from 'availity-reactstrap-validation'

// import images
import logoSm from '../../assets/images/logos/Icon_Com_Fundo.svg'
import { isValidEmail } from '../../util/string'
import { toast } from 'react-toastify'
import Services from '../../services/auth'

const ForgetPasswordPage = () => {
  const [email, setEmail] = useState('')
  const [errorEmail, setErrorEmail] = useState('')
  const [showFinalMessage, setShowFinalMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleRecoveryPassword = async () => {
    if (!isValidEmail(email)) return toast.error('Informe um email válido')
    setLoading(true)
    const response = await Services.passwordRecovery({ email })
    setLoading(false)
    console.log(response)
    if (response.status === 200) {
      setShowFinalMessage(true)
      toast.success(response.data.msg, {
        autoClose: 6000
      })
    } else {
      if (response?.response?.data?.msg) {
        setErrorMessage(response.response.data.msg)
        toast.error(response.response.data.msg, {
          autoClose: 6000
        })
      } else {
        setErrorMessage('Não foi possível recuperar a senha no momento')
        toast.error('Não foi possível recuperar a senha no momento', {
          autoClose: 6000
        })
      }
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 p-2">
                      Recuperar Senha
                    </h5>
                    <a href="index.html" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </a>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="alert alert-success mt-5" role="alert">
                    Digite seu e-mail e as instruções serão enviadas para você!
                  </div>

                  <AvForm className="form-horizontal mt-4">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="email@email.com">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          placeHolder="Entrar com email completo"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          invalid={!!errorEmail}
                        />
                        <FormFeedback>{errorEmail}</FormFeedback>
                      </FormGroup>
                    </div>

                    <Row className="mb-3">
                      <Col className="text-end">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                          onClick={() => handleRecoveryPassword()}
                        >
                          Reset
                        </button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  lembra-se ?{' '}
                  <Link to="login" className="fw-medium text-primary">
                    {' '}
                    logar na conta{' '}
                  </Link>{' '}
                </p>
                <p>
                  © {new Date().getFullYear()} Eventozz. Desenvolvido por Ctech
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ForgetPasswordPage
