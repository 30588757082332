import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import { Doughnut, Pie, Bar } from 'react-chartjs-2';
import { formatCurrency, formatDateIso } from '../../util/string';


export const Graph = ({
  data,
  labels,
  type,
  height,
  width
}) => {

  return (
    <>
    {
      type === 'Doughnut' ? (
        <Doughnut
        options={{
          responsive: true,
          maintainAspectRatio: true
        }}
        width={width} height={height} 
        data={{
          labels,
          datasets: [
            {
            label: 'Valor:',
            data,
            backgroundColor: [
              '#333547de',
              '#009688c2'
            ],
            hoverOffset: 4
          }
          ]
        }}
      />
      ) : (
        type === 'Vertical' ? (
          <Bar
              options={{
                responsive: true,
                plugins: {
                  labels: {
                    render: item => {
                      return isNaN(item.percentage) ? '' : `${item.percentage  }%`;
                    }
                  },
                  legend: {
                    labels: {
                        filter (item, chart) {
                            return false;
                        }
                    }
                  }                  
               }
              }}
              width={width} height={height} 
              data={{
                labels,
                datasets: [
                  {
                  label: 'Valor:',
                  data,
                  backgroundColor: [
                    '#333547de',
                    '#333547de',
                    '#333547de',
                    '#333547de',
                    '#333547de',
                    '#333547de',
                    '#333547de'
                  ],
                  hoverOffset: 4,
                  datalabels: {
                    anchor: 'end',
                    display: 'auto',
                    color: '#000',
                    labels: {
                        title: {
                            font: {
                                weight: 'bold',
                                size: 15
                            }
                        }
                    },
                    formatter (value, ctx) {
                      console.log(value)
                      const datasets = ctx.chart.data.datasets;
                      if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                        const sum = datasets[0].data.reduce((a, b) => a + b, 0);
                        const percentage = `${Math.round((value / sum) * 100)  }%`;
                        return percentage;
                      } else {
                        return '%';
                      }
                    }
                  }
                }
                ]
              }}
            />
        ) : (<></>))
    }
    </>
  )
}

