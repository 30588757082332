import React from 'react'

import { Col, Card } from 'reactstrap'
import Money from '../../assets/images/services-icon/money.png'
import Graph from '../../assets/images/services-icon/graph.png'
import Line from '../../assets/images/services-icon/line.png'
import Tickets from '../../assets/images/services-icon/tickets.png'
import CC from '../../assets/images/services-icon/cc.png'
import Pix from '../../assets/images/services-icon/pix.png'
import Faturado from '../../assets/images/services-icon/faturado.png'
import Boleto from '../../assets/images/services-icon/boleto.png'
import Dinheiro from '../../assets/images/services-icon/dinheiro.png'
import { formatCurrency } from '../../util/string'

export const CardIndicador = ({
  icon,
  value,
  text,
  color,
  xl,
  md,
  xs,
  type,
  value2 = null
}) => {
  const styles = {
    col: {
      paddingLeft: 4,
      paddingRight: 4
    },
    card: {
      backgroundColor:
        color === 'secondary'
          ? '#336963d9'
          : color === 'warning'
          ? '#df0b17ba'
          : color === 'success'
          ? '#009688c2'
          : '#333547de',
      padding: 10,
      marginBottom: 10
    },
    cima: { width: '100%', display: 'flex', alignItems: 'center' },
    logo: { width: color === 'secondary' ? 18 : 22 },
    baixo: {
      width: '100%',
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    indicador: {
      marginBottom: 0,
      display: 'flex',
      marginRight: 5,
      color: 'white',
      fontWeight: 500,
      fontSize: color === 'secondary' ? 14 : 16,
      textAlign: 'end',
      justifyContent: 'flex-end'
    },
    value2: {
      marginBottom: 0,
      marginRight: 5,
      color: 'white',
      fontWeight: 300,
      fontSize: color === 'secondary' ? 10 : 14,
      textAlign: 'end'
    },
    label: {
      width: '80%',
      marginBottom: 0,
      color: 'white',
      fontWeight: 300,
      fontSize: color === 'secondary' ? 8 : 10
    },
    labelDiv: {
      width: '80%',
      display: 'flex',
      marginLeft: 5,
      justifyContent: value2 ? 'space-between' : 'flex-start'
    }
  }

  const iconFunction = (name) => {
    switch (name) {
      case 'MONEY':
        return Money
      case 'TICKETS':
        return Tickets
      case 'GRAPH':
        return Graph
      case 'LINE':
        return Line
      case 'CC':
        return CC
      case 'BOLETO':
        return Boleto
      case 'PIX':
        return Pix
      case 'FATURADO':
        return Faturado
      case 'DINHEIRO':
        return Dinheiro
      default:
        return Tickets
    }
  }

  return (
    <Col xl={xl} md={md} xs={xs} style={styles.col}>
      <Card style={styles.card}>
        <div style={styles.cima}>
          <img src={iconFunction(icon)} style={styles.logo} />
          <div style={styles.labelDiv}>
            <h5 style={styles.label}>{text.toUpperCase()}</h5>
            {value2 && <h5 style={styles.value2}>{value2} </h5>}
          </div>
        </div>
        <div style={styles.baixo}>
          <h4 style={styles.indicador}>
            {type === 'money' ? formatCurrency({ value }) : value}
          </h4>
        </div>
      </Card>
    </Col>
  )
}
