import React, { useCallback, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import { Loading } from '../Loading'
import './styles.css'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { userService } from 'services/user'
import { Select } from 'components/select'
import { eventzzSelect } from 'store/actions'
import { SelectOption } from 'types/global'

export const ModalAddEventToAppUser = ({
  show,
  onSubmit,
  onClose,
  currentUser
}) => {
  const { updatePermissionsUserApp, getUserAppEvents } = userService
  const [loading, setLoading] = useState(false)
  const [eventzzList, setEventzzList] = useState<SelectOption[]>([])

  const [events, setEvents] = useState([])

  const handleChange = (value) => {
    setEvents(value)
  }
  const clearFields = () => {
    setEvents([])
  }

  const handleSubmitAddUser = async () => {
    try {
      console.log({ events })
      setLoading(true)
      const response = await updatePermissionsUserApp({
        id: currentUser.id,
        events
      })

      if (response.status === 200) {
        toast.success(
          response?.msg ? response?.msg : 'Permissões adicionadas/atualizadas'
        )
        onSubmit()
        clearFields()
        setLoading(false)
      } else {
        console.log('=============')
        toast.error(
          response?.msg ||
            'Ocorreu um erro ao tentar adicionar/atualizar as permissões'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const handleCancelAddUser = () => {
    clearFields()
    onClose()
  }

  const handleChangeEvent = (value) => {
    console.log({ value })
    if (value) {
      setEvents(value?.map((item) => item.value))
    } else {
      setEvents([])
    }
  }

  const loadEventzz = useCallback(async () => {
    const response = await eventzzSelect()
    if (response.status === 200) {
      const responseData: SelectOption[] = response?.data
      console.log({
        responseData
      })

      setEventzzList(responseData)
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [])

  const loadUserEvents = useCallback(async () => {
    const response = await getUserAppEvents(currentUser?.id)
    if (response.status === 200) {
      const responseData: SelectOption[] = response?.data
      console.log({
        responseData,
        here: responseData.map((item) => item.value)
      })

      setEvents(responseData.map((item) => item.value))
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [currentUser])

  useEffect(() => {
    if (currentUser && show) {
      loadUserEvents()
    }
  }, [currentUser])

  useEffect(() => {
    loadEventzz()
  }, [])

  return (
    <ReactModal
      isOpen={show}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container>
        <Loading open={loading} />

        <form autoComplete="off">
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>
                Adicionar usuário para o aplicativo
              </h1>
            </Col>
          </Row>
          <Row>
            <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
              <FormGroup>
                <Form.Label>Usuário</Form.Label>
                <Form.Control
                  type="text"
                  name="userName"
                  value={currentUser?.userName}
                  autoComplete="off"
                  placeholder="Ex.: user2023"
                  disabled
                  onChange={(event) =>
                    handleChange({
                      value: event.target.value,
                      field: 'userName'
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col item xs={12} md={6} style={{ marginBottom: 20 }}>
              <FormGroup>
                <Form.Label>Eventos</Form.Label>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={(value) => handleChangeEvent(value)}
                  options={eventzzList}
                  classNamePrefix="select2-selection"
                  placeholder="Selecione..."
                  defaultValue={events}
                  value={events}
                  hasErrors={false}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className="footerModalButtons">
            <Col xs={6}>
              <Button onClick={handleCancelAddUser} variant="secondary">
                Cancelar
              </Button>
            </Col>
            <Col xs={6}>
              <Button onClick={handleSubmitAddUser} variant="primary">
                {currentUser?.id ? 'Atualizar' : 'Adicionar'}
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
